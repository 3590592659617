import { request } from "./Request";
import { routes } from "../constants/constant.route";
import { urlConstants } from "../utils/urlConstants";
// import { routes } from '../constants/constantAPIRoute'

const { API_URL } = urlConstants;
const { STUDENT_GOAL, GOAL, STUDENT_HISTORY, PARENT_HISTORY, PARENT_GET_GOAL_BY_STUDENT, PARENT_GET_STUDENT_COURSE, STUDENT_PDF_REFLECTION, STUDENT_SAVE_REFLECTION} = routes.STUDENT;
const { PARENT_GET_STUDENTS } = routes.LADDER;

export default class StudentGoalService {
  //    static requirement(body){
  //        return request('POST', `${API_URL}${routes.studentgoal.requirement}` , null , body , null )
  //    }
  //    static getGoal(schoolValue, studentValue){
  //        return request('GET',`${API_URL}${routes.studentgoal.studentgoal}?school=${schoolValue}&student=${studentValue}`);
  //    }
  static goal(goalId) {
    return request(
      "GET",
      `${API_URL}${GOAL}/${goalId}`,
      null,
      null,
      null,
      null
    );
  }

  static getGoal(schoolValue, studentValue) {
    return request(
      "GET",
      `${API_URL}${STUDENT_GOAL}?school_id=${schoolValue}&student_id=${studentValue}`
    );
  }

  static getPdfReflection(obj) {
    return request(
      "GET",
      `${API_URL}${STUDENT_PDF_REFLECTION}?user_id=${obj.user_id}`
    );
  }

  static getStudentHistoryData = (body) => {
    return request('POST', `${API_URL}${STUDENT_HISTORY}`, null, body, null, null, null)
  }

  static saveReflection = (body) => {
    return request('PUT', `${API_URL}${STUDENT_SAVE_REFLECTION}`, null, body, null, null, null)
  }

  // Parent API

  static getParentHistoryData = (body) => {
    return request('POST', `${API_URL}${PARENT_HISTORY}`, null, body, null, null, null)
  }

  static getParentRoleStudentListbyGoal(queryValue) {
    let { goal_id } = queryValue;
    return request("GET", `${API_URL}${PARENT_GET_STUDENTS}?goal_id=${goal_id}`, null, null, null, null);
  }

  static getParentGoalByStudent(queryValue) {
    let { school, student } = queryValue;
    return request("GET", `${API_URL}${PARENT_GET_GOAL_BY_STUDENT}?school_id=${school}&student_id=${student}`, null, null, null, null);
  }

  static getParentStudentCourse(queryValue) {
    let { goal_id, school_id, student_id } = queryValue;
    return request("GET", `${API_URL}${PARENT_GET_STUDENT_COURSE}?goal_id=${goal_id}&school_id=${school_id}&student_id=${student_id}`, null, null, null, null);
  }
}
