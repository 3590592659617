import React, { Fragment, useState, useEffect, useRef } from "react";
import { faTimes, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./GoalChange.css";

const GoalChange = ({ setOpenGoalChange }) => {
  // const counselorRef = useRef();
  // const timeoutRef = useRef();
  // const activitiesRef = useRef();
  // const athleticsRef = useRef();

  const [counselor, setCounselor] = useState();
  const [goalChange, setGoalChange] = useState();
  const [messageText, setMessageText] = useState();

  // const [password, setPassword] = useState();
  // const [confirmPassword, setConfirmPassword] = useState();
  // const [showCounselorContainer, setShowCounselorContainer] = useState(false);
  // const [showActivitiesContainer, setShowAtivitiesContainer] = useState(false);
  // const [activitiesList, setActivitiesList] = useState();
  // const [selectedActivities, setSelectedActivities] = useState([]);
  // const [showAthleticsContainer, setShowAthleticsContainer] = useState(false);
  // const [athleticsList, setAthleticsList] = useState();
  // const [selectedAthletics, setSelectedAthletics] = useState([]);
  // const [counselorList, setCounselorList] = useState([]);
  // const [counselorName, setCounselorName] = useState();
  // const [email, setEmail] = useState();
  // const [parentEmail, setParentEmail] = useState();
  // const [recoveryEmail, setRecoveryEmail] = useState();
  // const [mobile, setMobile] = useState();
  // const [parentMobile, setParentMobile] = useState();
  // const [parentName, setParentName] = useState();
  // const [activities, setActivities] = useState();
  // const [athletics, setAthletics] = useState();
  // const [selectedCounselor, setSelectedCounselor] = useState();
  // const [firstInFamily, setFirstInFamily] = useState(false);
  // const [user, setUser] = useState(
  //   localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  // );
  // const [showPasswordError, setShowPasswordError] = useState(false);

  // const getCounselorName = (value) => {
  //   GetStudentService.getCounselorName(value, "counselor", user?.school_id)
  //     .then((data) => {
  //       console.log("data from the service", data.data);
  //       setCounselorList(data.data.objects);
  //     })
  //     .catch((error) => {
  //       console.log("error from the service", error);
  //     });
  // };

  // const counselorHandler = () => {
  //   clearTimeout(timeoutRef.current);
  //   if (!counselorName) {
  //     setShowCounselorContainer(false);
  //     setCounselorList([]);
  //   }
  //   setCounselorName(counselorRef.current.value);
  //   timeoutRef.current = setTimeout(() => {
  //     getCounselorName(counselorRef.current.value);
  //   }, 1000);
  //   setShowCounselorContainer(true);
  // };

  // const handleCounselorSelect = (item) => {
  //   setCounselorName(item?.counselor?.name);
  //   setSelectedCounselor(item?.counselor);
  //   setCounselorList([]);
  //   setShowCounselorContainer(false);
  // };

  // const passwordValidation = (value) => {
  //   if (password.length < 8) {
  //     setShowPasswordError(true);
  //   } else if (password.length >= 8) {
  //     setShowPasswordError(false);
  //   }
  // };

  // const handleActivitiesChange = () => {
  //   clearTimeout(timeoutRef.current);
  //   if (!activities) {
  //     setShowAtivitiesContainer(false);
  //     setActivitiesList([]);
  //   }
  //   setActivities(activitiesRef.current.value);
  //   timeoutRef.current = setTimeout(() => {
  //     getActivities(activitiesRef.current.value);
  //   }, 1000);
  //   setShowAtivitiesContainer(true);
  // };

  // const handleAthleticsChange = () => {
  //   clearTimeout(timeoutRef.current);
  //   if (!athletics) {
  //     setShowAthleticsContainer(false);
  //     setAthleticsList([]);
  //   }
  //   setAthletics(athleticsRef.current.value);
  //   timeoutRef.current = setTimeout(() => {
  //     getAthletics(athleticsRef.current.value);
  //   }, 1000);
  //   setShowAthleticsContainer(true);
  // };

  // const getActivities = (value) => {
  //   GetStudentService.getActivitiesList(value)
  //     .then((data) => {
  //       console.log("data from the service", data);
  //       setActivitiesList(data?.data?.objects);
  //     })
  //     .catch((error) => {
  //       console.log("error from the service", error);
  //     });
  // };

  // const getAthletics = (value) => {
  //   GetStudentService.getAthleticsList(value)
  //     .then((data) => {
  //       console.log("data from the service", data);
  //       setAthleticsList(data?.data?.objects);
  //     })
  //     .catch((error) => {
  //       console.log("error from the service", error);
  //     });
  // };

  // const handleSelectedActivities = (item) => {
  //   setActivitiesList([]);
  //   setShowAtivitiesContainer(false);
  //   activitiesRef.current.value = null;
  //   setActivities();
  //   setSelectedActivities([...selectedActivities, item]);
  // };

  // const handleSelectedAthletics = (item) => {
  //   setAthleticsList([]);
  //   setShowAthleticsContainer(false);
  //   athleticsRef.current.value = null;
  //   setAthletics();
  //   setSelectedAthletics([...selectedAthletics, item]);
  // };

  // const removeSelectedActivities = (value) => {
  //   let filteredValue = selectedActivities?.filter((item) => item !== value);
  //   setSelectedActivities(filteredValue);
  // };

  // const removeSelectedAthletics = (value) => {
  //   let filteredValue = selectedAthletics?.filter((item) => item !== value);
  //   setSelectedAthletics(filteredValue);
  // };

  // const saveButtonHandler = () => {
  //   let activitiesId = selectedActivities?.map((item) => item.id);
  //   let athleticsId = selectedAthletics?.map((item) => item.id);
  //   let obj = {
  //     student_opt_out: true,
  //     selectedactivities_obj: selectedActivities,
  //     selectedactivities: activitiesId,
  //     selectedathletics_obj: selectedAthletics,
  //     selectedathletics: athleticsId,
  //     counselor: [selectedCounselor?.id],
  //     current_grade: grade,
  //     first_name: firstName,
  //     last_name: lastName,
  //     email: email,
  //     parent_email: parentEmail,
  //     recovery_email: recoveryEmail,
  //     mobile: mobile,
  //     parent_mobile: parentMobile,
  //     parent_name: parentName,
  //     student_first_gen: firstInFamily,
  //     password: password,
  //     password_confirm: confirmPassword,
  //     school: user?.school_id,
  //     type: "student",
  //   };
  //   GetStudentService.saveStudent(obj)
  //     .then((data) => {
  //       console.log("data from the service", data);
  //       setSelectedAthletics([]);
  //       setSelectedActivities([]);
  //       setSelectedCounselor([]);
  //       setGrade();
  //       setFirstInFamily(false);
  //       setFirstName();
  //       setLastName();
  //       setEmail();
  //       setRecoveryEmail();
  //       setParentEmail();
  //       setMobile();
  //       setParentMobile();
  //       setPassword();
  //       setConfirmPassword();
  //       setParentName();
  //       setOpenCreateMessage(false);
  //     })
  //     .catch((error) => {
  //       console.log("error from the service", error, error?.response);
  //       swal({
  //         text: error?.response?.data?.message,
  //         icon: "warning",
  //         dangerMode: true,
  //         buttons: {
  //           cancel: true,
  //           confirm: true,
  //         },
  //         closeOnClickOutside: true,
  //       }).then((value) => {
  //         console.log("value", value);
  //       });
  //     });
  //};

  return (
    <Fragment>
      <div className="goal-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontSize: "18px", marginLeft: "28px" }}>
            Request to change goal
          </p>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ opacity: "0.2", cursor: "pointer" }}
            onClick={() => setOpenGoalChange(false)}
          />
        </div>
        <div style={{ padding: "0 30px" }}>
          {/* <div
              className="flex"
              style={{ marginTop: "10px", justifyContent: "space-between" }}
            > */}
          {/* <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Counselor
              </p>
              <input
                type="text"
                style={{ width: "75%" }}
                placeholder="Type the counselor name"
                ref={counselorRef}
                onChange={counselorHandler}
                value={counselorName}
              /> */}
          {/* </div> */}
          {/* {showCounselorContainer && (
              <ul className="maintain-group-student-dropdown-menu">
                {showCounselorContainer &&
                  counselorList?.length > 0 &&
                  counselorList.map((item, index) => (
                    <li
                      onClick={() => handleCounselorSelect(item)}
                      key={index}
                      className="flex maintain-group-student-list cursor"
                    >
                      <span>{item.counselor.name}</span>
                    </li>
                  ))}
              </ul>
            )} */}
          <div
            className="flex"
            style={{
              marginTop: "15px",
              position: "relative",
            }}
          >
            <p
              style={{ width: "40px", fontSize: "14px" }}
              className="bold no-margin-bottom"
            >
              To :
            </p>
            <select
              onChange={(e) => setCounselor(e.target.value)}
              style={{ width: "75%", height: "30px" }}
            >
              <option disabled selected value={null}>
                Select Counselor
              </option>
              {/* {gradeData.map((item, index) => {
                  console.log("grade data frm item", item);
                  return (
                    <option
                      style={{ textTransform: "capitalize" }}
                      value={item?.value}
                    >
                      {item?.name}
                    </option>
                  );
                })} */}
            </select>
            {/* <FontAwesomeIcon
                style={{ fontSize: "12px", position: "absolute", right: "5px" }}
                icon={faAsterisk}
                color="red"
              /> */}
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          {/* <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                borderBottom: "1px solid #e5e5e5",
              }}
            >
              Personal information
            </p> */}
          <div style={{ padding: "0 30px" }}>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                position: "relative",
              }}
            >
              <p
                style={{ fontSize: "14px", marginBottom: "10px" }}
                className="bold no-margin-bottom"
              >
                Goal Change :
              </p>
              <input
                type="text"
                style={{ width: "115%", borderRadius: "5px", padding: "5px" }}
                placeholder="Type a goal change keyword"
                value={goalChange}
                onChange={(e) => setGoalChange(e.target.value)}
              />
            </div>

            <div
              className="flex"
              style={{
                marginTop: "15px",
                //   justifyContent: "space-between",
                position: "relative",
              }}
            >
              <textarea
                rows={10}
                cols={10}
                type="text"
                style={{
                  width: "115%",
                  borderRadius: "5px",
                  borderColor: "black",
                }}
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/*   
                {showActivitiesContainer && (
                  <div style={{ width: "75%", marginLeft: "25%" }}>
                    <ul className="maintain-group-student-dropdown-menu">
                      {activitiesList?.length > 0 &&
                        activitiesList.map((item, index) => (
                          <li
                            onClick={() => handleSelectedActivities(item)}
                            key={index}
                            className="flex maintain-group-student-list cursor"
                          >
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )} */}
        {/* <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "5px",
                  }}
                >
                  {selectedActivities &&
                    selectedActivities.length > 0 &&
                    selectedActivities?.map((item) => (
                      <div
                        style={{
                          background: "#009900",
                          color: "white",
                          padding: "3px 5px",
                          cursor: "pointer",
                          fontSize: "14px",
                          margin: "0px 2px",
                        }}
                      >
                        {item?.name}
                        <FontAwesomeIcon
                          icon={faTimes}
                          color="white"
                          style={{ marginLeft: "5px" }}
                          onClick={() => removeSelectedActivities(item)}
                        />
                      </div>
                    ))} 
                </div>*/}

        <button
          onClick={""}
          disabled={!goalChange}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "30px",
            position: "absolute",
            right: "40px",
            background: !goalChange ? "#337ab7" : "#009900",
            outline: "none",
            border: "none",
            color: "white",
            padding: "5px 15px",
            marginTop: "15px",
            borderRadius: "3px",
          }}
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            color="white"
            style={{
              cursor: "pointer",
              marginRight: "5px",
              marginTop: "3px",
            }}
          />
          Change Goal Request
        </button>
      </div>
    </Fragment>
  );
};
export default GoalChange;