import { request } from '../Request';
import { routes } from '../../constants/constant.route';
import { urlConstants } from "../../utils/urlConstants";
const {API_URL } = urlConstants;

export default class CounselorMsgService {
    static getMsgList1(body) {
        return request("POST", `${API_URL}${routes.counselor.getMsgList1}`, null, body, null);
    }
    static getMessageCountList() {
        return request("GET", `${API_URL}${routes.counselor.getMsgListCount}`, null, null, null);
    }
    static getMsgList(page,type) {
        return request("GET", `${API_URL}${routes.counselor.getMsgList}?page=${page}&event_type=${type}`, null, null, null);
    }
    static getStudentListForMessage(value, school) {
        return request("GET", `${API_URL}${routes.counselor.getStudentListForCounselor}?q=${value}&school_id=${school}`, null, null, null, null);
    }
    static reply(id, body) {
        return request("POST", `${API_URL}${routes.counselor.reply}/${id}/reply`, null, body, null, null);
    }
    static getCollegeName(value) {
        return request("GET", `${API_URL}${routes.counselor.getCollegeName}?q=${value}`, null, null, null, null);
    }
    static getRecruiterName(college) {
        return request("GET", `${API_URL}${routes.counselor.getRecruiterName}?college=${college}&role=recruiter`, null, null, null, null);
    }
    static getGroupName(counselor, value, school) {
        return request("GET", `${API_URL}${routes.counselor.getGroupName}?counselor_id=${counselor}&q=${value}&school_id=${school}`, null, null, null, null);
    }
    static sendGroupMessage(body) {
        return request("POST", `${API_URL}${routes.counselor.sendGroupMessage}`, null, body, null, null);
    }
    static getDeletedGroupMessage(obj){
        return request("GET", `${API_URL}${routes.counselor.getDeleteGroupMessage}?school_id=${obj.school}&counselor_id=${obj.counselor}&page=${obj.page}`, null, null, null, null);
    }
    static getDeletedGroupMessage1(body){
        return request("POST", `${API_URL}${routes.counselor.getDeleteGroupMessage1}`, null, body, null, null);
    }
    static getGroupListDetails(id) {
        return request("GET", `${API_URL}${routes.counselor.selectedGroupListStudent}?group_id=${id}`, null, null, null, null);
    }
    static getGroupDetails(id) {
        return request("GET", `${API_URL}${routes.counselor.groupListStudent}?group_id=${id}`, null, null, null, null);
    }

    static goalOverride(body){
        return request("POST", `${API_URL}${routes.counselor.override}`, null, body, null, null);
    }
    static getGroupNameForEvent(school, counselor, value) {
        console.log('value and school0', value, school)
        return request("GET", `${API_URL}${routes.counselor.getGroupName}?school_id=${school}&counselor_id=${counselor}&q=${value}`, null, null, null, null);
    }
    static getCannedMessageService(id,page){
        return request("GET", `${API_URL}${routes.counselor.cannedMessage}?college_id=${id}&page=${page}`, null, null, null, null);
    }

    static createCalendar(body){
        return request("PUT", `${API_URL}${routes.counselor.createCalendarEvent}`, null, body, null, null);
    }

    static getCalendarEventDetails(school){
        return request("GET", `${API_URL}${routes.counselor.createCalendarEvent}?school=${school}`, null, null, null, null);
    }

    static getCalendarEventDetailsAfterId(body){
        return request("POST", `${API_URL}${routes.counselor.getCalendarEvent}`, null, body, null, null);
    }


    static restoreDeletedMessage(body){
        return request("PUT", `${API_URL}${routes.counselor.restoreMessage}`, null, body, null, null);
    }
    static deletedParMessage(body){
        return request("POST", `${API_URL}${routes.counselor.restoreMessage}`, null, body, null, null);
    }

    static unoverrideGoal(body){
        return request("POST", `${API_URL}${routes.counselor.unoverride}`, null, body, null, null);
    }

    static messageEnvelopDelete(id){
        return request("GET", `${API_URL}${routes.counselor.message_group}/${id}`, null, null, null, null);
    }

    static afterMessageEnvelopDelete(id){
        return request("POST", `${API_URL}${routes.counselor.msg_group}`, null, id, null, null);
    }

    static deleteCalendarEvent(body){
        return request("DELETE", `${API_URL}${routes.counselor.deleteCalendarEvent}`, null, body, null, null);
    }

}