import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Tooltip,
} from "reactstrap";
import swal from "sweetalert";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { CommonMessages } from "../../../constants/CommonMessages";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import ProfileService from "../../../services/ProfileService";
import CommonButton from "../../../components/CommonButton/CommonButton";
import Loading from "../../../components/Loading/Loading";
import "./ParentProfile.scss";
import { useDispatch, useSelector } from "react-redux";
import { userInfoAction } from "../../../reducers/UserReducer";
import { useHistory } from "react-router-dom";

const ParentProfile = () => {
  const dispatch = useDispatch();
  let userData = useSelector((state) => state.UserReducer.userObj);
  let history = useHistory();
  const initState = {
    firstName: userData?.first_name,
    lastName: userData?.last_name,
    schollEmail: userData?.email,
    mobile: userData?.mobile,
    recoveryEmail: userData?.recovery_email,
    lang: userData?.language,
    pswd: "",
    confirmPswd: "",
  };
  const [loading, setLoading] = useState(false);
  const [formData, setFormaData] = useState(initState);
  const [showPswd, setShowPswd] = useState(false);
  const [showconfirmPswd, setShowconfirmPswd] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  
  // const handleKeyPress = (event) => {
  //     const charCode = event.which ? event.which : event.keyCode;
  //     const char = String.fromCharCode(charCode);
  //     const regex = /^[a-zA-Z\s]*$/;

  //     if (!regex.test(char)) {
  //       event.preventDefault();
  //     }
  // };

  const handleChangeProfile = (e) => {
    const { name, value } = e.target;
    const lowercasedValue =
      name === "recoveryEmail" ? value.toLowerCase() : value;
    setFormaData({ ...formData, [name]: lowercasedValue });
  };

  const handleMobileChange = (e) => {
    const { name, value } = e.target;
    // const cleanedValue = value.replace(/\D/g, '');
    const cleanedValue = value.replace(/[^0-9-+()]/g, "");
    setFormaData({ ...formData, [name]: cleanedValue });
  };

  // const handleRecoveryEmail = (e) => {
  //     const { name, value } = e.target;
  //     const cleanedEmail = recoveryEmail.replace(/\s/g, '')
  //     setFormaData({ ...formData, [name]: cleanedEmail });
  //     if (!/^[\w.-]+@domain\.com$/.test(cleanedEmail)) {
  //         swal({
  //             icon: "warning",
  //             title: 'Invalid email address',
  //             text: 'Please enter a valid email address ending with "@domain.com',
  //             buttons: {
  //               OK: true,
  //             },
  //             closeOnClickOutside: false,
  //           });
  //     }
  // }

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      schollEmail,
      mobile,
      recoveryEmail,
      lang,
      pswd,
      confirmPswd,
    } = formData;
    let emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (
      recoveryEmail &&
      recoveryEmail.length > 0 &&
      !recoveryEmail.match(emailRegEx)
    ) {
      swal("", CommonMessages.recoveryEmailError, "error");
    } else if (pswd !== confirmPswd) {
      swal("", CommonMessages.passwordMatchError, "error");
    }
    // else if (mobile.length !== 10) {
    //     swal({
    //         icon: "warning",
    //         title: 'Invalid mobile number',
    //         text: 'Mobile number should be exactly 10 digits.',
    //         buttons: {
    //           OK: true,
    //         },
    //         closeOnClickOutside: false,
    //       });
    //       return;
    // }
    else {
      let payload = {};
      if(firstName){
        payload.first_name= firstName
      }
      if(lastName){
        payload.last_name= lastName
      }
      if(schollEmail){
        payload.email= schollEmail
      }
      if(mobile){
        payload.mobile= mobile
      }
      if(recoveryEmail){
        payload.recovery_email= recoveryEmail
      }
      if(lang){
        payload.language= lang
      }
      if(pswd){
        payload.password= pswd 
      }
      if(confirmPswd){
        payload.confirm_password= confirmPswd
      }
      setLoading(true);
      ProfileService.updateParentProfile(payload)
        .then((res) => {
          if (res?.status === 200) {
            let newUser = {
              ...userData,
              first_name: firstName,
              last_name: lastName,
              mobile: mobile,
              language: lang,
              password: pswd,
              confirm_password: confirmPswd,
              recovery_email: recoveryEmail,
            };
            dispatch(userInfoAction(newUser));
            swal("", res?.data?.message, "success");
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          swal("", error?.response?.data?.message, "error");
        });
    }
  };
  return (
    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="parentProfile">
              <div className="header-title d-flex justify-content-between align-items-center mb-3">
                <h4 className="cardHeader title-main">Profile Information</h4>
              </div>
              {loading ? (
                <Loading />
              ) : (
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <Card className="main-card mb-3 profile-main">
                    <CardHeader className="cardHeader">
                      Personal Information
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="text-left">
                            <Label className="custom-lable">
                              First Name<span className="requiredField">*</span>
                            </Label>
                            <Input
                              required
                              className="form-control"
                              type="text"
                              name="firstName"
                              placeholder="Enter your first name"
                              value={formData.firstName}
                              // onKeyPress={handleKeyPress}
                              onChange={handleChangeProfile}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="text-left">
                            <Label className="custom-lable">
                              Last Name<span className="requiredField">*</span>
                            </Label>
                            <Input
                              required
                              className="form-control"
                              type="text"
                              name="lastName"
                              placeholder="Enter your last name"
                              value={formData.lastName}
                              // onKeyPress={handleKeyPress}
                              onChange={handleChangeProfile}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="text-left">
                            <Label className="customLabel">
                              Language Preference
                            </Label>
                            <Input
                              required
                              className="form-control"
                              type="select"
                              name="lang"
                              value={formData.lang || ""}
                              onChange={handleChangeProfile}
                            >
                              <option value="en">en</option>
                              <option value="es">es</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardHeader className="cardHeader">
                      Contact Information
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="text-left">
                            <Label className="customLabel">
                              School Email Address
                              <span className="requiredField">*</span>
                              <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        id="TooltipSchoolEmail"
                                        style={{
                                          marginLeft: "5px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </Label>
                                    <Tooltip
                                      placement="right"
                                      isOpen={tooltipOpen}
                                      target="TooltipSchoolEmail"
                                      toggle={toggleTooltip}
                                    >
                                      Student email ID provided by school
                                    </Tooltip>
                            <Input
                              // required
                              // pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                              disabled
                              className="form-control"
                              placeholder="Enter your school email address"
                              type="email"
                              name="schollEmail"
                              value={formData.schollEmail || ""}
                              onChange={handleChangeProfile}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="text-left">
                            <Label className="customLabel">
                              Mobile Number
                              <span className="requiredField">*</span>
                            </Label>
                            <Input
                              required
                              className="form-control"
                              placeholder="Enter your mobile number"
                              type="tel"
                              name="mobile"
                              maxLength={18}
                              value={formData.mobile || ""}
                              onChange={handleMobileChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="text-left">
                            <Label className="customLabel">
                              Recovery Email Address
                              <span className="requiredField">*</span>
                            </Label>
                            <Input
                              required
                              className="form-control"
                              placeholder="Enter your recovery email address"
                              type="email"
                              name="recoveryEmail"
                              value={formData.recoveryEmail || ""}
                              onChange={handleChangeProfile}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardHeader className="cardHeader">
                      User Information
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="text-left position-relative">
                            <Label className="customLabel">Password</Label>
                            <Input
                              required={
                                formData.confirmPswd.length > 0 ? true : false
                              }
                              className="form-control"
                              placeholder="Enter your password"
                              type={showPswd ? "text" : "password"}
                              name="pswd"
                              minLength="8"
                              maxLength="16"
                              value={formData.pswd || ""}
                              onChange={handleChangeProfile}
                            />
                            <FontAwesomeIcon
                              icon={showPswd ? faEye : faEyeSlash}
                              onClick={() => setShowPswd(!showPswd)}
                              style={{
                                position: "absolute",
                                right: "13px",
                                top: "41px",
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="text-left position-relative">
                            <Label className="customLabel">
                              Confirm Password
                            </Label>
                            <Input
                              required={formData.pswd.length > 0 ? true : false}
                              className="form-control"
                              placeholder="Enter your confirm password"
                              type={showconfirmPswd ? "text" : "password"}
                              name="confirmPswd"
                              minLength="8"
                              maxLength="16"
                              value={formData.confirmPswd || ""}
                              onChange={handleChangeProfile}
                            />
                            <FontAwesomeIcon
                              icon={showconfirmPswd ? faEye : faEyeSlash}
                              onClick={() =>
                                setShowconfirmPswd(!showconfirmPswd)
                              }
                              style={{
                                position: "absolute",
                                right: "13px",
                                top: "41px",
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div className="btnWrap">
                            <div className="btnInner">
                              <CommonButton
                                name="Cancel"
                                btnClass="secondaryBtn formBtn"
                                btnWidth="153px"
                                btnHeight="50px"
                                onClick={() => history.goBack()}
                              />
                            </div>
                            <div className="btnInner">
                              <CommonButton
                                type="submit"
                                name="Save profile"
                                btnClass="primaryBtn formBtn"
                                disabled={
                                  !formData.firstName ||
                                  !formData.lastName ||
                                  !formData.schollEmail ||
                                  !formData.mobile ||
                                  !formData.recoveryEmail
                                }
                                btnWidth="153px"
                                btnHeight="50px"
                                style={{
                                  opacity:
                                    !formData.firstName ||
                                    !formData.lastName ||
                                    !formData.schollEmail ||
                                    !formData.mobile ||
                                    !formData.recoveryEmail
                                      ? 0.5
                                      : 1,
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParentProfile;
