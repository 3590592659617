import { faPlus, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { useHistory } from "react-router";
import AppHeader from "../../../../Layout/AppHeader";
import AppSidebar from "../../../../Layout/AppSidebar";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import QueryService from "../../../../services/QueryService";
import swal from "sweetalert";
import GetStudentService from "../../../../services/SchoolIT/GetStudentService";

const ExpressionModal = ({
  isOpen,
  closeHandler,
  data,
  selectedOptionforModal,
  optionValueForModal,
  setOptionValueForModal,
  saveMethodForModal,
}) => {
  console.log("data from the mdal", optionValueForModal);

  const handleInputChange = (e, index) => {
    optionValueForModal[index].map_val = e.target.value;
    setOptionValueForModal([...optionValueForModal]);
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen}>
        <ModalHeader>
          <p>{data?.criteria}</p>
        </ModalHeader>
        <ModalBody>
          <div
            style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}
          >
            <div>
              <p>Configured Values</p>
              {selectedOptionforModal?.map((item, index) => (
                <div style={{ margin: "15px 0" }}>
                  <input type="text" value={item.name} disabled />
                </div>
              ))}
            </div>
            <div>
              <p>Mapped Values</p>
              {selectedOptionforModal?.map((item, index) => (
                <div style={{ margin: "15px 0" }}>
                  <input
                    type="text"
                    onChange={(e) => handleInputChange(e, index)}
                    value={optionValueForModal[index]?.map_val}
                  />
                </div>
              ))}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button onClick={saveMethodForModal} className="save-modal-button">
            <FontAwesomeIcon icon={faSave} style={{ margin: "0 3px" }} />
            Save
          </button>
          <button className="cancel-modal-button" onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} style={{ margin: "0 3px" }} />
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const MapCollege = () => {
  const [configureListData, setConfigureListData] = useState();
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [dataSource, setDataSource] = useState();
  const [criteria, setCriteria] = useState([]);
  const [dataSourceColumn, setDataSourceColumn] = useState([]);
  const [operatorData, setOperatorData] = useState([]);
  const [expressionCriteria, setExpressionCriteria] = useState([]);
  const [openExpressionModal, setOpenExpressionModal] = useState(false);
  const [expressionModalData, setExpressionModalData] = useState();
  const [ethnicityOption, setEthnicityOption] = useState([]);
  const [zipOption, setZipOption] = useState([]);
  const [genderOption, setGenderOption] = useState([]);
  const [selectedOptionforModal, setSelectedOptionforModal] = useState();
  const [optionValueForModal, setOptionValueForModal] = useState([]);
  const history = useHistory();

  const expressionModalHandler = (criteria) => {
    setOpenExpressionModal(true);
    setExpressionModalData(criteria);
  };

  const saveCollegeCriteria = () => {
    if(criteria[0] !== null){
      criteria.unshift(null);
    }
    let cr = criteria;
    let obj = {
      map_values: cr,
      school_id: user?.school_id,
    };
    GetStudentService.saveMapCollege(obj)
      .then((data) => {
        console.log("data from the saveMapCollegeService", data);
        swal({
          text: `Saved Successfully!`,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  useEffect(() => {
    if (expressionModalData) {
      if (expressionModalData?.criteria === "Gender") {
        setSelectedOptionforModal(genderOption);
      } else if (expressionModalData?.criteria === "Ethnicity") {
        setSelectedOptionforModal(ethnicityOption);
      } else if (expressionModalData?.criteria === "Zip Code") {
        setSelectedOptionforModal(zipOption);
      }
    }
  }, [expressionModalData]);

  useEffect(() => {
    let array = [];
    selectedOptionforModal?.map((item, index) => {
      let obj = {};
      obj["config_val"] = item?.name;
      obj["criteria_id"] = item?.id;
      obj["exp_id"] = "";
      obj["map_id"] = expressionModalData?.id;
      obj["map_val"] = "";
      array.push(obj);
    });
    console.log("array",array);
    setOptionValueForModal(array);
  }, [expressionModalData && selectedOptionforModal]);

  const getGenderOption = () => {
    QueryService.getGender()
      .then((data) => {
        setGenderOption(data.data.objects);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const getEthnicityOption = () => {
    QueryService.getEthnicity()
      .then((data) => {
        setEthnicityOption(data.data.objects);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const getZipOption = () => {
    QueryService.getEthnicity()
      .then((data) => {
        setZipOption(data.data.objects);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const getArithemeticOperator = () => {
    GetStudentService.getArithemeticOperator()
      .then((data) => {
        setOperatorData(data?.data?.objects);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const getDataSourceCol = (value) => {
    GetStudentService.getDataSrcCol(value)
      .then((data) => {
        setDataSourceColumn(data?.data?.objects);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const criteriaNameHandler = (e, index) => {
    criteria[index].criteria = e.target.value;
    setCriteria([...criteria]);
  };

  const createCriteria = (res) => {
    let array = [];
    res.map((item, index) => {
      let obj = {};
      obj["datasource"] = parseInt(item?.datasource);
      obj["datacolumn"] = parseInt(item?.datacolumn);
      obj["criteria"] = item?.name;
      obj["criteria_id"] = parseInt(item?.id);
      obj["expression"] = item?.expression;
      obj["is_criteriaexpression"] = item?.is_criteriaexpression;
      obj["id"] = parseInt(item?.criteriamap_id);
      obj["modal_show"] = item?.is_criteriaexpression === true ? true : false;
      array.push(obj);
      if (item?.datasource) {
        getDataSourceCol(item?.datasource);
      }
    });
    setCriteria(array);
  };

  const getConfigureList = () => {
    let obj = {
      list_name: "query_criteria",
      school: user?.school_id,
    };
    GetStudentService.getConfigureMapList(obj)
      .then((data) => {
        setConfigureListData(data?.data);
        createCriteria(data?.data);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const getDataSource = () => {
    GetStudentService.getDataSrc(user?.school_id)
      .then((data) => {
        setDataSource(data?.data?.objects);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const dataSourceHandler = (e, index) => {
    criteria[index].datasource = parseInt(e.target.value);
    getDataSourceCol(parseInt(e.target.value));
    setCriteria([...criteria]);
  };

  const dataSourceColumnHandler = (e, index) => {
    criteria[index].datacolumn = parseInt(e.target.value);
    setCriteria([...criteria]);
  };

  const handleExpressionCheckBox = (index) => {
    // if(index==3) criteria[index].modal_show = true
    criteria[index].is_criteriaexpression =
      !criteria[index].is_criteriaexpression;
    setCriteria([...criteria]);
  };

  const addNewExpression = (criteriaIndex) => {
    let newitem = expressionCriteria.length + 1;
    let array = [];
    let obj = {
      newitem: newitem,
      operator: "",
      val: "",
      id: "",
    };
    array.push(obj);
    criteria[criteriaIndex].expression.push(obj);
    setExpressionCriteria([...expressionCriteria, ...array]);
    setCriteria([...criteria]);
  };

  const saveMethodForModal = () => {
    let obj = {
      map_exp: optionValueForModal,
    };
    GetStudentService.saveModalOption(obj)
      .then((data) => {
        setOpenExpressionModal(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  useEffect(() => {
    getConfigureList();
    getDataSource();
    getArithemeticOperator();
    getGenderOption();
    getZipOption();
    getEthnicityOption();
  }, []);

  const handleOperatorChange = (i, e, criteriaIndex) => {
    expressionCriteria[i].operator = e.target.value;
    criteria[criteriaIndex].expression[i].operator = parseInt(e.target.value);
    setCriteria([...criteria]);
  };

  const handleValueChange = (index, event, criteriaIndex) => {
    expressionCriteria[index].val = event.target.value;
    criteria[criteriaIndex].expression[index].val = event.target.value;
    setCriteria([...criteria]);
  };

  return (
      <Fragment>
      <AppHeader />
      <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
      <div style={{ padding: "15px" }}>
        <div className="flex" style={{ justifyContent: "space-between" }}>
          <p style={{ fontSize: "24px" }}>Map School College Data</p>
          <button
            onClick={() => history.push("/school_it/ccplus")}
            className="create-maintain-group-button"
          >
            Back
          </button>
        </div>

        <div>
          <p style={{ fontSize: "14px" }}>
            <strong>Criterias</strong>
          </p>
          <div>
            {criteria?.map((item, index) => (
              <div key={index} className="flex" style={{ padding: "15px 0px" }}>
                <input
                  style={{ margin: "0 10px" }}
                  type="text"
                  onChange={(e) => criteriaNameHandler(e, index)}
                  value={criteria[index].criteria}
                />
                <select
                  style={{ padding: "4px", margin: "0 10px" }}
                  value={criteria[index].datasource}
                  onChange={(e) => dataSourceHandler(e, index)}
                >
                  <option>---Select Data Source---</option>
                  {dataSource?.map((item) => {
                    return (
                      <option value={parseInt(item?.id)}>{item?.name}</option>
                    );
                  })}
                </select>

                <select
                  style={{ padding: "4px", margin: "0 10px" }}
                  value={criteria[index].datacolumn}
                  onChange={(e) => dataSourceColumnHandler(e, index)}
                >
                  <option>---Select Data Column---</option>
                  {dataSourceColumn?.map((item) => {
                    if (criteria[index].datasource)
                      return (
                        <option value={parseInt(item?.id)}>
                          {item?.column_name}
                        </option>
                      );
                  })}
                </select>

                <div
                  className="flex"
                  style={{ alignItems: "flex-start" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      value={criteria[index].is_criteriaexpression}
                      checked={criteria[index].is_criteriaexpression}
                      onChange={() => handleExpressionCheckBox(index)}
                    />
                    <p className="no-margin-bottom" style={{ margin: "0 5px" }}>
                      Add Expression
                    </p>
                  </div>
                  {criteria[index].is_criteriaexpression === true &&
                    criteria[index].modal_show === true && (
                      <button className="create-maintain-group-button"
                        onClick={() => expressionModalHandler(criteria[index])}
                      >{`${criteria[index].criteria} map`}</button>
                    )}
                  {criteria[index].is_criteriaexpression &&
                    criteria[index].modal_show === false && (
                      <>
                        {expressionCriteria &&
                          expressionCriteria.length > 0 &&
                          expressionCriteria.map((item, i) => (
                            <div>
                              <select
                                style={{ padding: "4px", marginRight: "5px" }}
                                value={expressionCriteria[i].operator}
                                onChange={(e) =>
                                  handleOperatorChange(i, e, index)
                                }
                              >
                                <option value={null} disabled selected>
                                  --Select operator--
                                </option>
                                {operatorData?.map((item) => (
                                  <option value={parseInt(item?.id)}>
                                    {item.operator}
                                  </option>
                                ))}
                              </select>
                              <input
                                type="text"
                                onChange={(e) => handleValueChange(i, e, index)}
                                value={expressionCriteria[i].val}
                              />
                            </div>
                          ))}
                        <button onClick={() => addNewExpression(index)}>
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </>
                    )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div style={{ margin: "60px 10px" }}>
          <button className="save-modal-button" onClick={saveCollegeCriteria}>
            <FontAwesomeIcon style={{ margin: "0 3px" }} icon={faSave} />
            Save
          </button>
          <button
            className="cancel-modal-button2"
            onClick={() => history.push("/school_it/ccplus")}
          >
            <FontAwesomeIcon style={{ margin: "0 3px" }} icon={faTimes} />
            Cancel
          </button>
        </div>
      </div>

      {openExpressionModal && (
        <ExpressionModal
          isOpen={openExpressionModal}
          closeHandler={() => setOpenExpressionModal(false)}
          data={expressionModalData}
          selectedOptionforModal={selectedOptionforModal}
          optionValueForModal={optionValueForModal}
          setOptionValueForModal={setOptionValueForModal}
          saveMethodForModal={saveMethodForModal}
        />
      )}
      </div>
      </div>
    </Fragment>
  );
};
export default MapCollege;
