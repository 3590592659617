import React, { Fragment, useState, useRef, useEffect } from "react";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import GetAdminService from "../../../services/admin/GetAdminService";
import { useHistory } from "react-router";
import { Modal, ModalBody } from "reactstrap";
import CustomPagination from "../../pagination/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import CustomLoader from "../../customLoader/CustomLoader";
import Loader from "react-loader-spinner";
import "./RequiredCourseSet.css"
import swal from "sweetalert";

let addGoalOrUpdateGoal = [];

const DeleteGoalModal = ({ isOpen, setIsOpen, deleteGoal }) => {
  return (
    <Fragment>
      <Modal isOpen={isOpen} centered={true}>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            <p>Are you sure you want to delete?</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={deleteGoal}
                style={{
                  backgroundColor: "#009900",
                  color: "white",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginRight: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon
                  style={{ margin: "0 5px" }}
                  color="white"
                  icon={faCheck}
                />
                Ok
              </button>
              <button
                onClick={() => setIsOpen(false)}
                style={{
                  backgroundColor: "#efefef",
                  color: "black",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginLeft: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon style={{ margin: "0 5px" }} icon={faCheck} />
                Cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const Goals = () => {
  const [goalPageData, setGoalPageData] = useState();
  const [page, setPage] = useState(1);
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteGoalId, setDeleteGoalId] = useState();
  const [loading, setLoading] = useState(false);
  const [publishedLoading, setPublishedLoading] = useState(false);
  const [checkbox, setCheckbox] = useState(true);
  const [publishedButtonIndex, setPublishedButtonIndex] = useState(-1);
  const [uncheck, setUncheck] = useState([]);
  const [goalData, setGoalData] = useState();
  let history = useHistory();

  const getGoalPageData = () => {
    setLoading(true);
    let obj = {
      page: page,
      role: "admin",
    };
    GetAdminService.goalAdminPage(obj)
      .then((data) => {
        console.log("data from the service", data);
        setGoalPageData(data?.data);
        setGoalData(data?.data);
        let convertedData = DataMapping(data?.data?.result);
        if (convertedData.length > 0) {
          setConvertedDataToGoalpageData(convertedData);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error from the service", error);
      });
  };

  const duplicateGoal = (value) => {
    console.log("value from the duplicate", value);
    let obj = {
      goal_id: value?.id,
    };
    GetAdminService.duplicateAdminGoal(obj)
      .then((data) => {
        getGoalPageData();
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const deleteGoalRequirement = (value) => {
    setDeleteGoalId(value?.id);
    let obj = {
      goal_id: value?.id,
    };
    GetStudentService.deleteGoalRequirement(obj)
      .then((data) => {
        console.log("data from the service", data);
        setOpenDeleteModal(true);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const deleteGoalAfterRequirement = () => {
    let obj = {
      deleted: true,
    };
    GetStudentService.deleteGoal(obj)
      .then((data) => {
        console.log("data from the delete course requiremnt", data);
        goalDeleteConfirmation(deleteGoalId);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const deleteUndoGoal=()=>{
    let obj = {
      deleted: true,
    };
    GetStudentService.deleteGoal(obj)
      .then((data) => {
        console.log("data from the delete course requiremnt", data);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  }

  const goalDeleteConfirmation = (value) => {
    let obj = {
      delete_goal_id: value,
    };
    GetStudentService.deleteGoalConfirmation(obj)
      .then((data) => {
        console.log("data from the course delete confirmation", data);
        setOpenDeleteModal(false);
        setDeleteGoalId();
        getGoalPageData();
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const updateDefaultGoal = () => {
    let obj = {
      check: uncheck,
      default: addGoalOrUpdateGoal,
      school: user?.school_id,
    };
    GetStudentService.updateDefaultGoal(obj)
      .then((data) => {
        console.log("data from the service", data);
        getGoalPageData();
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const DataMapping = (value) => {
    for (let i = 0; i < value.length; i++) {
      for (let j = 0; j < value[i].publish_data.length; j++) {
        if (value[i].publish_data[j].school_id == user?.school_id) {
          value[i].published = value[i].publish_data[j].published;
          value[i].data = value[i].publish_data[j].data_mapped_percentage;
        }
        if (value[i].publish_data[j].default_goal === true) {
          value[i].default_goal = value[i].publish_data[j].default_goal;
        }
      }
      if (value[i].published == "" && value[i].data == "") {
        for (let j = 0; j < value[i].publish_data.length; j++) {
          if (value[i].publish_data[j].school_id == null) {
            value[i].published = value[i].publish_data[j].published;

            value[i].data = value[i].publish_data[j].data_mapped_percentage;
          }
        }
      }
    }
    return value;
  };

  console.log("uncheck", uncheck);
  const publishOrUnPublishGoal = (item, index, publishValue) => {
    setPublishedLoading(true);
    setPublishedButtonIndex(index);
    let obj = {
      goal_id: item?.id,
      inter_published: publishValue,
    };
    GetAdminService.goalPublished(obj)
      .then((data) => {
        setPublishedLoading(false);
        console.log("data from the goalPublished service", data);
        getGoalPageData();
        // if (data?.data?.goal_id?.length > 0 && data?.data?.map === 1) {
        //   swal({
        //     text: `This Goal/Course has been used by a counselor. Cannot be unpublished.`,
        //     icon: "warning",
        //     dangerMode: true,
        //     buttons: {
        //       cancel: true,
        //     },
        //     closeOnClickOutside: false,
        //   });
        // } else {
        //   getGoalPageData();
        // }
        // setPublishedLoading(false);
      })
      .catch((error) => {
        console.log("error fom the service", error);
        setPublishedLoading(false);
      });
  };

  const setConvertedDataToGoalpageData = (data) => {
    if (data) {
      setGoalPageData({ ...goalPageData, result: data });
      let defaultGoal = data
        ?.filter((item) => {
          if (item.published === true) {
            return item?.id;
          }
        })
        .map((item) => item.id);
      addGoalOrUpdateGoal = defaultGoal;
      let uncheck = data
        ?.filter((item) => item.published === false)
        .map((item) => item?.id);
      setUncheck(uncheck);
    }
  };

  const openGoalView = (value) => {
    history.push(`/admin/goal/${value?.id}`)
  };

  const openGoalView1 = () =>{
    swal({
          text: `Conseal Goal cannot be edited, Kindly Broadcast the Goal to edit.`,
          icon: "warning",
          dangerMode: true,
          buttons: {
            cancel: true,
          },
          closeOnClickOutside: false,
        });
      setPublishedLoading(false);
  }

  // const editGoalView = (value) => {
  //   history.push(`/school_it/edit-goal/${value?.id}`);
  // };

  const undoGoalDelete = (value) => {
    let obj = {
      goal_id: value?.id,
      deleted: "True",
    };
    GetStudentService.undoGoalDelete(obj)
      .then((data) => {
        console.log("data from the undo delete", data);
        getGoalPageData();
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const checkedGoal = (value) => {
    setCheckbox(!checkbox);
  };

  useEffect(() => {
    // deleteUndoGoal();
    getGoalPageData();
  }, [page]);

  return (
    <Fragment>
      <div style={{ width: "100%" }}>
        <p style={{ fontSize: "24px", marginTop: "20px" }}>Goals</p>
        <div
          style={{
            display: "flex",
            width: "55%",
            marginBottom: "15px",
          }}
        >
          <button
            onClick={() => history.push("/admin/goal")}
            className="create-maintain-button"
          >
            New Goals
          </button>
          {/* <button className="create-maintain-group-button">Test Goals</button> */}
          {/* {addGoalOrUpdateGoal?.length > 0 && (
            <button
              onClick={updateDefaultGoal}
              disabled={checkbox}
              style={{ opacity: checkbox ? "0.65" : "1" }}
              className="create-maintain-group-button"
            >
              Update Default Goals
            </button>
          )}
          {addGoalOrUpdateGoal?.length <= 0 && (
            <button
              disabled={checkbox}
              style={{ opacity: checkbox ? "0.65" : "1" }}
              className="create-maintain-group-button"
            >
              Add Default Goals
            </button>
          )} */}
        </div>
        {loading ? (
          <CustomLoader />
        ) : (
          goalPageData?.result.map((item, index) => (
            <div
              style={{
                padding: "12px",
                border: "1px solid #ccc",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p
                  style={{ fontSize: "14px" }}
                  className="bold no-margin-bottom"
                >
                  {item?.goal_name}
                </p>
                {/* <p style={{ fontSize: "14px" }} className="no-margin-bottom">
                  {`Data Mapping :${item?.data}%`}
                  {!!item?.published && (
                    <input
                      onClick={() => checkedGoal(!item.default_goal)}
                      style={{ marginLeft: "30px" }}
                      type="checkbox"
                      checked={item?.default_goal}
                      className="danger-button"
                    />
                  )}
                </p> */}
              </div>
              <div>
                {item.inter_published && item.deleted === false ? (
                  <button
                    onClick={() => publishOrUnPublishGoal(item, index, "False")}
                    className="create-maintain-button"
                    style={{
                      opacity:"1"}}
                  >
                    Conseal Goal
                    {publishedButtonIndex === index && publishedLoading && (
                      <span>
                        <Loader
                          type="TailSpin"
                          color="white"
                          height={20}
                          width={20}
                        />
                      </span>
                    )}
                  </button>
                ) : !item?.inter_published && item.deleted === false ? (
                  <button
                    style={{
                      opacity:"1",
                    }}
                    // disabled={item?.data < 100 || item?.data == null}
                    className="create-maintain-group-button"
                    onClick={() => publishOrUnPublishGoal(item, index, "True")}
                  >
                    Broadcast Goal
                    {publishedButtonIndex === index && publishedLoading && (
                      <span>
                        <Loader
                          type="TailSpin"
                          color="white"
                          height={20}
                          width={20}
                        />
                      </span>
                    )}
                  </button>
                ) : null}
                {item.deleted === false && (
                  <button
                    className="create-maintain-button"
                    onClick={() => duplicateGoal(item)}
                  >
                    Duplicate
                  </button>
                )}
                
                {/* {item?.is_global && item.deleted === false && ( */}
                  {/* <button
                    onClick={() => openGoalView(item)}
                    className="create-maintain-button"
                  >
                    Edit
                    {/* view */}
                 {/* </button> */}
                {/* )} */}
                {item?.is_global === true && item?.deleted === false && item?.inter_published === false ? (
                  <button
                  onClick={() => openGoalView(item)}
                  className="create-maintain-group-button"
                  >
                    Edit
                  </button>
                ) : 
                (
                  <button
                  onClick={() => openGoalView1()}
                  className="create-maintain-group-button"
                  >
                    Edit
                  </button>
                )}
                {item.deleted === false && (
                  <button
                    className="create-maintain-button"
                    onClick={() => deleteGoalRequirement(item)}
                  >
                    Delete
                  </button>
               )} 
                {item?.deleted && (
                  <button
                    onClick={() => undoGoalDelete(item)}
                    className="danger-button"
                  >
                    Undo
                  </button>
                )}
              </div>
            </div>
          ))
        )}
      </div>
      <CustomPagination
        count={goalData}
        pageValue={page}
        setPage={setPage}
      />
      {openDeleteModal && (
        <DeleteGoalModal
          setIsOpen={setOpenDeleteModal}
          isOpen={openDeleteModal}
          deleteGoal={deleteGoalAfterRequirement}
        />
      )}
    </Fragment>
  );
};
export default Goals;
