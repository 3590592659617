import { faAsterisk, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState, useEffect, useRef } from "react";
import Loader from "react-loader-spinner";
import { useHistory, useLocation } from "react-router";
import swal from "sweetalert";
import AppHeader from "../../../../Layout/AppHeader";
import AppSidebar from "../../../../Layout/AppSidebar";
import GetStudentService from "../../../../services/SchoolIT/GetStudentService";
import GetAdminService from "../../../../services/admin/GetAdminService";

const RequiredGoal = () => {
  const history = useHistory();
  const location = useLocation();
  const [courseId, setCourseId] = useState(
    location?.pathname?.split("/").pop() === "course"? "" : location?.pathname?.split("/").pop()
    
  );
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [courseData, setCourseData] = useState();
  const [courseName, setCourseName] = useState();
  const [courseDescription, setCourseDescription] = useState();
  const [minCreditRequired, setMinCreditRequired] = useState();
  const [minGradeRequired, setMinGradeRequired] = useState();
  const [startYear, setStartYear] = useState();
  const [endYear, setEndYear] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [gradeValue, setGradeValue] = useState("Grade");
  const [yearValue, setYearValue] = useState();
  const [loading, setLoading] = useState(false);

  const getCourseData = () => {
    // console.log(courseId);
    GetAdminService.getCourse(courseId)
      .then((data) => {
        console.log("data from the course service", data);
        setCourseData(data?.data);
        setCourseName(data?.data?.name);
        setCourseDescription(data?.data?.description);
        setMinCreditRequired(data?.data?.min_credit_req);
        setMinGradeRequired(data?.data?.min_grade);
        setStartYear(data?.data?.start_year);
        setEndYear(data?.data?.end_year);
        setYearValue(data?.data?.unit);
        if (data?.data?.is_global === true) {
          setIsDisabled(true);
        } else if (data?.data?.is_global === true) {
          setIsDisabled(false);
        }
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const getGoalField = () => {
    GetAdminService.goalField()
      .then((data) => {
        console.log(data);
        // setGoalFieldOption([...data?.data?.objects, ...goalFieldOptionArray]);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const saveHandler = () => {
    console.log("clicked from the save handler");
    setLoading(true);
    let obj = {
      description: courseDescription,
      end_year: endYear,
      grade: gradeValue,
      min_credit_req: minCreditRequired,
      min_grade: minGradeRequired,
      name: courseName,
      school_id: user?.school_id,
      start_year: startYear,
      unit: yearValue,
    };
    {
      !courseId
        ? GetAdminService.createCourse(obj)
            .then((data) => {
              console.log("data from the create course", data);
              setCourseName();
              setCourseDescription();
              setGradeValue("Grade");
              setMinCreditRequired();
              setEndYear();
              setStartYear();
              setYearValue();
              setMinGradeRequired();
              setLoading(false);
              history.push("/admin/ccplus");
            })
            .catch((error) => {
              setLoading(false);
              if (
                error?.response?.status === 404 ||
                error?.response?.data?.message === "Course name exist"
              ) {
                swal({
                  text: `${error?.response?.data?.message}`,
                  icon: "warning",
                  dangerMode: true,
                  buttons: {
                    cancel: true,
                  },
                  closeOnClickOutside: false,
                });
              }
            })
        : GetAdminService.updateCourse(obj, courseId)
            .then((data) => {
              console.log("data from the create course", data);
              setCourseName();
              setCourseDescription();
              setGradeValue("Grade");
              setMinCreditRequired();
              setEndYear();
              setStartYear();
              setYearValue();
              setMinGradeRequired();
              setLoading(false);
              history.push("/admin/ccplus");
            })
            .catch((error) => {
              setLoading(false);
              if (
                error?.response?.status === 404 ||
                error?.response?.data?.message === "Course name exist"
              ) {
                swal({
                  text: `${error?.response?.data?.message}`,
                  icon: "warning",
                  dangerMode: true,
                  buttons: {
                    cancel: true,
                  },
                  closeOnClickOutside: false,
                });
              }
            });
    }
  };

  useEffect(() => {
    getCourseData();
    getGoalField();
  }, []);

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div style={{ padding: "25px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: "24px", marginBottom: "0px" }}>
                Create Required Course Set
              </p>
              <button
                onClick={() => history.push("/admin/ccplus")}
                className="create-maintain-group-button"
              >
                Back
              </button>
            </div>
            <div className="requiredname-input-container">
              <div style={{ textAlign: "right" }}>
                <p className="no-margin-bottom requirement-text">
                  Requirement Name
                </p>
              </div>
              <div style={{ position: "relative" }}>
                <input
                  value={courseName}
                  onChange={(e) => setCourseName(e.target.value)}
                  style={{ width: "100%", padding: "5px", borderRadius: "5px" }}
                  type="text"
                  className="require-input-name"
                />
                <FontAwesomeIcon
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  icon={faAsterisk}
                  color="red"
                />
              </div>
            </div>
            <div className="requiredname-input-container">
              <div style={{ textAlign: "right" }}>
                <p className="no-margin-bottom requirement-text">
                  Requirement Description
                </p>
              </div>
              <div style={{ position: "relative" }}>
                <textarea
                  value={courseDescription}
                  onChange={(e) => setCourseDescription(e.target.value)}
                  style={{ width: "100%", padding: "5px", borderRadius: "5px" }}
                  type="text"
                  className="require-input-name"
                  rows="5"
                />
                <FontAwesomeIcon
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                  icon={faAsterisk}
                  color="red"
                />
              </div>
            </div>
            <div style={{ margin: "8px 0px", fontSize: "14px", color: "#333" }}>
              <p>
                Student must take a minimum of{" "}
                <span>
                  <input
                    type="text"
                    value={minCreditRequired}
                    style={{ width: "50px" }}
                    disabled={isDisabled}
                    onChange={(e) => setMinCreditRequired(e.target.value)}
                  />
                </span>
                <span>
                  <select
                    value={yearValue}
                    disabled={isDisabled}
                    onChange={(e) => setYearValue(e.target.value)}
                    style={{ padding: "4px 0" }}
                  >
                    <option selected disabled value={null}>
                      --Select--
                    </option>
                    <option value="Years">Years</option>
                    <option value="Semesters">Semesters</option>
                    <option value="Quarters">Quarters</option>
                    <option value="Units">Units</option>
                    <option value="Others">Others</option>
                  </select>
                </span>{" "}
                worth of courses.
              </p>
            </div>
            <div style={{ margin: "8px 0px", fontSize: "14px", color: "#333" }}>
              <p>
                Student must have a minimum of{" "}
                <span>
                  <input
                    type="text"
                    value={gradeValue}
                    placeholder="Grade"
                    style={{ width: "60px" }}
                    onChange={(e) => setGradeValue(e.target.value)}
                    disabled="true"
                  />
                </span>
                <span>
                  <input
                    type="text"
                    value={minGradeRequired}
                    style={{ width: "60px" }}
                    disabled={isDisabled}
                    onChange={(e) => setMinGradeRequired(e.target.value)}
                  />
                </span>{" "}
                for a course to qualify.
              </p>
            </div>
            <div style={{ margin: "8px 0px", fontSize: "14px", color: "#333" }}>
              <p>
                (optional)
                <span style={{ margin: "0 50px" }}>
                  <span>Start Year:</span>
                  <select
                    disabled={isDisabled}
                    value={startYear}
                    onChange={(e) => setStartYear(e.target.value)}
                  >
                    <option selected disabled value={null}>
                      Select the start year
                    </option>
                    <option value={"06"}>Grade - 6th</option>
                    <option value={"07"}>Grade - 7th</option>
                    <option value={"08"}>Grade - 8th</option>
                    <option value={"09"}>Freshman - 9th</option>
                    <option value={"10"}>Sophmore - 10th</option>
                    <option value={"11"}>Junior - 11th</option>
                    <option value={"12"}>Senior - 12th</option>
                  </select>
                </span>
                <span>
                  <span>End Year:</span>
                  <select
                    disabled={isDisabled}
                    value={endYear}
                    onChange={(e) => setEndYear(e.target.value)}
                  >
                    <option selected disabled value={null}>
                      Select the end year
                    </option>
                    <option value={"06"}>Grade - 6th</option>
                    <option value={"07"}>Grade - 7th</option>
                    <option value={"08"}>Grade - 8th</option>
                    <option value={"09"}>Freshman - 9th</option>
                    <option value={"10"}>Sophmore - 10th</option>
                    <option value={"11"}>Junior - 11th</option>
                    <option value={"12"}>Senior - 12th</option>
                  </select>
                </span>
              </p>
            </div>
            <div className="flex">
              <button
                disabled={
                  !courseName ||
                  !courseDescription ||
                  !minCreditRequired ||
                  !startYear ||
                  !endYear ||
                  !minGradeRequired ||
                  !yearValue
                }
                onClick={saveHandler}
                className={
                  !courseName ||
                  !courseDescription ||
                  !minCreditRequired ||
                  !startYear ||
                  !endYear ||
                  !minGradeRequired ||
                  !yearValue
                    ? "disabled-modal-button"
                    : "save-modal-button flex"
                }
              >
                <FontAwesomeIcon style={{ margin: "0 5px" }} icon={faSave} />
                Save
                {loading && (
                  <div style={{ marginLeft: "5px" }}>
                    <Loader
                      type="TailSpin"
                      color="white"
                      height={20}
                      width={20}
                    />
                  </div>
                )}
              </button>

              <button
                onClick={() => history.push("/admin/ccplus")}
                className="cancel-modal-button2"
              >
                <FontAwesomeIcon
                  style={{ margin: "0 5px" }}
                  icon={faTimes}
                  color="black"
                />
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default RequiredGoal;
