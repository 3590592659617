import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import GetStudentService from "../../../../services/SchoolIT/GetStudentService";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import AppHeader from "../../../../Layout/AppHeader";
import AppSidebar from "../../../../Layout/AppSidebar";
import swal from "sweetalert";

const MapCourseData = () => {
  const history = useHistory();
  const location = useLocation();
  const [courseId, setCourseId] = useState(
    location?.pathname?.split("/").pop()
  );
  const [courseData, setCourseData] = useState();
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [gradeColumnExpression, setGradeColumnExpression] = useState(false);
  const [unitColumnExpression, setUnitColumnExpression] = useState(false);
  const [allCourseData, setAllCourseData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  const [selectedCourseName, setSelectedCourseName] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [selectedDataSource, setSelectedDataSource] = useState();
  const [dataColumn, setDataColumn] = useState([]);
  const [addGradeValue, setAddGradeValue] = useState([]);
  const [addUnitValue, setAddUnitValue] = useState([]);
  const [arithemeticData, setArithemeticData] = useState([]);
  const [addClassValue, setAddClassValue] = useState([]);
  const [showAddNewCourse, setShowAddNewCourse] = useState(false);
  const [columnId, setColumnId] = useState();
  const [gradeExpressionColumnId, setGradeExpressionColumnId] = useState();
  const [unitExpressionColumnId, setUnitExpressionColumnId] = useState();
  const [courseMapData, setCourseMapData] = useState();
  const [disabledCourseList, setDisabledCourseList] = useState(false);

  const getColumnForDataSource = () => {
    GetStudentService.getDataSrcCol(selectedDataSource)
      .then((data) => {
        console.log("data frm the source", data);
        setDataColumn(data?.data?.objects);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const saveMapCourse = () => {
    let courseObj = {
      class_column: columnId,
      class_id: "",
      course: addClassValue,
      courselist_id: courseMapData?.id || "",
      data_src: selectedDataSource && selectedDataSource,
      grade_checkbox: gradeColumnExpression,
      grade_column: gradeExpressionColumnId,
      grade_expression: addGradeValue,
      unit_checkbox: unitColumnExpression,
      unit_column: unitExpressionColumnId,
      unit_expression: addUnitValue,
    };
    let obj = {};
    obj["course_id"] = courseId;
    obj["course_values"] = showAddNewCourse ? [null, courseObj] : [];
    obj["school_id"] = user?.school_id;
    console.log("obj from the save map course", obj);
    GetStudentService.listUpdateMapCourse(obj)
      .then((data) => {
        console.log("data from the list update", data);
        history.push("/school_it/ccplus");
      })
      .catch((error) => {
        console.log("error from the service", error);
        history.push("/school_it/ccplus");
      });
  };

  const getCourseData = () => {
    GetStudentService.getCourse(courseId)
      .then((data) => {
        setCourseData(data?.data);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const getAllCourses = () => {
    GetStudentService.getAllCourse(user?.school_id)
      .then((data) => {
        console.log("data from the all service", data);
        setAllCourseData(data?.data?.objects);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const getDataSource = () => {
    GetStudentService.getDataSrc(user?.school_id)
      .then((data) => {
        console.log("data from the user", data);
        setDataSource(data?.data?.objects);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getArithemeticOperator = () => {
    GetStudentService.getArithemeticOperator()
      .then((data) => {
        console.log("data from the arithemetic service", data);
        setArithemeticData(data?.data?.objects);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  useEffect(() => {
    getCourseData();
    getAllCourses();
    getDataSource();
    getArithemeticOperator();
    copyMapCourse(courseId, "map");
    if (courseMapData?.data_src) {
      console.log(selectedDataSource, courseMapData?.data_src);
      getColumnForDataSource();
    }
  }, []);

  useEffect(() => {
    let course = allCourseData?.filter(
      (item) => item.name === selectedCourseName
    );
    console.log(course[0]);
    setSelectedCourse(course[0]);
  }, [selectedCourseName]);

  const copyMapCourse = (courseId, mode) => {
    console.log("143, ", courseId, mode);
    GetStudentService.copyCourseMap(courseId, user?.school_id).then((data) => {
      console.log(data);
      if (data?.data?.objects?.length <= 0 && mode === "copy") {
        console.log("first");
        swal({
          text: `No Mapping Found For the Required Course Set you have Selected`,
          icon: "warning",
          dangerMode: true,
          buttons: {
            cancel: true,
          },
          closeOnClickOutside: false,
        });
      } else if (data?.data?.objects?.length > 0) {
        // else{
        console.log("first");
        setCourseMapData(data?.data?.objects[0]);
        setShowAddNewCourse(true);
      }
    });
  };
  // console.log("courseMapData", courseMapData);

  useEffect(() => {
    console.log(courseMapData);
    if (courseMapData) {
      setColumnId(courseMapData?.class_column);
      setSelectedDataSource(courseMapData?.data_src);
      addClassGradeUnitValue();
      setUnitExpressionColumnId(courseMapData?.unit_column);
      setGradeExpressionColumnId(courseMapData?.grade_column);
      setDisabledCourseList(true);
    }
  }, [courseMapData]);

  const addClassGradeUnitValue = () => {
    let arr = [];
    let obj = courseMapData?.course_name;
    for (let x in obj) {
      arr.push({
        newitem: x,
        active: 1,
        course: obj[x][0],
        id: obj[x][1],
      });
    }
    setAddClassValue(arr);
    arr = [];
    obj = courseMapData?.grade_expression;
    for (let x in obj) {
      arr.push({
        newitem: x,
        grade_exp: obj[x][0],
        grade_val: obj[x][1],
        id: obj[x][2],
      });
    }
    if (arr.length > 0) setGradeColumnExpression(true);
    setAddGradeValue(arr);
    arr = [];
    obj = courseMapData?.unit_expression;
    for (let x in obj) {
      arr.push({
        newitem: x,
        unit_exp: obj[x][0],
        unit_val: obj[x][1],
        id: obj[x][2],
      });
    }
    if (arr.length > 0) setUnitColumnExpression(true);
    setAddUnitValue(arr);
  };

  useEffect(() => {
    if (selectedDataSource) {
      getColumnForDataSource();
    }
  }, [selectedDataSource]);

  const addGradeExpression = () => {
    console.log(addGradeValue);
    if (addGradeValue?.length <= 2) {
      let newitem = addGradeValue.length;
      let push_item = {
        newitem: newitem,
        grade_exp: "",
        grade_val: "",
        id: "",
      };
      setAddGradeValue([...addGradeValue, push_item]);
    }
  };

  const addUnitExpression = () => {
    if (addUnitValue?.length <= 2) {
      let newitem = addUnitValue.length;
      let push_item = {
        newitem: newitem,
        unit_exp: "",
        unit_val: "",
        id: "",
      };
      setAddUnitValue([...addUnitValue, push_item]);
    }
  };

  const addGradeExpressionOperatorHandler = (e, index) => {
    addGradeValue[index].grade_exp = parseInt(e.target.value);
    setAddGradeValue([...addGradeValue]);
  };

  const addGradeExpressionInputHandler = (e, index) => {
    addGradeValue[index].grade_val = e.target.value;
    setAddGradeValue([...addGradeValue]);
  };

  const addUnitExpressionOperatorHandler = (e, index) => {
    addUnitValue[index].unit_exp = parseInt(e.target.value);
    setAddUnitValue([...addUnitValue]);
  };

  const addUnitExpressionInputHandler = (e, index) => {
    addUnitValue[index].unit_val = e.target.value;
    setAddUnitValue([...addUnitValue]);
  };

  const addClass = () => {
    let newitem = addClassValue.length;
    console.log(newitem);
    var push_item = {
      newitem: newitem,
      course: "",
      id: "",
      active: 1,
    };
    setAddClassValue([...addClassValue, push_item]);
  };

  const handleClassInputChange = (e, index) => {
    addClassValue[index].course = e.target.value;
    console.log(addClassValue);
    setAddClassValue([...addClassValue]);
  };

  const removeClassValueFromArray = (index) => {
    addClassValue?.splice(index, 1);
    setAddClassValue([...addClassValue]);
  };

  // console.log("addGrade Value Expression", addClassValue);
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div style={{ padding: "15px" }}>
            <div className="flex" style={{ justifyContent: "space-between" }}>
              <p style={{ fontSize: "24px", fontWeight: "700" }}>
                Map Course Data
              </p>
              <button
                onClick={() => history.push("/school_it/ccplus")}
                className="create-maintain-group-button"
              >
                Back
              </button>
            </div>
            <div>
              <p style={{ fontSize: "18px", fontWeight: "700" }}>{`Course: ${
                courseData?.name
              }`}</p>
              <p>{courseData?.description}</p>
              <p>{`Years : ${courseData?.min_credit_req}`}</p>
              <p>{`Grade : ${courseData?.min_grade}`}</p>
            </div>
            <div>
              <select
                disabled={disabledCourseList}
                value={selectedCourseName}
                onChange={(e) => setSelectedCourseName(e.target.value)}
              >
                <option selected disabled value={null}>
                  --Required Course Set--
                </option>
                {allCourseData?.map((item, index) => (
                  <option value={item?.name}>{item?.name}</option>
                ))}
              </select>
            </div>
            <div style={{ marginTop: "15px" }}>
              <button
                onClick={() => copyMapCourse(selectedCourse?.id, "copy")}
                disabled={!selectedCourse}
                style={{
                  opacity: !selectedCourse ? "0.6" : "1",
                  border: "none",
                  outline: "none",
                  borderRadius: "5px",
                  background: !selectedCourse ? "#5bc0de" : "#057ebb",
                  color: "white",
                  padding: "5px",
                }}
              >
                Copy Required Course Set
              </button>
            </div>
            {!showAddNewCourse && (
              <div style={{ marginTop: "15px" }}>
                <button
                  onClick={() => setShowAddNewCourse(!showAddNewCourse)}
                  className="add-new-course"
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ margin: "0px 5px" }}
                  />
                  Add New Course
                </button>
              </div>
            )}
            {showAddNewCourse && (
              <div
                className="flex"
                style={{ justifyContent: "space-around", marginTop: "10px" }}
              >
                <select
                  value={selectedDataSource}
                  onChange={(e) => setSelectedDataSource(e.target.value)}
                  style={{ alignSelf: "baseline" }}
                >
                  <option>--Data Source--</option>
                  {dataSource?.map((item) => (
                    <option value={item?.id}>{item?.name}</option>
                  ))}
                </select>

                <select
                  style={{ alignSelf: "baseline" }}
                  value={columnId}
                  onChange={(e) => setColumnId(e.target.value)}
                >
                  <option>--Class ID Column--</option>
                  {dataColumn?.map((item) => (
                    <option value={item?.id}>{item?.column_name}</option>
                  ))}
                </select>
                <div
                  className="flex"
                  style={{
                    flexDirection: "column",
                    width: "300px",
                    alignSelf: "baseline",
                  }}
                >
                  <div className="flex">
                    <p className="no-margin-bottom">
                      <strong>Grade = </strong>
                    </p>
                    <select
                      value={gradeExpressionColumnId}
                      onChange={(e) =>
                        setGradeExpressionColumnId(e.target.value)
                      }
                    >
                      <option>--Grade Column--</option>
                      {dataColumn?.map((item) => (
                        <option value={item?.id}>{item?.column_name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex">
                    <input
                      type="checkbox"
                      checked={gradeColumnExpression}
                      onChange={() => {
                        if (
                          !gradeColumnExpression &&
                          addGradeValue.length === 0
                        )
                          addGradeExpression();
                        setGradeColumnExpression(!gradeColumnExpression);
                      }}
                      value={gradeColumnExpression}
                    />
                    <p className="no-margin-bottom"> &nbsp; Add Expression</p>
                  </div>
                  {addGradeValue.length > 0 &&
                    gradeColumnExpression &&
                    addGradeValue.map((item, index) => {
                      return (
                        <div className="flex">
                          <select
                            value={item.grade_exp}
                            onChange={(e) =>
                              addGradeExpressionOperatorHandler(e, index)
                            }
                          >
                            <option>--Select Operator--</option>
                            {arithemeticData?.map((item) => (
                              <option value={parseInt(item?.id)}>
                                {item.operator}
                              </option>
                            ))}
                          </select>{" "}
                          &nbsp;
                          {!(item.grade_exp === 5 || item.grade_exp === 6) && (
                            <input
                              value={item.grade_val}
                              style={{ width: "100px" }}
                              onChange={(e) =>
                                addGradeExpressionInputHandler(e, index)
                              }
                            />
                          )}
                        </div>
                      );
                    })}
                  {gradeColumnExpression &&
                    addGradeValue.length > 0 &&
                    addGradeValue?.length <= 2 && (
                      <button onClick={addGradeExpression}>
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    )}
                </div>
                <div
                  className="flex"
                  style={{
                    flexDirection: "column",
                    width: "300px",
                    alignSelf: "baseline",
                  }}
                >
                  <div className="flex">
                    <p className="no-margin-bottom">
                      <strong>Years =</strong>
                    </p>
                    <select
                      onChange={(e) =>
                        setUnitExpressionColumnId(e.target.value)
                      }
                      value={unitExpressionColumnId}
                    >
                      <option>--Units Column--</option>
                      {dataColumn?.map((item) => (
                        <option value={item?.id}>{item?.column_name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex">
                    <input
                      type="checkbox"
                      checked={unitColumnExpression}
                      onChange={() => {
                        if (!unitColumnExpression && addUnitValue.length === 0)
                          addUnitExpression();
                        setUnitColumnExpression(!unitColumnExpression);
                      }}
                      value={unitColumnExpression}
                    />
                    <p className="no-margin-bottom"> &nbsp; Add Expression</p>
                  </div>

                  {addUnitValue.length > 0 &&
                    unitColumnExpression &&
                    addUnitValue.map((item, index) => {
                      return (
                        <div className="flex">
                          <select
                            value={item.unit_exp}
                            onChange={(e) =>
                              addUnitExpressionOperatorHandler(e, index)
                            }
                          >
                            <option>--Select Operator--</option>
                            {arithemeticData?.map((item) => (
                              <option value={parseInt(item?.id)}>
                                {item.operator}
                              </option>
                            ))}
                          </select>{" "}
                          &nbsp;
                          {!(item.unit_exp === 5 || item.unit_exp === 6) && (
                            <input
                              style={{ width: "100px" }}
                              value={item.unit_val}
                              onChange={(e) =>
                                addUnitExpressionInputHandler(e, index)
                              }
                            />
                          )}
                        </div>
                      );
                    })}
                  {unitColumnExpression &&
                    addUnitValue.length > 0 &&
                    addUnitValue?.length <= 2 && (
                      <button onClick={addUnitExpression}>
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    )}
                </div>
              </div>
            )}
            {addClassValue?.length > 0 &&
              addClassValue?.map((item, index) => {
                return (
                  <div style={{ margin: "10px 0px 10px 20px" }}>
                    <p style={{ margin: 0, fontWeight: 700 }}>Courses</p>
                    <div className="flex">
                      <button
                        className="remove-class-button "
                        onClick={() => removeClassValueFromArray(index)}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>

                      <input
                        type="text"
                        onChange={(e) => handleClassInputChange(e, index)}
                        value={addClassValue[index].course}
                      />
                    </div>
                  </div>
                );
              })}
            {showAddNewCourse && (
              <div style={{ marginTop: "15px" }}>
                <button onClick={addClass} className="add-new-course">
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ margin: "0px 5px" }}
                  />
                  Add New Class
                </button>
              </div>
            )}

            <div style={{ marginTop: "15px" }}>
              <button
                style={{ marginRight: "5px" }}
                className="save-modal-button"
                onClick={saveMapCourse}
              >
                Save
              </button>
              <button
                style={{ marginLeft: "5px" }}
                className="cancel-modal-button"
                onClick={() => history.push("/school_it/ccplus")}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default MapCourseData;
