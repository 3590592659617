import React, { useState , useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const CustomPagination=({pageValue , setPage , count})=>{
  
    const handleChange=(event , value)=>{
      setPage(value)
    }
   return(
       <div style={{padding:'15px 0px'}}>
           <Pagination count={count && count.max} page={pageValue} onChange={handleChange}/>
       </div>
   )
}
export default CustomPagination