import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
// import Main from './DemoPages/Main';
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";
import App from "./App";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "./reducers/rootReducer";
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react';

// export const store = configureStore();


const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  // rootReducer,
  persistedReducer,
  // other store enhancers (like middleware) here
  composeWithDevTools(applyMiddleware(thunk))
);

const persistor = persistStore(store);

const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter>
          <Component />
        </HashRouter>
      </PersistGate>
    </Provider>,
    rootElement
  );
};

renderApp(App);

// if (module.hot) {
//     module.hot.accept('./DemoPages/Main', () => {
//         const NextApp = require('./DemoPages/Main').default;
//         renderApp(NextApp);
//     });
// }
// serviceWorker.unregister();
