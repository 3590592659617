import React,{ Fragment } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const ErrorModal=({openErrorModal , setOpenErrorModal, text, showDontDeleteBannerMsg})=>{
  return(
      <Fragment>
            <Modal
                className="save-modal"
                wrapClassName="modal-dialog"
                contentClassName="modal-dialog"
                isOpen={openErrorModal}
                toggle={() => setOpenErrorModal(false)}
            >
                <ModalHeader className="modal-header">
                    <div style={{ justifyContent: 'space-between' }} className="flex">
                        {!showDontDeleteBannerMsg && <div style={{ fontSize: '16px', fontWeight: 'bold', wordBreak: 'break-all' }} className="no-margin-bottom color">
                            <p className="no-margin-bottom">{`The given ${text === "Campaign" ? "Campaign" : text === "Banner" ? "Banner" : "Query"} Name already exist`}</p>
                        </div>}
                        {showDontDeleteBannerMsg && <div style={{ fontSize: '16px', fontWeight: 'bold', wordBreak: 'break-all' }} className="no-margin-bottom color">
                            <p className="no-margin-bottom">{`The banner you are trying to delete is already in use.`}</p>
                        </div>}
                        <div onClick={() => setOpenErrorModal(false)} className="cursor">
                            <CloseIcon />
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <button className="dialog-button" style={{display:'flex', justifyContent:'center', alignItems:"center", width:'100%'}} onClick={()=>setOpenErrorModal(false)}>
                        <span><CheckIcon /></span>
                        <span>Ok</span>
                    </button>
                </ModalBody>
                </Modal>
      </Fragment>
  )
}
export default ErrorModal