import React from "react";
import { Modal } from "reactstrap";

function Privacy({ isOpen, setIsOpen }) {
  const toggle = () => {
    setIsOpen(false);
  };
  return (
    <Modal isOpen={isOpen} centered={true} toggle={toggle}>
      <div className="modal-header">
        <h4 className="modal-title w3-text-blue">
          <center>Siembra Mobile, Inc. Privacy Policy</center>
        </h4>
        <button
          type="button"
          className="close"
          onClick={toggle}
          aria-hidden="true"
        >
          ×
        </button>
      </div>
      <div
        style={{ padding: "25px", textAlign: "justify" }}
        className="modal-body"
      >
        <h3>
          <span style={{ fontSize: "medium" }}>
            <strong>Siembra Mobile Inc. – Privacy Policy</strong>
          </span>
        </h3>
        <p>
          <span style={{ fontSize: "small" }}>
            At Siembra Mobile, Inc., we have created and operate a student
            management platform and associated services that provide college
            admission offices with innovation. Like many hosted service
            providers, the name of our company is also the name of our suite of
            product and service solutions. We refer to our platform and services
            as “Siembra” (which means “to plant and to harvest”). In this Policy
            we also refer to the Siembra Mobile, Inc. entity as the “Company,”
            “we” or “our.” We have documented our policy in plain English, to
            make this Policy, as well as our Terms of Use Policy, more
            accessible to the students, parents, College Admissions
            professionals, and educators
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            In basic terms, Siembra is designed to be a hub for student mobile
            educational interventions and empowering ownership of their academic
            careers directly in an effective game-ified environment. We provide
            a configurable holistic solution for student performance. The core
            of our feature set is to make collecting and providing actionable
            information easier for University Recruiters, Community College
            Outreach Coordinators, Community College Transfer Services, K-12
            Superintendents, Parents and most importantly, the Student (called
            the “college recruiting matrix”). In addition to parties within the
            recruiting matrix, many of the Universities, Community Colleges and
            school districts who use Siembra authorize other champions (like the
            Gear UP program) to access student progress information. We also
            give parents or guardians the ability to login to a Siembra’s
            “parent portal” to access progress information relating to her
            student. To make this Policy a bit more readable, unless the context
            indicates otherwise, we refer to:
          </span>
        </p>
        <ul>
          <li style={{ fontSize: "small" }}>
            University Admissions in their work directly with students as
            “Recruiters,”
          </li>
          <li style={{ fontSize: "small" }}>
            Superintendents, Principals, and other support personnel authorized
            by a school to use Siembra as “Educators,”
          </li>
          <li style={{ fontSize: "small" }}>
            Adult parents or guardians of a minor student authorized by a school
            to use Siembra as “Parents,” and authorized school official and
            parent or student user as “you.”
          </li>
          <li style={{ fontSize: "small" }}>
            Although we provide features for K-12 personnel that provide direct
            student interaction, Siembra is currently intended for use by
            Recruiters, Community College Outreach Coordinators, Early Outreach
            Professionals, Students, and their Parents to support students to
            the College of their choice
          </li>
        </ul>
        <p>
          <span style={{ fontSize: "small" }}>
            Much of our value is our ability to process and present information
            relating to student progress in usable ways. In other words, when
            schools and school officials take advantage of Siembra’s full
            potential, they are providing and accessing information relating to
            the students entrusted to them and are in turn entrusting that
            information to us. That trust is integral to our social mission to
            advance all students to higher education in a fair and equitable
            way, and we have prepared this Policy to be transparent about the
            steps we take to protect information entered in Siembra about
            Students, School Officials and Recruiters, who has access to that
            information and how that information is used.
          </span>
        </p>
        <h3>
          <span style={{ fontSize: "small" }}>
            <strong>SCOPE OF POLICY </strong>
          </span>
        </h3>
        <p>
          <span style={{ fontSize: "small" }}>
            This Policy describes the types of information we may collect, or
            that you may provide, when registering with and accessing or using
            Siembra. This Policy does not apply to information we collect
            offline or on any other Company websites (such as our company site
            here) or to information that you may provide to, or is collected by,
            third parties.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            <strong>ROLE OF SCHOOL AND SCHOOL OFFICIALS </strong>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            Although the balance of this Policy will focus largely on what we do
            — and what we confirm we will not do — with information entered in
            Siembra, we believe schools and school officials are critical
            partners in our efforts to protect and ensure only appropriate use
            of student related information entrusted to them and to us. In that
            regard, it is important that schools and school officials using
            Siembra be mindful that in granting or allowing access to Siembra,
            they are controlling who has access to student information. When we
            reference “granting or allowing access,” we are referring to both
            intentional actions, such as an administrator authorizing a Siembra
            account for a Recruiter, as well as unintentional actions or
            consequences that may flow from, for example, allowing students
            access to Siembra login credentials or a school’s failure to
            maintain sufficient data governance or security practices.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            In cases where FERPA applies (more below), access to certain student
            information remains the legal responsibility of the applicable
            school. In all situations, it is incumbent upon our customers to
            make an affirmative determination prior to furnishing access to
            anyone that the party has a legitimate need for access to Siembra
            and the sensitive information that may be accessible to that party
            through Siembra.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            <strong>INFORMATION ABOUT STUDENTS </strong>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            Although the Family Educational Rights and Privacy Act, or FERPA,
            was enacted roughly 45 years ago, and certainly well before
            internet-based services became ubiquitous in academic settings, one
            of its core tenets was and remains the protection of the privacy of
            personally identifiable information (often called “PII”) in
            students’ education records. As defined in FERPA, “education
            records” are “those records, files, documents and other materials
            which (i) contain information directly related to a student; and
            (ii) are maintained by an educational agency or institution or by a
            person acting for such agency or institution.” PII from education
            records includes information, such as a student’s name or
            identification number that can be used to distinguish or trace an
            individual’s identity, either directly or indirectly through
            linkages with other information.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            FERPA generally requires that educational institutions and agencies
            that receive certain federal funds (for example, public schools) get
            prior consent from a parent before disclosing any education records
            regarding that student to a third party. Consequently, if you are
            using Siembra on behalf of an educational agency or institution and
            FERPA applies, before you enter, upload, or access any data
            concerning a minor student, you must confirm that your agency or
            institution has (1) obtained appropriate consent from the parent or
            guardian of that student or (2) determined that one of the limited
            exceptions to the consent requirement applies.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            Some people tend to link (and sometimes confuse) FERPA and COPPA.
            The intent of the Children’s Online Privacy Protection Act, or
            COPPA, is to give parents control over commercial websites and
            online services’ collection, use and disclosure of information from
            children under the age of 13. Many assume COPPA applies to all
            internet-based services, regardless of the identity of the end user.
            When Siembra is used as intended by high school officials and
            parents, COPPA does not apply.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            <strong>DISCLOSURE AND RETENTION OF PII </strong>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            When we integrate with a school’s information system, we receive
            personally identifiable information:
          </span>
        </p>
        <ul>
          <li style={{ fontSize: "small" }}>School</li>
          <li style={{ fontSize: "small" }}>Name </li>
          <li style={{ fontSize: "small" }}>Email </li>
          <li style={{ fontSize: "small" }}>Mobile Number </li>
          <li style={{ fontSize: "small" }}>
            Parent/Guardian email (not applicable for Community College or
            University Students){" "}
          </li>
          <li style={{ fontSize: "small" }}>Gender/Ethnicity </li>
          <li style={{ fontSize: "small" }}>Grade Point Average Range </li>
          <li style={{ fontSize: "small" }}>Course Completion </li>
        </ul>
        <p>
          <span style={{ fontSize: "small" }}>
            You can opt out any time by unchecking the box on your profile page.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            We only use PII from students’ education records to enable
            Recruiters, Administrators and Parents to access and use Siembra.
            Unless a school official expressly instructs otherwise, we do not
            share or reuse PII from education records for any other purpose.
            Siembra is an “opt-out” system for non-profit university
            recruitment, we limit such information to non-profit University
            Admissions Offices, Community College Outreach Coordinators, Early
            Outreach Organizations like Gear UP, Educators and Parents. If in
            the event in the future Siembra provides a separate “opt-in” for
            for-profit health science colleges, that designation will be obvious
            and separate, requiring a distinct and separate action for students
            who might seek that potential college option.{" "}
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            Except as expressly set forth below and under the Third-Party
            Information Collection heading above, and only in those limited
            circumstances, we will not disclose any PII relating to students,
            parents, or school officials to third parties without your consent
            or the consent of your associated school.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            If a third-party purchase all or most of our ownership interests or
            assets, or we merge with another organization, it is possible that
            we would need to disclose PII to the other organization following
            the transaction, for example, were we to integrate Siembra with the
            other organization’s product offerings. To the extent any such
            transaction would alter our practices relative to this Policy, we
            will give schools advance notice of those changes and any choices
            they may have regarding PII.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            We may also disclose PII to comply with a court order, law, or legal
            process (including a government or regulatory request), but before
            we would do that, we would provide the applicable school with notice
            of the requirement so that, if the school so chooses, it could seek
            a protective order or other remedy. If after providing that notice
            we remain obligated to disclose the demanded PII, we will disclose
            no more than that portion PII which, on the advice of our legal
            counsel, the order, law, or process specifically requires us to
            disclose.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            We retain PII for as long as the applicable school maintains it. We
            align with the applicable school’s policy. When those subscriptions
            lapse or terminate, unless a written agreement between us and a
            school provides otherwise, we retain PII for up to 12 months after
            which time it will be destroyed. Any retained PII will of course
            remain subject to the restrictions on disclosure and use outlined in
            this policy for as long as it resides with us.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            We do not display any commercial advertising on our site. We do use
            third parties, such as Facebook, to provide information about our
            educational products. Only hashed, non-identifiable information is
            provided to these third parties. Only individuals that have
            independently created an account on our site and opted in to receive
            marketing communications from us may receive interest-based
            promotions. No personally identifiable information is shared with
            those third parties.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            Finally, although we outlined earlier in this Policy what
            constitutes PII, we also want to be clear what information is not
            PII. Once PII, whether relating a school official, parent or student
            has been de-identified, that information is no longer PII. PII may
            be de-identified through aggregation or Siembra’s Platform and
            Service Privacy Policy or by various other means. The U.S.
            Department of Education has issued guidance on de-identifying PII in
            education records here. To allow us to proactively address customer
            needs, we anticipate using de-identified information to improve
            Siembra and other of our products and services. That said, we will
            use reasonable de-identification approaches to ensure that in doing
            so, we are not compromising the privacy or security of the PII you
            entrust to us.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            <strong>DATA SECURITY</strong>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            Our primary integration is directly between the application server
            and the school information system data source. Data is encrypted at
            the source and decrypted at the web server running on the same host
            as the application server. We have security measures in place to
            help protect against the loss, misuse, and alteration of the data
            under our control. When the service is accessed using a supported
            web browser, Transport Layer Security (TLS) technology protects
            information using both server authentication and data encryption to
            help ensure that data are safe, secure, and available only to
            authorized users. We also implement an advanced security method
            based on dynamic data and encoded session identifications and hosts
            the service in a secure server environment that uses a firewall and
            other advanced technology to prevent interference or access from
            outside intruders. We require unique account identifiers, usernames
            and passwords that must be entered each time a user signs on. Single
            Sign-On (SSO) &amp; Security Assertion Mark-Up Language (SAML)
            Requirements are supported by our application hosted by Amazon Web
            Service. Authentication and authorization are built into the
            application. Data is exchanged through encrypted channels.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            We have also implemented measures designed to secure PII from
            accidental loss and from unauthorized access, use, alteration, and
            disclosure. Among other things, PII is encrypted in transit to and
            from Siembra using SSL technology. In addition, all PII is stored on
            secure servers behind firewalls by our hosting providers. In
            addition, consistent with guidance from the U.S. Department of
            Education that storing sensitive education records within the United
            States is a “best practice,” all the servers used by Siembra are in
            the United States. All that said, unfortunately, the transmission of
            information via the internet is not completely secure and, although
            we do our best to protect PII, neither we nor any other hosted
            service provider can absolutely guarantee the security of all
            personally identifiable information.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            As discussed under Disclosure and Retention of PII, we use
            third-party providers to support elements of our infrastructure and
            functionality. These providers may, like us, use automatic
            information collection technologies to enable or streamline certain
            features they are providing on our behalf. In all cases, these
            providers are contractually bound to us to keep PII confidential and
            to only in use it to fulfill their responsibilities to us. With
            respect to technology that automates information collection, we use
            the following:
          </span>
        </p>
        <ul>
          <li style={{ fontSize: "small" }}>
            {" "}
            &nbsp;&nbsp;&nbsp;&nbsp; Amazon Web Service Secure File Transfer
            Protocol (SFTP) for data transfer
          </li>
          <li style={{ fontSize: "small" }}>
            {" "}
            &nbsp;&nbsp;&nbsp;&nbsp; Application Programming Interface (API){" "}
          </li>
        </ul>
        <p>
          <span style={{ fontSize: "small" }}>
            In support of operating, hosting, and enhancing our website(s),
            personally identifiable information may be accessed by contractors
            who work on programming and technical aspects of website operation.
            All contractors follow our confidentiality and privacy conditions.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            Fraud Prevention and Test Security: We may collect data to identify
            specific individuals for fraud prevention or test security. This
            information may be collected, stored, reviewed, and used for (1)
            identifying and/or investigating possible test security incidents;
            (2) collecting evidence in connection with possible test security
            incidents; and (3) enhancing test security. We will not use or
            disclose this information except as described above, as requested by
            law enforcement, and/or as reasonably necessary. In the event of a
            data breach, we suspend service immediately and communicate to our
            partners within 24 hours of the incident.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            <strong>CHANGES TO OUR PRIVACY POLICY</strong>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            We may update this Privacy Policy from time to time. If we make
            material changes, we will post the updated policy on this page (with
            a notice that the policy has been updated) and notify schools by
            email using the primary email address specified in their accounts.
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            <strong>CONTACT INFORMATION</strong>
          </span>
        </p>
        <p>
          <span style={{ fontSize: "small" }}>
            You can and should ask questions about this Policy and our privacy
            practices. You should always feel free to contact us at:
          </span>
        </p>
        <ul>
          <li style={{ fontSize: "small" }}>Siembra Mobile, Inc. </li>
          <li style={{ fontSize: "small" }}>
            Email: support@siembramobile.com{" "}
          </li>
          <li style={{ fontSize: "small" }}>Dr. Michael Heideman</li>
          <li style={{ fontSize: "small" }}>mike@siembramobile.com </li>
          <li style={{ fontSize: "small" }}>Phone: (650) 823-1905 </li>
        </ul>
        <br />
      </div>
    </Modal>
  );
}
export default Privacy;