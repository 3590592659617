// Type
export const MESSAGE_NOTIFICATION = 'MESSAGE_NOTIFICATION';

// Action
export const messageNotificationAction = (data) => {
    return ({
        type: MESSAGE_NOTIFICATION,
        data: data
    })
}

const initState = {
    messageNotification: null,
}

const MessageNotificationReducer = (state = initState, action) => {
    switch (action.type) {
        case MESSAGE_NOTIFICATION:
            return {
                ...state,
                messageNotification: action.data
            }

        default: return state;
    }
}
export default MessageNotificationReducer;