const initialState = {
  query: {},
};
export const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_LOCATION_QUERY":
      return { ...state, query: action.payload };

    default:
      return state;
  }
};

export const changeQueryLocation = (data) => {
  return {
    type: "CHANGE_LOCATION_QUERY",
    payload: data,
  };
};
