import { request } from '../services/Request';
import { routes } from '../constants/constant.route';
import { urlConstants } from "../utils/urlConstants";
const {API_URL } = urlConstants;

export default class CampaignService {
    static getCampaignData(body) {     
        return request("POST", `${API_URL}${routes.campaignPage.campaignPage}`, null, body, null);
    }
    static getBannerData(body) {     
        return request("POST", `${API_URL}${routes.bannerPage.bannerPage}`, null, body, null);
    }
    static getCampaignQuery(value){
        return request("GET", `${API_URL}${routes.getCampaignQuery.getCampaignQuery}?college=${value}`, null,null,null)
    }
    static getCampaignResultTable(value){
        return request("POST", `${API_URL}${routes.getCampaignResultTable.getCampaignResultTable}`, null,value,null)
    }
    static getCampaignBannerQuery(value){
        return request("GET", `${API_URL}${routes.getCampaignBannerQuery.getCampaignBannerQuery}?college=${value}`, null,null,null)
    }
    static createCampaign(body) {     
        return request("POST", `${API_URL}${routes.createCampaign.createCampaign}`, null, body, null);
    }
    static deleteCampaign(body){
        return request("POST", `${API_URL}${routes.deleteCampaign.deleteCampaign}`, null, body, null);
    }
    static saveEditCampaign(id , body){
        return request("PATCH", `${API_URL}${routes.editCampaign.editCampaign}${id}`, null, body, null);
    }
    static knowBannerDeleteOrNot(body){
        return request("POST", `${API_URL}${routes.deleteForBannerRequirement.deleteForBannerRequirement}`, null, body, null);
    }
    static uploadImageBanner(body){
        return request("POST", `${API_URL}${routes.uploadBannerImage.uploadBannerImage}`, null, body, null, null, "formData")
    }
    static createBanner(body){
        return request("POST", `${API_URL}${routes.createBanner.createBanner}` , null, body, null, null)
    }
}