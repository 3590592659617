import React, { Fragment } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledButtonDropdown,
  DropdownItem,
} from "reactstrap";

import { toast, Bounce } from "react-toastify";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import Storage from "../../../services/Storage";
import axios from "axios";
import { connect } from "react-redux";
import { resetStateAction } from "../../../reducers/ResetReducer";
import { Roles } from "../../../utils/Roles";

const API_URL = process.env.REACT_APP_API_URL;
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const guideUrls = {
  [Roles.STUDENT_NAME]:
    "https://frontend.siembramobile.com/WebUserguide/Student270124/index.html",
  [Roles.PARENT_NAME]:
    "https://frontend.siembramobile.com/WebUserguide/Parent194890/index.html",
  [Roles.RECRUITER_NAME]:
    "https://frontend.siembramobile.com/WebUserguide/Recruiter950378/index.html",
  [Roles.COUNSELOR_NAME]: {
    true:
      "https://frontend.siembramobile.com/WebUserguide/SuperAdmin096534/index.html",
    false:
      "https://frontend.siembramobile.com/WebUserguide/Counselor239856/index.html",
  },
};
class UserBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  notify2 = () =>
    (this.toastId = toast(
      "You don't have any new items in your calendar for today! Go out and play!",
      {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-center",
        type: "success",
      }
    ));
  handleProfile = () => {
    const { userInfo } = this.props;
    if (userInfo?.role === Roles.STUDENT_NAME) {
      this.props.history.push("/student/profile");
    } else if (userInfo?.role === Roles.PARENT_NAME) {
      this.props.history.push("/parent/profile");
    } else if (userInfo?.role === Roles.RECRUITER_NAME) {
      this.props.history.push("/recruiter/profile");
    } else if (
      userInfo?.role === Roles.COUNSELOR_NAME &&
      userInfo?.is_super_counselor === false
    ) {
      this.props.history.push("/counselor/profile");
    } else if (
      userInfo?.role === Roles.COUNSELOR_NAME &&
      userInfo?.is_super_counselor === true
    ) {
      this.props.history.push("/super_admin/profile");
    } else if (userInfo?.role === Roles.SCHOOL_IT_NAME) {
      this.props.history.push("/school_it/profile");
    } else if (userInfo?.role === Roles.ADMIN_NAME) {
      this.props.history.push("/admin/profile");
    }
  };

  handleLogout = () => {
    const { userInfo } = this.props;
    let sessionid = userInfo && userInfo?.sessionid;
    let obj = {
      sessionid,
    };
    axios
      .post(`${API_URL}/api/ua/logout/`, obj)
      .then((res) => {
        if (res.status === 200) {
          Storage.removeTokenCookie();
          Storage.removeSessionId();
          Storage.clearLocalStorage();
          Storage.clearSessionStorage();
          this.props.history.push("/login");
          this.props.dispatch(resetStateAction());
        }
      })
      .catch((err) => {
        console.log("err: ", err.response);
      });
  };

  handleGuide = () => {
    const { role, is_super_counselor } = this.props.userInfo;
    let url = "";

    if (role === Roles.COUNSELOR_NAME) {
      url = guideUrls[Roles.COUNSELOR_NAME][is_super_counselor];
    } else {
      url = guideUrls[role];
    }

    if (url) {
      window.open(url, "_blank");
    }
  };

  render() {
    let { userInfo } = this.props;
    return (
      <Fragment>
        <div className="header-btn-lg pr-0">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left">
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="link" className="p-1 user-detail-box">
                    <div className="d-flex align-items-center">
                      <img
                        width={35}
                        height={35}
                        className="rounded-circle"
                        src={
                          // userInfo && userInfo?.profile_image !== null
                          //   ?
                          userInfo?.profile_image || IMG_URL + "placeholder.jpg"
                        }
                        alt=""
                      />
                      <div className="widget-content-left  ml-3 header-user-info">
                        <div className="widget-heading">
                          {userInfo
                            ? userInfo?.first_name + " " + userInfo?.last_name
                            : null}{" "}
                          <FontAwesomeIcon
                            className="ml-2 opacity-8"
                            icon={faAngleDown}
                          />
                        </div>
                      </div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu
                    right
                    className="rm-pointers dropdown-menu-lg"
                    style={{ borderRadius: "10px" }}
                  >
                    <div className="rm-pointers dropdown-menu-sm p-0">
                      {/* <div className="dropdown-menu-header-inner bg-info"> */}
                      <DropdownItem onClick={this.handleProfile}>
                        My Profile
                      </DropdownItem>
                      {userInfo?.role !== Roles.ADMIN_NAME && userInfo?.role !== Roles.SCHOOL_IT_NAME &&
                      <DropdownItem onClick={this.handleGuide}>
                        {userInfo?.role === Roles.STUDENT_NAME &&
                          "Student Guide"}
                        {userInfo?.role === Roles.PARENT_NAME && "Parent Guide"}
                        {userInfo?.role === Roles.RECRUITER_NAME &&
                          "Recruiter Guide"}
                        {userInfo?.role === Roles.COUNSELOR_NAME &&
                          userInfo?.is_super_counselor === true &&
                          "Super Admin Guide"}
                        {userInfo?.role === Roles.COUNSELOR_NAME &&
                          userInfo?.is_super_counselor === false &&
                          "Counselor Guide"}
                      </DropdownItem>
                      }
                      <DropdownItem onClick={this.handleLogout}>
                        Logout
                      </DropdownItem>
                      {/* </div> */}
                    </div>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

// export default withRouter(UserBox);

const mapStateToProps = (state) => {
  return {
    userInfo: state.UserReducer.userObj,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(UserBox)
);
