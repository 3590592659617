// Type
export const USER_DETAIL = 'USER_DETAIL';

// Action
export const userInfoAction = (data) => async dispatch => {
    await dispatch({
        type: USER_DETAIL,
        data: data
    })
}

const initState = {
    userObj: {}
}

const UserReducer = (state = initState, action) => {
    switch (action.type) {
        case USER_DETAIL:
            return {
                ...state,
                userObj: action.data
            }

        default: return state;
    }
}
export default UserReducer;