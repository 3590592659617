import React , { Fragment , useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';


const ShowImageModal = ({openModal , setOpenModal}) => {
    return(
        <Fragment>
        <Modal
        className="save-modal"
        wrapClassName="modal-dialog"
        contentClassName="modal-dialog"
        isOpen={openModal}
        toggle={() => setOpenModal(false)}
    >
        <ModalHeader className="modal-header">
            <div style={{ justifyContent: 'space-between' }} className="flex">
                <div style={{ fontSize: '16px', fontWeight: 'bold', wordBreak: 'break-all' }} className="no-margin-bottom color">
                    <p className="no-margin-bottom">{`Image must be 468x60 or 160x600`}</p>
                </div>
                <div onClick={() => setOpenModal(false)} className="cursor">
                    <CloseIcon />
                </div>
            </div>
        </ModalHeader>
        <ModalBody>
            <button className="dialog-button" style={{display:'flex', justifyContent:'center', alignItems:"center", width:'100%'}} onClick={()=>setOpenModal(false)}>
                <span>Ok</span>
            </button>
        </ModalBody>
        </Modal>
</Fragment>
    )
}
export default ShowImageModal