import React from 'react';
import { Link } from 'react-router-dom';
import './CounSelorRegister.scss'
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const CounSelorRegister = () => {
    return (
        <div className='counselorRegWrap'>
            <div className='header text-center'>
                <img src={IMG_URL+"logo.png"} alt='colorLogo' className='img-fluid colorLogo' />
            </div>
            <div className='connectBlockWrap'>
                <div className='connectBlockInner text-center'>
                        <div className='connectBlock boxShadow'>
                            <img src={IMG_URL+"infoIcon.png"} alt='infoIcon' className='img-fluid infoIcon' />
                            <p>Contact the Administrator to Register as a Counselor</p>
                        </div>
                    <Link className='backLink' to='login'> {'<'} Back to Sign In</Link>
                </div>
            </div>
        </div>
    )
}

export default CounSelorRegister