import React, { Fragment, useState, useEffect } from "react";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";

const GPASlider = ({ handleGPASlider, minGPA, maxGPA }) => {
  const marks = {
    0: {
      style: {
        width: "10px",
      },
      label: "0",
    },
    0.5: {
      style: {
        width: "15px",
      },
      label: "0.5",
    },
    1: {
      style: {
        width: "20px",
      },
      label: "1",
    },
    1.5: {
      style: {
        width: "25px",
      },
      label: "1.5",
    },
    2: {
      style: {
        width: "30px",
      },
      label: "2",
    },
    2.5: {
      style: {
        width: "35px",
      },
      label: "2.5",
    },
    3: {
      style: {
        width: "40px",
      },
      label: "3",
    },
    3.5: {
      style: {
        width: "45px",
      },
      label: "3.5",
    },
    4: {
      style: {
        width: "50px",
      },
      label: "4",
    },
  };

  return (
    <Fragment>
      <div
        style={{ width: "100%", marginTop: "20px", border: "1px solid #ecee" }}
      >
        <div className="query-title-containers ">
          <p style={{ marginBottom: "0px" }} className="bold">{`GPA RANGE`}</p>
        </div>
        <div
          className="col-md-12 row flex"
          style={{ margin: "10px", alignItems: "center", padding: "20px 20px" }}
        >
          <div className="col-md-6">
            <Range
              step={0.5}
              defaultValue={[minGPA, maxGPA]}
              marks={marks}
              min={0}
              max={4}
              tipFormatter={(value) => <span className="tooltip">{value}</span>}
              activeDotStyle={{ borderColor: "#049804" }}
              dotStyle={{ borderColor: "#e36221" }}
              onChange={(value) => handleGPASlider(value)}
            />
          </div>
          <div
            style={{ justifyContent: "space-evenly" }}
            className="col-md-6 flex"
          >
            <div style={{ border: "1px solid #ece", padding: "10px" }}>
              <p style={{ marginBottom: "5px" }} className="bold font">
                Minimum GPA
              </p>
              <p className="font">{minGPA}</p>
            </div>
            <p>To</p>
            <div style={{ border: "1px solid #ece", padding: "10px" }}>
              <p style={{ marginBottom: "5px" }} className="bold font">
                Maximum GPA
              </p>
              <p className="font">{maxGPA}</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default GPASlider;
