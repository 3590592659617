import { request } from "../services/Request";
import { routes } from "../constants/constant.route";
import { urlConstants } from "../utils/urlConstants";

const { UPDATE_PROFILE, PROFILE_UPLOAD, PARENT_UPDATE_PROFILE } = routes.PROFILE;
const {API_URL} = urlConstants;

export default class ProfileService {
  static uploadProfileImage(body) {
    return request(
      "POST",
      `${API_URL}${PROFILE_UPLOAD}`,
      null,
      body,
      null,
      null,
      "formData-single-attachment",
      "user_image"
    );
  }

  static updateProfile(body) {
    return request(
      "PUT",
      `${API_URL}${UPDATE_PROFILE}`,
      null,
      body,
      null,
      null
    );
  }

  // Parent Role API
  static updateParentProfile(body) {
    return request(
      "PUT",
      `${API_URL}${PARENT_UPDATE_PROFILE}`,
      null,
      body,
      null,
      null
    );
  }
}
