import React , {Fragment, useState, useEffect } from 'react';
import CustomDatePicker from '../../datepicker/DatePicker';
const CampaignComponent=({
    isEdit,
    handleInputCampaignName,
    campaignInputValue,
    resetButton,
    saveCampaign,
    saveCampaignAfterEdit,
    startDate,
    endDate,
    selectedBanner,
    selectedQuery,
    handleRadioButtonChange,
    selectedRadioButton,
    handleSelectChange,
    bannerQuery,
    selectedResultTable,
    campaignResultTable,
    handleBannerChange,
    bannerCampaignQuery,
    setStartDate,
    setEndDate,
    divId,
    setDivId,
    adPath,
    setAdPath,
    showSelectedBanner,
    showSelectedQuery,
    showResultTable
})=>{
    return(
        <Fragment>
            <div>
            <div style={{ background: 'var(--background-color)', height: '550px' }}>
                                    <div className="campaign-header">
                                        <p className="color bold no-margin-bottom">{isEdit ? 'EDIT CAMPAIGN' : 'CREATE NEW CAMPAIGN'}</p>
                                        <div style={{ padding: '15px 0px' }}>
                                            <input
                                                className="campaign-input"
                                                placeholder={"Campaign Name"}
                                                type="text"
                                                onChange={(e) => handleInputCampaignName(e)}
                                                value={campaignInputValue}
                                            />
                                        </div>
                                        <div style={{ justifyContent: 'space-between' }} className='flex'>
                                            <div className='flex'>
                                                <input
                                                    name="use-query"
                                                    type="radio"
                                                    onChange={(e) => handleRadioButtonChange(e)}
                                                    value="use-query"
                                                    checked={selectedRadioButton === "use-query"}

                                                />
                                                <p style={{ marginLeft: '10px', fontSize: '14px' }}
                                                    className="no-margin-bottom color bold">Use Query</p>
                                            </div>
                                            <div style={{ marginRight: '60px' }} className='flex'>
                                                <input
                                                    name="use-query"
                                                    type="radio"
                                                    onChange={(e) => handleRadioButtonChange(e)}
                                                    value="use-results-table"
                                                    checked={selectedRadioButton === "use-results-table"}
                                                />
                                                <p style={{ marginLeft: '10px', fontSize: '14px' }}
                                                    className="no-margin-bottom color bold">Use Results Table</p>
                                            </div>
                                        </div>
                                        {
                                            selectedRadioButton === "use-query" && <div>
                                                <select className="use-result-table"
                                                    onChange={(e) => handleSelectChange(e)}
                                                    name="use-query"
                                                    value={selectedQuery}  >
                                                    <option disabled hidden selected value={1}>Select Student Query</option>
                                                    {bannerQuery && bannerQuery.map((item, index) => (
                                                        <option value={item.name}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        }
                                        {
                                            selectedRadioButton === "use-results-table" && <div>
                                                <select className="use-result-table"
                                                    onChange={(e) => handleSelectChange(e)}
                                                    name="use-result-table"
                                                    value={selectedResultTable} >
                                                    <option disabled hidden selected value={1}>Select Results Table</option>
                                                    {campaignResultTable && campaignResultTable.map((item, index) => (
                                                        <option value={item.table_name}>{item.table_name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        }
                                        <div style={{marginTop:'20px'}}>
                                            <select className="use-result-table"
                                                onChange={(e) => handleBannerChange(e)}
                                                name="use-result-table" 
                                                value={selectedBanner}>
                                                <option hidden selected disabled value={1}>Select Banner To Display</option>
                                                {bannerCampaignQuery && bannerCampaignQuery.map((item, index) => (
                                                    <option value={item.name}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div style={{justifyContent:'space-between',marginTop:'25px'}} className="flex">
                                            <div className="date-container">
                                                <CustomDatePicker 
                                                    date={startDate}
                                                    setDate={setStartDate}
                                                    placeHolder="Start date"
                                                 />
                                            </div>
                                            <div>
                                                <p className="no-margin-bottom">
                                                    To
                                                </p>
                                            </div>    
                                            <div className="date-container">
                                                <CustomDatePicker 
                                                   date={endDate}
                                                   setDate={setEndDate}
                                                   placeHolder="End Date" 
                                                />
                                            </div>
                                        </div> 
                                        <div className='flex' style={{justifyContent:'space-between', marginTop:'25px', marginLeft:'5px'}}>
                                            <div style={{width:'180px',height:'45px'}}>
                                                <input 
                                                  style={{width:'100%', height:'100%', paddingLeft:'5px'}}
                                                  type="text"
                                                  value={divId}
                                                  placeholder="Div Id"
                                                  onChange={(e)=>setDivId(e.target.value)} 
                                                />      
                                            </div>
                                            <div style={{width:'180px',height:'45px'}}>
                                                <input 
                                                  style={{width:'100%', height:'100%', paddingLeft:"5px"}}
                                                  type="text"
                                                  value={adPath}
                                                  placeholder="Ad Path"
                                                  onChange={(e)=>setAdPath(e.target.value)} 
                                                />      
                                            </div>
                                        </div>   
                                        <div style={{justifyContent:'space-between', marginTop:'25px'}}
                                         className="flex">
                                            <button
                                            //    disabled={ 
                                            //     !showSelectedBanner || !(showSelectedQuery || showResultTable) || !startDate || !endDate || !divId || !adPath} 
                                               className={`campaign-button active-color`} 
                                            //    ${!(campaignInputValue && campaignInputValue.trim()) || 
                                            //    !showSelectedBanner || !(showSelectedQuery || showResultTable) || !startDate || !endDate || !divId || !adPath ? 'less-opacity' :""} `
                                        // }
                                               onClick={isEdit ? saveCampaignAfterEdit : saveCampaign}>
                                                   Save Campaign
                                            </button>
                                            <button onClick={resetButton} className="campaign-button inactive-color">Reset</button>
                                        </div>   
                                    </div>
                                </div>
            </div>
        </Fragment>
    )
}
export default CampaignComponent