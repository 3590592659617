import React, {
  Fragment,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation } from "react-router";
import Loader from 'react-loader-spinner';
import Pagination from "@mui/material/Pagination";
import QueryService from "../../../services/QueryService";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../../customLoader/CustomLoader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";

const MyStudentList = () => {

  let history = useHistory();
  let location = useLocation();
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState(0);
  const [paginationCount, setPaginationCount] = useState();
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [loaderForExport, setLoaderForExport] = useState(false);
  const [selectedRowFromResultTable, setSelectedRowFromResultTable] = useState([])
  // const prevSelectionModel = React.useRef(selectedRowFromResultTable);
  // const prevSelectionModelData = React.useRef(saveSelection);
  const [saveSelection, setSaveSelection] = useState([])
  const [queryStudentData, setQueryStudentData] = useState();
  const [newSelectedIds, setNewSelectedIds] = useState([]);
  const [filteredSaveSelection, setFilteredSaveSelection] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [from, setFrom] = useState(location?.state?.fromSavedQueriedResult);


  let userData = useSelector((state) => state.UserReducer.userObj);

  const getFavStudentData = () => {
    setLoading(true);
    // const body = {
    //   "college_id": userData.college,
    //   "recruiter_id": userData.id,
    //   "role" : userData.role
    // }
    QueryService.getFavoritePage()
      .then(async (data) => {
        console.log("data",data.data);
        setQueryStudentData(data.data);
        const newArrayOfObj = data.data.student_list.map(
          ({ 8: id, ...rest }) => ({
            id,
            ...rest,
          })
        );
        console.log("newArray",newArrayOfObj);
        setTableData(newArrayOfObj);
        setPaginationCount(data.data.total_pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error from the service", error);
      });
  };

  useEffect(()=>{
    getFavStudentData();
  },[])

  const columns = [
    {
      field: "0",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      type: "string",
      //editable: true,
      minWidth: "300",
      resizable: true,
      //  flex:1
    },
    {
      field: "1",
      headerName: "First name",
      headerClassName: "super-app-theme--header",
      type: "string",
      editable: true,
      resizable: true,
      // flex:1
      //minWidth:200
    },
    {
      field: "2",
      headerName: "Last name",
      headerClassName: "super-app-theme--header",
      type: "string",
      editable: true,
      resizable: true,
      // flex:1
      //   minWidth:200
    },
    {
      field: "3",
      headerName: "Ethnicity",
      headerClassName: "super-app-theme--header",
      type: "string",
      editable: true,
      resizable: true,
      //flex:1
    },
    {
      field: "4",
      headerName: "Gender",
      headerClassName: "super-app-theme--header",
      type: "string",
      editable: true,
      resizable: true,
      //flex:1
    },
    {
      field: "5",
      headerName: "Overall GPA",
      headerClassName: "super-app-theme--header",
      type: "number",
      editable: true,
      resizable: true,
      //  flex:1
    },
    {
      field: "6",
      headerName: "Grade",
      headerClassName: "super-app-theme--header",
      type: "string",
      //width: 110,
      editable: true,
      resizable: true,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "7",
      headerName: "School Name",
      headerClassName: "super-app-theme--header",
      type: "string",
      //width: 110,
      editable: true,
      resizable: true,
      minWidth: 400,
      flex: 1,
    },
  ];

  const handleCellClick = (cell) => {
    // console.log("yy",cell);
    let cel = cell.row;
    if (saveSelection && Array.isArray(saveSelection)) {
      console.log("inside if");
      let filteredValue = saveSelection.includes(cel);
      console.log("save selection", saveSelection);
      if (filteredValue === true) {
        let index = saveSelection.indexOf(cel);
        saveSelection.splice(index, 1);
        setSaveSelection(saveSelection);
        return;
      }
    } else if (
      saveSelection === "selected All" &&
      typeof saveSelection === "string"
    ) {
      const selectedRows = tableData.filter((item) =>
        selectedRowFromResultTable.includes(item.id)
      );
      let filteredValue = selectedRows.includes(cel);
      if (filteredValue === true) {
        let index = selectedRows.indexOf(cel);
        selectedRows.splice(index, 1);
        setSaveSelection(selectedRows);
        return;
      }
    }
  };

  const downloadFile = (downloadURL, fileName) => {
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.download = fileName;
    downloadLink.href = downloadURL;
    downloadLink.click();
}

const deleteTranscript = () => {
  let payload;
    let n2 = {};
    let n1 = newSelectedIds.map((i, index) => {
      n2[index] = { school_id: i["9"], student_id: i["10"] };
    });
      if (saveSelection === "selected All") {
        payload = {
          is_all: true,
          // recruiter_id: userData.id,
          // college_id: n2.school_id,
        };
      } else {
        payload = {
          is_all: false,
          details: n2,
          // recruiter_id: userData.id,
      } }

  QueryService.deleteTrans(payload)
  .then((data) => {
    console.log("data from the service", data);
    swal({
      text: `Students Deleted Successfully from My Student List.`,
      icon: "success",
      buttons: {
        OK: true,
      },
      closeOnClickOutside: false,
    }).then((value) => {
      if (value === "OK") {
        window.location.reload();
      }
    });
  })
  .catch((error) => {
    console.log("error from save student service", error);
  })}

const downloadTranscript = () => {
    setLoaderForExport(true);
    let payload;
    let n2 = {};
    let n1 = newSelectedIds.map((i, index) => {
      n2[index] = { school_id: i["9"], student_id: i["10"] };
    });
      if (saveSelection === "selected All") {
        payload = {
          is_all: true,
          // recruiter_id: userData.id,
        };
      } else {
        payload = {
          is_all: false,
          details: n2,
          // recruiter_id: userData.id,
      } }
    QueryService.downloadTrans(payload)
      .then((data) => {
        console.log("data from the service", data);
        downloadFile(data.data.download, "My File")
        setLoaderForExport(false);
        // setLoaderForSave(false);
        // swal({
        //     text: `Student Data Saved Successfully to My Student List.`,
        //     icon: "success",
        //     buttons: {
        //       OK: true,
        //     },
        //     closeOnClickOutside: false,
        //   }).then((value) => {
        //     if (value === "OK") {
        //       history.push('/recruiter/campaign')
        //     }
        //   });
      })
      .catch((error) => {
        console.log("error from save student service", error);
        setLoaderForExport(false);
        // setLoaderForSave(false);
        // if (error.response.status == 409) {
        //   swal({
        //     text: error?.response?.data?.message,
        //     icon: "warning",
        //     dangerMode: true,
        //     buttons: {
        //       cancel: true,
        //     },
        //     closeOnClickOutside: false,
        //   });
        // }
        })
}


  const onSelection = (ids) => {
    console.log("getting clled");
    if (Array.isArray(ids) && ids.length > 0) {
      setSelectedRowFromResultTable(ids);
      const selectedIDs = new Set(ids);
      let selectedRows = tableData.filter((item) => selectedIDs.has(item.id));
      setNewSelectedIds(selectedRows);
      // console.log('ids from the onSelection', ids, selectedRows, selectedRows.length)
      // let filteredValue = saveSelection.filter(item => selectedIDs.has(!item.id))
      // selectedRows = filteredValue
      if (page !== 1) {
        setSelectAll(true);
        const selectedIDs = new Set(ids);
        setSaveSelection([...saveSelection, ...selectedRows]);
        // const selectedRows = tableData.filter(item => selectedIDs.has(item.id));
      } else if (page === 1 && selectedRows?.length === tableData.length) {
        setSelectAll(true);
        if (from) {
          setSaveSelection(selectedRows);
        } else if (!from) {
          // setShowGroupMessageAlert(true);
          setSaveSelection("selected All");
        }
      } else if (page === 1 && selectedRows?.length !== tableData.length) {
        setSelectAll(true);
        const selectedIDs = new Set(ids);
        setSaveSelection([...saveSelection, ...selectedRows]);
        // const selectedRows = tableData.filter(item => selectedIDs.has(item.id));
      }
    } else if (Array.isArray(ids) && ids.length === 0) {
      setSaveSelection([]);
      setSelectedRowFromResultTable([]);
      setFilteredSaveSelection([]);
    }
  };


  return (
    <Fragment>
      <div>
        <div>
          <div>
            <p
              style={{ fontSize: "18px", marginBottom: "10px" }}
              className="bold"
            >
              Student Records
            </p>
            {/* <p onClick={() => {dispatch(changeQueryLocation({})); navigateToQuery()}} className="no-margin-bottom font cursor" style={{ textDecoration: 'underline', paddingLeft: '18px' }}>Create New Query Criteria</p> */}
          </div>
          {/* <div>
                                    <p style={{ color: '#333333', opacity: '0.8' }} className="no-margin-bottom bold">{`${queryResultData ? queryResultData?.num_students : from ? tableData.length : 0} Students Found`}</p>
                                </div> */}
        </div>
        {/* <div style={{ padding: '15px' }}>
                                <p style={{ color: '#333333', opacity: '0.8' }} className="bold">{`You are viewing ${tableData ? tableData?.length : 0} Student out of ${queryResultData ? queryResultData?.num_students : 0} Results`}</p>
                            </div> */}
        {loading ? (
                <CustomLoader />
              ) : (
                <div
                  // className={
                  //   paginationCount && paginationCount > 1
                  //     ? "table-container"
                  //     : "table-container-less"
                  // }
                >
        
         <DataGrid
          style={{ height: "510px" }}
          // className="result-table"
          pageSize={pageSize}
          rows={tableData}
          columns={columns}
          rowsPerPageOptions={[20]}
          checkboxSelection
          onSelectionModelChange={(ids) => onSelection(ids)}
          // hideFooterPagination={!from ? true : false}
          hideFooterSelectedRowCount={false}
          rowHeight={100}
          columnHeader
          selectionModel={selectedRowFromResultTable}
          onRowClick={(row) => handleCellClick(row)}
          disableSelectionOnClick={true}
          autoPageSize={true}
        />
        </div>  )}

      <div className="result-button-container">
        <button
         onClick={()=> downloadTranscript()}
          style={{ justifyContent: "center"}}
          className="modal-button-inactive flex"
          disabled={saveSelection.length > 0 ? false : true}
        >
          Export Transcript{" "}
          {loaderForExport && (
            <span>
              <Loader type="TailSpin" color="#E36221" height={20} width={20} />
            </span>
          )}
        </button>
        <button className="modal-button-active" onClick={()=> deleteTranscript()} disabled={saveSelection.length > 0 ? false : true}>
          Delete
        </button>
        <button className="modal-button-active" onClick={()=> history.push("/recruiter/search-query")}>
          Cancel
        </button>
      </div>
      <br/>
      <br/>
      {/* {paginationCount && <div>
                        <Pagination
                            onChange={(event, value) => {
                                prevSelectionModel.current = selectedRowFromResultTable
                                prevSelectionModelData.current = saveSelection
                                setPage(value)
                            }}
                            // count={from ? Math.ceil(tableData.length / 20) : paginationCount}
                            color="primary"
                            className="query-result-pagination" />
                    </div>
                    } */}
      </div>
    </Fragment>
  );
};

export default MyStudentList;