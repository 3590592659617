// Type
export const RESET = 'RESET';

// Action
export const resetStateAction = () => ({ type: RESET });

const initState = {}

const ResetReducer = (state = initState, action) => {
    switch (action.type) {
        case RESET:
            return state;

        default: return state;
    }
}
export default ResetReducer;