import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  faTimes,
  faAsterisk,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./InviteStudent.css";
import GetAdminService from "../../../services/admin/GetAdminService";
import swal from "sweetalert";

const InviteCounselor = ({ setOpenInviteCounselor, schoolId }) => {
  const [emailError, setEmailError] = useState(true);
  const [email, setEmail] = useState();
  const [counselorId, setCounselorId] = useState();
  
  const sendEmail = () => {
    let obj = {
    "email":email,
    "school":schoolId,
    "role":"counselor",
    }
    if(obj.role == "counselor"){
        obj.counselor= counselorId;
    }
    GetAdminService.sendCounselorEmail(obj)
    .then((data) => {
      console.log("data from the service", data);
      swal({
        title: "Successfully",
        text: "Email send successfully",
        icon: "success",
        dangerMode: true,
        buttons: {
          ok: true,
        },
        closeOnClickOutside: true,
      });
    })
    .catch((error) => {
      console.log("error from the service", error);
      swal({
        title: "Oops!!",
        text: error?.response?.data?.message,
        icon: "warning",
        dangerMode: true,
        buttons: {
          cancel: true,
        },
        closeOnClickOutside: true
    })
    });
    setEmailError(true);
    setEmail("");
  }

  const handleEmail=(e)=>{
    setEmail(e.target.value.toLowerCase())
    if(e.target.value.match("[a-z0-9]+@[a-z]+\.[a-z]{2,3}"))
    setEmailError(false)
    else {setEmailError(true);}
  }

  return (
    <Fragment>
      <div className="goal-full-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontSize: "18px" }}>Invite Counselor</p>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ opacity: "0.2", cursor: "pointer" }}
            onClick={() => setOpenInviteCounselor(false)}
          />
        </div>
         <div style={{ marginTop: "10px" }}>
                    <div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "15px",
                borderBottom: "1px solid #e5e5e5",
              }}
            >
              Contact
            </p>
            <div>
              <div
                className="flex"
                style={{
                  marginTop: "15px",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <input
                  type="email"
                  style={{ width: "85%", padding: "3px" }}
                  placeholder="Email"
                  value={email}
                  onChange={handleEmail}
                />
                <FontAwesomeIcon
                  style={{
                    fontSize: "12px",
                    position: "absolute",
                    right: "75px",
                  }}
                  icon={faAsterisk}
                  color="red"
                />
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={sendEmail}
          disabled={emailError}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "30px",
            position: "absolute",
            right: "30px",
            backgroundColor: emailError ? "#337ab7" : "#009900",
            outline: "none",
            border: "none",
            color: "white",
            padding: "5px 15px",
            marginTop: "15px",
            borderRadius: "3px",
          }}
        >
          <FontAwesomeIcon
            icon={faPaperPlane}
            color="white"
            style={{
              cursor: "pointer",
              marginRight: "5px",
              marginTop: "3px",
            }}
          />
          Invite
        </button>
      </div>
    </Fragment>
  );
};
export default InviteCounselor;