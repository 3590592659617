import React, { Suspense, Fragment } from "react";
import Loading from "../components/Loading/Loading";
import PublicRoute from "../components/PublicRoute/PublicRoute";
import { CounSelorRegister, CreateParentAccount, CreateStudentAccount, ForgotPassword, LetsGetStart, Login, ResetPassword, VerifyEmail } from "../Pages";
import SelectAccount from "../Pages/SelectAccount/SelectAccount";
import CreateStudentPersonal from "../Pages/CreateStudentPersonal/CreateStudentPersonal";
import CreateStudentPersonalAccount from "../Pages/CreateStudentPersonalAccount/CreateStudentPersonalAccount";
import CreateStudentOTP from "../Pages/CreateStudentOTP/CreateStudentOTP";

const suspenseRoutesArr = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword
  },
  {
    path: "/verify-email",
    component: VerifyEmail
  },
  {
    path: "/verify-password/:id?",
    component: ResetPassword
  },
  {
    path: "/lets-get-start",
    component: LetsGetStart
  },
  {
    path: "/select-account",
    component: SelectAccount
  },
  {
    path: "/create-student-personal",
    component: CreateStudentPersonal
  },
  {
    path: "/create-student-personal-account",
    component: CreateStudentPersonalAccount
  },
  {
    path: "/counselor-register",
    component: CounSelorRegister
  },
  {
    path: "/create-student-account",
    component: CreateStudentAccount
  },
  {
    path: "/create-student-otp",
    component: CreateStudentOTP
  },
  {
    path: "/create-parent-account",
    component: CreateParentAccount
  },

];

const PublicRoutes = () => {
  return (
    <Fragment>
      {suspenseRoutesArr.map((route, i) => (
        <Suspense fallback={<Loading />} key={i}>
          <PublicRoute path={route.path} component={route.component} />
        </Suspense>
      ))}
    </Fragment>
  );
};

export default PublicRoutes;
