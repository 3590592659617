import React, { Fragment, useState, useEffect, useRef } from "react";
import Loader from "react-loader-spinner";
import CustomLoader from "../../customLoader/CustomLoader";
import GetAdminService from "../../../services/admin/GetAdminService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import {
  faUser,
  faUsers,
  faCheckSquare,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InviteStudent from "./InviteStudent";
import InviteCounselor from "./InviteCounselor";
import CustomPagination from "../../pagination/Pagination";
import swal from "sweetalert";

const Invites = () => {
  const [schoolName, setSchoolName] = useState();
  const [showSchoolNameContainer, setShowSchoolNameContainer] = useState(false);
  const [schoolNameList, setSchoolNameList] = useState();
  const [studentNameList, setStudentNameList] = useState();
  const schoolNameRef = useRef();
  const searchNameRef = useRef();
  const timeoutRef = useRef();
  const [schoolNameLoading, setSchoolNameLoading] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [openInviteStudent, setOpenInviteStudent] = useState(false);
  const [openInviteCounselor, setOpenInviteCounselor] = useState(false);
  const [
    loadingForStudentCsvDownload,
    setLoadingForStudentCsvDownload,
  ] = useState(false);
  const [searchName, setSearchName] = useState();
  const [schoolId, setSchoolId] = useState();

  const [emailCount, setEmailCount] = useState(0);
  const [emailParentCount, setEmailParentCount] = useState(0);
  const [defaultAllCount, setDefaultAllCount] = useState({
    email: 0,
    mobile: 0,
    parent_email: 0,
    parent_mobile: 0,
  });
  const [mobileCount, setMobileCount] = useState(0);
  const [mobileParentCount, setMobileParentCount] = useState(0);
  const [emailCheckbox, setEmailCheckbox] = useState([false]);
  const [emailParentCheckbox, setEmailParentCheckbox] = useState([false]);
  const [mobileCheckbox, setMobileCheckbox] = useState([false]);
  const [mobileParentCheckbox, setMobileParentCheckbox] = useState([false]);
  const [clearEmail, setClearEmail] = useState(false);
  const [clearMobile, setClearMobile] = useState(false);
  const [clearParentEmail, setClearParentEmail] = useState(false);
  const [clearParentMobile, setClearParentMobile] = useState(false);
  const [disableSendInvites, setDisableSendInvites] = useState(true);
  const [pageData, setPageData] = useState();
  const [loading, setLoading] = useState([]);
  const [page, setPage] = useState(1);

  const getSchoolsList = (value) => {
    setSchoolNameLoading(true);
    setShowSchoolNameContainer(true);
    GetAdminService.getSchoolsList(value)
      .then((data) => {
        console.log("data from the service", data);
        setSchoolNameList(data?.data?.school);
        setSchoolNameLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setSchoolNameLoading(false);
      });
  };

  const sendInvites = () => {
    let obj = {
      users: [],
      parents: [],
      mobile: [],
      parent_mobile: [],
      school: schoolId,
      selectall_email: emailCheckbox[0] ? "mail_selected" : "",
      selectall_mobile: mobileCheckbox[0] ? "mobile_selected" : "",
      selectall_parent_email: emailParentCheckbox[0]
        ? "parent_mail_selected"
        : "",
      selectall_parent_mobile: mobileParentCheckbox[0]
        ? "parent_mobile_selected"
        : "",
    };
    let arr1 = [];
    emailCheckbox.map((item, index) => {
      console.log("email", pageData?.result?.[index - 1]?.id);
      if (item && index !== 0) {
        arr1.push(pageData?.result?.[index - 1]?.id);
      }
    });
    if (!obj.selectall_email) {
      // console.log("arr1",arr1)
      obj.users = arr1;
    }
    let arr2 = [];
    mobileCheckbox.map((item, index) => {
      if (item && index !== 0 && pageData?.result?.[index - 1]?.mobile) {
        arr2.push(pageData?.result?.[index - 1]?.id);
      }
    });
    if (!obj.selectall_mobile) obj.mobile = arr2;

    let arr3 = [];
    emailParentCheckbox.map((item, index) => {
      // console.log("emailParent",pageData?.result?.[index]?.parent_id)
      if (item && index !== 0) {
        arr3.push(pageData?.result?.[index - 1]?.parent_id);
      }
    });
    if (!obj.selectall_parent_email) {
      // console.log("arr3",arr3)
      obj.parents = arr3;
    }
    let arr4 = [];
    mobileParentCheckbox.map((item, index) => {
      if (item && index !== 0 && pageData?.result?.[index - 1]?.parent_mobile) {
        arr4.push(pageData?.result?.[index - 1]?.parent_id);
      }
    });
    if (!obj.selectall_parent_mobile) obj.parent_mobile = arr4;
    console.log(obj);
    setLoading(true);
    GetAdminService.sendInvites(obj)
      .then((data) => {
        console.log("data from the service", data);
        if (data?.status === 200) {
          swal({
            text: "Email/Text send successfully",
            icon: "success",
            buttons: {
              OK: true,
            },
            closeOnClickOutside: false,
          }).then((value) => {
            // console.log("value from swal then:", value);
            if (value === "OK") {
              setEmailCheckbox(Array(emailCheckbox.length).fill(false));
              setEmailParentCheckbox(Array(emailParentCheckbox.length).fill(false));
              setMobileCheckbox(Array(mobileCheckbox.length).fill(false));
              setMobileParentCheckbox(Array(mobileParentCheckbox.length).fill(false));
              // setClearEmail(false);
              // setClearMobile(false);
              // setClearParentEmail(false);
              // setClearParentMobile(false);
              // setEmailCheckbox([false]);
              // setMobileCheckbox([false]);
              // setEmailParentCheckbox([false]);
              // setMobileParentCheckbox([false]);
            }
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error from the services", error);
        setLoading(false);
      });
  };

  const send_invites_pagination = (obj) => {
    setLoading(true);
    GetAdminService.createStudentsSearch(obj)
      .then((data) => {
        console.log("data from the service", data);
        setLoading(false);
        setPageData(data?.data);
        console.log("what", Array(data?.data?.result.length + 1).fill(false));
        setEmailCheckbox(Array(data?.data?.result.length + 1).fill(false));
        setEmailParentCheckbox(
          Array(data?.data?.result.length + 1).fill(false)
        );
        setMobileCheckbox(Array(data?.data?.result.length + 1).fill(false));
        setMobileParentCheckbox(
          Array(data?.data?.result.length + 1).fill(false)
        );
      })
      .catch((error) => {
        console.log("error from the services", error);
        setLoading(false);
      });
  };

  const handleSchoolName = () => {
    clearTimeout(timeoutRef.current);
    setSchoolName(schoolNameRef.current.value);
    if (!schoolNameRef.current.value) {
      setShowSchoolNameContainer(false);
      setSchoolNameList([]);
      setShowInvite(false);
    } else {
      timeoutRef.current = setTimeout(() => {
        getSchoolsList(schoolNameRef.current.value);
      }, 1000);
    }
  };

  // const handleStudentName = () => {
  //   clearTimeout(timeoutRef.current);
  //   setSearchName(searchNameRef.current.value);
  //   if (!searchNameRef.current.value) {
  //     setShowSchoolNameContainer(false);
  //     setStudentNameList([]);
  //   } else {
  //     timeoutRef.current = setTimeout(() => {
  //       let obj = {
  //         school: schoolId,
  //         page: "1",
  //         invite_student_search: searchName,
  //         send_invites: "send_invites",
  //       };
  //       send_invites_pagination(obj);
  //     }, 1000);
  //   }
  // };

  const handleStudentName = () => {
    clearTimeout(timeoutRef.current);
    setSearchName(searchNameRef.current.value);
    timeoutRef.current = setTimeout(() => {
      let obj = {
        school: schoolId,
        page: "1",
        q: searchNameRef.current.value,
        role: "student",
        // send_invites: "send_invites",
      };
      setEmailCount(0);
      setMobileCount(0);
      setEmailParentCount(0);
      setMobileParentCount(0);
      setClearEmail(false);
      setClearMobile(false);
      setClearParentEmail(false);
      setClearParentMobile(false);
      setEmailCheckbox([false]);
      setMobileCheckbox([false]);
      setEmailParentCheckbox([false]);
      setMobileParentCheckbox([false]);
      send_invites_pagination(obj);
    }, 1000);
  };

  function getHeaderRow() {
    var template = [];
    template.push([
      '"Student First Name"',
      '"Student Middle Name"',
      '"Student Last Name"',
      '"Student Grade"',
      '"Student ID"',
      '"Student Email"',
      '"Student Mobile"',
      '"Student DOB"',
      '"Counselor Emails"',
    ]);
    var arr = template;
    var ret = [];
    for (var i = 0, len = arr.length; i < len; i++) {
      var line = [];
      for (var key in arr[i]) {
        if (arr[i].hasOwnProperty(key)) {
          line.push(arr[i][key]);
        }
      }
      ret.push(line.join(","));
    }
    return ret;
  }

  function addStudentsToCsv(csv, students, counselormap) {
    var studentRecord = [];
    for (var i = 0; i < students.length; i++) {
      studentRecord[0] = students[i].first_name;
      studentRecord[1] = students[i].middle_name;
      studentRecord[2] = students[i].last_name;
      studentRecord[3] = students[i].current_grade;
      studentRecord[4] = students[i].student_id;
      studentRecord[5] = students[i].email;
      studentRecord[6] = students[i].mobile;
      studentRecord[7] = students[i].student_dob;
      studentRecord[8] = students[i].counselor_emails;
      var newRow = studentRecord.join(",");
      csv.push(newRow);
    }
  }

  const handleStudent1 = (downloadURL, fileName) => {
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.download = fileName;
    downloadLink.href = downloadURL;
    downloadLink.click();
  };

  const getCounselorsList = (
    schoolIds
    // offset,
    // role,
    // limit,
    // handleStudent1 = ""
  ) => {
    setLoadingForStudentCsvDownload(true);
    GetAdminService.getCSVData(schoolIds)
      .then((data) => {
        // if (handleStudent1) {
        handleStudent1(data?.data?.download);
        // }
        setLoadingForStudentCsvDownload(false);
      })
      .catch((error) => {
        setLoadingForStudentCsvDownload(false);
        console.log("error from the services", error);
      });
  };

  const onDownloadFileSelect = () => {
    var result_csv = getHeaderRow();
    var hasNext = true;
    var nextOffset = 0;
    var counselormap = {};

    const handleStudents = (res) => {
      addStudentsToCsv(result_csv, res.objects, counselormap);
      if (res.meta.total_count > 1000) {
        nextOffset = res.meta.offset + 1000;
      }
      hasNext = res.meta.next;
      if (hasNext) {
        getCounselorsList(
          schoolId,
          nextOffset,
          "student",
          1000,
          handleStudents
        );
      } else {
        var csvDownload = result_csv.join("\n");
        var saving = document.createElement("a");
        saving.href = "data:attachment/csv," + encodeURIComponent(csvDownload);
        saving.download = "csv_student_download.csv";
        document.body.appendChild(saving);
        saving.click();
      }
    };
    getCounselorsList(schoolId, nextOffset, "student", "", handleStudents);
    // LoggedInRestangular.all('admin/users-to-invite').getList({'school': schoolId, 'offset': nextOffset, 'role':'student'}).then(handleStudents);
  };

  const handleSelectedSchoolName = (item) => {
    setSchoolNameList([item]);
    setShowSchoolNameContainer(false);
    setSchoolName(item.name);
    setShowInvite(true);
    setSchoolId(item?.id);
    setDefaultAllCount({
      email: item?.email_count,
      mobile: item?.mobile_count,
      parent_email: item?.parent_email_count,
      parent_mobile: item?.parent_mobile_count,
    });
    setEmailCount(0);
    setMobileCount(0);
    setEmailParentCount(0);
    setMobileParentCount(0);
    setClearEmail(false);
    setClearMobile(false);
    setClearParentEmail(false);
    setClearParentMobile(false);
    send_invites_pagination({
      school: item?.id,
      page: "1",
      role: "student",
      // send_invites: "send_invites",
    });
  };

  const selectAll = (e, name) => {
    if (e.target.checked === true) {
      if (name === "email") {
        let n = emailCheckbox.length;
        setEmailCheckbox(Array(n).fill(true));
        setClearEmail(false);
        setEmailCount(defaultAllCount.email);
      } else if (name === "mobile") {
        let n = mobileCheckbox.length;
        setMobileCheckbox(Array(n).fill(true));
        setClearMobile(false);
        setMobileCount(defaultAllCount.mobile);
      } else if (name === "parent_email") {
        let n = emailParentCheckbox.length;
        setEmailParentCheckbox(Array(n).fill(true));
        setClearParentEmail(false);
        setEmailParentCount(defaultAllCount.parent_email);
      } else if (name === "parent_mobile") {
        let n = mobileCheckbox.length;
        setMobileParentCheckbox(Array(n).fill(true));
        setClearParentMobile(false);
        setMobileParentCount(defaultAllCount.parent_mobile);
      }
    } else {
      // if (name === "email") {
      //   emailCheckbox[0] = false;
      //   setEmailCheckbox([...emailCheckbox]);
      // } else if (name === "mobile") {
      //   mobileCheckbox[0] = false;
      //   setMobileCheckbox([...mobileCheckbox]);
      // } else if (name === "parent_email") {
      //   emailParentCheckbox[0] = false;
      //   setEmailParentCheckbox([...emailParentCheckbox]);
      // } else if (name === "parent_mobile") {
      //   mobileParentCheckbox[0] = false;
      //   setMobileParentCheckbox([...mobileParentCheckbox]);
      // }
      if (name === "email") {
        setClearEmail(true);
        setEmailCheckbox(Array(emailCheckbox.length).fill(false));
        setEmailCount(0);
      } else if (name === "mobile") {
        setClearMobile(true);
        setMobileCheckbox(Array(mobileCheckbox.length).fill(false));
        setMobileCount(0);
      } else if (name === "parent_email") {
        setClearParentEmail(true);
        setEmailParentCheckbox(Array(emailParentCheckbox.length).fill(false));
        setEmailParentCount(0);
      } else if (name === "parent_mobile") {
        setClearParentMobile(true);
        setMobileParentCheckbox(Array(mobileParentCheckbox.length).fill(false));
        setMobileParentCount(0);
      }
    }
  };

  // const clearAll = (e, name) => {
  //   if (e.target.checked === true) {
  //     if (name === "email") {
  //       setClearEmail(true);
  //       setEmailCheckbox(Array(emailCheckbox.length).fill(false));
  //       setEmailCount(0);
  //     } else if (name === "mobile") {
  //       setClearMobile(true);
  //       setMobileCheckbox(Array(mobileCheckbox.length).fill(false));
  //       setMobileCount(0);
  //     } else if (name === "parent_email") {
  //       setClearParentEmail(true);
  //       setEmailParentCheckbox(Array(emailParentCheckbox.length).fill(false));
  //       setEmailParentCount(0);
  //     } else if (name === "parent_mobile") {
  //       setClearParentMobile(true);
  //       setMobileParentCheckbox(Array(mobileParentCheckbox.length).fill(false));
  //       setMobileParentCount(0);
  //     }
  //   } else {
  //     if (name === "email") {
  //       setClearEmail(false);
  //     } else if (name === "mobile") {
  //       setClearMobile(false);
  //     } else if (name === "parent_email") {
  //       setClearParentEmail(false);
  //     } else if (name === "parent_mobile") {
  //       setClearParentMobile(false);
  //     }
  //   }
  // };

  const handleCheckbox = (e, index, name) => {
    if (e.target.checked === true) {
      if (name === "email") {
        emailCheckbox[index + 1] = true;
        setClearEmail(false);
        setEmailCount(emailCount + 1);
      } else if (name === "mobile") {
        mobileCheckbox[index + 1] = true;
        setClearMobile(false);
        setMobileCount(mobileCount + 1);
      }
      if (name === "parent_email") {
        emailParentCheckbox[index + 1] = true;
        setClearParentEmail(false);
        setEmailParentCount(emailParentCount + 1);
      } else if (name === "parent_mobile") {
        mobileParentCheckbox[index + 1] = true;
        setClearParentMobile(false);
        setMobileParentCount(mobileParentCount + 1);
      }
    } else {
      if (name === "email") {
        emailCheckbox[index + 1] = false;
        emailCheckbox[0] = false;
        setEmailCount(emailCount - 1);
      } else if (name === "mobile") {
        mobileCheckbox[index + 1] = false;
        mobileCheckbox[0] = false;
        setMobileCount(mobileCount - 1);
      } else if (name === "parent_email") {
        emailParentCheckbox[index + 1] = false;
        emailParentCheckbox[0] = false;
        setEmailParentCount(emailParentCount - 1);
      } else if (name === "parent_mobile") {
        mobileParentCheckbox[index + 1] = false;
        mobileParentCheckbox[0] = false;
        setMobileParentCount(mobileParentCount - 1);
      }
    }
    setEmailCheckbox([...emailCheckbox]);
    setMobileCheckbox([...mobileCheckbox]);
    setEmailParentCheckbox([...emailParentCheckbox]);
    setMobileParentCheckbox([...mobileParentCheckbox]);
  };

  useEffect(() => {
    if (
      mobileCount === 0 &&
      emailCount === 0 &&
      mobileParentCount === 0 &&
      emailParentCount === 0
    )
      setDisableSendInvites(true);
    else setDisableSendInvites(false);
  }, [mobileCount, emailCount, mobileParentCount, emailParentCount]);

  useEffect(() => {
    if (schoolId) {
      let obj = {
        school: schoolId,
        page: page,
        q: searchName,
        role: "student",
        // send_invites: "send_invites",
      };
      send_invites_pagination(obj);
    }
  }, [page]);

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="csv-import-container">
            <div className="flex canned-message-heading">
              <p style={{ fontSize: "25px", fontWeight: "400" }}>Invites</p>
              {showInvite && (
                <div>
                  <button
                    onClick={() => setOpenInviteStudent(true)}
                    style={{ marginLeft: "auto", marginRight: "10px" }}
                    className="create-maintain-group-button"
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={faUsers}
                        style={{ marginRight: "10px" }}
                      />
                      Invite Student
                    </span>
                  </button>
                  <button
                    onClick={() => setOpenInviteCounselor(true)}
                    className="create-maintain-group-button"
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ marginRight: "10px" }}
                      />
                      Invite Counselor
                    </span>
                  </button>
                </div>
              )}
            </div>

            <div className="school-name-container">
              <p>Select School</p>
              <input
                ref={schoolNameRef}
                className="search-admin-school-input"
                type="text"
                placeholder="School Name"
                value={schoolName}
                onChange={handleSchoolName}
                autoComplete="off"
              />
              {showInvite && (
                <div
                  style={{ position: "absolute", top: "15px", left: "570px" }}
                >
                  <p style={{ marginLeft: "15px" }}>Download Student</p>

                  <button
                    onClick={() => onDownloadFileSelect()}
                    className="download-button btn-secondary"
                    style={{ marginLeft: "8%" }}
                  >
                    Download Student
                    {loadingForStudentCsvDownload && (
                      <span>
                        <Loader
                          type="TailSpin"
                          color="white"
                          height={15}
                          width={15}
                        />
                      </span>
                    )}
                  </button>
                </div>
              )}

              {showSchoolNameContainer && (
                <ul className="admin-school-name-list">
                  {schoolNameLoading && <CustomLoader />}
                  {schoolNameList?.length > 0 &&
                    schoolNameList.map((item, index) => (
                      <li
                        onClick={() => handleSelectedSchoolName(item)}
                        key={index}
                        className="flex admin-school-list cursor"
                      >
                        <span>{item.name}</span>
                      </li>
                    ))}
                </ul>
              )}
            </div>

            <div>
              {showInvite && (
                <div style={{ padding: "15px 0" }}>
                  <div style={{ paddingLeft: "15px" }}>
                    <input
                      ref={searchNameRef}
                      className="search-student-input"
                      type="text"
                      placeholder="Search Student"
                      value={searchName}
                      onChange={handleStudentName}
                      autoComplete="off"
                    />
                    <div style={{ margin: "20px 0 10px 0", fontWeight: "500" }}>
                      <button
                        className={`${disableSendInvites &&
                          "disabled"} create-maintain-group-button`}
                        disabled={disableSendInvites}
                        onClick={sendInvites}
                      >
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          style={{ marginRight: "10px" }}
                        />
                        Send Invites
                      </button>
                      <br />
                      <br />
                      {/* <span style={{ marginRight: "30px" }}>
                        Email User List{" "}
                        <span className="badge">{emailCount}</span>
                      </span>
                      <span>
                        Mobile User List{" "}
                        <span className="badge">{mobileCount}</span>
                      </span> */}
                    </div>
                    <div>
                      Invites Sent{" "}
                      <FontAwesomeIcon color="green" icon={faCheckSquare} />
                    </div>
                    <br />
                    <div style={{ margin: "10px 0", fontWeight: "500" }}>
                      <input
                        type="checkbox"
                        checked={emailCheckbox[0]}
                        onChange={(e) => selectAll(e, "email")}
                      />
                      &nbsp;
                      <span style={{ marginLeft: "5px" }}>
                        check all students email invites &emsp; &nbsp;
                      </span>
                      <input
                        type="checkbox"
                        checked={emailParentCheckbox[0]}
                        onChange={(e) => selectAll(e, "parent_email")}
                      />
                      &nbsp;
                      <span style={{ marginLeft: "5px" }}>
                        check all parents email invites &emsp; &nbsp;
                      </span>
                      {/* <input
                        type="checkbox"
                        checked={clearEmail}
                        onChange={(e) => clearAll(e, "email")}
                      />
                      &nbsp;
                      <span style={{ marginLeft: "5px" }}>
                        &nbsp;clear all student email invites{" "}
                      </span> */}
                    </div>
                    <div style={{ marginBottom: "20px", fontWeight: "500" }}>
                      <input
                        type="checkbox"
                        checked={mobileCheckbox[0]}
                        onChange={(e) => selectAll(e, "mobile")}
                      />
                      &nbsp;
                      <span style={{ marginLeft: "5px" }}>
                        check all students mobile invites &emsp;
                      </span>
                      
                      <input
                        type="checkbox"
                        checked={mobileParentCheckbox[0]}
                        onChange={(e) => selectAll(e, "parent_mobile")}
                      />
                      &nbsp;
                      <span style={{ marginLeft: "5px" }}>
                        check all parents mobile invites &emsp;
                      </span>
                      {/* <input
                        type="checkbox"
                        checked={clearMobile}
                        onChange={(e) => clearAll(e, "mobile")}
                      />
                      &nbsp;
                      <span style={{ marginLeft: "5px" }}>
                        clear all student mobile invites{" "}
                      </span> */}
                    </div>

                    {/* <b>For Parents</b> */}
                    <div style={{ margin: "10px 0", fontWeight: "500" }}>
                      
                      {/* <input
                        type="checkbox"
                        checked={clearParentEmail}
                        onChange={(e) => clearAll(e, "parent_email")}
                      />
                      &nbsp;
                      <span style={{ marginLeft: "5px" }}>
                        &nbsp;clear all parent email invites{" "}
                      </span> */}
                    </div>
                    <div style={{ marginBottom: "20px", fontWeight: "500" }}>
                      
                      {/* <input
                        type="checkbox"
                        checked={clearParentMobile}
                        onChange={(e) => clearAll(e, "parent_mobile")}
                      />
                      &nbsp;
                      <span style={{ marginLeft: "5px" }}>
                        clear all parent mobile invites{" "}
                      </span> */}
                    </div>
                  </div>
                  {/* <div className="admin-student-list-container1">
                    {loading ? (
                      <CustomLoader />
                    ) : (
                      pageData?.result?.map((item, index) => (
                        <div className="student-list">
                          <span>
                            <b>
                              Student Email{" "}
                            </b>
                            {"   "}
                            <input
                              type="checkbox"
                              checked={emailCheckbox[index + 1]}
                              onChange={(e) =>
                                handleCheckbox(e, index, "email")
                              }
                            />
                          </span>
                          <b style={{ marginLeft: "150px" }}> Student Mobile</b>
                          {"   "}
                          {item.mobile && (
                            <input
                              type="checkbox"
                              checked={mobileCheckbox[index + 1]}
                              onChange={(e) =>
                                handleCheckbox(e, index, "mobile")
                              }
                            />
                          )}
                          <span>
                            <b style={{ marginLeft: "150px" }}> Parent Email </b>
                            {"   "}
                            <input
                              type="checkbox"
                              checked={emailParentCheckbox[index + 1]}
                              onChange={(e) =>
                                handleCheckbox(e, index, "parent_email")
                              }
                            />
                          </span>
                          <b style={{ marginLeft: "150px" }}> Parent Mobile</b>
                          {"   "}
                          {item.parent_mobile && (
                            <input
                              type="checkbox"
                              checked={mobileParentCheckbox[index + 1]}
                              onChange={(e) =>
                                handleCheckbox(e, index, "parent_mobile")
                              }
                            />
                          )}
                          <br />
                          <div>
                          <span>
                            {item?.email}
                          </span>
                          <span style={{ marginLeft: "150px" }}>
                            {item?.mobile}
                          </span>
                          <span style={{ marginLeft: "150px" }}>
                            {item?.parent_email}
                          </span>
                          <span style={{ marginLeft: "150px" }}>
                            {item?.parent_mobile}
                          </span>
                          </div>
                        </div>
                      ))
                    )}
                  </div> */}
                  <div className="admin-student-list-container">
  {loading ? (
    <CustomLoader />
  ) : (
    pageData?.result?.map((item, index) => (
      <div key={index} className="student-list">
        <div className="checkbox-group">
          <span>
            <b> Student Email </b>
            {item.email && (
            <input
              type="checkbox"
              checked={emailCheckbox[index + 1]}
              onChange={(e) => handleCheckbox(e, index, "email")}
            />)}
          </span>
          <span>
            <b> Student Mobile </b>
            {item.mobile && (
              <input
                type="checkbox"
                checked={mobileCheckbox[index + 1]}
                onChange={(e) => handleCheckbox(e, index, "mobile")}
              />
            )}
          </span>
          <span>
            <b> Parent Email </b>
            {item.parent_email && (
            <input
              type="checkbox"
              checked={emailParentCheckbox[index + 1]}
              onChange={(e) => handleCheckbox(e, index, "parent_email")}
            />)}
          </span>
          <span>
            <b> Parent Mobile </b>
            {item.parent_mobile && (
              <input
                type="checkbox"
                checked={mobileParentCheckbox[index + 1]}
                onChange={(e) => handleCheckbox(e, index, "parent_mobile")}
              />
            )}
          </span>
        </div>
        <div className="info-group">
          <div>
            <span>{item?.email}{" "}</span>
          </div>
          <div>
            <span>{item?.mobile}{" "}</span>
          </div>
          <div>
            <span>{item?.parent_email}{" "}</span>
          </div>
          <div>
            <span>{item?.parent_mobile}{" "}</span>
          </div>
        </div>
      </div>
    ))
  )}
</div>

                </div>
              )}
            </div>

            {pageData && pageData?.page_list?.length > 1 && (
              <div style={{ margin: "0 20px" }}>
                <CustomPagination
                  count={pageData && pageData}
                  pageValue={page}
                  setPage={setPage}
                />
              </div>
            )}

            {openInviteStudent && (
              <InviteStudent
                setOpenInviteStudent={setOpenInviteStudent}
                schoolId={schoolId}
              />
            )}
            {openInviteCounselor && (
              <InviteCounselor
                setOpenInviteCounselor={setOpenInviteCounselor}
                schoolId={schoolId}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Invites;
