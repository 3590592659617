import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Clear from "@material-ui/icons/Clear";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MsgDataService from "../../services/MsgService";
import MessageTemplateService from '../../services/MessageTemplateService';
import {
  faTrashAlt,
  faImage,
  faPaperclip,
  faSmile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Check from "@mui/icons-material/Check";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const ModalExample = ({
  open,
  closeHandler,
  buttonLabel,
  isStudentOrCounselor,
  getMsgData,
  NameOrGroupName,
  setNameOrGroupName,
  setMsg,
  msg,
  selectedGroupMessageStudent,
  setShowErrorAlert,
  setShowSuccessAlert,
  setShowNotificationAlert,
  setOpen,
  setSelectedGroupMessageStudent,
  numberOfStudents,
  // page,
}) => {
  console.log("getMsgData",msg);
  const ref = useRef();
  const inputRef = useRef();
  const timeout = useRef();
  const location = useLocation();
  // const [NameOrGroupName, setNameOrGroupName] = useState();
  const [page, setPage] = useState(1);
  const [cannedMsgData, setCannedMsgData] = useState("");
  const [openAccordion, setOpenAccordion] = useState(false);
  const [student, setStudent] = useState([]);
  const [showStudentContainer, setShowStudentContainer] = useState(false);
  const [loadingNameForStudent, setLoadingNameForStudent] = useState(false);
  const [messageId, setMessageId] = useState();
  // const [msg, setMsg] = useState();
  const [selected, setSelected] = useState();
  const [selectedfile, setSelectedFile] = useState();
  const [fileDatafromApi, setFileDataFromApi] = useState();
  let userData = useSelector((state) => state.UserReducer.userObj);

  const messageInputHandler = (e) => {
    setMsg(e.target.value);
  };
  const handleFileChange = async (e) => {
    await setSelectedFile(ref.current.files[0]);
  };

  console.log("selectedFile", selectedfile);

  const submitAttachment = async () => {
    if (selectedfile) {
      MsgDataService.attachment(selectedfile)
        .then((data) => {
          setFileDataFromApi(data.data);
          ref.current.value = "";
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    submitAttachment();
  }, [selectedfile]);

  const getStudentData = (value) => {
    console.log(
      "selectedGroupStudent",
      value,
      NameOrGroupName,
      typeof NameOrGroupName
    );
    if (typeof NameOrGroupName === "string") {
      MsgDataService.getList(isStudentOrCounselor, value)
        .then((data) => {
          setLoadingNameForStudent(false);
          setStudent(data.data.objects);
        })
        .catch((err) => {
          setLoadingNameForStudent(false);
          console.log("err from the service", err);
        });
    }
  };

  // useEffect(() => {
  //     clearTimeout(timeout.current)
  //     setTimeout(() => {
  //         getStudentData()
  //     }, 5000);
  // }, [NameOrGroupName]);

  useEffect(() => {
    if (!msg) {
      setSelected(false);
    }
  }, [msg, NameOrGroupName]);

  const handleNameOrGroupChange = (e) => {
    clearTimeout(timeout.current);
    if (!NameOrGroupName) {
      setShowStudentContainer(false);
      setStudent([]);
    }
    setLoadingNameForStudent(true);
    setNameOrGroupName(inputRef.current.value);
    timeout.current = setTimeout(() => {
      getStudentData(inputRef.current.value);
    }, 1000);
    setShowStudentContainer(true);
  };

  const getCannedMsgData = () => {
    MsgDataService.getCannedMsgPage(userData?.college,page)
      .then(data => {
          console.log("data canned", data);
          setCannedMsgData(data.data?.result)
          // setLoading(false);
          // setIncludeFeedback(false);
      })
      .catch(error => {
          // setLoading(false)
          console.error('error from the service', error)
      })
  };

  const accordionHandler = () => {
    setOpenAccordion(!openAccordion);
  };

  const selectTemplate = (item) => {
    setOpenAccordion(false);
    setMsg(item);
  };

  const selectStudent = (value) => {
    console.log("vale from the select Student", value);
    setNameOrGroupName(value);
    setMessageId(value?.id);
    setShowStudentContainer(false);
  };

  const sendCopyToParents = () => {
    setSelected(!selected);
  };

  const sendMessage = (e) => {
    let selectedStudent = [];
    if (msg === "" || msg.trim() === "" || msg === "undefined") {
      e.preventDefault();
    }
    else{
    if (Array.isArray(NameOrGroupName)) {
      NameOrGroupName &&
        NameOrGroupName.map((item, index) => {
          selectedStudent.push(item.id);
        });
    }    
    let obj = {
      // answers: [],
      message_body: typeof msg === "object" ? msg.messages.en.body : msg,
      dst_id:  Array.isArray(NameOrGroupName) ? selectedStudent : [messageId],
      event_type:
      isStudentOrCounselor === "counselors"
      ? "recruiter_counselor"
      : isStudentOrCounselor === "students"
      ? "recruiter_student"
      : isStudentOrCounselor === "international-students"
      ? "international_student"
      : null
      // student: [msgData && msgData && msgData.student.id],
    };

    if (fileDatafromApi) {
      obj.attachment_url= fileDatafromApi?.Status?.image_name;
    }
    if (selected) {
      obj.parent_ack = selected;
    }
    setShowNotificationAlert(true);
    setNameOrGroupName();
    setMsg();
    setSelected(false);
    setSelectedGroupMessageStudent([]);
    setOpen(false);

    MsgDataService.sendMessage(obj)
      .then((data) => {
        if(data?.data?.error){
          setShowErrorAlert(true);
        }
        else{
          setShowSuccessAlert(true);
        }
        setNameOrGroupName();
        setMsg();
        setSelected(false);
        //closeHandler();
        setFileDataFromApi("");
        getMsgData();
        setMsg("");
        setNameOrGroupName("");
        setSelectedGroupMessageStudent([]);
        setShowNotificationAlert(false);
      })
      .catch((error) => {
        setNameOrGroupName();
        setMsg();
        setSelected(false);
        setMsg("");
        setNameOrGroupName("");
        setShowNotificationAlert(false);
        setFileDataFromApi("");
      });
  }}

  useEffect(() => {
    if (location?.state) {
      setNameOrGroupName(location.state.name);
      setMessageId(location.state.id);
    }
  }, [location]);

  // console.log("data 22",fileDatafromApi);

  const deleteAttachment = () => {
    console.log("data delete",fileDatafromApi?.Status?.image_name)
    let body ={
      message_file:fileDatafromApi?.Status?.image_name
    }
    MsgDataService.deleteattachment(body)
      .then((data) => {
        setFileDataFromApi();
      })
      .catch((error) => console.log("error from the service", error));
  };

  useEffect(() => {
    getCannedMsgData();
  }, []);

  const onModalClose = () => {
    setNameOrGroupName();
    setStudent([]);
    setLoadingNameForStudent(false);
    setShowStudentContainer(false);
    closeHandler();
  };

  const sendMessageWhenAllSelected = (e) => {
    if (msg === "" || msg.trim() === "" || msg === "undefined") {
      e.preventDefault();
    }
    else{  
    // console.log("selectedStudent", selectedStudent); 
    let query = JSON.parse(localStorage.getItem("query"));
    let obj = {
      message_body: typeof msg === "object" ? msg.messages.en.body : msg,
      // dst_id: [messageId],
      is_all:true,
      query:[query],
      event_type:
      isStudentOrCounselor === "counselors"
      ? "recruiter_counselor"
      : isStudentOrCounselor === "students"
      ? "recruiter_student"
      : isStudentOrCounselor === "international-students"
      ? "international_student"
      : null
      // student: [msgData && msgData && msgData.student.id],
    };
    if(isStudentOrCounselor === "international-students"){
        obj.is_international = true;
    }
    if (selected) {
      obj.parent_ack = selected;
    }
    if (fileDatafromApi) {
      obj.attachment_url= fileDatafromApi?.Status?.image_name;
    }
    let final = {...obj };
    setShowNotificationAlert(true);
    setNameOrGroupName();
    setMsg();
    setSelected(false);
    setSelectedGroupMessageStudent([]);
    setOpen(false);
    MsgDataService.query_result_message(final)
      .then((data) => {
        setNameOrGroupName();
        setMsg();
        setSelected(false);
        //closeHandler();
        getMsgData();
        setShowSuccessAlert(true);
        setMsg("");
        setNameOrGroupName("");
        setSelectedGroupMessageStudent([]);
        setShowNotificationAlert(false);
        setFileDataFromApi();
      })
      .catch((error) => {
        console.log("error from the service", error);
        setShowErrorAlert(true);
        setNameOrGroupName();
        setMsg();
        setSelected(false);
        setMsg("");
        setNameOrGroupName("");
        setShowNotificationAlert(false);
        setFileDataFromApi();
      });
  }}

  return (
    <div>
      <Modal
        isOpen={open}
        toggle={closeHandler}
        modalClassName="modalClassName"
        centered={true}
        contentClassName="modalContentClassName"
      >
        <ModalHeader>
          <div className="header-container">
            <p style={{ marginBottom: "0px" }}>NEW MESSAGE</p>
            <div style={{ cursor: "pointer" }} onClick={onModalClose}>
              <span style={{ fontSize: "18px", fontWeight: "bold" }}>x</span>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <input
            type="text"
            disabled={
              Array.isArray(selectedGroupMessageStudent) === true
                ? selectedGroupMessageStudent.length > 0
                : selectedGroupMessageStudent
            }
            className="nameorgroupname"
            ref={inputRef}
            onChange={handleNameOrGroupChange}
            placeholder="Type name(s) or group name(s)..."
            value={
              typeof NameOrGroupName === "object"
                ? NameOrGroupName.name
                : NameOrGroupName
            }
          />
          {loadingNameForStudent && (
            <div style={{ padding: "5px" }}>
              <p>Loading...</p>
            </div>
          )}
          {!loadingNameForStudent &&
          selectedGroupMessageStudent &&
          Array.isArray(selectedGroupMessageStudent) ? (
            <div style={{ maxHeight: "360px", overflowY: "scroll" }}>
              {selectedGroupMessageStudent &&
                selectedGroupMessageStudent.map((item) => (
                  <div>{`${item["1"]} ${item["2"]}`}</div>
                ))}
            </div>
          ) : (
            !loadingNameForStudent &&
            Array.isArray(selectedGroupMessageStudent) &&
            selectedGroupMessageStudent && (
              <div style={{ maxHeight: "360px", overflowY: "scroll" }}>
                {`${selectedGroupMessageStudent &&
                  selectedGroupMessageStudent[
                    "1"
                  ]} ${selectedGroupMessageStudent &&
                  selectedGroupMessageStudent["2"]}`}
              </div>
            )
          )}
          {typeof selectedGroupMessageStudent === "string" &&
            selectedGroupMessageStudent === "selected All" && (
              <div style={{ padding: "10px" }}>
                <p className="no-margin-bottom">{`You have selected ${numberOfStudents} Students.`}</p>
              </div>
            )}
          {!loadingNameForStudent &&
            showStudentContainer &&
            student &&
            NameOrGroupName && (
              <div className="student-container">
                {student &&
                  student.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => selectStudent(item)}
                        className="single-student"
                      >
                        {item.name}
                      </div>
                    );
                  })}
              </div>
            )}
          <div
            className="modalAccordion"
            onClick={accordionHandler}
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            Choose a Scheduled Message Template 
            <div>
              <KeyboardArrowDownIcon />
            </div>
          </div>
          <div className="accordion" id="accordionExample">
            <div
              id="collapseOne"
              className={`collapse cannedWrapper ${openAccordion && "show"}`}
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              {cannedMsgData &&
                cannedMsgData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => selectTemplate(item)}
                      className="cannedMsgContainer"
                    >
                      <p style={{ fontWeight: "bold" }}>
                        {item.messages.en.subject}
                      </p>
                      <p>{item.messages.en.body}</p>
                    </div>
                  );
                })}
            </div>
          </div>
          <textarea
            rows="6"
            cols="30"
            className="textAreaInput"
            placeholder="Or type your message..."
            onChange={messageInputHandler}
            value={typeof msg === "object" ? msg.messages.en.body : msg}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px",
            }}
            className="reply_container"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ cursor: "pointer" }}>
                <FontAwesomeIcon
                  icon={faImage}
                  color="var(--input-label-color)"
                />
              </div>
              <div style={{ marginLeft: "10px", cursor: "pointer" }}>
                <FontAwesomeIcon icon={faPaperclip} />
              </div>
              <div style={{ marginLeft: "10px", cursor: "pointer" }}>
                <FontAwesomeIcon icon={faSmile} />
              </div>
              {isStudentOrCounselor === "students" ? (
                <div>
                  <div
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ cursor: "pointer" }}  onClick={sendCopyToParents}>
                      {selected ? (
                        <Check className={selected ? "check" : ""} />
                      ) : (
                        <CheckBoxOutlineBlankIcon
                          className={!msg || !NameOrGroupName ? "" : "check"}
                        />
                      )}
                    </div>
                    <span
                      onClick={sendCopyToParents}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    >
                      <button
                        disabled={
                          selectedGroupMessageStudent === "selected All" &&
                          typeof selectedGroupMessageStudent === "string"
                            ? !msg
                            : !msg || !NameOrGroupName
                        }
                        className="sendmessage"
                        style={{
                          color:
                            selectedGroupMessageStudent === "selected All" &&
                            typeof selectedGroupMessageStudent === "string" &&
                            msg
                              ? "white"
                              : !msg || !NameOrGroupName
                              ? "var(--input-label-color)"
                              : "white",
                        }}
                      >
                        Send Copy to Parents&nbsp;&nbsp;
                      </button>
                    </span>
                  </div>
                </div>
              ) : null}
              &emsp;
              <div>
                <input type="file" ref={ref} onChange={handleFileChange} />
              </div>
              {fileDatafromApi && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ color: "#008000", marginBottom: "0px" }}>
                  {fileDatafromApi && fileDatafromApi?.Status?.filename.slice(0,15)}
                  </p>
                  <div onClick={deleteAttachment} style={{ cursor: "pointer" }}>
                    <Clear className="cancelAttachment" />
                  </div>
                </div>
              )}
            </div>
            <span>
              <button
                onClick={
                  selectedGroupMessageStudent === "selected All" &&
                  typeof selectedGroupMessageStudent === "string"
                    ? sendMessageWhenAllSelected
                    : sendMessage
                }
                className="sendmessage"
                style={{
                  color:
                    selectedGroupMessageStudent === "selected All" &&
                    typeof selectedGroupMessageStudent === "string" &&
                    msg
                      ? "white"
                      : !msg || !NameOrGroupName
                      ? "var(--input-label-color)"
                      : "white",
                }}
                disabled={selectedGroupMessageStudent === "selected All" && typeof selectedGroupMessageStudent === 'string' ? !msg : !msg || !msg || !NameOrGroupName}
                // disabled={selectedGroupMessageStudent === "selected All" && typeof selectedGroupMessageStudent === 'string' ? !msg || !msg.trim() : !msg || !msg.trim() || !NameOrGroupName}
              >
                SEND MESSAGE
              </button>
            </span>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalExample;