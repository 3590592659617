import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, FormGroup, Input, Row } from 'reactstrap';
import Loading from '../../../components/Loading/Loading';
import AppHeader from '../../../Layout/AppHeader';
import AppSidebar from '../../../Layout/AppSidebar';
import LaddersDataService from '../../../services/LaddersService';
import './ParentLadder.scss';

const ParentLadder = () => {
    const [loading, setLoading] = useState(false);
    const [studentList, setStudentList] = useState([]);
    const [checkListObj, setCheckListObj] = useState({});
    const [studentId, setStudentId] = useState('');
    const [tagline, setTagline] = useState('');

    useEffect(() => {
        getStudentList();
    }, [])

    const getStudentList = () => {
        setLoading(true);
        LaddersDataService.getParentRoleStudentList().then(res => {
            if (res?.status === 200) {
                if (res?.data && res?.data?.list && res?.data?.list.length > 0) {
                    let studentArr = res?.data?.list || [];
                    // let tempArr = [{name: "Vibha", id: 99463}, {name: "heena", id: 5678}];
                    // studentArr.push(...tempArr);
                    setStudentList(studentArr);
                    setStudentId(studentArr[0]?.id);
                    setLoading(false);
                }
            }

        }).catch(error => {
            console.log('error from the service', error)
            setLoading(false);
        })
    }

    useEffect(() => {
        if (studentList && studentList.length > 0 && studentId) {
            getParentladders();
        }
    }, [studentList, studentId])

    const getParentladders = () => {
        let payload = {
            studentid: studentId || ''
        }
        setLoading(true);
        LaddersDataService.getParentRoleLadders(payload).then(res => {
            if (res?.status === 200) {
                if (res?.data) {
                    let chekedObj = res?.data?.message[0] || {};
                    let tagVal = res?.data?.profile_quote || '';
                    setCheckListObj(chekedObj);
                    setTagline(tagVal);
                    setLoading(false);
                }
            }
        }).catch(error => {
            console.log('error from the service', error)
            setLoading(false);
        })
    }

    const handleChangeStudent = (e) => {
        setStudentId(e.target.value);
    }
    return (
        <>
            <AppHeader />
            <div className="app-main">
                <AppSidebar />

                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <div className="parentLadder">
                            <div className="header-title d-flex justify-content-between align-items-center mb-3">
                                <h4 className="cardHeader title-main">College Checklist</h4>
                            </div>
                            {studentList && studentList.length > 0 ?
                                <Row>
                                    <Col lg="12">
                                        <FormGroup className='text-left studentSelect'>
                                            <Input
                                                className="form-control"
                                                type='select'
                                                name='studentId'
                                                value={studentId || ''}
                                                onChange={handleChangeStudent}
                                            >
                                                {studentList.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                : ''}
                            <div className="ladder-main">
                                <Row>
                                    <Col lg="12" className="mx-auto">
                                        <Card className="main-card mb-3 interest-main">
                                            <CardBody>
                                                <>
                                                    {loading ? (
                                                        <Loading />
                                                    ) : (
                                                        <>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <div className="shadow-box">
                                                                        <label className="chekbox-container">
                                                                            College Enrollment Ladders Checklist
                                                                            <input
                                                                                type="checkbox"
                                                                                disabled
                                                                                checked={checkListObj.complete_all || false}
                                                                            />
                                                                            <span className="checkmark" />
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <div className="shadow-box">
                                                                        <label className="chekbox-container">
                                                                            Completed FAFSA
                                                                            <input
                                                                                type="checkbox"
                                                                                disabled
                                                                                checked={checkListObj.fafsa || false}
                                                                            />
                                                                            <span className="checkmark" />
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <div className="shadow-box">
                                                                        <label className="chekbox-container">
                                                                            Provided Recommendations
                                                                            <input
                                                                                type="checkbox"
                                                                                disabled
                                                                                checked={checkListObj.p_recom || false}
                                                                            />
                                                                            <span className="checkmark" />
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <div className="shadow-box">
                                                                        <label className="chekbox-container">
                                                                            Completed College Essay
                                                                            <input
                                                                                type="checkbox"
                                                                                disabled
                                                                                checked={checkListObj.essay || false}
                                                                            />
                                                                            <span className="checkmark" />
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <div className="shadow-box">
                                                                        <label className="chekbox-container">
                                                                            Submitted Transcript
                                                                            <input
                                                                                type="checkbox"
                                                                                disabled
                                                                                checked={checkListObj.transcript || false}
                                                                            />
                                                                            <span className="checkmark" />
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <div className="shadow-box">
                                                                        <label className="chekbox-container">
                                                                            Paid Application Fees
                                                                            <input
                                                                                type="checkbox"
                                                                                disabled
                                                                                checked={checkListObj.paid_fee || false}
                                                                            />
                                                                            <span className="checkmark" />
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                                {/* {tagline ?

                                                                    <Col lg='12'>
                                                                        <div className="tagLineHeader">Tag-Line</div>
                                                                        <p className='tagText'>"{tagline}"</p>
                                                                    </Col>

                                                                    : ''} */}

                                                            </Row>
                                                        </>
                                                    )}
                                                </>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ParentLadder
