import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import MsgDataService from "../../services/MsgService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const MessageList = ({
  data,
  selectMessage,
  selectedMessageIndex,
  getMsgData,
  searchList
}) => {
  const [dataFromProps, setDataFromProps] = useState(data);
  const [dataList, setDataList] = useState([]);
  // const messageBody = (item) => {
  //   let count = Object.keys(item).length;
  //   let messageBody;
  //   messageBody = item && item[`${count}`].body;
  //   return messageBody;
  // };

  // const messageDate = (item) => {
  //   // console.log(item)
  //   let count = Object.keys(item)?.length;
  //   let messageDate1;
  //   messageDate1 = item && item[`${count}`].date_sent;
  //   messageDate1 = moment(messageDate1).format("ddd MMM Do YYYY, hh:mm:ss a");
  //   return messageDate1;
  // };

  const onSelectedDelete = (e, item) => {
    let body = {
      message_id: item && item.id,
      // type: "single_message",
    };
    MsgDataService.deleteRecruiterMsg(body)
      .then((data) => {
        console.log("data from the delete service", data);
        getMsgData();
      })
      .catch((error) => console.log("errror from service", error));
    e.stopPropagation();
  };

  // const showAttachment = (item) => {
  //   let count = Object.keys(item).length;
  //   if (item?.[count]?.attachment) {
  //     return (
  //       <div>
  //         <a
  //           className="download-attachment-anchor"
  //           target="_blank"
  //           href={item?.[count].attachment}
  //         >
  //           <span className="flex download-attachment">
  //             <FontAwesomeIcon
  //               style={{ marginRight: "7px" }}
  //               icon={faDownload}
  //             />
  //             <p className="no-margin-bottom">Download Attachment</p>
  //           </span>
  //         </a>
  //       </div>
  //     );
  //   }
  // };;

  useEffect(()=>{
    if(searchList) {
    setDataList(searchList)
    } else {
    setDataList(data.data)
    }
  },[searchList])

  // const showAttachment = (item) => {
  //   let count = Object.keys(item).length;
  //   if (item?.[count]?.file_data?.url) {
  //     return (
  //       <div>
  //         <a
  //           className="download-attachment-anchor"
  //           target="_blank"
  //           href={item?.[count].file_data?.url}
  //         >
  //           <span className="flex download-attachment">
  //             <FontAwesomeIcon
  //               style={{ marginRight: "7px" }}
  //               icon={faDownload}
  //             />
  //             <p className="no-margin-bottom">
  //               {/* Download Attachment */}
  //               {item?.[count].file_data?.file_name}
  //               </p>
  //           </span>
  //         </a>
  //       </div>
  //     );
  //   }
  // };
//  console.log("dataList", dataList);
  return (
    <Fragment>
      {
      dataList?.length > 0 ?
      // dataFromProps &&
      //   dataFromProps.result &&
      dataList?.map((item, index) => {
          // console.log("item from the messagelist", item);
          return (
            <div
              key={item.src_id}
              onClick={() => selectMessage(item, index)}
              className={
                "single_msg_container " +
                (index === selectedMessageIndex
                  ? "active_selectedMsg"
                  : "inactive_selectedMsg")
              }
            >
              <div className="date_delete_container">
                <p className="date">{moment(item.time).format("ddd MMM Do YYYY, hh:mm:ss a")}</p>
                <img
                  onClick={(e) => onSelectedDelete(e, item)}
                  style={{ width: "15px", height: "15px" }}
                  src={IMG_URL+"TrashIcon.svg"}
                  alt="delete"
                />
              </div>
              <div style={{ display: "flex", padding: "25px 10px 15px 10px" }}>
                <img
                  style={{
                    width: "50px",
                    borderRadius: "50%",
                    height: "50px",
                    marginTop: "-10px",
                  }}
                  src={IMG_URL+"studentplaceholder.png"}
                  alt="Placeholder"
                />
                <div
                  style={{
                    marginTop: "-10px",
                    marginLeft: "5px",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      marginBottom: "0px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >{`${item.name}`}</p>
                  <div
                    className=""
                    style={{
                      margin: "5px 5px",
                      fontSize: "14px",
                      height: "70px",
                      overflowY: "scroll",
                    }}
                  >
                    {item.last_message}
                    <br/><br/>
                    <div>
                    <a target="_blank" style={{ textDecorationLine: "Underline" }} href={item?.attachment_url}>
                      {item?.file_name}
                    </a>
                    </div>
                    {/* <div>{showAttachment(item.messages)}</div> */}
                  </div>
                </div>
              </div>
            </div>
          );
        }) : "No Data Found"}
    </Fragment>
  );
};
export default MessageList;
