import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './GoalNumberCard.scss';
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const GoalNumberCard = ({ cardColorClass, cardNumberData, cardName, onClick, isSelected }) => {
    return (
        <div className={`cardNumberBlock ${cardColorClass ? cardColorClass : ''}`} onClick={onClick}>
            <div className='cardBlockInner'>
                <div className='textWrap'>
                    <div className={`number ${cardColorClass ? cardColorClass : ''}`}>{cardNumberData}</div>
                    <div className='cardTextArea'>
                        <img src={IMG_URL+"./userIcon.png"} alt='userIcon' className='userIcon' />
                        <div className='cardText'>{cardName}</div>
                    </div>
                </div>
                {isSelected ?
                    <div className='selectIcon'>
                        <FontAwesomeIcon icon={faCheck} />
                    </div>
                    : ''}

            </div>
        </div>
    )
}

export default GoalNumberCard
