import React, { Component, useState, useEffect } from "react";
import QueryService from "../../../services/QueryService";
import makeAnimated from "react-select/animated";
import MySelect from "./MySelect.js";
import { components } from "react-select";
import { Divider } from "@mui/material";

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const allOption = {
  label: "",
  value: "*"
};

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }
  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All Selected";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const animatedComponents = makeAnimated();
export default class Example extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: null,
      collageData: [],
      loading: false
    };
  }  

  handleChange = selected => {
    this.setState({
      optionSelected: selected
    });
    console.log("selected", selected);
    this.props.onSelect(selected)
  };
  
  getCollage = () => {
      // const body = {
      //   list_name: "collage",
      // };
      this.setState({...this.state,loading:true});
      console.log("getCollege called");
      QueryService.getCollageDataCCC()
        .then((data) => {
          let AllCollegeData = data?.data?.ccc_view?.college
          console.log(AllCollegeData);
          this.setState({...this.state,loading:false});
          this.setState({...this.state, collageData:AllCollegeData[0]});
        })
        .catch((error) => {
          this.setState({...this.state,loading:false});
          console.log("error frm the service", error);
        });
    };
  
    componentDidMount(){
      this.getCollage()
    }

  render() {
    const {collageData, loading} = this.state
    return (
      <span
        class="d-inline-block col-md-12"
        data-toggle="popover"
        data-trigger="focus"
        data-content="Please select account(s)"
      >
       <div>
        <MySelect
          options={collageData?.map(data => ({label: data.name, value: data.name}))}
          isMulti
          placeholder="Select Community College"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
            MultiValue,
            ValueContainer,
            animatedComponents
          }}
          onChange={this.handleChange}
          allowSelectAll={true}
          value={this.state.optionSelected}
        />
        </div>
      </span>
    );
  }
}