import React, { Fragment, useEffect, useState } from 'react';
import Loader from "react-loader-spinner";


const LoaderForQuery = () => {
    return (
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'85px'}}>
            <Loader
                type="TailSpin"
                color="#E36221"
                height={40}
                width={40}
            // timeout={3000} //3 secs
            />
        </div>
    )
}

const SingleQuery = ({ data, name, onShowMore, handleCheckBox, selectedQueries,loading }) => {
    const [displayValue, setDisplayValue] = useState([]);
    useEffect(() => {
        setDisplayValue(selectedQueries)
    }, [JSON.stringify(selectedQueries)])
    return (
        <Fragment>
            <div style={{ height: '100%' }}>
                <div className="query-title-containers ">
                    <p style={{ marginBottom: '0px', textTransform: 'capitalize' }} className="bold">
                        {name}
                    </p>
                </div>
                <div style={{ border: '1px solid #ece8dd' }}>
                    {loading ? <LoaderForQuery /> : <div style={{ padding: '10px 20px' }}>
                       {data && data?.map((item, index) => {
                            return (
                                <div key={index} className="flex">
                                     <input type="checkbox"
                                        onChange={() => handleCheckBox(item, name)}
                                        checked={
                                            displayValue &&
                                            displayValue.some((q) => {
                                              const a = q?.query?.name || q?.query?.stem_name;
                                              return (
                                                q &&
                                                q.query &&
                                                (q.query.name || q.query.stem_name) &&
                                                a === (item.name || item.stem_name)
                                              );
                                            })
                                          }
                                    /> 
                                    <p style={{ marginLeft: '10px', fontSize: '14px', textTransform:'capitalize' }} className="no-margin-bottom">
                                        {item.stem_name ? item.stem_name : item.name|| item.goal_name}
                                    </p> 
                                </div>
                            )
                        })}

                    </div>}
                    {data && data?.length >= 3 && name !== "subject matter proficiency" &&
            name !== "community college" &&
                        <div style={{ textAlign: 'end', textDecoration: 'underline', textTransform: 'capitalize', fontSize: '14px', cursor: 'pointer' }}
                            onClick={(name) => onShowMore(name)}>
                            {`Show ${data && data.length > 3 ? "less" : 'All'} ${name}`}
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
}
export default SingleQuery