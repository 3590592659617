import React, { Fragment, useState, useEffect, useRef } from "react";
import { TrendingUpRounded } from "@mui/icons-material";
import Loader from "react-loader-spinner";
import CustomLoader from "../../customLoader/CustomLoader";
import GetAdminService from "../../../services/admin/GetAdminService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import "./CannedMessages.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router";
import CustomPagination from "../../pagination/Pagination";

const CannedMessages = () => {
  const [pageData, setPageData] = useState();
  const [page, setPage] = useState(1);
  const [schoolName, setSchoolName] = useState({ name: "" });
  const [showSchoolNameContainer, setShowSchoolNameContainer] = useState(false);
  const [schoolNameList, setSchoolNameList] = useState();
  const schoolNameRef = useRef();
  const timeoutRef = useRef();
  const [schoolNameLoading, setSchoolNameLoading] = useState(false);
  const [pageDataLoading, setPageDataLoading] = useState(false);
  let history = useHistory();
  let location = useLocation();
  const [schoolId, setSchoolId] = useState(
    location?.pathname?.split("/").pop()
  );

  const getSchoolsList = (value) => {
    setShowSchoolNameContainer(true);
    setSchoolNameLoading(true);
    GetAdminService.getSchoolsList(value)
      .then((data) => {
        console.log("data from the service", data);
        setSchoolNameList(data?.data?.school);
        setSchoolNameLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setSchoolNameLoading(false);
        setSchoolNameList();
      });
  };

  const getPageData = (id) => {
    setPageDataLoading(true);
    let obj = {
      page: page,
      school: id,
    };
    GetAdminService.getCannedPage(obj)
      .then((data) => {
        console.log("data from the service", data);
        setPageData(data.data);
        setPageDataLoading(false);
      })
      .catch((error) => {
        setPageDataLoading(false);
        console.error("error from the service", error);
      });
  };

  const createCannedMessage = () => {
    setPageDataLoading(true);
    let obj = {
      messages: {
        en: {
          subject: "",
          body: "",
        },
        es: {
          subject: "",
          body: "",
        },
      },
      school_id: schoolId,
    };
    GetAdminService.createCannedMessage(obj)
      .then((data) => {
        console.log("data from the service", data);
        history.push(
          `/admin/canned-mesage/${data?.data?.id}?new=1&school=${
            data?.data?.school_id
          }`
        );
      })
      .catch((error) => {
        setPageDataLoading(false);
        console.error("error from the service", error);
      });
  };

  useEffect(() => {
    console.log(schoolId);
    if (schoolId !== "canned") getPageData(schoolId);
  }, []);

  useEffect(() => {
    if (schoolId !== "canned") {
      getPageData(schoolId, page);
    }
  }, [page]);

  const editMessage = (id) => {
    history.push(`/admin/canned-mesage/${id}?school=${schoolId}`);
  };

  const handleSchoolName = () => {
    clearTimeout(timeoutRef.current);
    setSchoolName({ name: schoolNameRef.current.value });
    if (!schoolNameRef.current.value) {
      setShowSchoolNameContainer(false);
      setSchoolNameList();
      setPageData([])
    } else {
      timeoutRef.current = setTimeout(() => {
        getSchoolsList(schoolNameRef.current.value);
      }, 1000);
    }
  };

  const handleSelectedSchoolName = (item) => {
    setSchoolName(item);
    setShowSchoolNameContainer(false);
    getPageData(item?.id);
    setSchoolId(item?.id);
  };

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="admin-canned-container">
            <div className="flex canned-message-heading">
              <p>
                <span style={{ fontSize: "25px", fontWeight: "400" }}>
                  Canned Messages
                </span>
              </p>
              {pageData && (
                <button
                  className="create-message-button"
                  onClick={createCannedMessage}
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "5px" }}
                    icon={faEnvelope}
                  />
                  New Canned
                </button>
              )}
            </div>
            <div className="school-name-container">
              <p>Select School</p>
              <div>
                <input
                  ref={schoolNameRef}
                  className="search-admin-school-input"
                  type="text"
                  placeholder="School Name"
                  value={schoolName.name}
                  onChange={handleSchoolName}
                  autoComplete="off"
                />
                {showSchoolNameContainer && (
                  <div>
                    <ul className="admin-school-name-list">
                      {schoolNameLoading && <CustomLoader />}
                      {schoolNameList?.length > 0 &&
                        schoolNameList.map((item, index) => (
                          <li
                            onClick={() => handleSelectedSchoolName(item)}
                            key={index}
                            className="flex admin-school-list cursor"
                          >
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {pageData && (
              <div>
                {pageDataLoading && <CustomLoader />}
                <p style={{marginLeft:"16px"}}>Canned Messages</p>
                {pageData?.result?.map((item, index) => {
                  return (
                    <div
                      className="message-list"
                      onClick={() => editMessage(item?.id)}
                    >
                      {item?.messages?.en?.subject}
                    </div>
                  );
                })}
              </div>
            )}

            {pageData && pageData?.page_list?.length > 1 && (
              <div style={{ margin: "20px" }}>
                <CustomPagination
                  count={pageData && pageData}
                  pageValue={page}
                  setPage={setPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CannedMessages;
