import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CommonButton } from '../../components';
import './LetsGetStart.scss'
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const LetsGetStart = () => {
    const history = useHistory();
    return (
        <div className='connectWrap'>
            <div className='header text-center'>
                <img src={IMG_URL+"logo.png"} alt='colorLogo' className='img-fluid colorLogo' />
            </div>
            <div className='connectBlockWrap'>
                <div className='connectBlockInner text-center'>
                    <div className='connectBlock boxShadow'>
                        <h2 className='connect-title'>Let's Get Started !</h2>
                        <p>Select the account you are signing up for below:</p>
                        <div className='btnGroup'>
                            <CommonButton
                                name='Counselor Connect'
                                btnClass='primaryBtn w-100 connectBtn'
                                btnWidth='320px'
                                btnHeight='50px'
                                onClick={() => history.push(`counselor-register`)}
                            />
                            <CommonButton
                                name='Student Connect'
                                btnClass='primaryBtn w-100 connectBtn'
                                btnWidth='320px'
                                btnHeight='50px'
                                onClick={() => history.push(`select-account`)}
                            />
                            <CommonButton
                                name='Parent Connect'
                                btnClass='primaryBtn w-100 connectBtn'
                                btnWidth='320px'
                                btnHeight='50px'
                                onClick={() => history.push(`create-parent-account`)}
                            />
                        </div>
                    </div>
                    <Link className='backLink' to='login'> {'<'} Back to Sign In</Link>
                </div>
            </div>
        </div>
    )
}

export default LetsGetStart
