import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import {
  faAsterisk,
  faPlus,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GetStudentService from "../../../../services/SchoolIT/GetStudentService";
import swal from "sweetalert";
import AppHeader from "../../../../Layout/AppHeader";
import AppSidebar from "../../../../Layout/AppSidebar";
import { Modal, ModalBody } from "reactstrap";
import GetAdminService from "../../../../services/admin/GetAdminService";
import CustomLoader from "../../../customLoader/CustomLoader";

const goalFieldOptionArray = [
  { id: "course", field_name: "completed course requirement" },
  { id: "AND", field_name: "completed all of the following" },
  { id: "OR", field_name: "completed one of the following" },
];

const AddNewModal = ({ isOpen, closeHandler, data }) => {
  const [input, setInput] = useState("");

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={closeHandler} centered>
        <ModalBody>
          <h5 style={{ padding: "15px 16px 0 16px", textAlign: "center" }}>
            {data.title}
          </h5>
          <div style={{ position: "relative", padding: "10px" }}>
            <input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              style={{ width: "100%", padding: "5px", borderRadius: "5px" }}
              type="text"
              placeholder="Field Name"
              className="require-input-name"
            />
          </div>
        </ModalBody>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "5px 0 30px 0",
          }}
        >
          <button
            disabled={!input}
            style={{ padding: "5px 20px", margin: "0 30px", fontSize: "20px" }}
            className={!input ? "disabled-modal-button" : "save-modal-button"}
            onClick={() => data.save(input)}
          >
            <FontAwesomeIcon icon={faSave} style={{ margin: "0 10px" }} />
            Save
          </button>
          <button
            style={{
              padding: "5px 20px",
              margin: "0 30px",
              fontSize: "20px",
            }}
            className="cancel-modal-button"
            onClick={closeHandler}
          >
            <FontAwesomeIcon icon={faTimes} style={{ margin: "0 6px" }} />
            Cancel
          </button>
        </div>
      </Modal>
    </Fragment>
  );
};

const CreateGoal = () => {
  let history = useHistory();
  let location = useLocation();
  const [parentCount, setParentCount] = useState(0);
  const [parentCountArray, setParentCountArray] = useState([]);
  const [courseCriteria, setCourseCriteria] = useState([]);
  const [goalFieldOption, setGoalFieldOption] = useState([]);

  const [goalName, setGoalName] = useState();
  const [goalDescription, setGoalDescription] = useState();
  const [goalId, setGoalId] = useState(
    location?.pathname?.split("/").pop() === "goal"
      ? ""
      : location?.pathname?.split("/").pop()
  );
  const [goalData, setGoalData] = useState();
  const [addNewCourseModal, setAddNewCourseModal] = useState(false);
  const [addNewGoalModal, setAddNewGoalModal] = useState(false);
  const [marginForParent, setMarginForParent] = useState("");
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [loading, setLoading] = useState(false);
  const [requiredSubGoalData, setRequiredSubGoalData] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState([]);
  const [goalpublishData, setGoalPublishData] = useState([]);
  const [courseListData, setCourseListData] = useState([]);
  const [fieldOperators, setFieldOperators] = useState([]);
  const [addGoalName, setAddGoalName] = useState();
  const haveOrHaveNotRef = useRef();
  const gpaRef = useRef();
  const fieldOptionRef = useRef();
  const courseRef = useRef();
  const arithemeticOperatorRef = useRef();
  const timeout = useRef();
  const addGoalNameRef = useRef();

  const getGoalDetail = () => {
    setLoading(true);
    GetStudentService.getGoalDetail(goalId)
      .then((data) => {
        console.log("data from the goal service", data);
        setLoading(false);
        setGoalData(data?.data);
        if (data?.data?.green) {
          setCourseCriteria(Object.values(data?.data?.green));
        }
        setGoalName(data?.data?.goal_name);
        setGoalDescription(data?.data?.goal_description);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  const saveGoalMap = () => {
    console.log(goalId);
    if (goalId) {
      for (let i = 0; i < courseCriteria.length; i++) {
        if (courseCriteria[i].simple_exp) {
          courseCriteria[i].green_simple_exp = courseCriteria[i].simple_exp;
          delete courseCriteria[i].simple_exp;
        } else courseCriteria[i].green_simple_exp = "";
      }
    }

    let obj = {
      goal_description: goalDescription,
      goal_name: goalName,
      green: [null, ...courseCriteria],
      // school_id: user?.school_id,
      subgoal_id: requiredSubGoalData,
    };

    if (goalId) {
      obj.goal_id = goalId;
      obj.subgoal_id = Object.values(goalData?.subgoal_id);
    }
    console.log(obj);
    GetStudentService.createGoalMap(obj)
      .then((data) => {
        if (data?.msg) {
          swal({
            text: `Selected Literal goalfield ${data["msg"]}`,
            icon: "warning",
            dangerMode: true,
            buttons: {
              cancel: true,
            },
            closeOnClickOutside: false,
          });
        } else {
          history.push("/admin/ccplus");
        }
      })
      .catch((error) => {
        if (error.response.data) {
          swal({
            text: `The given Goal name already exists`,
            icon: "warning",
            dangerMode: true,
            buttons: {
              cancel: true,
            },
            closeOnClickOutside: false,
          });
        }
      });
  };

  const saveNewCourse = (name) => {
    let obj = {
      name: name,
      school_id: user?.school_id,
    };
    GetStudentService.saveNewCourse(obj)
      .then((data) => {
        console.log(data);
        setAddNewCourseModal(false);
      })
      .catch((error) => {
        if (error.response.data) {
          swal({
            text: error.response.data.message,
            icon: "warning",
            dangerMode: true,
            buttons: {
              cancel: true,
            },
            closeOnClickOutside: false,
          });
        }
      });
  };
  const saveNewGoalField = (name) => {
    let obj = {
      field_name: name,
      school_id: user?.school_id,
    };
    GetStudentService.saveNewGoalField(obj)
      .then((data) => {
        console.log(data);
        setAddNewGoalModal(false);
      })
      .catch((error) => {
        if (error.response.data) {
          swal({
            text: error.response.data.message,
            icon: "warning",
            dangerMode: true,
            buttons: {
              cancel: true,
            },
            closeOnClickOutside: false,
          });
        }
      });
  };

  useEffect(() => {
    if (selectedGoal?.length > 0) {
      let array = [];
      selectedGoal.map((item, index) => {
        let obj = {};
        obj["sub_goal"] = item?.goal?.id;
        obj["goal_name"] = item?.goal?.goal_name;
        obj["sub_goal_id"] = item?.goal?.subgoal_id;
        array.push(obj);
      });
      setRequiredSubGoalData(array);
    }
  }, [selectedGoal]);

  useEffect(() => {
    setParentCountArray([...parentCountArray, parentCount + 1]);
  }, [parentCount]);

  useEffect(() => {
    setMarginForParent(40 * parentCountArray.length);
  }, [JSON.stringify(parentCountArray)]);

  const AddNewCriteria = (parentValue) => {
    //  console.log(parentValue);
    if (parentValue) {
      setParentCount(parentCount + 1);
    }
    let array = [];
    let data = {
      field_name: "",
      operator: "",
      value: "",
      course_req: "",
      green_simple_exp: "",
      parent: parentValue ? parentCountArray : [],
      level: parentValue ? parentCountArray.length : 0,
      margin: parentValue ? `${marginForParent}px` : "margin",
      have_not: "",
    };
    array.push(data);
    setCourseCriteria([...courseCriteria, ...array]);
  };

  const removeCourseCriteria = (index) => {
    courseCriteria.splice(index, 1);
    setCourseCriteria([...courseCriteria]);
  };

  const getCourseList = () => {
    GetAdminService.listOfCourse("broadcastcourse")
      .then((data) => {
        console.log("data from the course list", data);
        setCourseListData(data.data.objects);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const getFieldOperators = () => {
    GetStudentService.listOfOperators()
      .then((data) => {
        console.log("data from the operators", data);
        setFieldOperators(data?.data?.objects);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const getGoalField = () => {
    GetAdminService.goalFieldForCreateGoal(100)
      .then((data) => {
        console.log(data);
        setGoalFieldOption([...data?.data?.objects, ...goalFieldOptionArray]);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const handleGoalNameChange = () => {
    clearTimeout(timeout.current);
    setAddGoalName(addGoalNameRef.current.value);
    timeout.current = setTimeout(() => {
      getPublishData(addGoalNameRef.current.value);
    }, 1000);
  };

  const getPublishData = (goalName) => {
    GetAdminService.adminGoalSearch(goalName)
      .then((data) => {
        console.log("data from the get pubish data", data);
        setGoalPublishData(data?.data?.objects);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const handlehaveOrHaveNotChange = (index, e) => {
    // console.log(haveOrHaveNotRef.current.value)
    courseCriteria[index].have_not = e.target.value;
    setCourseCriteria([...courseCriteria]);
  };

  const handleFieldOption = (index, e) => {
    // courseCriteria[index].field_name = fieldOptionRef.current.value;
    courseCriteria[index].field_name = e.target.value;
    setCourseCriteria([...courseCriteria]);
  };

  const handleCourseChange = (index) => {
    courseCriteria[index].course_req = courseRef.current.value;
    console.log(courseCriteria, ", index: ", index);
    setCourseCriteria([...courseCriteria]);
  };

  const handleArithemeticOperatorChange = (index) => {
    courseCriteria[index].operator = arithemeticOperatorRef.current.value;
    setCourseCriteria([...courseCriteria]);
  };

  const handleGPAChange = (index) => {
    courseCriteria[index].value = gpaRef.current.value;
    setCourseCriteria([...courseCriteria]);
  };

  const handleSelectedGoal = (item) => {
    let array = [];
    array.push(item);
    setSelectedGoal([...selectedGoal, ...array]);
    setGoalPublishData([]);
    addGoalNameRef.current.value = null;
  };

  const removeSelectedGoal = (index) => {
    selectedGoal.splice(index, 1);
    setSelectedGoal([...selectedGoal]);
  };

  useEffect(() => {
    if (goalId) getGoalDetail();
    getCourseList();
    getFieldOperators();
    getGoalField();
  }, []);

  console.log("courseCriteria", courseCriteria);
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          {loading ? (
            <CustomLoader />
          ) : (
            <div style={{ padding: "15px" }}>
              <div className="flex" style={{ justifyContent: "space-between" }}>
                <p style={{ fontSize: "24px" }} className="no-margin-bottom">
                  Create New Goal
                </p>
                <div>
                  <button
                    className="create-maintain-group-button"
                    onClick={() => setAddNewCourseModal(true)}
                  >
                    Add New Required Course Set
                  </button>
                  <button
                    style={{ margin: "0 5px" }}
                    onClick={() => setAddNewGoalModal(true)}
                    className="create-maintain-group-button"
                  >
                    Add New Goal Field
                  </button>
                  <button
                    onClick={() => history.push("/admin/ccplus")}
                    className="create-maintain-group-button"
                  >
                    Back
                  </button>
                </div>
              </div>
              <div className="requiredname-input-container">
                <div style={{ textAlign: "right" }}>
                  <p className="no-margin-bottom requirement-text">Goal Name</p>
                </div>
                <div style={{ position: "relative" }}>
                  <input
                    value={goalName}
                    onChange={(e) => setGoalName(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                    type="text"
                    placeholder="Type a goal name"
                    className="require-input-name"
                  />
                  <FontAwesomeIcon
                    style={{ position: "absolute", top: "10px", right: "10px" }}
                    icon={faAsterisk}
                    color="red"
                  />
                </div>
              </div>

              <div className="requiredname-input-container">
                <div style={{ textAlign: "right" }}>
                  <p className="no-margin-bottom requirement-text">
                    Goal Description
                  </p>
                </div>
                <div style={{ position: "relative" }}>
                  <textarea
                    value={goalDescription}
                    onChange={(e) => setGoalDescription(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                    type="text"
                    className="require-input-name"
                    placeholder="Type a goal description"
                    rows="5"
                  />
                  <FontAwesomeIcon
                    style={{ position: "absolute", top: "10px", right: "10px" }}
                    icon={faAsterisk}
                    color="red"
                  />
                </div>
                <div
                  style={{ marginTop: "-15px" }}
                  className="requiredname-input-container"
                >
                  <div />
                  <div>
                    <input
                      ref={addGoalNameRef}
                      onChange={handleGoalNameChange}
                      style={{
                        width: "100%",
                        padding: "5px",
                        borderRadius: "5px",
                        marginBottom: "10px",
                      }}
                      type="text"
                      className="require-input-name"
                      placeholder="Type a goal name to add"
                    />
                    {goalpublishData.length > 0 && (
                      <ul className="goal-dropdown-list">
                        {goalpublishData?.map((item, index) => (
                          <li
                            className="goal-dropdown-item"
                            onClick={() => handleSelectedGoal(item)}
                          >
                            {item?.goal_name}
                          </li>
                        ))}
                      </ul>
                    )}
                    {selectedGoal?.length > 0 &&
                      selectedGoal?.map((item, index) => (
                        <div
                          style={{
                            background: "#090",
                            color: "white",
                            padding: "5px",
                            margin: "5px",
                            borderRadius: "3px",
                            width: "fit-content",
                          }}
                        >
                          {item?.goal_name}
                          <FontAwesomeIcon
                            onClick={() => removeSelectedGoal(index)}
                            icon={faTimes}
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="create-a-new-set">
                <div
                  style={{
                    border: "1px solid #ccc",
                    borderBottom: "none",
                    padding: "6px",
                  }}
                >
                  Create a set of criteria for green
                </div>
                <div style={{ borderBottom: "1px solid #ccc" }} />
              </div>
              {courseCriteria?.length > 0 &&
                courseCriteria?.map((item, index) => (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        margin: "10px 10px",
                        alignItems: "center",
                        fontSize: "16px",
                      }}
                    >
                      <button
                        // disabled={goalData?.is_global}
                        onClick={() => removeCourseCriteria(index)}
                        style={{
                          background: "#d9534f",
                          // opacity: goalData?.is_global ? "0.65" : "1",
                          color: "white",
                          padding: "5px 12px",
                          marginLeft: item.level > 0 ? "60px" : "0",
                          borderRadius: "5px",
                          border: "none",
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                      &nbsp;
                      <p
                        style={{ margin: "5px 15px" }}
                        className="no-margin-bottom"
                      >
                        Student must
                      </p>
                      &nbsp;
                      <select
                        value={item?.have_not}
                        // disabled={goalData?.is_global}
                        // ref={haveOrHaveNotRef}
                        onChange={(e) => handlehaveOrHaveNotChange(index, e)}
                      >
                        <option value="" selected disabled>
                          --Select option---
                        </option>
                        <option value="True">have</option>
                        <option value="False">have not </option>
                      </select>
                      <select
                        ref={fieldOptionRef}
                        value={item?.field_name}
                        onChange={(e) => handleFieldOption(index, e)}
                        // disabled={goalData?.is_global}
                        style={{ width: "220px" }}
                      >
                        <option disabled selected value="">
                          --Select--
                        </option>

                        {goalFieldOption?.map((item, index) => (
                          <option value={item?.id}>{item?.field_name}</option>
                        ))}
                      </select>
                      {console.log("561: ", item)}
                      {/*  {item.field_name == 1 && ( */}
                      {!(
                        item.field_name === "AND" ||
                        item.field_name === "OR" ||
                        item.field_name === "course" ||
                        item.field_name === ""
                      ) && (
                        <div>
                          <select
                            value={item?.operator}
                            // disabled={goalData?.is_global}
                            ref={arithemeticOperatorRef}
                            onChange={() =>
                              handleArithemeticOperatorChange(index)
                            }
                          >
                            <option disabled selected value="">
                              --Select Operator--
                            </option>
                            {fieldOperators?.map((item, index) => (
                              <option value={parseInt(item?.id)}>
                                {item?.operator}
                              </option>
                            ))}
                          </select>
                          <input
                            type="text"
                            ref={gpaRef}
                            value={item?.value}
                            // disabled={goalData?.is_global}
                            onChange={() => handleGPAChange(index)}
                          />
                        </div>
                      )}
                      {item.field_name === "course" && (
                        <select
                          ref={courseRef}
                          value={item?.course_req}
                          // disabled={goalData?.is_global}
                          // style={{ width: "380px" }}
                          onChange={() => handleCourseChange(index)}
                        >
                          <option>--Select Course--</option>
                          {courseListData?.map((item, index) => (
                            <option value={item?.course?.id}>
                              {item?.course?.name}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                    {(item.field_name === "AND" ||
                      item.field_name === "OR") && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "5px 10px 0 70px",
                        }}
                      >
                        <button
                          style={{
                            background: "#057ebb",
                            padding: "5px 10px",
                            color: "white",
                            marginRight: "10px",
                            borderRadius: "5px",
                            border: "none",
                          }}
                          onClick={() => AddNewCriteria(true)}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{ margin: "0px 5px" }}
                          />
                        </button>
                        <p className="no-margin-bottom">
                          <b>Add additional sub-criteria</b>
                        </p>
                      </div>
                    )}
                  </div>
                ))}

              <div style={{ marginTop: "20px" }}>
                <button
                  style={{ marginLeft: "20px" }}
                  // disabled={goalData?.is_global}
                  className="disabled-modal-button"
                  // {
                  //   goalData?.is_global
                  //     ? "disabled-modal-button"
                  //     : "create-maintain-group-button"
                  // }
                  onClick={() => AddNewCriteria(false)}
                >
                  <FontAwesomeIcon icon={faPlus} style={{ margin: "0 5px" }} />
                  Add additional Criteria
                </button>
              </div>
              <div style={{ marginTop: "50px" }}>
                <button
                  disabled={!(goalName && goalDescription)}
                  className="save-modal-button"
                  // {
                  //   goalName && goalDescription
                  //     ? "save-modal-button"
                  //     : "disabled-modal-button"
                  // }
                  onClick={saveGoalMap}
                >
                  <FontAwesomeIcon icon={faSave} style={{ margin: "0 3px" }} />
                  Save
                </button>

                <button
                  className="cancel-modal-button2"
                  onClick={() => history.push("/admin/ccplus")}
                >
                  <FontAwesomeIcon icon={faTimes} style={{ margin: "0 3px" }} />
                  Cancel
                </button>
              </div>

              {addNewCourseModal && (
                <AddNewModal
                  isOpen={addNewCourseModal}
                  closeHandler={() => {
                    setAddNewCourseModal(false);
                  }}
                  data={{
                    title: "Create Required Course Set",
                    save: saveNewCourse,
                  }}
                />
              )}

              {addNewGoalModal && (
                <AddNewModal
                  isOpen={addNewGoalModal}
                  closeHandler={() => {
                    setAddNewGoalModal(false);
                  }}
                  data={{ title: "New Goal Field", save: saveNewGoalField }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default CreateGoal;
