import React, { Fragment, useState, useEffect, useRef } from "react";
import CustomLoader from "../../customLoader/CustomLoader";
import GetAdminService from "../../../services/admin/GetAdminService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import CreateCounselor from "./CreateCounselor";
import CustomPagination from "../../pagination/Pagination";

const Counselors = () => {
  const [schoolName, setSchoolName] = useState();
  const [showSchoolNameContainer, setShowSchoolNameContainer] = useState(false);
  const [schoolNameList, setSchoolNameList] = useState();
  const schoolNameRef = useRef();
  const timeoutRef = useRef();
  const [schoolNameLoading, setSchoolNameLoading] = useState(false);
  const [schoolId, setSchoolId] = useState();
  const [pageData, setPageData] = useState();
  const [schoolAdminList, setSchoolAdminList] = useState([]);
  const [counselorList, setCounselorList] = useState([]);
  const [openCreateCounselor, setOpenCreateCounselor] = useState(false);
  const [openModifyCounselor, setOpenModifyCounselor] = useState(false);
  const [modifyData, setModifyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const getSchoolsList = (value) => {
    setSchoolNameLoading(true);
    setShowSchoolNameContainer(true);
    setSchoolNameList([]);
    GetAdminService.getSchoolsList(value)
      .then((data) => {
        console.log("data from the service", data);
        setSchoolNameList(data?.data?.school);
        setSchoolNameLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setSchoolNameLoading(false);
      });
  };

  const getPageData = (id, p) => {
    setLoading(true);
    let obj = {
      school: id,
      page: 1,
      role: "counselor",
    };
    if (p) obj.page = p;
    GetAdminService.postAdminPage(obj)
      .then((data) => {
        console.log("data from the service", data);
        setLoading(false);
        setPageData(data?.data);
        setCounselorList(
          data?.data?.result.filter((item) => item.is_super_counselor === false)
        );
        setSchoolAdminList(
          data?.data?.result.filter((item) => item.is_super_counselor === true)
        );
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  const handleSchoolName = () => {
    clearTimeout(timeoutRef.current);
    setSchoolName(schoolNameRef.current.value);
    if (!schoolNameRef.current.value) {
      setShowSchoolNameContainer(false);
      setSchoolNameList([]);
      setCounselorList([]);
      setSchoolAdminList([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getSchoolsList(schoolNameRef.current.value);
      }, 1000);
    }
  };

  const handleSelectedSchoolName = (item) => {
    setSchoolNameList([item]);
    setShowSchoolNameContainer(false);
    setSchoolName(item?.name);
    setSchoolId(item?.id);
    getPageData(item?.id);
  };

  const modifyCounselor = (item) => {
    console.log(item);
    setLoading(true);
    GetAdminService.getModifyCounselor(item?.id)
      .then((data) => {
        setModifyData(data?.data?.objects);
        setOpenModifyCounselor(true);
        setLoading(false);
        console.log("data from the service", data);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error from the service", error);
      });
  };

  useEffect(() => {
    if (schoolId) {
      getPageData(schoolId, page);
    }
  }, [page]);

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="csv-import-container">
            <div className="flex canned-message-heading">
              <p style={{ fontSize: "25px", fontWeight: "400" }}>Counselors</p>
              {schoolId && (
                <button
                  className="create-message-button"
                  onClick={() => setOpenCreateCounselor(true)}
                >
                  <FontAwesomeIcon
                    style={{ marginRight: "5px" }}
                    icon={faUser}
                  />
                  New Counselor
                </button>
              )}
            </div>

            <div className="school-name-container">
              <p>Select School</p>
              <div>
                <input
                  ref={schoolNameRef}
                  className="search-admin-school-input"
                  type="text"
                  placeholder="School Name"
                  value={schoolName}
                  onChange={handleSchoolName}
                  autoComplete="off"
                />
                {showSchoolNameContainer && (
                  <div>
                    <ul className="admin-school-name-list">
                      {schoolNameLoading && <CustomLoader />}
                      {schoolNameList?.length > 0 &&
                        schoolNameList.map((item, index) => (
                          <li
                            onClick={() => handleSelectedSchoolName(item)}
                            key={index}
                            className="flex maintain-group-student-list cursor"
                          >
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {loading ? (
              <CustomLoader />
            ) : (
              pageData && (
                <div>
                  <div className="admin-student-list-container">
                    <p
                      style={{
                        padding: "15px",
                        margin: "0",
                        fontSize: "18px",
                      }}
                    >
                      Counselors
                    </p>
                    {counselorList.map((item, index) => (
                      <div
                        className="student-list"
                        onClick={() => modifyCounselor(item)}
                      >
                        {item?.email}
                      </div>
                    ))}
                  </div>

                  <div className="admin-student-list-container">
                    <p
                      style={{
                        padding: "15px",
                        margin: "20px 0 0 0",
                        fontSize: "18px",
                      }}
                    >
                      School Admin
                    </p>
                    {schoolAdminList.map((item, index) => (
                      <div
                        className="student-list"
                        onClick={() => modifyCounselor(item)}
                      >
                        {item?.email}
                      </div>
                    ))}
                  </div>

                  {openModifyCounselor && (
                    <CreateCounselor
                      setOpenCreateCounselor={setOpenModifyCounselor}
                      modify={modifyData}
                      school_id={schoolId}
                      getPageData={getPageData}
                    />
                  )}
                </div>
              )
            )}

            {pageData && pageData?.page_list?.length > 1 && (
              <div style={{ margin: "20px" }}>
                <CustomPagination
                  count={pageData && pageData}
                  pageValue={page}
                  setPage={setPage}
                />
              </div>
            )}
            
            {openCreateCounselor && (
              <CreateCounselor
                setOpenCreateCounselor={setOpenCreateCounselor}
                modify={false}
                school_id={schoolId}
                getPageData={getPageData}
                school_name={schoolNameList[0]?.name}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Counselors;
