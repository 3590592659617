import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  faTimes,
  faAsterisk,
  faSave,
  faTrash,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import GetAdminService from "../../../services/admin/GetAdminService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomLoader from "../../customLoader/CustomLoader";
import swal from "sweetalert";
import { Modal, ModalBody } from "reactstrap";

const MessageModal = ({ isOpen, setIsOpen, data }) => {
  return (
    <Fragment>
      <Modal isOpen={isOpen} centered={true}>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            <p>{data.message}</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={data.function1}
                style={{
                  backgroundColor: "#009900",
                  color: "white",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginRight: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon
                  style={{ margin: "0 5px" }}
                  color="white"
                  icon={faCheck}
                />
                Ok
              </button>
              <button
                onClick={() => setIsOpen(false)}
                style={{
                  backgroundColor: "#efefef",
                  color: "black",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginLeft: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon style={{ margin: "0 5px" }} icon={faTimes} />
                Cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const CreateRecruiter = ({
  setOpenRecruiter,
  modify,
  college_id,
  recruiterCall,
  //   getPageData,
  //   school_name,
}) => {
  const timeoutRef = useRef();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [recoveryEmail, setRecoveryEmail] = useState();
  const [mobile, setMobile] = useState();
  const [promote, setPromote] = useState(false);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showCounselorContainer, setShowCounselorContainer] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [schoolName, setSchoolName] = useState();
  const [showSchoolNameContainer, setShowSchoolNameContainer] = useState(false);
  const [schoolNameList, setSchoolNameList] = useState();
  const schoolNameRef = useRef();
  const [schoolNameLoading, setSchoolNameLoading] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState([]);

  // const getSchoolsList = (value) => {
  //   setSchoolNameLoading(true);
  //   setShowSchoolNameContainer(true);
  //   GetAdminService.getSchoolsList(value)
  //     .then((data) => {
  //       console.log("data from the service", data);
  //       setSchoolNameList(data?.data?.objects);
  //       setSchoolNameLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log("error from the service", error);
  //       setSchoolNameLoading(false);
  //     });
  // };

  //   console.log(modify, school_id);

  const modifyData = () => {
    console.log(modify);
    setFirstName(modify?.first_name || "");
    setLastName(modify?.last_name || "");
    setEmail(modify?.email || "");
    setRecoveryEmail(modify?.recovery_email || "");
    setMobile(modify?.mobile || "");
    setPromote(modify?.is_college_admin)
  };

  const setNull = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setMobile("");
    setPassword("");
    setConfirmPassword("");
  };

  const passwordValidation = (value) => {
    if (!modify && password?.length < 8) {
      setShowPasswordError("Password must be greater than 8 characters");
    } else if (password?.length >= 8) {
      setShowPasswordError(false);
    }
  };

  const confirmPasswordValidation = (value) => {
    if (value !== password)
      setShowPasswordError("Password and Confirm Password should be same.");
    else setShowPasswordError(false);
  };

  const saveButtonHandler = () => {
    let obj = {
      college: college_id,
      first_name: firstName,
      last_name: lastName,
      email: email,
      recovery_email: recoveryEmail,
      mobile: mobile,
      type: "recruiter",
      is_college_admin: promote,
    };
    obj.password = password;
    obj.password_confirm = confirmPassword;

    let obj1 = {
      user_id: modify?.id,
      old_email: modify?.email,
      old_firstname: modify?.first_name,
      old_lastname: modify?.last_name,
      first_name: firstName,
      last_name: lastName,
      email: email,
      recovery_email: recoveryEmail,
      mobile: mobile,
      type: "recruiter",
      is_college_admin: promote,
    }
    console.log(obj);
    if (modify) {
      GetAdminService.updateRecruiter(obj1, modify?.id)
        .then((data) => {
          console.log("data from the service", data);
          recruiterCall(college_id);
          setNull();
          //   getPageData(school_id);
          setOpenRecruiter(false);
        })
        .catch((error) => {
          console.log("error from the service", error, error?.response);
          swal({
            text: error?.response?.data?.message,
            icon: "warning",
            dangerMode: true,
            buttons: {
              cancel: true,
              confirm: true,
            },
            closeOnClickOutside: true,
          }).then((value) => {
            console.log("value", value);
          });
        });
    } else {
      GetAdminService.saveRecruiter(obj)
        .then((data) => {
          console.log("data from the service", data);
          recruiterCall(college_id);
          setNull();
          //   getPageData(school_id);
          setOpenRecruiter(false);
        })
        .catch((error) => {
          console.log("error from the service", error, error?.response);
          swal({
            text: error?.response?.data?.message,
            icon: "warning",
            dangerMode: true,
            buttons: {
              cancel: true,
              confirm: true,
            },
            closeOnClickOutside: true,
          }).then((value) => {
            console.log("value", value);
          });
        });
    }
  };

  const deleteCounslor = () => {
    // let obj = {
    //   college_id:modify?.id
    // }
    GetAdminService.deleteRecruiter(modify?.id)
      .then((data) => {
        recruiterCall(college_id);
        console.log("student from the service", data);
        swal({
          text: "Recruiter deleted successfully!",
          icon: "success",
          buttons: {
            confirm: true,
          },
          closeOnClickOutside: false,
        });
        setNull();
        // getPageData(school_id);
        setOpenRecruiter(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    if (modify) {
      modifyData();
    }
  }, [modify]);

  return (
    <Fragment>
      <div className="create-student-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontSize: "18px" }}>
            {modify ? "Modify Recruiter" : "New Recruiter"}
          </p>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ cursor: "pointer", fontSize: "18px" }}
            onClick={() => {
              setOpenRecruiter(false);
              setNull();
            }}
          />
        </div>
        <div style={{ marginTop: "5px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "400",
              borderBottom: "1px solid #e5e5e5",
            }}
          >
            Personal information
          </p>
          <div style={{ padding: "0 30px" }}>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                First Name
              </p>
              <input
                type="text"
                style={{ width: "75%" }}
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <FontAwesomeIcon
                style={{ fontSize: "12px", position: "absolute", right: "5px" }}
                icon={faAsterisk}
                color="red"
              />
            </div>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Last Name
              </p>
              <input
                type="text"
                style={{ width: "75%" }}
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <FontAwesomeIcon
                style={{ fontSize: "12px", position: "absolute", right: "5px" }}
                icon={faAsterisk}
                color="red"
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "400",
              borderBottom: "1px solid #e5e5e5",
            }}
          >
            Contact
          </p>
          <div style={{ padding: "0 30px" }}>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Email
              </p>
              <input
                type="email"
                style={{ width: "75%" }}
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
              />
              <FontAwesomeIcon
                style={{ fontSize: "12px", position: "absolute", right: "5px" }}
                icon={faAsterisk}
                color="red"
              />
            </div>

            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Recovery Email
              </p>
              <div style={{ width: "75%" }}>
                <input
                  type="email"
                  style={{ width: "100%" }}
                  placeholder="Recovery Email"
                  value={recoveryEmail}
                  onChange={(e) => setRecoveryEmail(e.target.value.toLowerCase())}
                />
                <p className="help-block">
                  Used in case account needs to be recovered
                </p>
              </div>
            </div>

            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Mobile
              </p>
              <div style={{ width: "75%" }}>
                <input
                  type="text"
                  style={{ width: "100%" }}
                  placeholder="+19999999999"
                  onChange={(e) => setMobile(e.target.value)}
                  value={mobile}
                />
                <p className="help-block">Hint: +13238511115</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style={{ marginTop: "10px" }}>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                borderBottom: "1px solid #e5e5e5",
              }}
            >
              User information
            </p>
          </div>
          <div style={{ padding: "0 30px" }}>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Password
              </p>
              <input
                type="password"
                style={{ width: "75%" }}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={() => passwordValidation(password)}
              />
              <FontAwesomeIcon
                style={{ fontSize: "12px", position: "absolute", right: "5px" }}
                icon={faAsterisk}
                color="red"
              />
            </div>
            <div
              className="flex"
              style={{
                marginTop: "15px",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <p
                style={{ width: "20%", fontSize: "14px" }}
                className="bold no-margin-bottom"
              >
                Confirm Password
              </p>
              <input
                type="password"
                style={{ width: "75%" }}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onBlur={() => confirmPasswordValidation(confirmPassword)}
              />
              <FontAwesomeIcon
                style={{ fontSize: "12px", position: "absolute", right: "5px" }}
                icon={faAsterisk}
                color="red"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "15px 20px",
            }}
          >
            <input
              type="checkbox"
              style={{ marginRight: "5px" }}
              value={promote}
              onChange={() => setPromote(!promote)}
              checked={promote}
            />
            <span
              style={{ fontSize: "14px", color: "#040C22", fontWeight: "bold" }}
            >
              Enable recruiter as College Admin
            </span>
          </div>
          {showPasswordError && (
            <p style={{ color: "#a94442", margin: "10px 20px" }}>
              {showPasswordError}
            </p>
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            gap: "20px",
            margin: "20px 20px 30px 0",
          }}
        >
          {modify && (
            <button
              className="cancel-modal-button"
              onClick={() => setOpenDeleteModal(true)}
            >
              <FontAwesomeIcon icon={faTrash} />
              &nbsp;Delete
            </button>
          )}
             {!modify ?
          <button
            onClick={saveButtonHandler}
            disabled={
              !(
                email &&
                firstName &&
                lastName &&
                password &&
                confirmPassword &&
                !showPasswordError
              )
            }
            className={
              !(
                firstName &&
                email &&
                lastName &&
                password &&
                confirmPassword &&
                !showPasswordError
              )
                ? "disabled-modal-button"
                : "save-modal-button"
            }
          >
            <FontAwesomeIcon
              icon={faSave}
              style={{
                marginRight: "5px",
                marginTop: "3px",
              }}
            />
            Save
          </button> : <button
            onClick={saveButtonHandler}
            className="save-modal-button"
          >
            <FontAwesomeIcon
              icon={faSave}
              style={{
                marginRight: "5px",
                marginTop: "3px",
              }}
            />
            Save
          </button> }
        </div>
      </div>
      {openDeleteModal && (
        <MessageModal
          setIsOpen={setOpenDeleteModal}
          isOpen={openDeleteModal}
          data={{
            function1: deleteCounslor,
            message: "Are you sure you want to delete?",
          }}
        />
      )}
    </Fragment>
  );
};
export default CreateRecruiter;
