import React, { useState, useEffect, Fragment } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';
import QueryService from '../../services/QueryService';
import { useHistory } from 'react-router';

const DuplicateModal = ({ openDuplicateModal, 
                        setOpenDuplicateModal, 
                        duplicateQuery,
                        setOpenErrorModal,
                        getSchoolQueryPage,
                        inputValue,
                        setInputValue,
                        openErrorModal,
                        setOpenConfirmationModal,
                        openConfirmationModal
                    }) => {
    let history = useHistory();
    let authuser = localStorage.getItem('user');
    let parsedUser =  JSON.parse(authuser);
  
    const [user , setUser] = useState(parsedUser);
    
    const saveForCollege=()=>{
        let obj={
            college_id: user && user.college_id,
            creator_id: user && user.id,
            id: duplicateQuery,
            is_global: false,
            name: inputValue,
            query: [],
            type: "recruiter.simple_query",
        }
        QueryService.saveQueryForCollege(obj)
        .then(data => {
            console.log("data from the saveQueryFromCollege", data)
            setOpenDuplicateModal(false)
            getSchoolQueryPage();
        })
        .catch(error => {
            console.log('error from the service', error.response)
            if(error.response && error.response.status === 409){
               setOpenDuplicateModal(false)
               setOpenErrorModal(true)
            }
        })
    }
    const saveForSiembra=()=>{
       if(inputValue && inputValue.trim()){
            setOpenDuplicateModal(false);
            setOpenConfirmationModal(true)
       }
    }
    
    return (
        <Fragment>
            <Modal
                className="save-modal"
                wrapClassName="modal-dialog"
                contentClassName="modal-dialog"
                isOpen={openDuplicateModal}
                toggle={() => setOpenDuplicateModal(false)}
            >
                <ModalHeader className="modal-header">
                    <div style={{ justifyContent: 'space-between' }} className="flex">
                        <div style={{ fontSize: '16px', fontWeight: 'bold', wordBreak: 'break-all' }} className="no-margin-bottom color">
                            <p className="no-margin-bottom">Update Query</p>
                        </div>
                        <div onClick={() => setOpenDuplicateModal(false)} className="cursor">
                            <CloseIcon />
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div style={{ padding: '30px' }}>
                        <p style={{ fontSize: '16px', fontWeight: 'bold', wordBreak: 'break-word' }} className="no-margin-bottom color">
                            Please name your query
                        </p>
                        <input className="save-modal-input" type="text" onChange={(e) => setInputValue(e.target.value)} placeholder="Query Name" value={inputValue} />

                    </div>
                    <div className="flex col-md-12" style={{ justifyContent: 'space-evenly', padding: '0 0px 30px' }}>
                        <button
                            style={{ width:"150px"  }}
                            className={"modal-button-active"}
                            onClick={saveForCollege}
                        >
                             Save for College
                        </button>
                        <button
                            style={{ width: '150px' }}
                            className="modal-button-inactive"
                            onClick={saveForSiembra}
                        >
                            Save for Siembra
                        </button>
                        <button style={{ width:"150px" }} className={"modal-button-inactive"} onClick={() => setOpenDuplicateModal(false)}>
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}
export default DuplicateModal