import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  faCheck,
  faEnvelope,
  faEye,
  faQuestion,
  faUsers,
  faTrash,
  faReply,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import CreateMessage from "../StudentMessage/CreateMessage";
import CannedModal from "../StudentMessage/CannedModal";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import CounselorMsgService from "../../../services/Counselor/CounselorMsgService";

const GroupMessage = () => {
  let location = useLocation();
  let history = useHistory();
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [page, setPage] = useState(1);
  const [groupMessageDetails, setGroupMessageDetails] = useState([]);
  const [selectedGroupMessageValue, setSelectedGroupMessageValue] = useState();
  const [showSingleMessage, setShowSingleMessage] = useState(false);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(-1);
  const [openCreateGroupMessage, setOpenCreateGroupMessage] = useState(
    location?.state?.openCreateGroupMessage
      ? location?.state?.openCreateGroupMessage
      : false
  );
  const [message, setMessage] = useState();
  const [openCannedModal, setOpenedCannedModal] = useState(false);
  const [
    messageValueForCreateMessage,
    setMessageValueForCreateMessage,
  ] = useState();

  const getGroupMessageDetails = () => {
    let obj = {
      counselor_id: user?.counselor_id,
      page: page,
      school_id: user?.school_id,
    };
    GetStudentService.getMessageForGroupInIT(obj)
      .then((data) => {
        console.log("data from the service", data);
        setGroupMessageDetails(data?.data?.result);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const deleteGroupMessage = (value) => {
    let obj = {
      id: value?.id,
      type: 'group',
      // type: "group_message",
    };
    console.log("value from the delete group", value);
    GetStudentService.deleteMessageForStudent(obj)
      .then((data) => {
        console.log("data from the delete service", data);
        getGroupMessageDetails();
        setShowSingleMessage(false);
        setSelectedGroupMessageValue();
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const handleGroupMessageSelect = (value, index) => {
    setSelectedGroupMessageValue(value);
    setShowSingleMessage(true);
    setSelectedGroupIndex(index);
  };

  const createGroupMessageHandler = () => {
    setOpenCreateGroupMessage(true);
  };

  const handleCannedMessageSelect = (value) => {
    console.log("value from the user", value);
    setMessageValueForCreateMessage(value?.messages?.en?.body);
    setOpenedCannedModal(false);
  };

  useEffect(() => {
    getGroupMessageDetails();
  }, []);

  console.log("selected Value", selectedGroupMessageValue);
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="student-message-container">
            <div className="flex" style={{ justifyContent: "space-between" }}>
              <p style={{ fontSize: "24px", marginBottom: "0px" }}>
                Group Messages
              </p>
              <div>
                <button
                  onClick={() => {
                    history.push({
                      pathname: "/school_it/maintain_group",
                      openGroupMessage: true,
                    });
                  }}
                  className="create-maintain-group-button"
                >
                  <span style={{ marginRight: "10px" }}>
                    <FontAwesomeIcon icon={faUsers} />
                  </span>
                  Create Group
                </button>
                <button
                  style={{ marginLeft: "15px" }}
                  className="create-maintain-group-button"
                  onClick={createGroupMessageHandler}
                >
                  <span style={{ marginRight: "10px" }}>
                    <FontAwesomeIcon icon={faUsers} />
                  </span>
                  Create Group Message
                </button>
              </div>
            </div>
            <div style={{ marginTop: "10px" }} className="school_group_message">
              <div>
                {groupMessageDetails &&
                  groupMessageDetails.map((item, index) => (
                    <div
                      className={
                        index === selectedGroupIndex && "message-active-class"
                      }
                      onClick={() => handleGroupMessageSelect(item, index)}
                      key={index}
                      style={{
                        border: "1px solid #ddd",
                        padding: "20px 10px 10px",
                        cursor: "pointer",
                        position:"relative"
                      }}
                    >
                       {item?.priority === "critical" && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "20px",
                            position: "absolute",
                            left: "-10px",
                            top: "40px",
                          }}
                        >
                          <FontAwesomeIcon icon={faExclamationCircle} />
                        </span>
                      )}
                      <div>
                        {/* {(item?.groups).map((i) => ( */}
                          <span
                            style={{
                              backgroundColor: "#009900",
                              color: "white",
                              padding: "4px 10px",
                              fontSize: "12px",
                              margin: "5px 10px",
                              borderRadius: "5px",
                            }}
                          >
                             {JSON.parse(item?.groups?.replace(/'/g, '"'))['1']?.name}
                          </span>
                        {/* ))} */}
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          marginRight: "30px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#777",
                            fontWeight: "400",
                            marginRight: "40px",
                          }}
                          className="no-margin-bottom"
                        >
                          {moment(item?.date_sent).format(
                            "ddd DD MMM YYYY, HH:mm A"
                          )}
                        </span>
                        <FontAwesomeIcon
                          onClick={() => deleteGroupMessage(item)}
                          icon={faTrash}
                        />
                      </div>
                      <p
                        className="no-margin-bottom"
                        style={{
                          fontSize: "14px",
                          fontWeight: "700",
                          padding: "5px 0 10px 10px",
                        }}
                      >
                        {item?.subject}
                      </p>
                      <div
                        className="flex"
                        style={{
                          width: "70%",
                          justifyContent: "space-between",
                          marginLeft: "30px",
                          color: "#919AA0",
                        }}
                      >
                        <span>
                          <FontAwesomeIcon icon={faEnvelope} />
                          &nbsp;
                          {item?.count_messages}
                        </span>
                        <span>
                          <FontAwesomeIcon icon={faEye} />
                          &nbsp;
                          {item?.count_opened}
                        </span>
                        <span>
                          <FontAwesomeIcon icon={faCheck} />
                          &nbsp;
                          {item?.count_ack}
                        </span>
                        <span>
                          <FontAwesomeIcon icon={faQuestion} />
                          &nbsp;
                          {item?.count_feedback}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
              <div>
                {showSingleMessage && selectedGroupMessageValue && (
                  <div
                    style={{ padding: "15px" }}
                    className="schoolit-group-message-details"
                  >
                    <div>
                      {/* {Object.values(selectedGroupMessageValue?.groups).map(
                        (i) => ( */}
                          <span
                            style={{
                              backgroundColor: "#009900",
                              color: "white",
                              padding: "4px 10px",
                              fontSize: "12px",
                              margin: "5px 20px 5px 0",
                              borderRadius: "5px",
                            }}
                          >
                           {JSON.parse(selectedGroupMessageValue?.groups?.replace(/'/g, '"'))['1']?.name}
                          </span>
                        {/* )
                      )} */}
                    </div>
                    <p
                      className="no-margin-bottom"
                      style={{
                        fontSize: "14px",
                        color: "#333",
                        fontWeight: "700",
                        margin: "10px 0px",
                      }}
                    >
                      {selectedGroupMessageValue?.subject}
                    </p>
                    <p
                      className="no-margin-bottom"
                      style={{
                        fontSize: "14px",
                        color: "#777",
                        fontWeight: "400",
                      }}
                    >
                      {selectedGroupMessageValue?.body}
                    </p>
                    <br/>
                    {selectedGroupMessageValue?.attachment_url !== null && (
                              <a target="_blank" href={selectedGroupMessageValue?.attachment_url}>
                              <button className="import-csv-button"> Download Attachment</button>
                              </a>
                            )}
                    <div className="message-open-container">
                      <div className="single-count-container">
                        <span
                          style={{ background: "#efefef", padding: "5px 10px" }}
                        >
                          <FontAwesomeIcon icon={faEnvelope} />
                        </span>

                        <span style={{ position: "absolute" }}>
                          &nbsp;
                          {`${selectedGroupMessageValue?.count_messages}  Sent`}
                        </span>
                      </div>
                      <div className="single-count-container">
                        <span
                          style={{ background: "#efefef", padding: "5px 10px" }}
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </span>

                        <span>
                          &nbsp;
                          {`${selectedGroupMessageValue?.count_opened} viewed`}
                        </span>
                      </div>
                      <div className="single-count-container">
                        <span
                          style={{ background: "#efefef", padding: "5px 10px" }}
                        >
                          <FontAwesomeIcon icon={faReply} />
                        </span>

                        <span
                        //style={{ position: "absolute" }}
                        >
                          &nbsp;
                          {`${
                            selectedGroupMessageValue?.count_maybe
                          } Didn't reply yet.`}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {openCreateGroupMessage && (
            <CreateMessage
              role="group"
              close={() => setOpenCreateGroupMessage(false)}
              openCannedModal={openCannedModal}
              setOpenedCannedModal={setOpenedCannedModal}
              message={messageValueForCreateMessage}
              setMessage={setMessageValueForCreateMessage}
              getMessageDetails={getGroupMessageDetails}
            />
          )}

          {openCannedModal && (
            <CannedModal
              open={openCannedModal}
              close={() => setOpenedCannedModal(false)}
              handleCannedMessageSelect={(value) =>
                handleCannedMessageSelect(value)
              }
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default GroupMessage;
