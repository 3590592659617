import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Form, FormGroup, Input, Label } from "reactstrap";
import swal from "sweetalert";
import { CommonButton } from "../../components";
import Loading from "../../components/Loading/Loading";
import { CommonMessages } from "../../constants/CommonMessages";
import RegisterService from "../../services/RegisterService";
import "./CreateParentAccount.scss";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const CreateParentAccount = () => {
  let history = useHistory();
  let initState = {
    schoolDistrict: "",
    schoolName: "",
    otherDistrctName: "",
    otherSchoolName: "",
    studentId: "",
    firstName: "",
    lastName: "",
    parentEmail: "",
    mobile: "",
    language: "en",
    pswd: "",
    confirmPswd: "",
  };
  const [loading, setLoading] = useState(false);
  const [districtList, setDistrictList] = useState([]);
  const [showDistContainer, setShowDistContainer] = useState(
    false
  );
  const [schoolList, setSchoolList] = useState([]);
  const [filterSchoolList, setFilterSchoolList] = useState([]);
  const [formData, setFormaData] = useState(initState);
  const [showPswd, setShowPswd] = useState(false);
  const [showconfirmPswd, setShowconfirmPswd] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState([]);
  const timeoutRef = useRef();
  const distRef = useRef();

  const getSchoolDataDistrictwise = (districtVal) => {
    console.log("log",districtVal);
 
    // setLoading(true);
    RegisterService.getDistrictbaseSchoolNameList(districtVal)
      .then((res) => {
        console.log("res", res);
        if (
          res?.status === 200 &&
          res?.data?.objects &&
          res?.data?.objects.length > 0
        ) {
          setSchoolList(res?.data?.objects);
          setFilterSchoolList(res?.data?.objects);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    const lowercasedValue = name === 'parentEmail' ? value.toLowerCase() : value;
    setFormaData({ ...formData, [name]: lowercasedValue });

    if (name === "schoolDistrict") {
      if (value === "Other") {
        getSchoolDataDistrictwise(null);
      } else {
        getSchoolDataDistrictwise(value);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let {
      schoolDistrict,
      schoolName,
      otherDistrctName,
      otherSchoolName,
      studentId,
      firstName,
      lastName,
      parentEmail,
      mobile,
      language,
      pswd,
      confirmPswd,
    } = formData;
    let emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (
      parentEmail &&
      parentEmail.length > 0 &&
      !parentEmail.match(emailRegEx)
    ) {
      swal("", CommonMessages.emailError, "error");
    } else if (pswd !== confirmPswd) {
      swal("", CommonMessages.passwordMatchError, "error");
    } else if (schoolDistrict === "Other" && schoolName === "Other") {
      // call api for other district and school, have no school id
      let payload = {
        first_name: firstName,
        last_name: lastName,
        email: parentEmail,
        mobile: mobile,
        school_name: otherSchoolName,
        distict_name: otherDistrctName,
        lang: language,
        password: pswd,
      };
      SaveProfile(payload);
    } else {
      let payload = {
        // district_id: schoolDistrict,
        school_id: schoolName === "Other" ? null : schoolName,
        student_id: studentId,
        first_name: firstName,
        last_name: lastName,
        email: parentEmail,
        mobile: mobile,
        // school_name: otherDistrctName,
        // distict_name: otherSchoolName,
        lang: language,
        password: pswd,
      };
      SaveProfile(payload);
    }
  };

  const SaveProfile = (payload) => {
    setLoading(true);
    RegisterService.createParentAccount(payload)
      .then((res) => {
        if (res?.status === 200) {
          swal({
            text: `Parent Registered Successfully.`,
            icon: "success",
            buttons: {
              OK: true,
            },
            closeOnClickOutside: false,
          }).then((value) => {
            if (value === "OK") {
              history.push("/login");
            }
          });
          setFormaData(initState);
        }
        else if (res?.status === 201) {
          swal({
            text: res?.data?.message,
            icon: "success",
            buttons: {
              OK: true,
            },
            closeOnClickOutside: false,
          }).then((value) => {
            if (value === "OK") {
              history.push("/login");
            }
          });
          setFormaData(initState);
        }
        setLoading(false);
      })
      .catch((error) => {
        swal("", error?.response?.data?.message, "error");
        setLoading(false);
      });
  };

  const handleDistChange = () => {
    let value=distRef.current.value;
    setFormaData({ ...formData, schoolDistrict: distRef.current.value });
    clearTimeout(timeoutRef.current);
    if (!distRef.current.value) {
      setShowDistContainer(false);
      setDistrictList([]);
    } else {
      timeoutRef.current = setTimeout(() => {
      
      if (value !== "" && value.length >= 3) {
        setLoading(false);
        let query = {
          q: value,
        };
        RegisterService.getDistrictList(query)
          .then((data) => {
            setDistrictList(data?.data?.objects);
            setShowDistContainer(true)
            let districtData = data?.data?.objects || [];

            if (districtData && districtData.length > 0) {
              let selectedDistrictData =
                districtData.filter(
                  (disData) =>
                    disData?.name.toLowerCase().trim() ===
                    value.toLowerCase().trim()
                ) || [];
              setSelectedDistrict(selectedDistrictData);
            }
            setLoading(false);
          })
          .catch((error) => {
            console.log("error from the service", error);
            setLoading(false);
          });
      } 
      }, 1000);
    }
  };
  return (
    <>
      {loading && <Loading />}
      <div className="parnetAccountWrap">
        <div className="parnetAccountInner text-center">
        <img src={IMG_URL+"white-logo.png"} alt='whiteLogo' className='logoImg img-fluid' />
          <div className="parnetAccountBlockwrap">
            <div className="parnetAccountBlockInner">
              <Form onSubmit={handleSubmit} autoComplete="off">
                <div className="boxWrap">
                  <div className="boxwrapInner">
                    <h2 className="accountTitle text-center">
                      Create your parent connect account below
                    </h2>

                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        School District <span className="requiredField">*</span>
                      </Label>
                      <input
                        required
                        placeholder="Enter your district name"
                        className="form-control custom-select"
                        type="text"
                        ref={distRef}
                        list="listOfDistrict"
                        name="schoolDistrict"
                        value={formData.schoolDistrict || ""}
                        onChange={handleDistChange}
                        autoComplete="off"
                      />
                       {showDistContainer && (
                  <div>
                    <ul className="admin-school-name-list">
                      {districtList && districtList.length > 0
                          ? districtList.map((item, index) => {
                              return (
                                <li
                            onClick={() =>{
                              setShowDistContainer(false);
                              
                              setFormaData({ ...formData, schoolDistrict: item.name });
                              {formData.schoolName === 'Other'? getSchoolDataDistrictwise(null)
                              :
                               getSchoolDataDistrictwise(item.id)}}}
                            key={index}
                            className="flex admin-school-list cursor"
                          >
                            <span>{item.name}</span>
                          </li>
                              );
                            })
                          : ""}
                    
                    </ul>
                  </div>
                )}
                      {/* <datalist id="listOfDistrict" className="col-12">
                        
                        {districtList && districtList.length > 0
                          ? districtList.map((item, index) => {
                              return (
                                <option value={item.name} key={index}>
                                </option>
                              );
                            })
                          : ""}
                      </datalist> */}
                    </FormGroup>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        School Name <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        type="select"
                        name="schoolName"
                        value={formData.schoolName || ""}
                        onChange={handleChange}
                      >
                        <option value={''}>Select School</option>
                        {filterSchoolList &&
                          filterSchoolList.length > 0 &&
                          filterSchoolList.map((item, index) => {
                            return (
                              <option key={index} value={item?.id}>
                                {item?.name}
                              </option>
                            );
                          })}
                        <option value={'Other'}>Other</option>
                      </Input>
                    </FormGroup>
                    {formData.schoolDistrict === "Other" ? (
                      <>
                        <FormGroup className="text-left">
                          <Label className="customLabel">
                            Enter Your District Name{" "}
                            <span className="requiredField">*</span>
                          </Label>
                          <Input
                            required
                            placeholder="Enter other District Name"
                            type="text"
                            name="otherDistrctName"
                            value={formData.otherDistrctName || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label className="customLabel">
                            Enter Your School Name{" "}
                            <span className="requiredField">*</span>
                          </Label>
                          <Input
                            required
                            placeholder="Enter other School Name"
                            type="text"
                            name="otherSchoolName"
                            value={formData.otherSchoolName || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </>
                    ) : (
                      ""
                    )}
                    {formData.schoolDistrict === "Other" ? (
                      ""
                    ) : (
                      <FormGroup className="text-left">
                        <Label className="customLabel">
                          Student ID <span className="requiredField">*</span>
                        </Label>
                        <Input
                          required
                          placeholder="Enter Student ID"
                          type="text"
                          name="studentId"
                          value={formData.studentId || ""}
                          onChange={handleChange}
                          // onKeyPress={(e) => {
                          //     if (!/[0-9]/.test(e.key)) {
                          //         e.preventDefault();
                          //     }
                          // }}
                        />
                      </FormGroup>
                    )}
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        Parent / Guardian First Name{" "}
                        <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        placeholder="Enter First Name"
                        type="text"
                        name="firstName"
                        value={formData.firstName || ""}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        Parent / Guardian Last Name{" "}
                        <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        placeholder="Enter Last Name"
                        type="text"
                        name="lastName"
                        value={formData.lastName || ""}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        Parent / Guardian Email Address{" "}
                        <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        // pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                        placeholder="Enter email address"
                        type="email"
                        name="parentEmail"
                        value={formData.parentEmail || ""}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        Parent / Guardian Mobile Number{" "}
                        <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        placeholder="Enter Mobile Number"
                        type="text"
                        name="mobile"
                        value={formData.mobile || ""}
                        onChange={handleChange}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        Language Preference{" "}
                      </Label>
                      <Input
                        required
                        type="select"
                        name="language"
                        value={formData.language || ""}
                        onChange={handleChange}
                      >
                        <option value="en">en</option>
                        <option value="es">es</option>
                      </Input>
                    </FormGroup>

                    <FormGroup className="text-left position-relative">
                      <Label className="customLabel">
                        Password <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required={
                          formData.confirmPswd.length > 0 ? true : false
                        }
                        placeholder="Enter your password"
                        type={showPswd ? "text" : "password"}
                        name="pswd"
                        minLength="8"
                        value={formData.pswd || ""}
                        onChange={handleChange}
                      />
                      <FontAwesomeIcon
                        icon={showPswd ? faEye : faEyeSlash}
                        onClick={() => setShowPswd(!showPswd)}
                        style={{
                          position: "absolute",
                          right: "13px",
                          top: "41px",
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="text-left position-relative">
                      <Label className="customLabel">
                        Confirm Password{" "}
                        <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required={formData.pswd.length > 0 ? true : false}
                        className="form-control"
                        placeholder="Re-enter your confirm password"
                        type={showconfirmPswd ? "text" : "password"}
                        name="confirmPswd"
                        minLength="8"
                        value={formData.confirmPswd || ""}
                        onChange={handleChange}
                      />
                      <FontAwesomeIcon
                        icon={showconfirmPswd ? faEye : faEyeSlash}
                        onClick={() => setShowconfirmPswd(!showconfirmPswd)}
                        style={{
                          position: "absolute",
                          right: "13px",
                          top: "41px",
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className="btnWrap">
                    <div className="btnWrapper">
                      <div className="btnInner">
                        <CommonButton
                          name="Cancel"
                          btnClass="secondaryBtn w-100 stdFormBtn"
                          onClick={() => history.goBack()}
                        />
                      </div>
                      <div className="btnInner">
                        <CommonButton
                          type="submit"
                          name="Create your account"
                          btnClass="primaryBtn w-100 stdFormBtn"
                          //   onClick={}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateParentAccount;