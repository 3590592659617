const initialState = {
  status: {},
  selectedGoal: "",
  color: "",
};
export const locationState = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_LOCATION":
      return { ...state, ...action.data };

    default:
      return state;
  }
};

export const ChangeLocation = (data) => {
  return {
    type: "CHANGE_LOCATION",
    data,
  };
};
