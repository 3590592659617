import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  faAsterisk,
  faPlus,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GetStudentService from "../../../../services/SchoolIT/GetStudentService";
import CustomLoader from "../../../customLoader/CustomLoader";
import AppSidebar from "../../../../Layout/AppSidebar";
import AppHeader from "../../../../Layout/AppHeader";
const API_URL = process.env.REACT_APP_API_URL;
const CreateDataSource = () => {
  let history = useHistory();
  let location = useLocation();
  const [selectedDataSourceId, setSelectedDataSourceId] = useState(
    location?.pathname?.split("/").pop()
  );
  const fileRef = useRef();
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [dataSourceName, setDataSourceName] = useState();
  const [dataSourceDescription, setDataSourceDescription] = useState();
  const [columnName, setColumnName] = useState();
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedDataSource, setSelectedDataSource] = useState();
  const [uploadFileError, setUploadFileError] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [nameInvalidError, setNameInvalidError] = useState(false);
  const [uploadedData, setUploadedData] = useState("");
  const [loading, setLoading] = useState(false);

  const fileHandler = () => {
    setUploadFileError("");
    setSelectedFile(fileRef.current.files[0]);
  };

  const getSelectedDataSource = () => {
    setLoading(true);
    GetStudentService.selectedDataSource(selectedDataSourceId)
      .then((data) => {
        console.log(
          "data from the selected Data Source",
          data,
          "  ",
          selectedDataSourceId
        );
        setSelectedDataSource(data?.data);
        setDataSourceName(data?.data?.name);
        setDataSourceDescription(data?.data?.description);
        setColumnName(data?.data?.student_id_column);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  const uploadDataSource = () => {
    let formData = new FormData();
    // formData.append("csvfilename", selectedFile.name);
    formData.append("datasource_id", selectedDataSourceId);
    formData.append("student_id_column", columnName);
    formData.append("csv_file", selectedFile);
    fetch(`${API_URL}/api/v1/datasrc/upload-file`, {
      method: "POST",
      headers: {
        "x-authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data from the upload data scource", data);
        if (data?.message === "student col valid") {
          setUploadFileError(
            `Column name "${columnName}"  is not present in the uploaded file`
          );
          setUploadSuccess(false);
        } else if (data?.message && data?.message !== "student col valid") {
          setUploadFileError(false);
          setUploadSuccess(true);
          setUploadedData(data);
        } else {
          setUploadFileError("You have uploaded a invalid file.");
          setUploadSuccess(false);
        }
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  useEffect(() => {
    if (selectedFile && columnName) {
      uploadDataSource();
    }
  }, [selectedFile]);

  useEffect(() => {
    if (selectedDataSourceId && selectedDataSourceId !== "createDataSource") {
      getSelectedDataSource();
    }
  }, []);

  const saveDataSrc = () => {
    let obj = {
      description: dataSourceDescription,
      name: dataSourceName,
      school_id: user?.school_id,
      student_id_column: columnName,
      upload: uploadedData?.message,
    };
    if (selectedDataSourceId !== "createDataSource") {
      obj.datasource_id = selectedDataSourceId;
      if (!obj.upload) obj.upload = "";
      GetStudentService.updateDataSource(obj, selectedDataSourceId)
        .then((data) => {
          console.log("data from the upload data src", data);
          history.push("/school_it/manageDataSource");
        })
        .catch((error) => {
          if (error.response.status === 409) {
            setNameInvalidError(true);
            setUploadSuccess(false);
          }
        });
    } else if (uploadSuccess) {
      GetStudentService.saveDataSource(obj)
        .then((data) => {
          console.log("data from the upload data src", data);
          history.push("/school_it/manageDataSource");
        })
        .catch((error) => {
          if (error.response.status === 409) {
            setNameInvalidError(true);
            setUploadSuccess(false);
          }
        });
    }
  };

  console.log("selected Data Source id", selectedDataSourceId);
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          {loading ? (
            <CustomLoader />
          ) : (
            <div style={{ padding: "15px" }}>
              <div className="flex" style={{ justifyContent: "space-between" }}>
                <p style={{ fontSize: "24px" }} className="no-margin-bottom">
                  Create Data Source
                </p>
                <button
                  onClick={() => history.push("/school_it/manageDataSource")}
                  className="create-maintain-group-button"
                >
                  Back
                </button>
              </div>
              <div className="requiredname-input-container">
                <div style={{ textAlign: "right" }}>
                  <p className="no-margin-bottom requirement-text">
                    Data Source Name
                  </p>
                </div>
                <div style={{ position: "relative" }}>
                  <input
                    value={dataSourceName}
                    onChange={(e) => setDataSourceName(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                    type="text"
                    placeholder="Type a data source name"
                    className="require-input-name"
                  />
                  <FontAwesomeIcon
                    style={{ position: "absolute", top: "10px", right: "10px" }}
                    icon={faAsterisk}
                    color="red"
                  />
                </div>
              </div>
              <div className="requiredname-input-container">
                <div style={{ textAlign: "right" }}>
                  <p className="no-margin-bottom requirement-text">
                    Data Source Description
                  </p>
                </div>
                <div style={{ position: "relative" }}>
                  <textarea
                    value={dataSourceDescription}
                    onChange={(e) => setDataSourceDescription(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                    type="text"
                    className="require-input-name"
                    placeholder="Type a data source description"
                    rows="5"
                  />
                  <FontAwesomeIcon
                    style={{ position: "absolute", top: "10px", right: "10px" }}
                    icon={faAsterisk}
                    color="red"
                  />
                </div>
              </div>
              <div className="requiredname-input-container">
                <div style={{ textAlign: "right" }}>
                  <p className="no-margin-bottom requirement-text">
                    Column Name for Student ID
                  </p>
                </div>
                <div style={{ position: "relative" }}>
                  <input
                    value={columnName}
                    onChange={(e) => setColumnName(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                    type="text"
                    placeholder="Type a column name"
                    className="require-input-name"
                  />
                  <FontAwesomeIcon
                    style={{ position: "absolute", top: "10px", right: "10px" }}
                    icon={faAsterisk}
                    color="red"
                  />
                </div>
              </div>
              <div>
                <p style={{ fontSize: "18px" }}>Select File</p>
                <input
                  type="file"
                  accept="text/csv"
                  ref={fileRef}
                  onChange={fileHandler}
                  disabled={!columnName}
                />
              </div>
              {uploadFileError && (
                <div className="error-class">
                  <p className="no-margin-bottom">{uploadFileError}</p>
                </div>
              )}
              {nameInvalidError && (
                <div className="error-class">
                  <p className="no-margin-bottom">
                    Data Source name already exists.
                  </p>
                </div>
              )}
              {uploadSuccess && (
                <div className="success-class">
                  <p className="no-margin-bottom">
                    You have uploaded a valid datasource, please do proceed to
                    save the data
                  </p>
                </div>
              )}
              <div style={{ display: "flex", marginTop: "25px" }}>
                <button
                  disabled={
                    !dataSourceName || !dataSourceDescription || !columnName
                    //   uploadSuccess === false
                  }
                  className={
                    !dataSourceName || !dataSourceDescription || !columnName
                      ? //  !uploadedData
                        "disabled-modal-button"
                      : "save-modal-button"
                  }
                  onClick={saveDataSrc}
                >
                  <FontAwesomeIcon
                    icon={faSave}
                    style={{ margin: "0 5px", color: "white" }}
                  />
                  Save
                </button>
                <button
                  onClick={() => history.push("/school_it/manageDataSource")}
                  className="cancel-modal-button2"
                >
                  <FontAwesomeIcon icon={faTimes} style={{ margin: "0 5px" }} />
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default CreateDataSource;
