import React, { useState, useEffect, Fragment } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';

const StudentImportConfirmation = ({
    isOpen,
    closeHandler,
    student,
    uploadFileHandlerForInsert,
    showSuccessAlert,
    school_it_ok,
    setShowSuccessAlert
}) => {

    const convertArrayToTable = async (apiArray, fileName) => {
        const tableRows = apiArray.map(obj =>
            [`<tr>
              ${Object.keys(obj).map(key => `<td>${obj[key] === null || key === "id" || obj[key] === '' ? '' : obj[key]}</td>`).join('')}
           <tr/>`]).join('');
        const table = `<table>${tableRows}</table>`.trim();
        const xmlTable = createXMLTable(table, fileName);
        const downloadURL = createFileUrl(xmlTable);
        downloadFile(downloadURL, fileName);
    }

    const createXMLTable = (table, fileName) => {
        const xmlTable = `
              <html xmlns:o="urn:schemas-microsoft-com:office:office xmlns:x="urn:schemas-microsoft-com:office:excel"
             xmlns="http://www.w3.org/TR/REC-html40"
              >
                 <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"/>
                 <head>
                    <xml>
                      <x:ExcelWorkbook>
                          <x:ExcelWorksheets>
                              <x:ExcelWorksheet>
                                  <x:Name>${fileName}</x:Name>
                                  <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
                              </x:ExcelWorksheet>
                          </x:ExcelWorksheets>
                      </x:ExcelWorkbook>
                    </xml>
                 </head>
                 <body>
                   ${table}
                 </body>
              </html> `
        return xmlTable;
    }

    const createFileUrl = (xmlTable) => {
        const tableBlob = new Blob([xmlTable], { type: 'application/vnd.ms-excel;base64,' });
        const downloadURL = URL.createObjectURL(tableBlob);
        return downloadURL;
    }

    // useEffect(()=>{
    //     downloadFile(csvfile, "Test Download")
    // },[])
    const downloadFile = (downloadURL, fileName) => {
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.download = fileName;
        downloadLink.href = downloadURL;
        downloadLink.click();
    }

   
    return (
        <Fragment>
            <Modal
                isOpen={isOpen}
                toggle={closeHandler}
            //centered={true}
            >
                <ModalBody className="student-import-modal">
                    <div>
                       { showSuccessAlert && <div style={{padding:'10px', borderRadius:'5px', marginBottom:'15px'}} className="success_alert">
                            <p className="no-margin-bottom"><strong>Success!</strong>New Students Inserted Successfully.</p>
                        </div>}
                        <p style={{fontSize:'18px', fontWeight:'bold'}}>Student Import</p>
                        <div>
                            <div>
                                <div style={{ backgroundColor: 'white', justifyContent: 'space-between', color: 'black',padding:'5px 15px',marginBottom:'2px' }} className="flex">
                                    <p className="no-margin-bottom">New</p>
                                    <p style={{fontSize:'12px'}} className="value-badge no-margin-bottom">{student?.students?.new}</p>
                                </div>
                                <div style={{ backgroundColor: 'white', justifyContent: 'space-between', color: 'black',padding:'5px 15px',marginBottom:'2px' }} className="flex">
                                    <p className="no-margin-bottom">Already Exists</p>
                                    <p style={{fontSize:'12px'}} className="value-badge no-margin-bottom">{student?.students?.repeated}</p>
                                </div>
                                <div style={{ backgroundColor: 'white', justifyContent: 'space-between', color: 'black',padding:'5px 15px',marginBottom:'2px' }} className="flex">
                                    <p className="no-margin-bottom">Fail</p>
                                    <p style={{fontSize:'12px'}} className="value-badge no-margin-bottom">{student?.students?.fail}</p>
                                </div>
                            </div>
                        </div>
                        {
                            student?.error && <>
                            <div style={{padding:'10px'}} className="error-container-modal">
                                <p className="no-margin-bottom" style={{fontSize:'14px', fontWeight:'400', color:'#000'}}>Import student(s) failed:</p>
                                <p className="no-margin-bottom" style={{fontSize:'14px', fontWeight:'400' ,color:'#607d8b'}}>{student?.error}</p>
                            </div>
                            <div className="flex" style={{justifyContent:'initial', padding:'20px 0px 10px 0px'}}>
                                <button onClick={closeHandler}  className="student-import-update-button">OK</button>
                                <button onClick={()=>convertArrayToTable(student?.error_csv, "Failed")} className="student-import-cancel-button">Download Failed CSV Files</button>
                            </div>
                        </>
                        }
                        {!school_it_ok && !showSuccessAlert && !student?.error && <div className="flex" style={{justifyContent:'initial', padding:'20px 0px 10px 0px'}}>
                            <button onClick={uploadFileHandlerForInsert}  className="student-import-update-button">Update</button>
                            <button onClick={closeHandler} className="student-import-cancel-button">Cancel</button>
                        </div>}
                        
                        {school_it_ok && !showSuccessAlert && !student?.error && <div className="flex" style={{justifyContent:'initial', padding:'20px 0px 10px 0px'}}>
                            <button onClick={closeHandler} className="student-import-update-button">OK</button>
                        </div>}
                        {
                            showSuccessAlert && <button onClick={closeHandler} style={{marginTop:'15px',background:'#2C121B', width:'max-content',padding:'0 20px', height:'40px'}} className="save-portfolio-button">OK</button>
                        }
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}
export default StudentImportConfirmation