import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState,  useRef } from "react";
import { Col, Container, FormGroup, Input, Row } from "reactstrap";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import LaddersDataService from "../../../services/LaddersService";
import MessageTemplateService from "../../../services/MessageTemplateService";
import MsgDataService from "../../../services/MsgService";
import Loading from "../../../components/Loading/Loading";
import "./ParentMessage.scss";
import { useDispatch, useSelector } from "react-redux";
import { messageNotificationAction } from "../../../reducers/MessageNotificationReducer";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const ParentMessage = () => {
  let dispatch = useDispatch();
  let userData = useSelector((state) => state.UserReducer.userObj);
  const [loading, setLoading] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [studentId, setStudentId] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [allStudentList, setAllStudentList] = useState([]);
  const [list, setList] = useState();
  const [filterList, setFilterList] = useState([]);
  const [searchMsg, setSearchMsg] = useState("");
  const [totalNotification, setTotalNotification] = useState(0);
  const [editChat, setEditChat] = useState(false);
  const [editUserInfo, setEditUserInfo] = useState({});
  const [userChat, setUserChat] = useState([]);
  const [allCount, setAllCount] = useState({});

  useEffect(() => {
    getStudentList();
  }, []);

  const getStudentList = () => {
    setLoading(true);
    LaddersDataService.getParentRoleStudentList()
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data && res?.data?.list && res?.data?.list.length > 0) {
            let studentArr = res?.data?.list || [];
            setAllStudentList(studentArr);
            setStudentList(studentArr);
            setStudentId(res?.data?.list[0]?.id);
            setSchoolId(res?.data?.list[0]?.school_id);
          }
          setLoading(false);
        }
  })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  const getStudentWiseunreadCount = () => {
    setLoading(true);
    MsgDataService.getUnreadCountForParent(studentId)
      .then((res) => {
        setAllCount(res?.data);
        setTotalNotification(res?.data?.total_notification);
        dispatch(messageNotificationAction(res?.data?.total_notification));
        setLoading(false);
      })
      .catch((error) => {
        console.log("total count error", error);
        setLoading(false);
      });
  };

  const handleChangeStudent = (e) => {
    setLoading(true);
    setSearchMsg("");
    setEditChat(false);
    setUserChat([]);
    let { value } = e?.target;
    setStudentId(value);
    let arr = studentList.filter((elem) => elem?.id === Number(value)) || [];
    if (value && arr && arr[0]?.school_id) {
      setSchoolId(arr[0]?.school_id);
    }
  };

  useEffect(() => {
      if (studentId) {
          getUserList();
          getStudentWiseunreadCount(studentId);
      }
  }, [studentId])

  const getUserList = () => {
    setLoading(true);
    MsgDataService.getParentMessageList(studentId)
      .then((res) => {
        if (res?.status === 200) {
        let messageList = res?.data?.data;
        setList(messageList);
        setFilterList(messageList);
        }
        setLoading(false);
      })
        .catch((error) => {
          console.log("error from the service", error);
          setLoading(false);
        });
  };

  const handleChangeSearchMsg = (e) => {
    setSearchMsg(e.target.value);
    let filterArr = list.filter(
      (item) =>
        item.name
          .toLowerCase()
          .replace(/ +/g, "")
          .match(e.target.value.toLowerCase().replace(/ +/g, "")) ||
        item.src_role
          .toLowerCase()
          .replace(/ +/g, "")
          .match(e.target.value.toLowerCase().replace(/ +/g, ""))
    );
    setFilterList(filterArr);
  };

  const openClickedContact = (item) => {
    console.log("item run", item);
    setEditUserInfo(item);
    getReadCountbyDate(item.src_id, studentId)
    setUserChat([]);
    let page = 1;
    MsgDataService.getMessageParentListPerUser(page, item.src_id, studentId)
      .then((data) => {
        // setSelectedUserMsgList(data.data.messages);
        // getReadCountbyDate(item.src_id);
        if (data.data.messages) {
          let msgObjArr = data.data.messages;
          if (msgObjArr && msgObjArr?.length > 0) {
            if (userData?.language === "es") {
              let msgArr = msgObjArr.map((e) => e.message_body);
              getTranslatedVal(msgArr, msgObjArr);
            } else {
              setUserChat(msgObjArr);
            }
          }
        }
        setEditChat(true);
        getStudentWiseunreadCount(studentId, schoolId);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  // const getParentRoleStudentList = () => {
  //   setLoading(true);
  //   MsgDataService.getTotalParentCount()
  //     .then((res) => {
  //     //   if (res?.status === 200) {
  //     //     setCountParent(res?.data?.count)
  //     //   setLoading(false);
  //     // }

  //   }).catch(error => {
  //     console.log('error from the service', error)
  //     setLoading(false);
  //   })
  // }

  const getReadCountbyDate = (id, student_id) => {
    let payload = {
      src_id: id,
      child_id: student_id,
    };
    setLoading(true);
    MsgDataService.readCountDate(payload)
      .then((res) => {
        if (res?.status === 200) {
          console.log("date res", res);
          setLoading(true);
          dispatch(messageNotificationAction(res?.data?.count))
          getStudentWiseunreadCount();
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };
    // let payload = {
    //   student_id: studentId,
    //   massenger_id: item?.id,
    // };
    // setLoading(true);
    // MsgDataService.getParentRoleMessagePerUser(payload)
    //   .then((res) => {
    //     if (res.status === 200) {
    //       if (res?.data?.messages[0]?.message.length > 0) {
    //         let messageArr = res?.data?.messages[0]?.message.map((item) => ({
    //           ...item,
    //           esName: item.body,
    //         }));
    //         if (userData?.language === "es") {
    //           getTranslatedVal(userData?.language, messageArr);
    //         } else {
    //           // setUserChat(res?.data?.messages[0]?.message);
    //           setUserChat(messageArr);
    //         }
    //       }
    //       getStudentWiseunreadCount(studentId, schoolId);
    //       setEditChat(true);
    //     }
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log("message error", error);
    //     setLoading(false);
    //   });

  const getTranslatedVal = (lang, msgArr) => {
    let payload = {
      list_of_values: msgArr.map((val) => val.message_body) || [],
      to: userData?.language, // 'es'
    };
    setLoading(true);
    MessageTemplateService.parentTranslateBragbook(payload)
      .then((res) => {
        if (res?.status === 200) {
          let esArr = res?.data?.list_of_results;
          let newArr = [...msgArr];
          newArr.map((item, index) => (item.esName = esArr[index]));
          setUserChat(newArr);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error from the service", error);
      });
  };

  const getCountData = (item) => {
    if (allCount === undefined) return;
    let message = Object.keys(allCount ? allCount : {}).map((key, value) => {
      return allCount[key][item.src_id];
    });
    let data = message && message.filter((x) => x !== undefined);

    if (data && data[0] && data[0] !== 0) {
      return <div className="message-notification">{`${data[0]}`}</div>;
    } else {
      return "";
    }
  };

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    scrollContainerRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  })

  return (
    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner parentMessage">
            <div className="msg-main">
              <Container fluid>
                {loading ? (
                  <Loading />
                ) : studentList && studentList.length > 0 ? (
                  <Row className="studentWrap">
                    <Col lg={4} md={6}>
                      <FormGroup className="text-left studentSelect">
                        <Input
                          className="form-control"
                          type="select"
                          name="studentId"
                          value={studentId || ""}
                          onChange={handleChangeStudent}
                        >
                          {studentList.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <div className="msgWrapper">
                  <Row>
                    <Col lg={4} md={6}>
                      <div className="messageSidebar">
                        <div className="sidebar-header">
                          <span className="title">Message</span>
                          <div className="contactSearchWrap">
                            <FontAwesomeIcon
                              icon={faSearch}
                              className="searchContactIcon"
                            />
                            <input
                              type="text"
                              className="form-control search-input contactSearch"
                              placeholder="Search contact and messages"
                              name="searchMsg"
                              value={searchMsg || ""}
                              autoComplete="off"
                              onChange={handleChangeSearchMsg}
                            />
                          </div>
                        </div>
                        <div className="list-wrap">
                          <p className="messageCount">
                            You have{" "}
                            <span
                              className={`countText ${
                                totalNotification && totalNotification > 0
                                  ? "green"
                                  : ""
                              }`}
                            >
                              {totalNotification && totalNotification > 0
                                ? totalNotification
                                : "no"}{" "}
                              new messages
                            </span>
                          </p>
                          {/* <p className='messageCount'>
                                                    You have{" "}
                                                    <span className={`countText ${count && count.total_notification > 0 ? 'green' : ''}`}>
                                                        {count ? count.total_notification : 0} {count && count.total_notification > 0 ? 'new' : ''} message
                                                    </span>
                                                </p> */}
                          {filterList && filterList.length > 0
                            ? filterList.map((item, index) => {
                                return (
                                  <div
                                    className="list"
                                    key={index}
                                    onClick={() => openClickedContact(item)}
                                  >
                                    <img
                                      className="profile-img"
                                      src={
                                        item?.profile_image ||
                                        IMG_URL+"placeholder.jpg"
                                      }
                                      alt=""
                                    />
                                    <div className="info">
                                      <span className="user">{item.name}</span>
                                      <span className="text">{item.src_role}</span>
                                    </div>
                                    <div>
                                      {/* <div className="count">09:10 PM</div> */}
                                      <div className="time">
                                        {getCountData(item)}
                                        {/* <img src={calenderIcon} alt="img" /> */}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : "No Data Found"}
                        </div>
                      </div>
                    </Col>

                    {/* Edit Screen */}
                    {editChat ? (
                      loading ? (
                        <Loading />
                      ) : (
                        <Col lg={8} md={6}>
                          <div className="content">
                            <header>
                              <img
                                src={
                                  editUserInfo?.profile_image ||
                                  IMG_URL+"placeholder.jpg"
                                }
                                alt=""
                              />
                              <div className="info d-flex flex-column">
                                <span className="user">
                                  {editUserInfo?.name}
                                </span>
                                {/* <span className="status">Online</span> */}
                              </div>
                            </header>
                            <div className="message-wrap">
                              {userChat && userChat.length > 0
                                ? userChat.map((item, index) => {
                                    return ((index === userChat.length - 1) ? 
                                    (
                                      <div
                                        className="userChatBlock"
                                        ref={scrollContainerRef} 
                                        key={index}
                                      >
                                        <div
                                          className={`message-list ${
                                            item.src_id === studentId
                                              ? ""
                                              : "me"
                                          }`}
                                        >
                                          <div className="msg">
                                            <p>
                                              <div>{userData?.language === "es"
                                              ? item.esName
                                              : item.message_body}</div>
                                              <a
                                                target="_blank"
                                                style={{
                                                  color: "white",
                                                  textDecorationLine:
                                                    "Underline",
                                                  marginRight: "10px",
                                                }}
                                                href={item?.attachment_url}
                                              >
                                                {item?.file_name}
                                              </a>
                                            </p>
                                          </div>
                                          <div className="time">
                                            {moment(item.date_sent).format(
                                              "Do MMM | hh:mm a"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) :
                                    (
                                        <div
                                          className="userChatBlock"
                                          key={index}
                                        >
                                          <div
                                            className={`message-list ${
                                              item.src_id === studentId
                                                ? ""
                                                : "me"
                                            }`}
                                          >
                                            <div className="msg">
                                              <p>
                                                <div>{userData?.language === "es"
                                              ? item.esName
                                              : item.message_body}</div>
                                                <a
                                                  target="_blank"
                                                  style={{
                                                    color: "white",
                                                    textDecorationLine:
                                                      "Underline",
                                                    marginRight: "10px",
                                                  }}
                                                  href={item?.attachment_url}
                                                >
                                                  {item?.file_name}
                                                </a>
                                              </p>
                                            </div>
                                            <div className="time">
                                              {moment(item.date_sent).format(
                                                "Do MMM | hh:mm a"
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                  })
                                : ""}
                            </div>
                          </div>
                        </Col>
                      )
                    ) : (
                      <Col lg={8} md={6}>
                        <div className="noOpenChatScreen">
                          <div className="nochatBlock">
                            <img
                              src={IMG_URL+"initChatImg.png"}
                              alt="initChatImg"
                              className="img-fluid"
                            />
                            <p>Let's begin a conversation</p>
                            <div className="chatText">
                              Pick a person from the menu on the left and start
                              your conversation
                            </div>
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParentMessage;
