import React, {
  Fragment,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import CampaignService from "../../services/CampaignService";
// import CustomDatePicker from '../datepicker/DatePicker';
import moment from "moment";
import ErrorModal from "../errorDisplayModal/ErrorDisplayModal";
import DeleteModal from "../deleteModal/DeleteModal";
import CampaignComponent from "./campaignComponent/CampaignComponent";
import CustomLoader from "../customLoader/CustomLoader";
import "./DigitalCampaign.css";
import BannerComponent from "./bannerComponent/BannerComponent";
import CampaignBannerList from "./campaignBannerList/CampaignBannerList";
import ShowImageModal from "./showImageModal/ShowImageModal";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import { useSelector } from "react-redux";
import MyStudentList from "./MyStudent/MyStudentList";
import InternationalQuery from "./International/InternationalQuery"
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const DigitalCampaign = () => {
  const bannerImageRef = useRef();
  const [isBannerEdit, setIsBannerEdit] = useState(false);
  const [dontDeleteBannerModal, setDontDeleteBannerModal] = useState(false);
  const [bannerName, setBannerName] = useState();
  const [bannerUrl, setBannerUrl] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [campaignData, setCampaignData] = useState();
  const [loading, setLoading] = useState(false);
  //   const [user, setUser] = useState(
  //     localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  //   );
  const [pageCount, setPageCount] = useState();
  const [campaignInputValue, setCampaignInputValue] = useState("");
  const [selectedRadioButton, setSelectedRadioButton] = useState("");
  const [bannerQuery, setBannerQuery] = useState();
  const [campaignResultTable, setCampaignResultTable] = useState();
  const [bannerCampaignQuery, setBannerCampaignQuery] = useState("");
  const [selectedBanner, setSelectedBanner] = useState(1);
  const [selectedQuery, setSelectedQuery] = useState(1);
  const [selectedResultTable, setSelectedResultTable] = useState(1);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteValue, setDeleteValue] = useState();
  const [editCampaignValue, setEditCampaignValue] = useState();
  const [
    selectedPremiumServiceOrBanner,
    setSelectedPremiumServiceOrBanner,
  ] = useState("international");
  const [bannerPage, setBannerPage] = useState();
  const [onEditClickForBanner, setOnEditClickForBanner] = useState(false);
  const [editBannerValue, setEditBannerValue] = useState();
  const [
    showSelectedImageNameFromEdit,
    setShowSelectedImageNameFromEdit,
  ] = useState();
  const [openImageErrorModal, setOpenImageErrorModal] = useState(false);
  const [uploadedBannerImageData, setUploadedBannerImageData] = useState();
  const [openDeleteModalForBanner, setOpenDeleteModalForBanner] = useState(
    false
  );
  const [bannerExistError, setBannerExistError] = useState(false);
  const [showSelectedBanner, setShowSelectedBanner] = useState();
  const [showSelectedQuery, setShowSelectedQuery] = useState();
  const [showResultTable, setShowResultTable] = useState();
  const [divId, setDivId] = useState();
  const [adPath, setAdPath] = useState();

  let user = useSelector((state) => state.UserReducer.userObj);
  console.log("user", user);
  const handleFileChange = (e) => {
    imageValidator(e.target.files[0]);
  };

  const createBanner = () => {
    let obj = {
      college_id: user && user.college_id,
      image_url: uploadedBannerImageData && uploadedBannerImageData,
      link_url: bannerUrl && bannerUrl,
      name: bannerName && bannerName,
      old_banner_name: null,
    };
    CampaignService.createBanner(obj)
      .then((data) => {
        console.log("data from the create banner service", data);
      })
      .catch((error) => {
        console.log("error from the service", error);
        if (error && error.response && error.response.status === 409) {
          setBannerExistError(true);
        }
      });
  };

  const saveBannerAfterEdit = () => {
    let imageName = bannerImage && bannerImage.split("/");

    let obj = {
      college_id: user && user.college_id,
      id: editBannerValue && editBannerValue.id,
      image_url: imageName && imageName[imageName.length - 1],
      link_url: bannerUrl && bannerUrl,
      name: bannerName && bannerName,
      old_banner_name: editBannerValue && editBannerValue.name,
    };
    CampaignService.createBanner(obj)
      .then((data) => {
        setBannerName("");
        setBannerUrl("");
        setEditCampaignValue();
        setUploadedBannerImageData("");
        setBannerImage("");
        bannerImageRef.current.value = "";
        getBannerPageData();
        setShowSelectedImageNameFromEdit();
      })
      .catch((error) => {
        console.error("error from the service", error);
      });
  };

  const imageValidator = (value) => {
    let img = new Image();
    img.src = window.URL.createObjectURL(value);
    img.onload = function() {
      let height = img.height;
      let width = img.width;
      if (
        (height === 60 && width === 468) ||
        (height === 600 && width === 160)
      ) {
        setBannerImage(value);
      } else if (
        (height !== 60 && width !== 468) ||
        (height !== 600 && width !== 160)
      ) {
        setOpenImageErrorModal(true);
      }
    };
  };

  const getBannerPageData = () => {
    let obj = {
      college: 8,
      page: page,
      recruiter_id: user?.id,
      role: user?.role,
    };
    setLoading(true);
    CampaignService.getBannerData(obj)
      .then((data) => {
        console.log("data from banner", data);
        setPageCount(data.data.max);
        setBannerPage(data.data.result);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error from the service", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    let resulttable =
      campaignResultTable &&
      campaignResultTable.filter(
        (item) => item.table_name === selectedResultTable
      );
    setShowResultTable(resulttable?.[0]);
  }, [selectedResultTable]);

  useEffect(() => {
    let query =
      bannerQuery && bannerQuery.filter((item) => item.name === selectedQuery);
    setShowSelectedQuery(query?.[0]);
  }, [selectedQuery]);

  useEffect(() => {
    let banner =
      bannerPage && bannerPage.filter((item) => item.name === selectedBanner);
    setShowSelectedBanner(banner?.[0]);
  }, [selectedBanner]);

  const saveCampaign = () => {
    if (
      campaignInputValue &&
      campaignInputValue.trim() &&
      showSelectedBanner &&
      (showSelectedQuery || showResultTable) &&
      startDate &&
      endDate &&
      (divId && divId.trim()) &&
      (adPath && adPath.trim())
    ) {
      let obj = {
        name: campaignInputValue,
        banner: showSelectedBanner.id,
        recruiter: user && user.id,
        college: user && user.college_id,
        query:
          showSelectedQuery && typeof showSelectedQuery === "object"
            ? showSelectedQuery.id
            : "",
        result:
          showResultTable && typeof showResultTable === "object"
            ? showResultTable.id
            : "",
        start: moment(startDate).format("YYYY-MM-DD"),
        end: moment(endDate).format("YYYY-MM-DD"),
        div_id: divId && divId,
        path: adPath && adPath,
      };
      console.log("obj from the create", obj);
      CampaignService.createCampaign(obj)
        .then((data) => {
          setCampaignInputValue();
          setSelectedBanner(1);
          setSelectedQuery(1);
          setSelectedRadioButton();
          setSelectedResultTable(1);
          setStartDate();
          setEndDate();
          setDivId();
          setAdPath();
          getPageData();
          setShowResultTable();
          setShowSelectedQuery();
          setShowSelectedBanner();
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 409) {
            setOpenErrorModal(true);
          }
        });
    } else {
      return false;
    }
  };

  const resetButton = () => {
    setCampaignInputValue("");
    setSelectedBanner(1);
    setSelectedQuery(1);
    setSelectedRadioButton("");
    setSelectedResultTable(1);
    setStartDate();
    setEndDate();
  };

  const handleInputCampaignName = (e) => {
    setCampaignInputValue(e.target.value);
  };

  const handleRadioButtonChange = (e) => {
    setSelectedQuery(1);
    setSelectedResultTable(1);
    setSelectedRadioButton(e.target.value);
  };

  const handleBannerChange = (e) => {
    console.log("efrom yje handleBanner change", e.target.value);
    setSelectedBanner(e.target.value);
  };

  useLayoutEffect(() => {
    setSelectedBanner(selectedBanner);
  }, [selectedBanner]);

  const handleSelectChange = (e) => {
    console.log("e from the handlrSelectchna", e.target.value);
    if (selectedRadioButton === "use-query") {
      setSelectedQuery(e.target.value);
    } else {
      setSelectedResultTable(e.target.value);
    }
  };

  const getPageData = () => {
    let obj = {
      college: 8,
      page: page,
      recruiter_id: user && user.id,
      role: user && user.role,
    };
    setLoading(true);
    CampaignService.getCampaignData(obj)
      .then((data) => {
        console.log("data from the service", data);
        setPageCount(data.data.max);
        setCampaignData(data.data.result);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error from the service", err);
        setLoading(false);
      });
  };

  const resetBanner = () => {
    setBannerName("");
    setBannerUrl("");
    setBannerImage();
    bannerImageRef.current.value = "";
    setUploadedBannerImageData("");
    setShowSelectedImageNameFromEdit();
  };

  useEffect(() => {
    getPageData();
    getBannerPageData();
  }, [page]);

  const getCampaignBannerData = () => {
    CampaignService.getCampaignBannerQuery(user && user.college_id)
      .then((data) => {
        console.log("data from the banner service", data);
        setBannerCampaignQuery(data.data.objects);
      })
      .catch((error) => {
        console.error("error from the service", error);
      });
  };

  const getCampaignQueryData = () => {
    CampaignService.getCampaignQuery(user && user.college_id)
      .then((data) => {
        console.log("data fom the query service", data);
        setBannerQuery(data.data.objects);
      })
      .catch((error) => {
        console.error("error from the service", error);
      });
  };

  const getCampaignResultTable = () => {
    let obj = {
      page: page,
      recruiter_id: user && user.id,
      role: user && user.role && user.role,
    };
    CampaignService.getCampaignResultTable(obj)
      .then((data) => {
        console.log("data fom the campaign service", data);
        setCampaignResultTable(data.data.result);
      })
      .catch((error) => {
        console.error("error from the service", error);
      });
  };
  useLayoutEffect(() => {
    getCampaignQueryData();
    // getCampaignResultTable();
    getCampaignBannerData();
  }, []);

  const deleteCampaignList = (item) => {
    setOpenDeleteModal(true);
    setDeleteValue(item);
  };

  const bannerDeleteRequirement = (item) => {
    let obj = {
      action: "check",
      banner_id: item.id,
    };
    CampaignService.knowBannerDeleteOrNot(obj)
      .then((data) => {
        if (data.data.message === "dont delete") {
          setDontDeleteBannerModal(true);
        } else if (data.data.message === "delete") {
          setOpenDeleteModalForBanner(true);
          setDeleteValue(item);
        }
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const onBannerEditForbanner = (item) => {
    setOnEditClickForBanner(true);
    setBannerImage(item.image_url);
    setEditBannerValue(item);
    setBannerName(item.name);
    setBannerUrl(item.link_url);
    let name = item.image_url.split("/");
    setShowSelectedImageNameFromEdit(name && name[name.length - 1]);
  };

  const onEditCampaign = (item) => {
    console.log("item from the edit campaign", item);
    setIsEdit(true);
    setEditCampaignValue(item);
    setCampaignInputValue(item.name);
    if (item.result_id !== "") {
      setSelectedRadioButton("use-results-table");
      let value =
        campaignResultTable &&
        campaignResultTable.filter((result) => result.id === item.result_id);
      setSelectedResultTable(value[0].table_name);
    } else if (item.query_id !== "") {
      setSelectedRadioButton("use-query");
      let value =
        bannerQuery &&
        bannerQuery.filter((result) => result.id === item.query_id);
      setSelectedQuery(value[0].name);
    }
    let bannerValue = bannerCampaignQuery.filter(
      (result) => result.id === item.banner_id
    );
    setSelectedBanner(bannerValue[0].name);
    setStartDate(new Date(item.start_date));
    setEndDate(new Date(item.end_date));
    setDivId(item.div_id);
    setAdPath(item.path);
  };

  const saveCampaignAfterEdit = () => {
    if (
      campaignInputValue &&
      campaignInputValue.trim() &&
      showSelectedBanner &&
      (showSelectedQuery || showResultTable) &&
      startDate &&
      endDate &&
      (divId && divId.trim()) &&
      (adPath && adPath.trim())
    ) {
      console.log(
        "selectedBanner",
        selectedBanner,
        selectedQuery,
        selectedResultTable
      );
      let obj = {
        banner: showSelectedBanner && showSelectedBanner.id,
        campaign: editCampaignValue && editCampaignValue.id,
        end: moment(endDate).format("YYYY-MM-DD"),
        name: campaignInputValue,
        oldend: editCampaignValue && editCampaignValue.end_date,
        oldname: editCampaignValue && editCampaignValue.name,
        oldstart: editCampaignValue && editCampaignValue.start_date,
        query:
          showSelectedQuery && typeof showSelectedQuery === "object"
            ? showSelectedQuery.id
            : "",
        result:
          showResultTable && typeof showResultTable === "object"
            ? showResultTable.id
            : "",
        start: moment(startDate).format("YYYY-MM-DD"),
        div_id: divId && divId,
        path: adPath && adPath,
      };
      console.log("obj", obj);
      CampaignService.saveEditCampaign(
        editCampaignValue && editCampaignValue.id,
        obj
      )
        .then((data) => {
          setCampaignInputValue();
          setSelectedBanner(1);
          setSelectedQuery(1);
          setSelectedRadioButton();
          setSelectedResultTable(1);
          setStartDate();
          setEndDate();
          setEditCampaignValue();
          getPageData();
          setIsEdit(false);
          setDivId();
          setAdPath();
          setShowResultTable();
          setShowSelectedQuery();
          setShowSelectedBanner();
        })
        .catch((error) => {
          console.log("error from the service", error);
        });
    }
  };

  const handlePremiumServiceOrBanner = (item) => {
    if (item === "my-students") {
      setSelectedPremiumServiceOrBanner(item);
    } else if (item === "international") {
      setSelectedPremiumServiceOrBanner(item);
    } else if (item === "premium-service") {
      setSelectedPremiumServiceOrBanner(item);
    } else if (item === "banner") {
      setSelectedPremiumServiceOrBanner(item);
    }
  };

  const uploadImage = () => {
    let obj = {
      file: bannerImage && bannerImage,
      filename: bannerImage && bannerImage.name,
    };
    CampaignService.uploadImageBanner(obj)
      .then((data) => {
        console.log("data from the upload image", data);
        setUploadedBannerImageData(data.data.message);
      })
      .catch((error) => console.log("error from the service", error));
  };

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          {loading ? (
            <CustomLoader />
          ) : (
            <div style={{ height: "100%"}}>
              <div>
                <p className="title-container bold no-margin-bottom">
                  PREMIUM SERVICE/DIGITAL CAMPAIGNS
                </p>
                  <div className="campaign-containers">
                    <div className="flex">
                      <div
                        onClick={() =>
                          handlePremiumServiceOrBanner("my-students")
                        }
                        style={{
                          width: "183px",
                          fontSize: "small",
                          marginLeft: "0px",
                        }}
                        className={`tab-button cursor ${
                          selectedPremiumServiceOrBanner === "my-students"
                            ? "active-tab"
                            : "inactive-tab"
                        }`}
                      >
                        My Students
                      </div>
                      <div
                        onClick={() =>
                          handlePremiumServiceOrBanner("international")
                        }
                        style={{ width: "183px", fontSize: "small" }}
                        className={`tab-button cursor ${
                          selectedPremiumServiceOrBanner === "international"
                            ? "active-tab"
                            : "inactive-tab"
                        }`}
                      >
                        International
                      </div>
                      <div
                        onClick={() =>
                          handlePremiumServiceOrBanner("premium-service")
                        }
                        style={{ width: "183px", fontSize: "small" }}
                        className={`tab-button cursor ${
                          selectedPremiumServiceOrBanner === "premium-service"
                            ? "active-tab"
                            : "inactive-tab"
                        }`}
                      >
                        Premium Service Campaign
                      </div>
                      <div
                        onClick={() => handlePremiumServiceOrBanner("banner")}
                        style={{ width: "183px" }}
                        className={`tab-button cursor ${
                          selectedPremiumServiceOrBanner === "banner"
                            ? "active-tab"
                            : "inactive-tab"
                        }`}
                      >
                        Banner
                      </div>
                    </div>
                    <div className="campaign-wrapper">
                      <div
                        style={{
                          justifyContent: "space-between",
                          padding: "10px 0px",
                          position: "relative",
                        }}
                        className="flex"
                      >
                        {selectedPremiumServiceOrBanner === "premium-service" && user.is_premium == true && (
                          <div className="flex">
                            <img
                              style={{
                                width: "50px",
                                height: "60px",
                                marginRight: "30px",
                              }}
                              src={IMG_URL+"DigitalCampaigns_Icon.png"}
                              alt="Digital Campaign"
                            />
                            <div>
                              <p
                                style={{ fontSize: "24px", color: "#333333" }}
                                className="no-margin-bottom bold"
                              >
                                5
                              </p>
                              <p
                                style={{ fontSize: "14px", color: "#333333" }}
                                className="no-margin-bottom bold"
                              >
                                Digital Campaigns
                              </p>
                            </div>
                          </div>
                        )}
                        {selectedPremiumServiceOrBanner === "banner" && user.is_premium == true && (
                          <div className="flex">
                            <img
                              style={{
                                width: "50px",
                                height: "60px",
                                marginRight: "30px",
                              }}
                              src={IMG_URL+"DigitalCampaigns_Icon.png"}
                              alt="Digital Campaign"
                            />
                            <div>
                              <p
                                style={{ fontSize: "24px", color: "#333333" }}
                                className="no-margin-bottom bold"
                              >
                                5
                              </p>
                              <p
                                style={{ fontSize: "14px", color: "#333333" }}
                                className="no-margin-bottom bold"
                              >
                                Digital Campaigns
                              </p>
                            </div>
                          </div>
                        )}
                        {selectedPremiumServiceOrBanner === "premium-service" && user.is_premium == false && (
                          <div style={{margin:"10px 0px 0px 10px", justifyContent:"center"}}>
                        <span>This is for <strong>Premium Users.</strong> For more details please contact to <strong>Admin.</strong></span>
                        </div>)}

                        {selectedPremiumServiceOrBanner === "banner" && user.is_premium == false && (
                          <div style={{margin:"10px 0px 0px 10px", justifyContent:"center"}}>
                        <span>This is for <strong>Premium Users.</strong> For more details please contact to <strong>Admin.</strong></span>
                        </div>)}
                        
                        {selectedPremiumServiceOrBanner ===
                          "premium-service" && user.is_premium == true && (
                          <div
                            className="cursor"
                            onClick={() =>
                              handlePremiumServiceOrBanner("banner")
                            }
                            style={{
                              justifyContent: "flex-end",
                              position: "absolute",
                              bottom: "0px",
                              right: "15px",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "14px",
                                textDecoration: "underline",
                              }}
                              className="no-margin-bottom"
                            >
                              Upload New Banner
                            </p>
                          </div>
                        )}
                        {selectedPremiumServiceOrBanner === "banner" && user.is_premium == true && (
                          <div
                            onClick={() =>
                              handlePremiumServiceOrBanner("premium-service")
                            }
                            className="cursor"
                            style={{
                              justifyContent: "flex-end",
                              position: "absolute",
                              bottom: "0px",
                              right: "15px",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "14px",
                                textDecoration: "underline",
                              }}
                              className="no-margin-bottom"
                            >
                              Create New Campaign
                            </p>
                          </div>
                        )}
                      </div>
                      {selectedPremiumServiceOrBanner === "premium-service" && user.is_premium == true && (
                        <div style={{ padding: "30px" }}>
                          <p className="no-margin-bottom color bold">
                            ALL PREMIUM SERVICE / CAMPAIGNS
                          </p>
                        </div>
                      )}
                      {selectedPremiumServiceOrBanner === "banner" && user.is_premium == true && (
                        <div style={{ padding: "30px" }}>
                          <p className="no-margin-bottom color bold">
                            ALL BANNER
                          </p>
                        </div>
                      )}
                      <div className="box-data">
                        {selectedPremiumServiceOrBanner === "my-students" && user.is_premium == true && (
                          <MyStudentList />
                        )}
                        {selectedPremiumServiceOrBanner === "my-students" && user.is_premium == false && (
                        <div style={{margin:"6px 0px 0px 10px", justifyContent:"center"}}>
                      <span>This is for <strong>Premium Users.</strong> For more details please contact to <strong>Admin.</strong></span>
                      </div>)}
                      </div>
                      <div className="box-data">
                        {selectedPremiumServiceOrBanner === "international" && user.is_premium == true && (
                          <InternationalQuery />
                        )}
                        {selectedPremiumServiceOrBanner === "international" && user.is_premium == false && (
                        <div style={{margin:"6px 0px 0px 10px", justifyContent:"center"}}>
                      <span>This is for <strong>Premium Users.</strong> For more details please contact to <strong>Admin.</strong></span>
                      </div>)}
                      </div>
                      <div className="campaign-grid-container">
                        <div>
                          {selectedPremiumServiceOrBanner === "banner" && user.is_premium == true ? (
                            <CampaignBannerList
                              data={bannerPage}
                              onEditClick={onBannerEditForbanner}
                              onDeleteClick={bannerDeleteRequirement}
                            />
                          ) : (
                            <CampaignBannerList
                              data={campaignData}
                              onEditClick={onEditCampaign}
                              onDeleteClick={deleteCampaignList}
                            />
                          )}
                        </div>
                        {selectedPremiumServiceOrBanner ===
                          "premium-service" && user.is_premium == true && (
                          <CampaignComponent
                            isEdit={isEdit}
                            handleInputCampaignName={handleInputCampaignName}
                            campaignInputValue={campaignInputValue}
                            resetButton={resetButton}
                            saveCampaign={saveCampaign}
                            saveCampaignAfterEdit={saveCampaignAfterEdit}
                            startDate={startDate}
                            endDate={endDate}
                            selectedBanner={selectedBanner}
                            selectedQuery={selectedQuery}
                            handleRadioButtonChange={handleRadioButtonChange}
                            selectedRadioButton={selectedRadioButton}
                            handleSelectChange={handleSelectChange}
                            bannerQuery={bannerQuery}
                            selectedResultTable={selectedResultTable}
                            campaignResultTable={campaignResultTable}
                            handleBannerChange={handleBannerChange}
                            bannerCampaignQuery={bannerCampaignQuery}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            divId={divId}
                            setDivId={setDivId}
                            adPath={adPath}
                            setAdPath={setAdPath}
                          />
                        )}
                        {selectedPremiumServiceOrBanner === "banner" && user.is_premium == true && (
                          <BannerComponent
                            imageRef={bannerImageRef}
                            handleInputCampaignName={handleInputCampaignName}
                            campaignInputValue={campaignInputValue}
                            bannerImage={bannerImage}
                            bannerName={bannerName}
                            bannerUrl={bannerUrl}
                            setBannerImage={setBannerImage}
                            setBannerName={setBannerName}
                            setBannerUrl={setBannerUrl}
                            resetBanner={resetBanner}
                            showSelectedImageNameFromEdit={
                              showSelectedImageNameFromEdit
                            }
                            handleFileChange={handleFileChange}
                            imageValidator={imageValidator}
                            uploadImage={uploadImage}
                            createBanner={createBanner}
                            uploadedBannerImageData={uploadedBannerImageData}
                            isEdit={onEditClickForBanner}
                            saveBannerAfterEdit={saveBannerAfterEdit}
                            showResultTable={showResultTable}
                            showSelectedBanner={showSelectedBanner}
                            showSelectedQuery={showSelectedQuery}
                          />
                        )}
                     </div>
                  </div>
                  </div>
              </div>
            </div>
          )}
          {openErrorModal && (
            <ErrorModal
              openErrorModal={openErrorModal}
              setOpenErrorModal={setOpenErrorModal}
              text="Campaign"
            />
          )}
          {bannerExistError && (
            <ErrorModal
              openErrorModal={bannerExistError}
              setOpenErrorModal={setBannerExistError}
              text="Banner"
            />
          )}
          {dontDeleteBannerModal && (
            <ErrorModal
              openErrorModal={dontDeleteBannerModal}
              setOpenErrorModal={setDontDeleteBannerModal}
              text="Banner"
              showDontDeleteBannerMsg={true}
            />
          )}
          {openDeleteModal && (
            <DeleteModal
              openDeleteModal={openDeleteModal}
              setOpenDeleteModal={setOpenDeleteModal}
              deleteValue={deleteValue}
              from="campaign"
              getQueryPage={getPageData}
            />
          )}
          {openDeleteModalForBanner && (
            <DeleteModal
              openDeleteModal={openDeleteModalForBanner}
              setOpenDeleteModal={setOpenDeleteModalForBanner}
              deleteValue={deleteValue}
              from="banner"
              getQueryPage={getBannerPageData}
            />
          )}
          {openImageErrorModal && (
            <ShowImageModal
              openModal={openImageErrorModal}
              setOpenModal={setOpenImageErrorModal}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default DigitalCampaign;