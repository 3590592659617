import React, { Fragment, useState, useEffect } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

const SchoolItChart = ({ studentGoal, studentStatisticsData }) => {
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );

  const SeniorsData = {
    labels: ["Seniors on track", "Seniors Not on Track"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          studentStatisticsData?.goal_data[0].Seniors[1],
          studentStatisticsData?.goal_data[0].Seniors[0] -
            studentStatisticsData?.goal_data[0].Seniors[1],
        ],
        backgroundColor: ["#1DE66D", "#029B95"],
        borderColor: ["#1DE66D", "#029B95"],
        borderWidth: 1,
      },
    ],
  };

  const JuniorsData = {
    labels: ["Juniors on track", "Juniors Not on Track"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          studentStatisticsData?.goal_data[0].Juniors[1],
          studentStatisticsData?.goal_data[0].Juniors[0] -
            studentStatisticsData?.goal_data[0].Juniors[1],
        ],
        backgroundColor: ["#1a6545", "#0ef6b1"],
        borderColor: ["#1a6545", "#0ef6b1"],
        borderWidth: 1,
      },
    ],
  };

  const SophmoresData = {
    labels: ["Sophomores on track", "Sophomores Not on Track"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          studentStatisticsData?.goal_data[0].Sophmores[1],
          studentStatisticsData?.goal_data[0].Sophmores[0] -
            studentStatisticsData?.goal_data[0].Sophmores[1],
        ],
        backgroundColor: ["#51b47d", "#065777"],
        borderColor: ["#51b47d", "#065777"],
        borderWidth: 1,
      },
    ],
  };

  const FreshMenData = {
    labels: ["Freshmen on track", "Freshmen Not on Track"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          studentStatisticsData?.goal_data[0].Freshman[1],
          studentStatisticsData?.goal_data[0].Freshman[0] -
            studentStatisticsData?.goal_data[0].Freshman[1],
        ],
        backgroundColor: ["#52fdc9", "#e37d81"],
        borderColor: ["#52fdc9", "#e37d81"],
        borderWidth: 1,
      },
    ],
  };

  const ActiveStudentOption = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: "y",
    responsive: true,
    scales: {
      yAxes: {},
    },
    type: "bar",
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          console.log(tooltipItem);
          return tooltipItem.yLabel;
        },
      },
    },
  };

  const Baroptions = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    type: "bar",
  };

  return (
    <Fragment>
      {studentStatisticsData && (
        <div style={{ padding: "20px" }}>
          <div>
            <div
              className="flex"
              style={{
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "2px solid black",
                paddingBottom: "20px",
              }}
            >
              
              {/* <div>
                <button className="save-portfolio-button">
                  Download Summary
                </button>
              </div> */}
            </div>
            <div className="totalstudent-vs-greenstudentchart-container">
              {/* <p
                style={{ fontSize: "18px", fontWeight: "bold", color: "#333" }}
              >
                TOTAL GREEN STUDENT VERSUS TOTAL STUDENT CHART
              </p> */}
              <Bar
                datasetIdKey="id"
                className="bar-chart"
                data={{
                  labels: ["On Track Students", "Total Students"],
                  datasets: [
                    {
                      label: "",
                      barPercentage: 1.2,
                      categoryPercentage: 0.5,
                      backgroundColor: ["#008000", "#d4e2e9"],
                      // borderColor: "#f5c09c",
                      borderWidth: 1,
                      //stack: 1,
                      // hoverBackgroundColor: "rgba(255,99,132,0.4)",
                      // hoverBorderColor: "rgba(255,99,132,1)",
                      data: [
                        studentStatisticsData &&
                          studentStatisticsData.green_stu_count,
                        studentStatisticsData &&
                          studentStatisticsData.total_students,
                      ],
                    },
                  ],
                }}
                options={ActiveStudentOption}
              />
            </div>
            <div>
              <p
                style={{
                  fontSize: "24px",
                  color: "#3c9db0",
                  margin: "15px 0px",
                }}
              >
                {studentGoal}
              </p>
            </div>
            <div className="bar-chart">
              <Bar
                datasetIdKey="id"
                data={{
                  labels: ["Seniors", "Juniors", "Sophomores", "Freshmen"],
                  datasets: [
                    {
                      label: "On Track Students",
                      backgroundColor: "#008000",
                      borderColor: "#174417",
                      borderWidth: 1,
                      //stack: 1,
                      hoverBackgroundColor: "#2a742a",
                      hoverBorderColor: "#2a742a",
                      data: [
                        studentStatisticsData &&
                          studentStatisticsData?.goal_data[0].Seniors[1],
                        studentStatisticsData &&
                          studentStatisticsData?.goal_data[0].Juniors[1],
                        studentStatisticsData &&
                          studentStatisticsData?.goal_data[0].Sophmores[1],
                        studentStatisticsData &&
                          studentStatisticsData?.goal_data[0].Freshman[1],
                      ],
                    },

                    {
                      label: "Total Students",
                      backgroundColor: "#d4e2e9",
                      borderColor: "#447ea0",
                      borderWidth: 1,
                      //stack: 1,
                      hoverBackgroundColor: "#7abddf",
                      hoverBorderColor: "#7abddf",
                      data: [
                        studentStatisticsData &&
                          studentStatisticsData?.goal_data[0].Seniors[0],
                        studentStatisticsData &&
                          studentStatisticsData?.goal_data[0].Juniors[0],
                        studentStatisticsData &&
                          studentStatisticsData?.goal_data[0].Sophmores[0],
                        studentStatisticsData &&
                          studentStatisticsData?.goal_data[0].Freshman[0],
                      ],
                    },
                  ],
                }}
                options={Baroptions}
              />
            </div>
            <div className="pie-chart-container">
              <div style={{ width: "258px" }}>
                <label style={{ fontSize: "18px", color: "#3c9db0" }}>
                  Seniors
                </label>
                <Pie data={SeniorsData} />
              </div>
              <div style={{ width: "258px" }}>
                <label style={{ fontSize: "18px", color: "#3c9db0" }}>
                  Juniors
                </label>
                <Pie data={JuniorsData} />
              </div>
              <div style={{ width: "258px" }}>
                <label style={{ fontSize: "18px", color: "#3c9db0" }}>
                  Sophomores
                </label>
                <Pie data={SophmoresData} />
              </div>
              <div style={{ width: "258px" }}>
                <label style={{ fontSize: "18px", color: "#3c9db0" }}>
                  Freshmen
                </label>
                <Pie data={FreshMenData} />
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default SchoolItChart;