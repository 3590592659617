import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import Goals from "./Goals";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import RequiredCourseSet from "./RequiredCourseSet";

const API_URL = process.env.REACT_APP_API_URL;

const OpenModal = ({ data, isOpen, closeHandler }) => {
  console.log("data from the modal", data);
  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={closeHandler}>
        <ModalHeader>
          Required Course Set Mapping Uploaded Successfully!!!
        </ModalHeader>
        <ModalBody>
          <div style={{ padding: "10px" }}>
            {data && (
              <div style={{ padding: "10px", background: "#fcf8e3" }}>
                <h4>Upload Course Map Error</h4>
                {data?.error?.map((item) => (
                  <div style={{ color: "#607d8b", fontSize: "14px" }}>
                    {item}
                  </div>
                ))}
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            style={{
              color: "white",
              background: "#009900",
              border: "none",
              outline: "none",
              padding: "5px",
              borderRadius: "3px",
            }}
            onClick={closeHandler}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const StudentProgress = () => {
  let history = useHistory();
  const uploadCourseRef = useRef();
  const [uploadCourseFile, setUploadCourseFile] = useState();
  const [dataAfterFileUpload, setDataAfterFileUpload] = useState();
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [openModalAfterUpload, setOpenModalAfterUpload] = useState(false);

  const uploadCourseHandler = () => {
    let file = uploadCourseRef.current.files[0];
    console.log("file from the upload course handler", file);
    setUploadCourseFile(file);
  };

  const uploadFileHandler = () => {
    let formData = new FormData();
    formData.append("csvfilename", undefined);
    formData.append("csv_file", uploadCourseFile);
    fetch(`${API_URL}/api/v1/course/upload-file`, {
      method: "POST",
      headers: {
        "x-authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data from the service", data);
        setDataAfterFileUpload(data);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  useEffect(() => {
    if (uploadCourseFile) {
      uploadFileHandler();
    }
  }, [uploadCourseFile]);

  const uploadCourse = () => {
    let obj = {
      school: user?.school_id,
      upload: dataAfterFileUpload?.message,
    };
    GetStudentService.uploadAfter(obj)
      .then((data) => {
        console.log("data from the upload course service", data);
        setDataAfterFileUpload(data.data);
        setOpenModalAfterUpload(true);
        uploadCourseRef.current.value = "";
        setUploadCourseFile(null);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
      <div style={{ padding: "15px 25px" }}>
        <div className="flex" style={{ justifyContent: "space-between" }}>
          <div style={{ marginTop: "20px", marginBottom: "10px" }}>
            <p
              style={{
                fontSize: "24px",
              }}
              className="no-margin-bottom"
            >
              Goal Editor
            </p>
          </div>
          {/* <div>
            <button
              onClick={() => history.push("/school_it/manageDataSource")}
              style={{ marginRight: "15px" }}
              className="create-maintain-group-button"
            >
              Manage Data Source
            </button>
            <button
              onClick={() => history.push("/school_it/uploadDataSource")}
              className="create-maintain-group-button"
            >
              Upload Data Source
            </button>
          </div> */}
        </div>
        {/* <div>
          <p style={{ fontSize: "14px", marginBottom: "0px" }} className="bold">
            For Upload Course Map :
          </p>
          <div
            style={{ border: "1px solid #ccc", borderLeft: "6px solid #ccc" }}
          >
            <p
              style={{
                color: "#3a3a3a",
                fontSize: "14px",
                marginBottom: "0px",
                padding: "0 10px",
              }}
            >
              To Upload Course map, first create a CSV file that should contain
              required course set names in the first row and the first column
              should be datasource name. All the course names should be mapped
              accordingly. Once the CSV file has been created, upload the file.
            </p>
          </div>
        </div> */}
        {/* <div
          className="flex map-college-data-container"
          style={{
            border: "1px solid #ccc",
            padding: "8px",
            justifyContent: "space-between",
          }}
        >
          <p style={{ fontSize: "14px" }} className="no-margin-bottom">
            Map College Data
          </p>
          <button
            onClick={() => history.push("/school_it/mapcollege")}
            className="create-maintain-group-button"
          >
            Map College Data
          </button>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            className="flex map-college-data-container"
            style={{
              border: "1px solid #ccc",
              padding: "8px",
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontSize: "14px" }} className="no-margin-bottom">
              Map Goal Data
            </p>
            <button
              onClick={() => history.push("/school_it/mapgoal")}
              className="create-maintain-group-button"
            >
              Map Goal Data
            </button>
          </div>
          <div
            className="flex map-college-data-container upload-course-map-container"
            style={{
              border: "1px solid #ccc",
              padding: "8px",
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontSize: "14px" }} className="no-margin-bottom">
              Upload Course Map
            </p>
            <button
              disabled={!uploadCourseRef?.current?.files[0]}
              onClick={uploadCourse}
              className="create-maintain-group-button"
            >
              Upload
            </button>
            <input
              type="file"
              ref={uploadCourseRef}
              onChange={uploadCourseHandler}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
        </div> */}
        <div className="goals-required-course-set-container">
          <div className="goals-required-course" style={{}}>
            <Goals />
          </div>
          <div className="goals-required-course" style={{}}>
            <RequiredCourseSet />
          </div>
        </div>
      </div>
      {openModalAfterUpload && (
        <OpenModal
          data={dataAfterFileUpload}
          isOpen={openModalAfterUpload}
          closeHandler={() => {
            setOpenModalAfterUpload(false);
            history.push("/admin/ccplus");
          }}
        />
      )}
      </div>
      </div>
    </Fragment>
  );
};
export default StudentProgress;
