import React, { Fragment, useState, useEffect, useRef } from "react";
import CustomLoader from "../../customLoader/CustomLoader";
import GetAdminService from "../../../services/admin/GetAdminService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import CustomPagination from "../../pagination/Pagination";
import { Table } from "reactstrap";
import moment from "moment";

const ExportResultRecruiter = () => {
  const [collegeName, setCollegeName] = useState();
  const [showCollegeNameContainer, setShowCollegeNameContainer] = useState(
    false
  );
  const [openModifyRecruiter, setOpenModifyRecruiter] = useState(false);
  const [page, setPage] = useState(1);
  const [recruiterListData, setRecruiterListData] = useState([]);
  const [collegeNameList, setCollegeNameList] = useState();
  const collegeNameRef = useRef();
  const timeoutRef = useRef();
  const [collegeNameLoading, setCollegeNameLoading] = useState(false);
  const [showRecruiter, setShowRecruiter] = useState(false);
  const [openRecruiter, setOpenRecruiter] = useState(false);
  const [collegeId, setCollegeId] = useState();
  const [loading, setLoading] = useState(false);
  const [modifyData, setModifyData] = useState({});
  const [modifyRecruiterIndex, setRecruiterIndex] = useState();
  const [pageData, setPageData] = useState();

  //   const getCollegesList = (value) => {
  //     setCollegeNameLoading(true);
  //     GetAdminService.getCollegesList(value)
  //       .then((data) => {
  //         console.log("data from the service", data);
  //         let recruiterData = data?.data?.college;
  //         let recruiterName = recruiterData?.map((item) => item.college);
  //         setCollegeNameList(recruiterName);
  //         setCollegeNameLoading(false);
  //         setShowCollegeNameContainer(true);
  //       })
  //       .catch((error) => {
  //         console.log("error from the service", error);
  //         setCollegeNameLoading(false);
  //       });
  //   };

  //   const handleCollegeName = () => {
  //     clearTimeout(timeoutRef.current);
  //     setCollegeName(collegeNameRef.current.value);
  //     if (!collegeNameRef.current.value) {
  //       setShowCollegeNameContainer(false);
  //       setCollegeNameList([]);
  //       setRecruiterListData([]);
  //     } else {
  //       timeoutRef.current = setTimeout(() => {
  //         getCollegesList(collegeNameRef.current.value);
  //       }, 1000);
  //     }
  //   };

  //   const handleSelectedCollegeName = (item) => {
  //     setCollegeNameList([item]);
  //     setShowCollegeNameContainer(false);
  //     setShowRecruiter(true);
  //     setCollegeId(item?.id);
  //     setCollegeName(item.name);
  //   };

  //   const handleSelectedRecruiter = (item) => {
  //     setModifyData(item);
  //     setOpenModifyRecruiter(true);
  //   };

  useEffect(() => {
    exportRecruiterData();
  }, [page]);

  const exportRecruiterData = () => {
    setLoading(true);
    let obj = {
        page: page
    };
    GetAdminService.getExportData(obj)
      .then((data) => {
        console.log("student from the service", data.data);
        setPageData(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="admin-canned-container">
            <div className="flex canned-message-heading">
              <p>
                <span style={{ fontSize: "25px", fontWeight: "400" }}>
                  Export Result Report (Recruiter)
                </span>
              </p>
            </div>
            {/* <div className="school-name-container">
              <p>Select College</p>
              <div>
                <input
                  ref={collegeNameRef}
                  className="search-student-input"
                  type="text"
                  style={{
                    width: "500px",
                    height: "35px",
                    padding: "5px 10px",
                    marginBottom: "10px",
                  }}
                  placeholder="College Name"
                  value={collegeName}
                  onChange={handleCollegeName}
                  autoComplete="off"
                />
                {showCollegeNameContainer && (
                  <div>
                    <ul
                      className="maintain-group-student-dropdown-menu"
                      style={{
                        width: "700px",
                        maxHeight: "600px",
                        overflowY: "scroll",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      {collegeNameLoading && <CustomLoader />}
                      {collegeNameList?.length > 0 &&
                        collegeNameList?.map((item, index) => (
                          <li
                            onClick={() => handleSelectedCollegeName(item)}
                            key={index}
                            className="flex maintain-group-student-list cursor"
                          >
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="admin-student-list-container">
                {loading ? (
                  <CustomLoader />
                ) : (
                  recruiterListData.length !== 0 && (
                    <div>
                      <p
                        style={{
                          padding: "8px 0px 8px 0px",
                          fontSize: "18px",
                        }}
                      >
                        Recruiters
                      </p>
                  {recruiterListData?.result?.map((item, index) => {
                    return (
                      <div
                        style={{ margin: "0" }}
                        className={`student-list ${index ===
                          modifyRecruiterIndex && "student-list-active"}`}
                        onClick={() => handleSelectedRecruiter(item)}
                      >
                        {/* <p style={{ marginBottom: "5px" }}>{`${
                          item.last_name
                        }, ${item.first_name}`}</p> */}
            {/* <p style={{ marginBottom: "5px" }}>{`<${
                          item.email
                        }>`}</p>
                      </div>
                    );
                  })}
                  </div>)
                )}
              </div>
            </div>
            {pageData && pageData?.page_list?.length > 1 && (
              <div style={{ margin: "0 20px" }}>
                <CustomPagination
                  count={pageData && pageData}
                  pageValue={page}
                  setPage={setPage}
                />
              </div>
            )} */}

            {loading ? (
              <CustomLoader />
            ) : (
              pageData && (
                <Table
                  className="text-center"
                  bordered
                  style={{
                    width: "90%",
                    background: "white",
                    marginTop: "20px",
                  }}
                >
                  {pageData?.result?.length > 0 && (
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>Recruiter Email</th>
                        <th style={{ textAlign: "center" }}>College Name</th>
                        <th style={{ textAlign: "center" }}>Query Type</th>
                        <th style={{ textAlign: "center" }}>Export Time</th>
                        <th style={{ textAlign: "center" }}>Result Count</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {pageData?.result?.map((item, index) => {
                      return (
                        <tr>
                          <td style={{ maxWidth: "400px" }}>
                            {item?.recruiter_email}
                          </td>
                          <td>{item?.college_name}</td>
                          <td>{item?.query_type}</td>
                          <td>
                            {moment(item.export_time).format(
                              "ddd MMM Do YYYY, hh:mm:ss a"
                            )}
                          </td>
                          <td>{item?.result_count}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )
            )}
            {pageData && pageData?.page_list?.length > 1 && (
              <div style={{ margin: "20px" }}>
                <CustomPagination
                  count={pageData && pageData}
                  pageValue={page}
                  setPage={setPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ExportResultRecruiter;
