import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router";
import swal from "sweetalert";
import AppHeader from "../../../../Layout/AppHeader";
import AppSidebar from "../../../../Layout/AppSidebar";
import GetStudentService from "../../../../services/SchoolIT/GetStudentService";
import CustomLoader from "../../../customLoader/CustomLoader";

const ManageDataSource = () => {
  let history = useHistory();
  const [page, setPage] = useState(1);
  const [manageSourceData, setManageSourceData] = useState([]);
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [loading, setLoading] = useState(false);

  const getManageDataSourcePage = () => {
    setLoading(true);
    let obj = {
      page: page,
      school: user?.school_id,
    };
    GetStudentService.getDataSrcPage(obj)
      .then((data) => {
        console.log("data from the source", data);
        setManageSourceData(data?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error from the service", error);
      });
  };

  const confirmDeleteDataSource = (value) => {
    let obj = {
      delete_datasource_id: value?.id,
    };
    GetStudentService.deleteDataSource(obj)
      .then((data) => {
        console.log("data from the delete source", data);
        getManageDataSourcePage();
      })
      .catch((error) => {
        console.log("error from the source", error);
      });
  };

  const deleteDataSource = (value) => {
    let obj = {
      datasource_id: value?.id,
    };
    GetStudentService.deleteDataSourceRequirement(obj)
      .then((data) => {
        console.log("data from the delete source", data);
        if (data?.data?.map === 1) {
          swal({
            text: `Cannot delete!! Data is already Map`,
            icon: "warning",
            dangerMode: true,
            buttons: {
              cancel: true,
              confirm: true,
            },
            closeOnClickOutside: false,
          }).then((res) => {
            if (res) {
              console.log("clicked from the res");
            }
          });
        } else {
          swal({
            text: `Are you sure you want to delete ?`,
            icon: "warning",
            dangerMode: true,
            buttons: {
              cancel: true,
              confirm: true,
            },
            closeOnClickOutside: false,
          }).then((res) => {
            if (res) {
              confirmDeleteDataSource(value);
            }
          });
        }
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const onEditClicked = (value) => {
    history.push(`/school_it/createDataSource/${value?.id}`);
  };

  useEffect(() => {
    getManageDataSourcePage();
  }, []);

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
      <div style={{ padding: "15px", fontSize: "14px" }}>
        <div className="flex" style={{ justifyContent: "space-between" }}>
          <p style={{ fontSize: "24px" }} className="no-margin-bottom">
            Data Source
          </p>
          <div className="flex">
            <button
              onClick={() => history.push("/school_it/createDataSource")}
              className="create-maintain-group-button"
            >
              New Data Source
            </button>
            <button
              onClick={() => history.push("/school_it/ccplus")}
              className="create-maintain-group-button"
            >
              Back
            </button>
          </div>
        </div>
        {loading ? (
          <CustomLoader />
        ) : (
          <div style={{ marginTop: "15px", width: "50%" }}>
            {manageSourceData &&
              manageSourceData?.result?.map((item, index) => (
                <div
                  style={{
                    padding: "10px",
                    border: "1px solid #ccc",
                    justifyContent: "space-between",
                  }}
                  className="flex"
                >
                  <p className="no-margin-bottom">{item?.name}</p>
                  <div className="flex">
                    <button
                      onClick={() => onEditClicked(item)}
                      className="create-maintain-group-button"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => deleteDataSource(item)}
                      className="create-maintain-group-button"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
      </div>
      </div>
    </Fragment>
  );
};
export default ManageDataSource;
