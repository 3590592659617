import React, { Fragment, useState, useEffect, useRef } from "react";
import CustomLoader from "../../customLoader/CustomLoader";
import GetAdminService from "../../../services/admin/GetAdminService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAsterisk,
  faBuilding,
  faCheck,
  faSave,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { Modal, ModalBody } from "reactstrap";
import { stateData } from "../../../constants/data";

const MessageModal = ({ isOpen, setIsOpen, data }) => {
  return (
    <Fragment>
      <Modal isOpen={isOpen} centered={true}>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            <p>{data.message}</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={data.function1}
                style={{
                  backgroundColor: "#009900",
                  color: "white",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginRight: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon
                  style={{ margin: "0 5px" }}
                  color="white"
                  icon={faCheck}
                />
                Ok
              </button>
              <button
                onClick={() => setIsOpen(false)}
                style={{
                  backgroundColor: "#efefef",
                  color: "black",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginLeft: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon style={{ margin: "0 5px" }} icon={faTimes} />
                Cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const Colleges = () => {
  const [collegeName, setCollegeName] = useState();
  const [showCollegeNameContainer, setShowCollegeNameContainer] = useState(
    false
  );
  const [collegeNameList, setCollegeNameList] = useState();
  const collegeNameRef = useRef();
  const timeoutRef = useRef();
  const [collegeNameLoading, setCollegeNameLoading] = useState(false);
  const [modify, setModify] = useState(false);
  const [openCreateCollege, setOpenCreateCollege] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [collegeInput, setCollegeInput] = useState({
    name: "",
    address: "",
    city: "",
    zip_code: "",
    state: "",
    schools: [],
    id: "",
    goal_id: "",
  });
  const [addSchool, setAddSchool] = useState();
  const [loading, setLoading] = useState(false);

  const [schoolName, setSchoolName] = useState();
  const [showSchoolNameContainer, setShowSchoolNameContainer] = useState(false);
  const [schoolNameList, setSchoolNameList] = useState();
  const schoolNameRef = useRef();
  const [schoolNameLoading, setSchoolNameLoading] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState([]);

  const [defaultGoal, setDefaultGoal] = useState();
  const [showGoalContainer, setShowGoalContainer] = useState(false);
  const [goalList, setGoalList] = useState();
  const [goalLoading, setGoalLoading] = useState();

  const getCollegesList = (value) => {
    setShowCollegeNameContainer(true);
    setCollegeNameLoading(true);
    GetAdminService.getCollegesList(value)
      .then((data) => {
        console.log("data from the service", data);
        let collegeData = data?.data?.college;
        let collegeName = collegeData?.map((item)=>item.college)
        setCollegeNameList(collegeName);
        setCollegeNameLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setCollegeNameLoading(false);
      });
  };

  const getAllCollegesList = () => {
    GetAdminService.getAllCollegesList()
      .then((data) => {
        console.log("data from the service", data);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const getGoalDetail = (value) => {
    setShowGoalContainer(true);
    setGoalLoading(true);
    GetAdminService.getGoalDetail(value)
      .then((data) => {
        console.log("data from the service", data);
        setGoalList(data?.data?.goal);
        setGoalLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setGoalLoading(false);
      });
  };

  const handleGoal = (value) => {
    setDefaultGoal(value);
    let goalNameSearch = value;
    clearTimeout(timeoutRef.current);
    setShowGoalContainer(true);
    if (!value) {
      setShowGoalContainer(false);
      setGoalList([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getGoalDetail(goalNameSearch);
      }, 1000);
    }
  };

  const handleSelectedGoal = (item) => {
    setDefaultGoal(item?.goal_name);
    setCollegeInput({ ...collegeInput, goal_id: item?.id });
    setShowGoalContainer(false);
    setGoalList([item]);
  };

  const saveCollege = () => {
    let arr = selectedSchool?.map((item) => item.id);
    let obj = {
      name: collegeInput?.name,
      address: collegeInput?.address,
      city: collegeInput?.city,
      zip_code: collegeInput?.zip_code,
      state: collegeInput?.state,
      goal_id: collegeInput?.goal_id,      
    };
    setLoading(true);
    if (modify) {
      obj.college_id = collegeInput?.id;
      obj.school_id = arr;
      GetAdminService.updateCollege(obj)
        .then((data) => {
          console.log("data from the service", data);
          setLoading(false);
          setNull();
          setOpenCreateCollege(false);
        })
        .catch((error) => {
          console.log("error from the service", error);
          setLoading(false);
        });
    } else {
      obj.school = arr;
      // obj.schools = selectedSchool;
      GetAdminService.saveCollege(obj)
        .then((data) => {
          console.log("data from the service", data);
          setLoading(false);
          setNull();
          setOpenCreateCollege(false);
        })
        .catch((error) => {
          console.log("error from the service", error);
          setLoading(false);
        });
    }
  };

  const setNull = () => {
    console.log("first");
    setSelectedSchool([]);
    setGoalList([]);
    setDefaultGoal("");
    setModify(false);
    setCollegeInput({
      name: "",
      address: "",
      schools: [],
      id: "",
      city: "",
      zip_code: "",
      state: "",
      goal_id: "",
    });
  };

  const deleteCollege = () => {
    setOpenDeleteModal(false);
    let body = {
      college_id:collegeInput?.id
    }
    setLoading(true);
    GetAdminService.deleteCollege(body)
      .then((data) => {
        console.log("student from the service", data);
        setNull();
        setLoading(false);
        setOpenCreateCollege(false);
        swal({
          text: "College deleted successfully!",
          icon: "success",
          buttons: {
            confirm: true
          },
          closeOnClickOutside: true,
        });
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  const handleCollegeName = () => {
    clearTimeout(timeoutRef.current);
    setCollegeName(collegeNameRef.current.value);
    if (!collegeNameRef.current.value) {
      setShowCollegeNameContainer(false);
      setCollegeNameList([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getCollegesList(collegeNameRef.current.value);
      }, 1000);
    }
  };

  const handleSelectedCollegeName = (item) => {
    setShowCollegeNameContainer(false);
    setCollegeName(item.name);
    setModify(true);
    setOpenCreateCollege(true);
    setCollegeNameList([item]);
    setCollegeInput(item);
    setSelectedSchool(item?.schools);
    setDefaultGoal(item?.goal?.goal_name);
  };

  // useEffect(()=>{
  //   getAllCollegesList();
  // })

  const getSchoolsList = (value) => {
    setSchoolNameLoading(true);
    setShowSchoolNameContainer(true);
    setSchoolNameList([]);
    GetAdminService.getSchoolsListForCollege(value)
      .then((data) => {
        console.log("data from the service", data);
        setSchoolNameList(data?.data?.school);
        setSchoolNameLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setSchoolNameLoading(false);
      });
  };

  const handleSchoolName = () => {
    clearTimeout(timeoutRef.current);
    setSchoolName(schoolNameRef.current.value);
    if (!schoolNameRef.current.value) {
      setShowSchoolNameContainer(false);
      setSchoolNameList([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getSchoolsList(schoolNameRef.current.value);
      }, 1000);
    }
  };

  const handleSelectedSchoolName = (item) => {
    setSchoolNameList([item]);
    setSelectedSchool([...selectedSchool, item]);
    setSchoolName("");
    setShowSchoolNameContainer(false);
  };

  const removeSchoolName = (value) => {
    let filteredValue = selectedSchool?.filter((item) => item !== value);
    setSelectedSchool(filteredValue);
  };

  const createCollege = (
    <div className="create-student-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "5px 20px",
        }}
      >
        <p style={{ fontSize: "18px" }}>
          {modify ? "Modify College" : "New College"}
        </p>
        <FontAwesomeIcon
          icon={faTimes}
          style={{ cursor: "pointer", fontSize: "18px" }}
          onClick={() => {
            setOpenCreateCollege(false);
            setNull();
          }}
        />
      </div>

      <div style={{ padding: "0 15px" }}>
        <div className="college-input-container">
          <p className="bold no-margin-bottom">College Name</p>
          <input
            type="text"
            placeholder="Enter College Name"
            value={collegeInput.name}
            onChange={(e) =>
              setCollegeInput({ ...collegeInput, name: e.target.value })
            }
          />
          <FontAwesomeIcon
            style={{ fontSize: "12px", position: "absolute", right: "50px" }}
            icon={faAsterisk}
            color="red"
          />
        </div>

        <div className="college-input-container">
          <p className="bold no-margin-bottom">Address</p>
          <input
            type="text"
            placeholder="Enter Address First Line"
            value={collegeInput?.address}
            onChange={(e) =>
              setCollegeInput({ ...collegeInput, address: e.target.value })
            }
          />
        </div>

        <div className="college-input-container">
          <p className="bold no-margin-bottom">City</p>
          <input
            type="text"
            placeholder="Enter City"
            value={collegeInput?.city}
            onChange={(e) =>
              setCollegeInput({ ...collegeInput, city: e.target.value })
            }
          />
        </div>

        <div className="college-input-container">
          <p className="bold no-margin-bottom">Zip code</p>
          <input
            type="text"
            placeholder="Enter Zip code"
            value={collegeInput?.zip_code}
            onChange={(e) =>
              setCollegeInput({ ...collegeInput, zip_code: e.target.value })
            }
            maxLength={6}
          />
        </div>

        <div className="college-input-container">
          <p className="bold no-margin-bottom">State</p>
          <select
            value={collegeInput?.state}
            onChange={(e) =>
              setCollegeInput({ ...collegeInput, state: e.target.value })
            }
          >
            <option disabled selected value="">
              Select State
            </option>
            {stateData.map((item, index) => (
              <option
                style={{ textTransform: "capitalize" }}
                value={item?.name}
              >
                {item?.name}
              </option>
            ))}
          </select>
        </div>

        <div className="college-input-container">
          <p className="bold no-margin-bottom">Default Goal</p>
          <input
            type="text"
            placeholder="Select Default Goal"
            value={defaultGoal}
            onChange={(e) => handleGoal(e.target.value)}
          />
        </div>
        {showGoalContainer && (
          <div>
            <ul className="admin-school-name-list">
              {goalLoading && <CustomLoader />}
              {goalList?.length > 0 &&
                goalList.map((item, index) => (
                  <li
                    onClick={() => handleSelectedGoal(item)}
                    key={index}
                    className="flex maintain-group-student-list cursor"
                  >
                    <span>{item?.goal_name}</span>
                  </li>
                ))}
            </ul>
          </div>
        )}

        <div className="college-input-container">
          <p className="bold no-margin-bottom">Add School</p>
          <input
            type="text"
            ref={schoolNameRef}
            placeholder="Type the school name"
            value={schoolName}
            onChange={handleSchoolName}
            autoComplete="off"
          />
        </div>
        {showSchoolNameContainer && (
          <div>
            <ul className="admin-school-name-list">
              {schoolNameLoading && <CustomLoader />}
              {schoolNameList?.length > 0 &&
                schoolNameList.map((item, index) => (
                  <li
                    onClick={() => handleSelectedSchoolName(item)}
                    key={index}
                    className="flex maintain-group-student-list cursor"
                  >
                    <span>{item.name}</span>
                  </li>
                ))}
            </ul>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            margin: "10px 50px 10px 10px",
          }}
        >
          {selectedSchool?.length > 0 &&
            selectedSchool?.map((item) => (
              <div
                style={{
                  background: "#009900",
                  color: "white",
                  padding: "3px 5px",
                  cursor: "pointer",
                  margin: "2px",
                }}
              >
                {item?.name}
                <FontAwesomeIcon
                  icon={faTimes}
                  color="white"
                  style={{ marginLeft: "5px" }}
                  onClick={() => removeSchoolName(item)}
                />
              </div>
            ))}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "20px",
          padding: "20px 20px 30px 0",
        }}
      >
        {modify && (
          <button
            className="cancel-modal-button"
            onClick={() => setOpenDeleteModal(true)}
          >
            <FontAwesomeIcon icon={faTrash} />
            &nbsp;Delete
          </button>
        )}
        <button
          onClick={saveCollege}
          disabled={!collegeInput.name}
          className={
            !collegeInput.name ? "disabled-modal-button" : "save-modal-button"
          }
        >
          <FontAwesomeIcon
            icon={faSave}
            style={{
              marginRight: "5px",
              marginTop: "3px",
            }}
          />
          Save
        </button>
      </div>
    </div>
  );

  console.log("schoolName",schoolName);

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="admin-canned-container">
            <div className="flex canned-message-heading">
              <p>
                <span style={{ fontSize: "25px", fontWeight: "400" }}>
                  Colleges
                </span>
              </p>
              <button
                className="create-message-button"
                onClick={() => setOpenCreateCollege(true)}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faBuilding}
                />
                New College
              </button>
            </div>

            <div className="school-name-container">
              <p>Select College</p>
              <div>
                <input
                  ref={collegeNameRef}
                  className="search-admin-school-input"
                  type="text"
                  placeholder="College Name"
                  value={collegeName}
                  onChange={handleCollegeName}
                  autoComplete="off"
                />
                {showCollegeNameContainer && (
                  <div>
                    <ul className="admin-school-name-list">
                      {collegeNameLoading && <CustomLoader />}
                      {collegeNameList?.length > 0 &&
                        collegeNameList.map((item, index) => (
                          <li
                            onClick={() => handleSelectedCollegeName(item)}
                            key={index}
                            className="flex admin-school-list cursor"
                          >
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {loading && <CustomLoader />}
            {openCreateCollege && createCollege}

            {openDeleteModal && (
              <MessageModal
                setIsOpen={setOpenDeleteModal}
                isOpen={openDeleteModal}
                data={{
                  function1: deleteCollege,
                  message: "Are you sure you want to delete?",
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Colleges;