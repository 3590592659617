import React, { Fragment, useState, useEffect, useRef } from "react";
import GetAdminService from "../../../services/admin/GetAdminService";
import AppHeader from "../../../Layout/AppHeader";
import { Button, Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faAsterisk,
  faPlus,
  faSave,
  faTrash,
  faTimes,
  faTimesCircle,
  faCheckCircle,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import AppSidebar from "../../../Layout/AppSidebar";
import "./Configuration.css";
import swal from "sweetalert";

const ConfigurationList = () => {
  const [goalNameType, setGoalNameType] = useState(false);
  const [name, setName] = useState(false);
  const [operator, setOperator] = useState(false);
  const [arithemeticOperator, setArithemeticOperator] = useState(false);
  const [dataType, setDataType] = useState(false);
  const [goalFieldName, setGoalFieldName] = useState("");
  const [goalTypeData, setGoalTypeData] = useState([]);
  const [expressionName, setExpressionName] = useState("");
  const [expressionData, setExpressionData] = useState([]);
  const [arithemeticData, setArithemeticData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [operatorNm, setOperatorNm] = useState("");
  const [arithemeticName, setArithemeticName] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [operatorValue, setOperatorValue] = useState("");
  const [operatorData, setOperatorData] = useState([]);
  const [manual, setManual] = useState();
  const valueNameRef = useRef();
  const timeoutRef = useRef();

  const [query, setQuery] = useState();
  const [queryChange, setQueryChange] = useState();
  const [queryData, setQueryData] = useState([]);
  const [reason, setReason] = useState();
  const [reasonChange, setReasonChange] = useState();
  const [reasonData, setReasonData] = useState([]);
  const [manualChange, setManualChange] = useState();
  const [manualData, setManualData] = useState([]);
  const [gender, setGender] = useState();
  const [genderChange, setGenderChange] = useState();
  const [genderData, setGenderData] = useState([]);
  const [ethnicity, setEthnicity] = useState();
  const [ethnicityChange, setEthnicityChange] = useState();
  const [ethnicityData, setEthnicityData] = useState([]);
  const [interest, setInterest] = useState();
  const [interestChange, setInterestChange] = useState();
  const [interestData, setInterestData] = useState([]);
  const [athletics, setAthletics] = useState();
  const [country, setCountry] = useState();
  const [certificate, setCertificate] = useState();
  const [english, setEnglish] = useState();
  const [level, setLevel] = useState();
  const [athleticsChange, setAthleticsChange] = useState();
  const [countryChange, setCountryChange] = useState();
  const [certificateChange, setCertificateChange] = useState();
  const [englishChange, setEnglishChange] = useState();
  const [levelChange, setLevelChange] = useState();
  const [athleticsData, setAthleticsData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [certificateData, setCertificateData] = useState([]);
  const [englishData, setEnglishData] = useState([]);
  const [levelData, setLevelData] = useState([]);
  const [academics, setAcademics] = useState();
  const [academicsChange, setAcademicsChange] = useState();
  const [academicsData, setAcademicsData] = useState([]);
  const [activities, setActivities] = useState();
  const [activitiesChange, setActivitiesChange] = useState();
  const [activitiesData, setActivitiesData] = useState([]);
  const [course, setCourse] = useState();
  const [courseChange, setCourseChange] = useState();
  const [courseData, setCourseData] = useState([]);
  const [valueName, setValueName] = useState();

  const [valueNameList, setValueNameList] = useState();
  const [valueNameLoading, setValueNameLoading] = useState(false);

  const goalType = () => {
    return setGoalNameType(!goalNameType);
  };
  const goalName = () => {
    return setName(!name);
  };
  const operatorName = () => {
    return setOperator(!operator);
  };
  const arithemeticOperators = () => {
    return setArithemeticOperator(!arithemeticOperator);
  };
  const dataTypeName = () => {
    return setDataType(!dataType);
  };

  const queryCriterias = () => {
    return setQuery(!query);
  };

  const contactReason = () => {
    return setReason(!reason);
  };

  const manualName = () => {
    return setManual(!manual);
  };

  const genderName = () => {
    return setGender(!gender);
  };

  const ethnicityName = () => {
    return setEthnicity(!ethnicity);
  };

  const courseName = () => {
    return setCourse(!course);
  };

  const interestName = () => {
    return setInterest(!interest);
  };

  const academicsName = () => {
    return setAcademics(!academics);
  };

  const athleticsName = () => {
    return setAthletics(!athletics);
  };

  const countryName = () => {
    return setCountry(!country);
  }

  const certificateName = () => {
    return setCertificate(!certificate)
  }

  const englishName = () => {
    return setEnglish(!english)
  }

  const levelName = () => {
    return setLevel(!level)
  }

  const activitiesName = () => {
    return setActivities(!activities);
  };

  const cancelAction = () => {
    window.location.reload();
  };

  const handleAddGoalType = () => {
    setGoalTypeData([...goalTypeData, {}]);
  };

  const handleAddExpression = () => {
    setExpressionData([...expressionData, {}]);
  };

  const handleAddField = () => {
    setTypeData([...typeData, {}]);
  };

  const handleAddOperator = () => {
    setOperatorData([...operatorData, {}]);
  };

  const handleArithmeticOpt = () => {
    setArithemeticData([...arithemeticData, {}]);
  };

  const handleSaveType = () => {
    let obj = {
      type_name: [goalFieldName],
    };
    GetAdminService.saveType(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleSaveExpression = () => {
    let obj = {
      type_name: [expressionName],
    };
    GetAdminService.saveExpression(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleSaveArthOperator = () => {
    let obj = {
      type_name: [arithemeticName],
    };
    GetAdminService.saveArthOptr(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleOperatorSave = () => {
    let obj = {
      operator_name: operatorNm,
      operator: operatorValue,
    };
    GetAdminService.saveOperator(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleSaveField = () => {
    let obj = {
      type_name: [fieldName],
    };
    GetAdminService.saveField(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const goalFieldData = () => {
    GetAdminService.goalFieldData().then((data) => {
      console.log("data from the service get Course data", data);
      const newData = data?.data?.data.map(
        (obj) => (
          {
            ...obj,
            disabled: true,
          }
        )
      );
      setGoalTypeData(newData);
    });
  };

  const goalFieldDelete = (idToDelete) => {
    GetAdminService.deleteGoalFieldData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            goalFieldData();
          }
        }
        );
      }
    });
  };

  const goalExpresssionData = () => {
    GetAdminService.goalExpressionData().then((data) => {
      console.log("data from the service get Course data", data);
      const newData = data?.data?.data.map((obj) => ({
        ...obj,
        disabled: true,
      }));
      setExpressionData(newData);
    });
  };

  const goalExpresssionDelete = (idToDelete) => {
    GetAdminService.deletegoalExpresssionData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            goalExpresssionData()
          }
        }
        );
      }
    });
  };

  const operatorShowData = () => {
    GetAdminService.operatorShowData().then((data) => {
      console.log("data from the service get Course data", data);
      const newData = data?.data?.data?.map((obj) => (
      {
        ...obj,
        disabled: true,
      }));
      setOperatorData(newData);
    });
  };

  const goalOperatorDelete = (idToDelete) => {
    GetAdminService.deletegoalOperatorData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            operatorShowData();
          }
        }
        );
      }
    });
  };

  const typeShowData = () => {
    GetAdminService.typeShowData().then((data) => {
      console.log("data from the service get Course data", data);
      const newData = data?.data?.data.map((obj) => ({
        ...obj,
        disabled: true,
      }));
      setTypeData(newData);
    });
  };

  const typeDelete = (idToDelete) => {
    GetAdminService.deletetypeData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            typeShowData();
          }
        }
        );
      }
    });
  };

  const arithemeticShowData = () => {
    GetAdminService.arithemeticShowData().then((data) => {
      console.log("data from the service get Course data", data);
      const newData = data.data.data.map((obj) => ({
        ...obj,
        disabled: true,
      }));
      setArithemeticData(newData);
    });
  };

  const arithematicDelete = (idToDelete) => {
    GetAdminService.deletearithematicData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            arithemeticShowData();
          }
        }
        );
      }
    });
  };

  const handleAddQuery = () => {
    setQueryData([...queryData, { id: "", active: true }]);
  };

  const queryDelete = (idToDelete) => {
    GetAdminService.deletequeryData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            queryCriteriasShowData();
          }
        }
        );
      }
    });
  };

  const handleAddReason = () => {
    setReasonData([...reasonData, { id: "", active: true }]);
  };

  const reasonDelete = (idToDelete) => {
    GetAdminService.deletequeryData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            queryCriteriasShowData();
          }
        }
        );
      }
    });
  };

  const handleAddManual = () => {
    setManualData([...manualData, { id: "", active: true}]);
  };

  const manualDelete = (idToDelete) => {
    GetAdminService.deletequeryData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            queryCriteriasShowData();
          }
        }
        );
      }
    });
  };

  const handleAddGender = () => {
    setGenderData([...genderData, { id: "", active: true }]);
  };

  const genderDelete = (idToDelete) => {
    GetAdminService.deletequeryData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            queryCriteriasShowData();
          }
        }
        );
      }
    });
  };

  const handleAddEthnicity = () => {
    setEthnicityData([...ethnicityData, { id: "", active: true }]);
  };

  const ethnicityDelete = (idToDelete) => {
    GetAdminService.deletequeryData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            queryCriteriasShowData();
          }
        }
        );
      }
    });
  };

  const handleAddInterest = () => {
    setInterestData([...interestData, { id: "", active: true }]);
  };

  const interestDelete = (idToDelete) => {
    GetAdminService.deletequeryData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            queryCriteriasShowData();
          }
        }
        );
      }
    });
  };

  const handleAddAcademics = () => {
    setAcademicsData([...academicsData, { id: "", active: true }]);
  };

  const academicsDelete = (idToDelete) => {
    GetAdminService.deletequeryData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            queryCriteriasShowData();
          }
        }
        );
      }
    });
  };

  const handleAddActivities = () => {
    setActivitiesData([...activitiesData, { id: "", active: true }]);
  };

  const activitiesDelete = (idToDelete) => {
    GetAdminService.deletequeryData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            queryCriteriasShowData();
          }
        }
        );
      }
    });
  };

  const handleAddAthletics = () => {
    setAthleticsData([...athleticsData, { id: "", active: true }]);
  };

  const handleAddCountry = () => {
    setCountryData([...countryData, {id: "", active: true}]);
  }

  const handleAddCertificate = () => {
    setCertificateData([...certificateData, {id: "", active: true}]);
  }

  const handleAddEnglish = () => {
    setEnglishData([...englishData, {id: "", active: true}]);
  }

  const handleAddLevel = () => {
    setLevelData([...levelData, {id: "", active: true}]);
  }

  const athleticsDelete = (idToDelete) => {
    GetAdminService.deletequeryData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            queryCriteriasShowData();
          }
        }
        );
      }
    });
  };

  const countryDelete = (idToDelete) => {
    GetAdminService.deletequeryData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            queryCriteriasShowData();
          }
        }
        );
      }
    });
  };

  const certificateDelete = (idToDelete) => {
    GetAdminService.deletequeryData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            queryCriteriasShowData();
          }
        }
        );
      }
    });
  };

  const englishDelete = (idToDelete) => {
    GetAdminService.deletequeryData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            queryCriteriasShowData();
          }
        }
        );
      }
    });
  };

  const levelDelete = (idToDelete) => {
    GetAdminService.deletequeryData(idToDelete)
    .then((data) => {
      if (data?.data) {
        swal({
          text: data?.data?.Status,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        })
        .then((value) => {
          if (value === "OK") {
            queryCriteriasShowData();
          }
        }
        );
      }
    });
  };

  // const concentrationDelete = (idToDelete) => {
  //   GetAdminService.deletequeryData(idToDelete)
  //   .then((data) => {
  //     if (data?.data) {
  //       swal({
  //         text: data?.data?.Status,
  //         icon: "success",
  //         buttons: {
  //           OK: true,
  //         },
  //         closeOnClickOutside: false,
  //       })
  //       .then((value) => {
  //         if (value === "OK") {
  //           queryCriteriasShowData();
  //         }
  //       }
  //       );
  //     }
  //   });
  // };

  const queryCriteriasShowData = () => {
    GetAdminService.queryCriteriasShowData().then((data) => {
      console.log("data from the service queryCriterias", data);

      const newData = data?.data?.object?.query_criteria;
      setQueryData(newData);

      const newData1 = data?.data?.object?.reasontocontact;
      setReasonData(newData1);

      const newData2 = data?.data?.object?.query_criteriamanual;
      setManualData(newData2);

      const newData3 = data?.data?.object?.gender;
      setGenderData(newData3);

      const newData4 = data?.data?.object?.ethnicity;
      setEthnicityData(newData4);

      const newData5 = data?.data?.object?.intrest;
      setInterestData(newData5);

      const newData6 = data?.data?.object?.academics;
      setAcademicsData(newData6);

      const newData7 = data?.data?.object?.athletics;
      setAthleticsData(newData7);

      const newData10 = data?.data?.object?.country;
      setCountryData(newData10);

      const newData11 = data?.data?.object?.['board certification']
      setCertificateData(newData11);

      const newData12 = data?.data?.object?.['english test']
      setEnglishData(newData12);

      const newData13 = data?.data?.object?.['desired degree']
      setLevelData(newData13);

      const newData8 = data?.data?.object?.activities;
      setActivitiesData(newData8);

      const newData9 = data.data.object.filter(
        (obj) => obj.list_name === "concentration_name"
      );
      setCourseData(newData9);
    });
  };
  const handleAddQueryChange = (e, i) => {
    const { value } = e.target;
    setQueryChange(e.target.value);
    const newQueryData = queryData?.map((q, id) => {
      if (id === i) {
        return { ...q, name: value };
      } else {
      return q;
      }
    });
    setQueryData(newQueryData);
  };

  const handleAddReasonChange = (e, i) => {
    const { value } = e.target;
    setReasonChange(e.target.value);
    const newReasonData = reasonData.map((r, id) => {
      if (id === i) {
        return { ...r, name: value };
      } else {
      return r;
      }
    });
    setReasonData(newReasonData);
  };

  const handleAddManualChange = (e, i) => {
    const { value } = e.target;
    setManualChange(e.target.value);
    const newManualData = manualData.map((m, id) => {
      if (id === i) {
        return { ...m, name: value };
      } else {
      return m;
      }
    });
    setManualData(newManualData);
  };

  const handleAddGenderChange = (e, i) => {
    const { value } = e.target;
    setGenderChange(e.target.value);
    const newGenderData = genderData.map((g, id) => {
      if (id === i) {
        return { ...g, name: value };
      } else {
      return g;
      }
    });
    setGenderData(newGenderData);
  };

  const handleAddEthnicityChange = (e, i) => {
    const { value } = e.target;
    setEthnicityChange(e.target.value);
    const newEthnicityData = ethnicityData.map((e, id) => {
      if (id === i) {
        return { ...e, name: value };
      } else {
      return e;
      }
    });
    setEthnicityData(newEthnicityData);
  };

  const handleAddInterestChange = (e, i) => {
    const { value } = e.target;
    setInterestChange(e.target.value);
    const newInterestData = interestData.map((s, id) => {
      console.log(s, i, id);
      if (id === i) {
        return { ...s, name: value };
      } else {
      return s;
       }
    });
    setInterestData(newInterestData);
  };

  const handleAddInterestDisabled = (i) => {
    const newInterestData = interestData.map((s, id) => {
      if (id === i) {
        return { ...s, active: !s.active };
      } else {
        return s;
      }
    });
    setInterestData(newInterestData);
  };

  const handleAddAcademicsChange = (e, i) => {
    const { value } = e.target;
    setAcademicsChange(e.target.value);
    const newAcademicsData = academicsData.map((j, id) => {
      if (id === i) {
        return { ...j, name: value };
      } else {
      return j;
      }
    });
    setAcademicsData(newAcademicsData);
  };

  const handleAddAcademicsDisabled = (i) => {
    const newAcademicsData = academicsData.map((j, id) => {
      if (id === i) {
        return { ...j, active: !j.active };
      } else {
        return j;
      }
    });
    setAcademicsData(newAcademicsData);
  };

  const handleAddAthleticsChange = (e, i) => {
    const { value } = e.target;
    setAthleticsChange(e.target.value);
    const newAthleticsData = athleticsData.map((t, id) => {
      if (id === i) {
        return { ...t, name: value };
      } else {
      return t;
      }
    });
    setAthleticsData(newAthleticsData);
  };

  const handleAddAthleticsDisabled = (i) => {
    const newAthleticsData = athleticsData.map((t, id) => {
      if (id === i) {
        return { ...t, active: !t.active };
      } else {
        return t;
      }
    });
    setAthleticsData(newAthleticsData);
  };

  const handleAddCountryChange = (e, i) => {
    const { value } = e.target;
    setCountryChange(e.target.value);
    const newCountryData = countryData.map((t, id) => {
      if (id === i) {
        return { ...t, name: value };
      } else {
      return t;
      }
    });
    setCountryData(newCountryData);
  };

  const handleAddCountryDisabled = (i) => {
    const newCountryData = countryData.map((t, id) => {
      if (id === i) {
        return { ...t, active: !t.active };
      } else {
        return t;
      }
    });
    setCountryData(newCountryData);
  };

  const handleAddCertificateChange = (e, i) => {
    const { value } = e.target;
    setCertificateChange(e.target.value);
    const newCertificateData = certificateData.map((t, id) => {
      if (id === i) {
        return { ...t, name: value };
      } else {
      return t;
      }
    });
    setCertificateData(newCertificateData);
  };

  const handleAddCertificateDisabled = (i) => {
    const newCertificateData = certificateData.map((t, id) => {
      if (id === i) {
        return { ...t, active: !t.active };
      } else {
        return t;
      }
    });
    setCertificateData(newCertificateData);
  };

  const handleAddEnglishChange = (e, i) => {
    const { value } = e.target;
    setEnglishChange(e.target.value);
    const newEnglishData = englishData.map((t, id) => {
      if (id === i) {
        return { ...t, name: value };
      } else {
      return t;
      }
    });
    setEnglishData(newEnglishData);
  };

  const handleAddEnglishDisabled = (i) => {
    const newEnglishData = englishData.map((t, id) => {
      if (id === i) {
        return { ...t, active: !t.active };
      } else {
        return t;
      }
    });
    setEnglishData(newEnglishData);
  };

  const handleAddLevelChange = (e, i) => {
    const { value } = e.target;
    setLevelChange(e.target.value);
    const newLevelData = levelData.map((t, id) => {
      if (id === i) {
        return { ...t, name: value };
      } else {
      return t;
      }
    });
    setLevelData(newLevelData);
  };

  const handleAddLevelDisabled = (i) => {
    const newLevelData = levelData.map((t, id) => {
      if (id === i) {
        return { ...t, active: !t.active };
      } else {
        return t;
      }
    });
    setLevelData(newLevelData);
  };


  

  const handleAddActivitiesChange = (e, i) => {
    const { value } = e.target;
    setActivitiesChange(e.target.value);
    const newActivitiesData = activitiesData.map((p, id) => {
      if (id === i) {
        return { ...p, name: value };
      } else {
      return p;
      }
    });
    setActivitiesData(newActivitiesData);
  };

  const handleAddActivitiesDisabled = (i) => {
    const newActivitiesData = activitiesData.map((p, id) => {
      if (id === i) {
        return { ...p, active: !p.active };
      } else {
        return p;
      }
    });
    setActivitiesData(newActivitiesData);
  };

  const handleSaveCriteria = (e) => {
    let obj = {
      list_name: "query_criteria",
      name: {...queryData},
    };
    GetAdminService.saveCriteria(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleSaveReason = () => {
    let obj = {
      list_name: "reasontocontact",
      name: {...reasonData},
    };
    GetAdminService.saveCriteria(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleSaveManual = () => {
    let obj = {
      list_name: "query_criteriamanual",
      name: {...manualData},
    };
    GetAdminService.saveCriteria(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleSaveGender = () => {
    let obj = {
      list_name: "gender",
      name: {...genderData},
    };
    GetAdminService.saveCriteria(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleSaveEthnicity = () => {
    let obj = {
      list_name: "ethnicity",
      name: {...ethnicityData},
    };
    GetAdminService.saveCriteria(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleSaveInterest = () => {
    let obj = {
      list_name: "intrest",
      // name: interestChange,
       name: {...interestData},
    };
    GetAdminService.saveCriteria(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleSaveAcademics = () => {
    let obj = {
      list_name: "academics",
      // name: academicsChange,
      name: {...academicsData},
    };
    GetAdminService.saveCriteria(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleSaveActivities = () => {
    let obj = {
      list_name: "activities",
      // name: activitiesChange,
      name: {...activitiesData},
    };
    GetAdminService.saveCriteria(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleSaveAthletics = () => {
    let obj = {
      list_name: "athletics",
      // name: athleticsChange,
      name: {...athleticsData},
    };
    GetAdminService.saveCriteria(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleSaveCountry = () => {
    let obj = {
      list_name: "country",
      // name: athleticsChange,
      name: {...countryData},
    };
    GetAdminService.saveCriteria(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleSaveCertificate = () => {
    let obj = {
      list_name: "board certification",
      // name: athleticsChange,
      name: {...certificateData},
    };
    GetAdminService.saveCriteria(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleSaveEnglish = () => {
    let obj = {
      list_name: "english test",
      // name: athleticsChange,
      name: {...englishData},
    };
    GetAdminService.saveCriteria(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const handleSaveLevel = () => {
    let obj = {
      list_name: "desired degree",
      // name: athleticsChange,
      name: {...levelData},
    };
    GetAdminService.saveCriteria(obj).then((data) => {
      console.log("data from the service get Course data", data);
      cancelAction();
    });
  };

  const getCourseList = (value) => {
    setValueNameLoading(true);
    GetAdminService.getCourseList(value)
      .then((data) => {
        console.log("data from the service", data);
        setValueNameList(data?.data?.objects);

        setValueNameLoading(false);
        // setShowSchoolNameContainer(true);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setValueNameLoading(false);
      });
  };

  const handleChangeConcentration = (index) => {
    clearTimeout(timeoutRef.current);
    setValueName(valueNameRef.current.value);
    const arr = courseData.map((s, id) => {
      if (id === index) {
        return { ...s, goal: valueNameRef.current.value };
      } else {
        return s;
      }
    });
    setCourseData([...arr]);
    if (!valueNameRef.current.value) {
      // setShowSchoolNameContainer(false);
      setValueNameList([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getCourseList(valueNameRef.current.value);
      }, 1000);
    }
  };

  const handleAddCourse = () => {
    console.log(courseData);
    setCourseData([...courseData, { name: "", goal: "", goals: "", id: "" }]);
  };

  const handleAddConcentrationChange = (e, i) => {
    const { value } = e.target;
    console.log(i, courseData);
    setCourseChange(e.target.value);
    const arr = courseData.map((s, id) => {
      if (id === i) {
        return { ...s, name: value };
      } else {
        return s;
      }
    });
    setCourseData([...arr]);
  };

  // const handleSaveCourse = () => {
  //   let obj = {
  //     list_name: "concentration_name",
  //     name: { ...courseData },
  //   };
  //   GetAdminService.saveCourse(obj).then((data) => {
  //     console.log("data from the service get Course data", data);
  //     cancelAction();
  //   });
  // };

  const handleGoalType = (e, index) => {
    let arr = goalTypeData;
    arr[index].goalfield_name = e.target.value;
    setGoalTypeData(arr);
    setGoalFieldName(e.target.value);
  };

  const handleExpressionName = (e, index) => {
    let arr = expressionData;
    arr[index].expression_name = e.target.value;
    setExpressionData(arr);
    setExpressionName(e.target.value);
  };

  const handleOperatorChange = (e, index) => {
    let arr = operatorData;
    arr[index].operator_name = e.target.value;
    setExpressionData(arr);
    setOperatorNm(e.target.value);
  };

  const handleOperatorChange1 = (e, index) => {
    let arr = operatorData;
    arr[index].operator = e.target.value;
    setExpressionData(arr);
    setOperatorValue(e.target.value);
  };

  const handleArithematicChange = (e, index) => {
    let arr = arithemeticData;
    arr[index].operator = e.target.value;
    setArithemeticData(arr);
    setArithemeticName(e.target.value);
  };

  const handleDataType = (e, index) => {
    let arr = typeData;
    arr[index].field_type = e.target.value;
    setTypeData(arr);
    setFieldName(e.target.value);
  };

  useEffect(() => {
    goalFieldData();
    goalExpresssionData();
    operatorShowData();
    arithemeticShowData();
    typeShowData();
    queryCriteriasShowData();
  }, []);

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="admin-canned-container">
            <div className="flex canned-message-heading">
              <p>
                <span style={{ fontSize: "25px", fontWeight: "400" }}>
                  Configurations
                </span>
              </p>
            </div>
            <div className="school-name-container">
              <p style={{ fontSize: "18px" }}>
                Configuration for Student Progress
              </p>
              <div style={{ marginTop: "40px" }}>
                <p className="shade" onClick={goalType}>
                  Add Goal Field Type
                  <FontAwesomeIcon
                    icon={!goalNameType ? faPlusCircle : faMinusCircle}
                    onClick={goalType}
                    className="admin-config-icon"
                    // style={{ marginLeft: "276px", cursor: "pointer" }}
                  />
                </p>
                <Collapse in={goalNameType}>
                  <div id="collapsePanel">
                    <div className="inner">
                      <div className="box">
                        {goalTypeData?.map((item, index) => (
                          <div>
                            <label>Goal Field Name</label>
                            &emsp;
                            <input
                              type="text"
                              className="input-border"
                              placeholder="Type a goal field name"
                              style={
                                item.disabled
                                  ? {
                                      cursor: "no-drop",
                                      backgroundColor: "lightgray",
                                    }
                                  : null
                              }
                              onChange={(e) => handleGoalType(e, index)}
                              disabled={item.disabled}
                              value={item?.goalfield_name}
                            />
                            <FontAwesomeIcon
                              icon={faAsterisk}
                              style={{ marginLeft: "45px", width: "10px" }}
                              className="star-mendatory"
                            />
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{
                                marginLeft: "45px",
                                width: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => goalFieldDelete(item?.id)}
                              className="star-mendatory"
                            />
                          </div>
                        ))}
                        <button
                          className="add-button"
                          onClick={handleAddGoalType}
                          // disabled={goalTypeData[goalTypeData.length -1]?.goalfield_name}
                        >
                          <FontAwesomeIcon icon={faPlus} /> Add Type
                        </button>
                        <br />
                        <button
                          className="save-button"
                          onClick={handleSaveType}
                          // disabled={goalTypeData[goalTypeData.length -1]?.goalfield_name}
                        >
                          <FontAwesomeIcon icon={faSave} /> Save
                        </button>
                        &emsp;
                        <button
                          className="cancel-button-config"
                          onClick={() => cancelAction()}
                        >
                          <FontAwesomeIcon icon={faTimes} /> Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Collapse>

                <br />
                <p className="shade" onClick={goalName}>
                  Add Expression Name
                  <FontAwesomeIcon
                    icon={!name ? faPlusCircle : faMinusCircle}
                    onClick={goalName}
                    className="admin-config-icon"
                    // style={{ marginLeft: "263px", cursor: "pointer" }}
                  />
                </p>
                <Collapse in={name}>
                  <div id="collapsePanel">
                    <div className="inner">
                      <div className="box">
                        {expressionData?.map((item, index) => (
                          <div>
                            <label>Add Expression Name</label>
                            &emsp;
                            <input
                              type="text"
                              placeholder="Type a expression name"
                              className="input-border"
                              style={
                                item.disabled
                                  ? {
                                      cursor: "no-drop",
                                      backgroundColor: "lightgray",
                                    }
                                  : null
                              }
                              onChange={(e) => handleExpressionName(e, index)}
                              disabled={item.disabled}
                              value={item?.expression_name}
                            />
                            <FontAwesomeIcon
                              icon={faAsterisk}
                              style={{ marginLeft: "45px", width: "10px" }}
                              className="star-mendatory"
                            />
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{
                                marginLeft: "45px",
                                width: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => goalExpresssionDelete(item?.id)}
                              className="star-mendatory"
                            />
                          </div>
                        ))}
                        <button
                          className="add-button"
                          onClick={handleAddExpression}
                          // disabled={!expressionData[expressionData.length -1]?.expression_name}
                        >
                          <FontAwesomeIcon icon={faPlus} /> Add Expression
                        </button>
                        <br />
                        <button
                          className="save-button"
                          onClick={handleSaveExpression}
                          // disabled={!expressionData[expressionData.length -1]?.expression_name}
                        >
                          <FontAwesomeIcon icon={faSave} /> Save
                        </button>
                        &emsp;
                        <button
                          className="cancel-button-config"
                          onClick={() => cancelAction()}
                        >
                          <FontAwesomeIcon icon={faTimes} /> Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Collapse>

                <br />
                <p className="shade" onClick={operatorName}>
                  Add Logical Operators
                  <FontAwesomeIcon
                    icon={!operator ? faPlusCircle : faMinusCircle}
                    onClick={operatorName}
                    className="admin-config-icon"
                    // style={{ marginLeft: "259px", cursor: "pointer" }}
                  />
                </p>
                <Collapse in={operator}>
                  <div id="collapsePanel">
                    <div className="inner">
                      <div className="box">
                        {operatorData?.map((item, index) => (
                          <div>
                            <label>Operator</label>
                            &emsp;
                            <input
                              type="text"
                              placeholder="Type a operator name"
                              className="input-border"
                              style={
                                item.disabled
                                  ? {
                                      cursor: "no-drop",
                                      backgroundColor: "lightgray",
                                      width: "138px",
                                    }
                                  : {
                                      width: "138px",
                                    }
                              }
                              onChange={(e) => handleOperatorChange(e, index)}
                              disabled={item.disabled}
                              value={item?.operator_name}
                            />
                            &emsp;
                            <input
                              type="text"
                              placeholder="Type a operator"
                              className="input-border"
                              style={
                                item.disabled
                                  ? {
                                      cursor: "no-drop",
                                      backgroundColor: "lightgray",
                                      width: "138px",
                                    }
                                  : {
                                      width: "138px",
                                    }
                              }
                              onChange={(e) => handleOperatorChange1(e, index)}
                              disabled={item.disabled}
                              value={item?.operator}
                            />
                            <FontAwesomeIcon
                              icon={faAsterisk}
                              style={{ marginLeft: "45px", width: "10px" }}
                              className="star-mendatory"
                            />
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{
                                marginLeft: "45px",
                                width: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => goalOperatorDelete(item?.id)}
                              className="star-mendatory"
                            />
                          </div>
                        ))}
                        <button
                          className="add-button"
                          onClick={handleAddOperator}
                          // disabled={!(operatorData[operatorData.length -1]?.operator_name && operatorData[operatorData.length -1]?.operator)}
                        >
                          <FontAwesomeIcon icon={faPlus} /> Add Operator
                        </button>
                        <br />
                        <button
                          className="save-button"
                          onClick={handleOperatorSave}
                          // disabled={!(operatorData[operatorData.length -1]?.operator_name && operatorData[operatorData.length -1]?.operator)}
                        >
                          <FontAwesomeIcon icon={faSave} /> Save
                        </button>
                        &emsp;
                        <button
                          className="cancel-button-config"
                          onClick={() => cancelAction()}
                        >
                          <FontAwesomeIcon icon={faTimes} /> Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Collapse>

                <br />
                <p className="shade" onClick={arithemeticOperators}>
                  Add Arithemetic Operators
                  <FontAwesomeIcon
                    icon={!arithemeticOperator ? faPlusCircle : faMinusCircle}
                    onClick={arithemeticOperators}
                    className="admin-config-icon"
                    // style={{ marginLeft: "223px", cursor: "pointer" }}
                  />
                </p>
                <Collapse in={arithemeticOperator}>
                  <div id="collapsePanel">
                    <div className="inner">
                      <div className="box">
                        {arithemeticData?.map((item, index) => (
                          <div>
                            <label>Arithemetic Operator</label>
                            &emsp;
                            <input
                              type="text"
                              placeholder="Type an operator name"
                              className="input-border"
                              style={
                                item.disabled
                                  ? {
                                      cursor: "no-drop",
                                      backgroundColor: "lightgray",
                                    }
                                  : null
                              }
                              onChange={(e) =>
                                handleArithematicChange(e, index)
                              }
                              disabled={item.disabled}
                              value={item?.operator}
                            />
                            <FontAwesomeIcon
                              icon={faAsterisk}
                              style={{ marginLeft: "45px", width: "10px" }}
                              className="star-mendatory"
                            />
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{
                                marginLeft: "45px",
                                width: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => arithematicDelete(item?.id)}
                              className="star-mendatory"
                            />
                          </div>
                        ))}
                        <button
                          className="add-button"
                          onClick={handleArithmeticOpt}
                          // disabled={!arithemeticData[arithemeticData.length -1]?.operator}
                        >
                          <FontAwesomeIcon icon={faPlus} /> Add Operator
                        </button>
                        <br />
                        <button
                          className="save-button"
                          onClick={handleSaveArthOperator}
                          // disabled={!arithemeticData[arithemeticData.length -1]?.operator}
                        >
                          <FontAwesomeIcon icon={faSave} /> Save
                        </button>
                        &emsp;
                        <button
                          className="cancel-button-config"
                          onClick={() => cancelAction()}
                        >
                          <FontAwesomeIcon icon={faTimes} /> Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Collapse>
                <br />

                <p className="shade" onClick={dataTypeName}>
                  Add Data Type
                  <FontAwesomeIcon
                    icon={!dataType ? faPlusCircle : faMinusCircle}
                    onClick={dataTypeName}
                    className="admin-config-icon"
                    // style={{ marginLeft: "313px", cursor: "pointer" }}
                  />
                </p>
                <Collapse in={dataType}>
                  <div id="collapsePanel">
                    <div className="inner">
                      <div className="box">
                        {typeData?.map((item, index) => (
                          <div>
                            <label>Field Type</label>
                            &emsp;
                            <input
                              type="text"
                              placeholder="Type a field type"
                              className="input-border"
                              style={
                                item.disabled
                                  ? {
                                      cursor: "no-drop",
                                      backgroundColor: "lightgray",
                                    }
                                  : null
                              }
                              onChange={(e) => handleDataType(e, index)}
                              disabled={item.disabled}
                              value={item?.field_type}
                            />
                            <FontAwesomeIcon
                              icon={faAsterisk}
                              style={{ marginLeft: "45px", width: "10px" }}
                              className="star-mendatory"
                            />
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{
                                marginLeft: "45px",
                                width: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() =>  typeDelete(item.id)}
                              className="star-mendatory"
                            />
                          </div>
                        ))}
                        <button
                          className="add-button"
                          onClick={handleAddField}
                          // disabled={!typeData[typeData.length -1]?.field_type}
                        >
                          <FontAwesomeIcon icon={faPlus} /> Add Expression
                        </button>
                        <br />
                        <button
                          className="save-button"
                          onClick={handleSaveField}
                          // disabled={!typeData[typeData.length -1]?.field_type}
                        >
                          <FontAwesomeIcon icon={faSave} /> Save
                        </button>
                        &emsp;
                        <button
                          className="cancel-button-config"
                          onClick={() => cancelAction()}
                        >
                          <FontAwesomeIcon icon={faTimes} /> Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Collapse>
                <br />

                <p style={{ fontSize: "18px" }}>
                  Configuration for Student Data Source
                </p>
                <div style={{ marginTop: "40px" }}>
                  <p className="shade" onClick={queryCriterias}>
                    Add Query Criterias
                    <FontAwesomeIcon
                      icon={!query ? faPlusCircle : faMinusCircle}
                      onClick={queryCriterias}
                      className="admin-config-icon"
                      // style={{ marginLeft: "279px", cursor: "pointer" }}
                    />
                  </p>
                  <Collapse in={query}>
                    <div id="collapsePanel">
                      <div className="inner">
                        <div className="box">
                          {queryData?.map((item, index) => (
                            <div>
                              <label>Query Criterias</label>
                              &emsp;
                              <input
                                type="text"
                                placeholder="Type a new value"
                                className="input-border"
                                style={
                                  !item.active
                                    ? {
                                      cursor: "no-drop",
                                      backgroundColor: "lightgray",
                                    }
                                    : null
                                }
                                disabled={item.active ? false : true}
                                onChange={(e) => handleAddQueryChange(e, index)}
                                value={item?.name}
                              />
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                style={{ marginLeft: "45px", width: "10px" }}
                                className="star-mendatory"
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  marginLeft: "45px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => queryDelete(item?.id)}
                                className="star-mendatory"
                              />
                            </div>
                          ))}
                          <button
                            className="add-button"
                            onClick={handleAddQuery}
                            // disabled={!queryChange}
                            // disabled={queryData[queryData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Criteria
                          </button>
                          <br />
                          <button
                            className="save-button"
                            onClick={handleSaveCriteria}
                            // disabled={!queryChange}
                            // disabled={queryData[queryData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faSave} /> Save
                          </button>
                          &emsp;
                          <button
                            className="cancel-button-config"
                            onClick={() => cancelAction()}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                  <br />

                  <p className="shade" onClick={contactReason}>
                    Add Reason to Contact (For Recruiters)
                    <FontAwesomeIcon
                      icon={!reason ? faPlusCircle : faMinusCircle}
                      onClick={contactReason}
                      className="admin-config-icon"
                      // style={{ marginLeft: "135px", cursor: "pointer" }}
                    />
                  </p>
                  <Collapse in={reason}>
                    <div id="collapsePanel">
                      <div className="inner">
                        <div className="box">
                          {reasonData?.map((item, index) => (
                            <div>
                              <label>Reason</label>
                              &emsp;
                              <input
                                type="text"
                                placeholder="Type a new value"
                                className="input-border"
                                onChange={(e) =>
                                  handleAddReasonChange(e, index)
                                }
                                disabled={item.active ? false : true}
                                value={item?.name}
                              />
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                style={{ marginLeft: "45px", width: "10px" }}
                                className="star-mendatory"
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  marginLeft: "45px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => reasonDelete(item?.id)}
                                className="star-mendatory"
                              />
                            </div>
                          ))}
                          <button
                            className="add-button"
                            onClick={handleAddReason}
                            // disabled={reasonData[reasonData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Reason
                          </button>
                          <br />
                          <button
                            className="save-button"
                            onClick={handleSaveReason}
                            // disabled={reasonData[reasonData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faSave} /> Save
                          </button>
                          &emsp;
                          <button
                            className="cancel-button-config"
                            onClick={() => cancelAction()}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>

                  <br />
                  <p className="shade" onClick={manualName}>
                    Add Query Criterias Manual
                    <FontAwesomeIcon
                      icon={!manual ? faPlusCircle : faMinusCircle}
                      onClick={manualName}
                      // style={{ marginLeft: "221px", cursor: "pointer" }}
                      className="admin-config-icon"
                    />
                  </p>
                  <Collapse in={manual}>
                    <div id="collapsePanel">
                      <div className="inner">
                        <div className="box">
                          {manualData?.map((item, index) => (
                            <div>
                              <label>Query Criteria Manual</label>
                              &emsp;
                              <input
                                type="text"
                                placeholder="Type a new value"
                                className="input-border"
                                // style={
                                //   item.active
                                //     ? null
                                //     : {
                                //       cursor: "no-drop",
                                //       backgroundColor: "lightgray",
                                //     }
                                // }
                                disabled={item.active ? false : true}
                                onChange={(e) =>
                                  handleAddManualChange(e, index)
                                }
                                value={item?.name}
                              />
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                style={{ marginLeft: "45px", width: "10px" }}
                                className="star-mendatory"
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  marginLeft: "45px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => manualDelete(item?.id)}
                                className="star-mendatory"
                              />
                            </div>
                          ))}
                          <button
                            className="add-button"
                            onClick={handleAddManual}
                            // disabled={manualData[manualData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Query Criteria
                            Manual
                          </button>
                          <br />
                          <button
                            className="save-button"
                            onClick={handleSaveManual}
                            // disabled={manualData[manualData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faSave} /> Save
                          </button>
                          &emsp;
                          <button
                            className="cancel-button-config"
                            onClick={() => cancelAction()}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>

                  <br />
                  <p className="shade" onClick={genderName}>
                    Add Gender
                    <FontAwesomeIcon
                      icon={!gender ? faPlusCircle : faMinusCircle}
                      onClick={genderName}
                      // style={{ marginLeft: "335px", cursor: "pointer" }}
                      className="admin-config-icon"
                    />
                  </p>
                  <Collapse in={gender}>
                    <div id="collapsePanel">
                      <div className="inner">
                        <div className="box">
                          {genderData?.map((item, index) => (
                            <div>
                              <label>Gender Value</label>
                              &emsp;
                              <input
                                type="text"
                                placeholder="Type a new value"
                                className="input-border"
                                style={
                                  item.active
                                    ? null
                                    : {
                                        cursor: "no-drop",
                                        backgroundColor: "lightgray",
                                      }
                                }
                                onChange={(e) =>
                                  handleAddGenderChange(e, index)
                                }
                                disabled={item.active ? false : true}
                                value={item?.name}
                              />
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                style={{ marginLeft: "45px", width: "10px" }}
                                className="star-mendatory"
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  marginLeft: "45px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => genderDelete(item?.id)}
                                className="star-mendatory"
                              />
                            </div>
                          ))}
                          <button
                            className="add-button"
                            onClick={handleAddGender}
                            // disabled={genderData[genderData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Gender
                          </button>
                          <br />
                          <button
                            className="save-button"
                            onClick={handleSaveGender}
                            // disabled={genderData[genderData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faSave} /> Save
                          </button>
                          &emsp;
                          <button
                            className="cancel-button-config"
                            onClick={() => cancelAction()}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>

                  <br />
                  <p className="shade" onClick={ethnicityName}>
                    Add Ethnicity
                    <FontAwesomeIcon
                      icon={!ethnicity ? faPlusCircle : faMinusCircle}
                      onClick={ethnicityName}
                      // style={{ marginLeft: "324px", cursor: "pointer" }}
                      className="admin-config-icon"
                    />
                  </p>
                  <Collapse in={ethnicity}>
                    <div id="collapsePanel">
                      <div className="inner">
                        <div className="box">
                          {ethnicityData?.map((item, index) => (
                            <div>
                              <label>Ethnicity Value</label>
                              &emsp;
                              <input
                                type="text"
                                placeholder="Type a new value"
                                className="input-border"
                                style={
                                  item.active
                                    ? null
                                    : {
                                        cursor: "no-drop",
                                        backgroundColor: "lightgray",
                                      }
                                }
                                onChange={(e) =>
                                  handleAddEthnicityChange(e, index)
                                }
                                disabled={item.active ? false : true}
                                value={item?.name}
                              />
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                style={{ marginLeft: "45px", width: "10px" }}
                                className="star-mendatory"
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  marginLeft: "45px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => ethnicityDelete(item?.id)}
                                className="star-mendatory"
                              />
                            </div>
                          ))}
                          <button
                            className="add-button"
                            onClick={handleAddEthnicity}
                            // disabled={ethnicityData[ethnicityData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Ethnicity
                          </button>
                          <br />
                          <button
                            className="save-button"
                            onClick={handleSaveEthnicity}
                            // disabled={ethnicityData[ethnicityData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faSave} /> Save
                          </button>
                          &emsp;
                          <button
                            className="cancel-button-config"
                            onClick={() => cancelAction()}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>

                  <br />
                  <p className="shade" onClick={interestName}>
                    Add Interests
                    <FontAwesomeIcon
                      icon={!interest ? faPlusCircle : faMinusCircle}
                      onClick={interestName}
                      // style={{ marginLeft: "325px", cursor: "pointer" }}
                      className="admin-config-icon"
                    />
                  </p>
                  <Collapse in={interest}>
                    <div id="collapsePanel">
                      <div className="inner">
                        <div className="box">
                          {interestData?.map((item, index) => (
                            <div>
                              <label>Interest Value</label>
                              &emsp;
                              <input
                                type="text"
                                placeholder="Type a new value"
                                className="input-border"
                                style={
                                  item.active
                                    ? null
                                    : {
                                        cursor: "no-drop",
                                        backgroundColor: "lightgray",
                                      }
                                }
                                onChange={(e) =>
                                  handleAddInterestChange(e, index)
                                }
                                disabled={item.active ? false : true}
                                value={item?.name}
                              />
                              &emsp;
                              <button
                                className={
                                  !item.active
                                    ? "cancel-button-config"
                                    : "save-button"
                                }
                                onClick={() => handleAddInterestDisabled(index)}
                              >
                                {item.active ? (
                                  <span>
                                    <FontAwesomeIcon icon={faCheckCircle} />{" "}
                                    Enabled
                                  </span>
                                ) : (
                                  <span>
                                    <FontAwesomeIcon icon={faTimesCircle} />{" "}
                                    Disabled
                                  </span>
                                )}
                              </button>
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                style={{ marginLeft: "30px", width: "10px" }}
                                className="star-mendatory"
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  marginLeft: "45px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => interestDelete(item?.id)}
                                className="star-mendatory"
                              />
                            </div>
                          ))}
                          <button
                            className="add-button"
                            onClick={handleAddInterest}
                            // disabled={interestData[interestData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Interest
                          </button>
                          <br />
                          <button
                            className="save-button"
                            onClick={handleSaveInterest}
                            // disabled={interestData[interestData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faSave} /> Save
                          </button>
                          &emsp;
                          <button
                            className="cancel-button-config"
                            onClick={() => cancelAction()}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>

                  <br />
                  <p className="shade" onClick={academicsName}>
                    Add Academics
                    <FontAwesomeIcon
                      icon={!academics ? faPlusCircle : faMinusCircle}
                      onClick={academicsName}
                      className="admin-config-icon"
                      // style={{ marginLeft: "306px", cursor: "pointer" }}
                    />
                  </p>
                  <Collapse in={academics}>
                    <div id="collapsePanel">
                      <div className="inner">
                        <div className="box">
                          {academicsData?.map((item, index) => (
                            <div>
                              <label>Academics Value</label>
                              &emsp;
                              <input
                                type="text"
                                placeholder="Type a new value"
                                className="input-border"
                                style={
                                  item.active
                                    ? null
                                    : {
                                        cursor: "no-drop",
                                        backgroundColor: "lightgray",
                                      }
                                }
                                onChange={(e) =>
                                  handleAddAcademicsChange(e, index)
                                }
                                disabled={item.active ? false : true}
                                value={item?.name}
                              />
                              &emsp;
                              <button
                                className={
                                  !item.active
                                    ? "cancel-button-config"
                                    : "save-button"
                                }
                                onClick={() =>
                                  handleAddAcademicsDisabled(index)
                                }
                              >
                                {item.active ? (
                                  <span>
                                    <FontAwesomeIcon icon={faCheckCircle} />{" "}
                                    Enabled
                                  </span>
                                ) : (
                                  <span>
                                    <FontAwesomeIcon icon={faTimesCircle} />{" "}
                                    Disabled
                                  </span>
                                )}
                              </button>
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                style={{ marginLeft: "20px", width: "10px" }}
                                className="star-mendatory"
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  marginLeft: "45px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => academicsDelete(item?.id)}
                                className="star-mendatory"
                              />
                            </div>
                          ))}
                          <button
                            className="add-button"
                            onClick={handleAddAcademics}
                            // disabled={academicsData[academicsData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Academics
                          </button>
                          <br />
                          <button
                            className="save-button"
                            onClick={handleSaveAcademics}
                            // disabled={academicsData[academicsData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faSave} /> Save
                          </button>
                          &emsp;
                          <button
                            className="cancel-button-config"
                            onClick={() => cancelAction()}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>

                  <br />
                  <p className="shade" onClick={activitiesName}>
                    Add Activities
                    <FontAwesomeIcon
                      icon={!activities ? faPlusCircle : faMinusCircle}
                      onClick={activitiesName}
                      className="admin-config-icon"
                      // style={{ marginLeft: "320px", cursor: "pointer" }}
                    />
                  </p>
                  <Collapse in={activities}>
                    <div id="collapsePanel">
                      <div className="inner">
                        <div className="box">
                          {activitiesData?.map((item, index) => (
                            <div>
                              <label>Activities Value</label>
                              &emsp;
                              <input
                                type="text"
                                placeholder="Type a new value"
                                className="input-border"
                                style={
                                  item.active
                                    ? null
                                    : {
                                        cursor: "no-drop",
                                        backgroundColor: "lightgray",
                                      }
                                }
                                onChange={(e) =>
                                  handleAddActivitiesChange(e, index)
                                }
                                disabled={item.active ? false : true}
                                value={item?.name}
                              />
                              &emsp;
                              <button
                                className={
                                  !item.active
                                    ? "cancel-button-config"
                                    : "save-button"
                                }
                                onClick={() =>
                                  handleAddActivitiesDisabled(index)
                                }
                              >
                                {item.active ? (
                                  <span>
                                    <FontAwesomeIcon icon={faCheckCircle} />{" "}
                                    Enabled
                                  </span>
                                ) : (
                                  <span>
                                    <FontAwesomeIcon icon={faTimesCircle} />{" "}
                                    Disabled
                                  </span>
                                )}
                              </button>
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                style={{ marginLeft: "30px", width: "10px" }}
                                className="star-mendatory"
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  marginLeft: "45px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => activitiesDelete(item?.id)}
                                className="star-mendatory"
                              />
                            </div>
                          ))}
                          <button
                            className="add-button"
                            onClick={handleAddActivities}
                            // disabled={activitiesData[activitiesData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Activities
                          </button>
                          <br />
                          <button
                            className="save-button"
                            onClick={handleSaveActivities}
                            // disabled={activitiesData[activitiesData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faSave} /> Save
                          </button>
                          &emsp;
                          <button
                            className="cancel-button-config"
                            onClick={() => cancelAction()}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>

                  <br />
                  <p className="shade" onClick={athleticsName}>
                    Add Athletics
                    <FontAwesomeIcon
                      icon={!athletics ? faPlusCircle : faMinusCircle}
                      onClick={athleticsName}
                      className="admin-config-icon"
                      // style={{ marginLeft: "324px", cursor: "pointer" }}
                    />
                  </p>
                  <Collapse in={athletics}>
                    <div id="collapsePanel">
                      <div className="inner">
                        <div className="box">
                          {athleticsData?.map((item, index) => (
                            <div>
                              <label>Athletics Value</label>
                              &emsp;
                              <input
                                type="text"
                                placeholder="Type a new value"
                                className="input-border"
                                style={
                                  item.active
                                    ? null
                                    : {
                                        cursor: "no-drop",
                                        backgroundColor: "lightgray",
                                      }
                                }
                                onChange={(e) =>
                                  handleAddAthleticsChange(e, index)
                                }
                                disabled={item.active ? false : true}
                                value={item?.name}
                              />
                              &emsp;
                              <button
                                className={
                                  !item.active
                                    ? "cancel-button-config"
                                    : "save-button"
                                }
                                onClick={() =>
                                  handleAddAthleticsDisabled(index)
                                }
                              >
                                {item.active ? (
                                  <span>
                                    <FontAwesomeIcon icon={faCheckCircle} />{" "}
                                    Enabled
                                  </span>
                                ) : (
                                  <span>
                                    <FontAwesomeIcon icon={faTimesCircle} />{" "}
                                    Disabled
                                  </span>
                                )}
                              </button>
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                style={{ marginLeft: "30px", width: "10px" }}
                                className="star-mendatory"
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  marginLeft: "45px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => athleticsDelete(item?.id)}
                                className="star-mendatory"
                              />
                            </div>
                          ))}
                          <button
                            className="add-button"
                            onClick={handleAddAthletics}
                            // disabled={athleticsData [athleticsData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Athletics
                          </button>
                          <br />
                          <button
                            className="save-button"
                            onClick={handleSaveAthletics}
                            // disabled={athleticsData [athleticsData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faSave} /> Save
                          </button>
                          &emsp;
                          <button
                            className="cancel-button-config"
                            onClick={() => cancelAction()}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                  <br />

                  {/* International */}

                  {/* <p className="shade" onClick={countryName}>
                    Add Country
                    <FontAwesomeIcon
                      icon={!country ? faPlusCircle : faMinusCircle}
                      onClick={countryName}
                      className="admin-config-icon"
                      // style={{ marginLeft: "324px", cursor: "pointer" }}
                    />
                  </p>
                  <Collapse in={country}>
                    <div id="collapsePanel">
                      <div className="inner">
                        <div className="box">
                          {countryData?.map((item, index) => (
                            <div>
                              <label>Country Value</label>
                              &emsp;
                              <input
                                type="text"
                                placeholder="Type a new value"
                                className="input-border"
                                style={
                                  item.active
                                    ? null
                                    : {
                                        cursor: "no-drop",
                                        backgroundColor: "lightgray",
                                      }
                                }
                                onChange={(e) =>
                                  handleAddCountryChange(e, index)
                                }
                                disabled={item.active ? false : true}
                                value={item?.name}
                              />
                              &emsp;
                              <button
                                className={
                                  !item.active
                                    ? "cancel-button-config"
                                    : "save-button"
                                }
                                onClick={() =>
                                  handleAddCountryDisabled(index)
                                }
                              >
                                {item.active ? (
                                  <span>
                                    <FontAwesomeIcon icon={faCheckCircle} />{" "}
                                    Enabled
                                  </span>
                                ) : (
                                  <span>
                                    <FontAwesomeIcon icon={faTimesCircle} />{" "}
                                    Disabled
                                  </span>
                                )}
                              </button>
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                style={{ marginLeft: "30px", width: "10px" }}
                                className="star-mendatory"
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  marginLeft: "45px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => countryDelete(item?.id)}
                                className="star-mendatory"
                              />
                            </div>
                          ))}
                          <button
                            className="add-button"
                            onClick={handleAddCountry}
                            // disabled={athleticsData [athleticsData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add countries
                          </button>
                          <br />
                          <button
                            className="save-button"
                            onClick={handleSaveCountry}
                            // disabled={athleticsData [athleticsData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faSave} /> Save
                          </button>
                          &emsp;
                          <button
                            className="cancel-button-config"
                            onClick={() => cancelAction()}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                  <br />

                  <p className="shade" onClick={certificateName}>
                    Add Board Certification
                    <FontAwesomeIcon
                      icon={!certificate ? faPlusCircle : faMinusCircle}
                      onClick={certificateName}
                      className="admin-config-icon"
                      // style={{ marginLeft: "324px", cursor: "pointer" }}
                    />
                  </p>
                  <Collapse in={certificate}>
                    <div id="collapsePanel">
                      <div className="inner">
                        <div className="box">
                          {certificateData?.map((item, index) => (
                            <div>
                              <label>Board Certificate Value</label>
                              &emsp;
                              <input
                                type="text"
                                placeholder="Type a new value"
                                className="input-border"
                                style={
                                  item.active
                                    ? null
                                    : {
                                        cursor: "no-drop",
                                        backgroundColor: "lightgray",
                                      }
                                }
                                onChange={(e) =>
                                  handleAddCertificateChange(e, index)
                                }
                                disabled={item.active ? false : true}
                                value={item?.name}
                              />
                              &emsp;
                              <button
                                className={
                                  !item.active
                                    ? "cancel-button-config"
                                    : "save-button"
                                }
                                onClick={() =>
                                  handleAddCertificateDisabled(index)
                                }
                              >
                                {item.active ? (
                                  <span>
                                    <FontAwesomeIcon icon={faCheckCircle} />{" "}
                                    Enabled
                                  </span>
                                ) : (
                                  <span>
                                    <FontAwesomeIcon icon={faTimesCircle} />{" "}
                                    Disabled
                                  </span>
                                )}
                              </button>
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                style={{ marginLeft: "30px", width: "10px" }}
                                className="star-mendatory"
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  marginLeft: "45px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => certificateDelete(item?.id)}
                                className="star-mendatory"
                              />
                            </div>
                          ))}
                          <button
                            className="add-button"
                            onClick={handleAddCertificate}
                            // disabled={athleticsData [athleticsData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Board Certification
                          </button>
                          <br />
                          <button
                            className="save-button"
                            onClick={handleSaveCertificate}
                            // disabled={athleticsData [athleticsData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faSave} /> Save
                          </button>
                          &emsp;
                          <button
                            className="cancel-button-config"
                            onClick={() => cancelAction()}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                  <br />

                  <p className="shade" onClick={englishName}>
                    Add English Test
                    <FontAwesomeIcon
                      icon={!english ? faPlusCircle : faMinusCircle}
                      onClick={englishName}
                      className="admin-config-icon"
                      // style={{ marginLeft: "324px", cursor: "pointer" }}
                    />
                  </p>
                  <Collapse in={english}>
                    <div id="collapsePanel">
                      <div className="inner">
                        <div className="box">
                          {englishData?.map((item, index) => (
                            <div>
                              <label>English Test Value</label>
                              &emsp;
                              <input
                                type="text"
                                placeholder="Type a new value"
                                className="input-border"
                                style={
                                  item.active
                                    ? null
                                    : {
                                        cursor: "no-drop",
                                        backgroundColor: "lightgray",
                                      }
                                }
                                onChange={(e) =>
                                  handleAddEnglishChange(e, index)
                                }
                                disabled={item.active ? false : true}
                                value={item?.name}
                              />
                              &emsp;
                              <button
                                className={
                                  !item.active
                                    ? "cancel-button-config"
                                    : "save-button"
                                }
                                onClick={() =>
                                  handleAddEnglishDisabled(index)
                                }
                              >
                                {item.active ? (
                                  <span>
                                    <FontAwesomeIcon icon={faCheckCircle} />{" "}
                                    Enabled
                                  </span>
                                ) : (
                                  <span>
                                    <FontAwesomeIcon icon={faTimesCircle} />{" "}
                                    Disabled
                                  </span>
                                )}
                              </button>
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                style={{ marginLeft: "30px", width: "10px" }}
                                className="star-mendatory"
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  marginLeft: "45px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => englishDelete(item?.id)}
                                className="star-mendatory"
                              />
                            </div>
                          ))}
                          <button
                            className="add-button"
                            onClick={handleAddEnglish}
                            // disabled={athleticsData [athleticsData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add English Test
                          </button>
                          <br />
                          <button
                            className="save-button"
                            onClick={handleSaveEnglish}
                            // disabled={athleticsData [athleticsData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faSave} /> Save
                          </button>
                          &emsp;
                          <button
                            className="cancel-button-config"
                            onClick={() => cancelAction()}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                  <br />

                  <p className="shade" onClick={levelName}>
                      Add Desired Degree
                    <FontAwesomeIcon
                      icon={!level ? faPlusCircle : faMinusCircle}
                      onClick={levelName}
                      className="admin-config-icon"
                      // style={{ marginLeft: "324px", cursor: "pointer" }}
                    />
                  </p>
                  <Collapse in={level}>
                    <div id="collapsePanel">
                      <div className="inner">
                        <div className="box">
                          {levelData?.map((item, index) => (
                            <div>
                              <label>Desired Degree Value</label>
                              &emsp;
                              <input
                                type="text"
                                placeholder="Type a new value"
                                className="input-border"
                                style={
                                  item.active
                                    ? null
                                    : {
                                        cursor: "no-drop",
                                        backgroundColor: "lightgray",
                                      }
                                }
                                onChange={(e) =>
                                  handleAddLevelChange(e, index)
                                }
                                disabled={item.active ? false : true}
                                value={item?.name}
                              />
                              &emsp;
                              <button
                                className={
                                  !item.active
                                    ? "cancel-button-config"
                                    : "save-button"
                                }
                                onClick={() =>
                                  handleAddLevelDisabled(index)
                                }
                              >
                                {item.active ? (
                                  <span>
                                    <FontAwesomeIcon icon={faCheckCircle} />{" "}
                                    Enabled
                                  </span>
                                ) : (
                                  <span>
                                    <FontAwesomeIcon icon={faTimesCircle} />{" "}
                                    Disabled
                                  </span>
                                )}
                              </button>
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                style={{ marginLeft: "30px", width: "10px" }}
                                className="star-mendatory"
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  marginLeft: "45px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => levelDelete(item?.id)}
                                className="star-mendatory"
                              />
                            </div>
                          ))}
                          <button
                            className="add-button"
                            onClick={handleAddLevel}
                            // disabled={athleticsData [athleticsData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Desired Degree
                          </button>
                          <br />
                          <button
                            className="save-button"
                            onClick={handleSaveLevel}
                            // disabled={athleticsData [athleticsData.length -1]?.name}
                          >
                            <FontAwesomeIcon icon={faSave} /> Save
                          </button>
                          &emsp;
                          <button
                            className="cancel-button-config"
                            onClick={() => cancelAction()}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse> */}
                  <br />


                  {/* <p className="shade" onClick={courseName}>
                    Add Course Concentration Requirements
                    <FontAwesomeIcon
                      icon={!course ? faPlusCircle : faMinusCircle}
                      onClick={courseName}
                      className="admin-config-icon"
                      // style={{ marginLeft: "117px", cursor: "pointer" }}
                    />
                  </p>
                  <Collapse in={course}>
                    <div id="collapsePanel">
                      <div className="inner">
                        <div className="box">
                          {courseData?.map((item, index) => (
                            <div>
                              <label>Concentration</label>
                              &emsp;
                              <input
                                type="text"
                                placeholder="Type a name"
                                className="input-border"
                                style={{ width: "120px" }}
                                //   item.active
                                //     ?
                                //     {
                                //       width: "120px",
                                //     }
                                //     : {
                                //       width: "120px",
                                //       cursor: "no-drop",
                                //       backgroundColor: "lightgray",
                                //     }
                                // }
                                onChange={(e) =>
                                  handleAddConcentrationChange(e, index)
                                }
                                // disabled={item.active ? false : true}
                                value={item?.name}
                              />
                              &emsp;
                              <input
                                ref={valueNameRef}
                                type="text"
                                placeholder="Select Goal"
                                className="input-border"
                                style={{ width: "120px" }}
                                //   item.active
                                //     ?
                                //     {
                                //         width: "120px",
                                //     }
                                //     :
                                //       {
                                //         cursor: "no-drop",
                                //         backgroundColor: "lightgray",
                                //         width: "120px",
                                //       }
                                // }
                                value={item.goal}
                                onChange={() =>
                                  handleChangeConcentration(index)
                                }
                                // disabled={item.active ? false : true}
                                autoComplete="off"
                              />
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                style={{ marginLeft: "45px", width: "10px" }}
                                className="star-mendatory"
                              />
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  marginLeft: "45px",
                                  width: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => concentrationDelete(item?.id)}
                                className="star-mendatory"
                              />
                            </div>
                          ))}
                          <button
                            className="add-button"
                            onClick={handleAddCourse}
                            // disabled={(courseData[courseData.length -1]?.name && courseData[courseData.length -1]?.goal)}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Concentration
                          </button>
                          <br />
                          <button
                            className="save-button"
                            onClick={handleSaveCourse}
                            // disabled={(courseData[courseData.length -1]?.name && courseData[courseData.length -1]?.goal)}
                          >
                            <FontAwesomeIcon icon={faSave} /> Save
                          </button>
                          &emsp;
                          <button
                            className="cancel-button-config"
                            onClick={() => cancelAction()}
                          >
                            <FontAwesomeIcon icon={faTimes} /> Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Collapse> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ConfigurationList;
