import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEnvelope,
  faEye,
  faQuestion,
  faUser,
  faUsers,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import React, { Fragment, useState, useEffect, useRef } from "react";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import moment from "moment";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import { useHistory } from "react-router-dom";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const MessageCenter = () => {
  let history = useHistory();
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [page, setPage] = useState(1);
  const [messageDetails, setMessageDetails] = useState();
  const [groupMessageDetails, setGroupMessageDetails] = useState([]);
  const [groupPage, setGroupPage] = useState(1);

  const getStudentMessage = () => {
    let obj = {
      counselor_id: user?.counselor_id,
      event_type: "school_it_student",
      page: page,
      // school: user?.school_id,
    };
    GetStudentService.getMessageForStudentInIT(obj)
      .then((data) => {
        console.log("data from the service", data);
        setMessageDetails(data?.data?.data);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  const getGroupMessageDetails = () => {
    let obj = {
      counselor_id: user?.counselor_id,
      page: groupPage,
      school_id: user?.school_id,
    };
    GetStudentService.getMessageForGroupInIT(obj)
      .then((data) => {
        console.log("data from the service", data);
        setGroupMessageDetails(data.data);
      })
      .catch((error) => {
        console.llog("error from the service", error);
      });
  };

  useEffect(() => {
    getStudentMessage();
    getGroupMessageDetails();
  }, []);

  const navigateToStudents = (item, index) => {
    history.push({
      pathname: "/school_it/message-students",
      state: item,
      open: true,
      index: index,
    });
  };

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div
            className="flex"
            style={{
              justifyContent: "space-between",
              padding: "25px 25px 0px",
            }}
          >
            <p className="no-margin-bottom" style={{ fontSize: "24px" }}>
              Messages
            </p>
            <div>
              <button
                onClick={() => {
                  history.push({
                    pathname: "/school_it/message-students",
                    openCreateMessage: true,
                  });
                }}
                style={{ marginRight: "15px" }}
                className="create-maintain-group-button"
              >
                <FontAwesomeIcon icon={faUser} style={{ marginRight: "5px" }} />
                Create Student Message
              </button>
              <button
                onClick={() => {
                  history.push({
                    pathname: "/school_it/message-groups",
                    openCreateGroupMessage: true,
                  });
                }}
                className="create-maintain-group-button"
              >
                <FontAwesomeIcon
                  icon={faUsers}
                  style={{ marginRight: "5px" }}
                />
                Create Group Message
              </button>
            </div>
          </div>
          <div className="message-center-container">
            <div className="message-single-grid">
              <span
                className="message-center-title"
                onClick={() => history.push("/school_it/students")}
              >
                <FontAwesomeIcon icon={faUser} style={{ marginRight: "5px" }} />
                Students
              </span>
              <div style={{ marginTop: "10px" }}>
                {messageDetails?.map((item, index) => {
                  console.log("item from the message Details", item);
                  return (
                    <div
                      onClick={() => navigateToStudents(item, index)}
                      style={{ justifyContent: "space-between" }}
                      className="flex schoolIT-student-message-container"
                    >
                      <div
                        style={{
                          justifyContent: "space-between",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {item?.priority === "critical" && (
                            <span
                              style={{
                                color: "red",
                                fontSize: "20px",
                                position: "relative",
                                left: "-20px",
                                width: "0",
                              }}
                            >
                              <FontAwesomeIcon icon={faExclamationCircle} />
                            </span>
                          )}
                          <img
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              marginRight: "30px",
                            }}
                            src={IMG_URL+"CounselorPlaceholder.png"}
                            alt="Placeholder"
                          />

                          <p
                            style={{ fontSize: "16px" }}
                            className="no-margin-bottom"
                          >
                            {item?.name}
                          </p>
                        </div>
                        <p
                          className="no-margin-bottom"
                          style={{
                            fontSize: "14px",
                            color: "#777",
                            fontWeight: "400",
                          }}
                        >
                          {moment(item?.date).format(
                            "ddd DD MMM YYYY, hh:mm A"
                          )}
                        </p>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <p
                          className="no-margin-bottom"
                          style={{ fontSize: "14px" }}
                        >
                          {
                          // Object.keys(item?.canned_message).length !== 0
                          //   ? item?.canned_message?.en?.body
                          // : 
                            item?.last_message}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="message-single-grid">
              <span
                className="message-center-title"
                onClick={() => history.push("/school_it/message-groups")}
              >
                <FontAwesomeIcon
                  icon={faUsers}
                  style={{ marginRight: "5px" }}
                />
                Groups
              </span>
              <div style={{ marginTop: "10px" }}>
                {groupMessageDetails &&
                  groupMessageDetails?.result &&
                  Object.values(groupMessageDetails?.result).map(
                    (item, index) => (
                      <div
                        key={index}
                        style={{
                          backgroundColor: "#dff0d8",
                          border: "1px solid #ddd",
                          padding: "10px",
                          cursor: "pointer",
                          position:"relative"
                        }}
                      >
                        {item?.priority === "critical" && (
                          <div
                            style={{
                              color: "red",
                              fontSize: "20px",
                              position: "absolute",
                              top:"40px",
                              left:"-10px"
                            }}
                          >
                            <FontAwesomeIcon icon={faExclamationCircle} />
                          </div>
                        )}
                        <div>
                          <p>
                            <div>
                              {/* {Object.values(item?.groups).map((i) => ( */}
                                <span
                                  style={{
                                    backgroundColor: "#009900",
                                    color: "white",
                                    padding: "4px 10px",
                                    fontSize: "12px",
                                    margin: "5px 15px 5px 0px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {JSON.parse(item?.groups?.replace(/'/g, '"'))['1']?.name}
                                </span>
                              {/* ))} */}
                            </div>
                          </p>
                          <p
                            style={{
                              fontSize: "12px",
                              color: "#777",
                              fontWeight: "400",
                              textAlign: "right",
                            }}
                            className="no-margin-bottom"
                          >
                            {moment(item?.date_sent).format(
                              "ddd DD MMM YYYY, h:m A"
                            )}
                          </p>
                          <p
                            className="no-margin-bottom"
                            style={{ fontSize: "14px", fontWeight: "700" }}
                          >
                            {item?.subject}
                          </p>
                        </div>
                        <div
                          className="flex"
                          style={{
                            width: "35%",
                            justifyContent: "space-between",
                            marginTop: "10px",
                            color: "#919AA0",
                          }}
                          onClick={() =>
                            history.push("/school_it/message-groups")
                          }
                        >
                          <FontAwesomeIcon icon={faEnvelope} />
                          {item?.count_messages}
                          <FontAwesomeIcon icon={faEye} />
                          {item?.count_opened}
                          <FontAwesomeIcon icon={faCheck} />
                          {item?.count_ack}
                          <FontAwesomeIcon icon={faQuestion} />
                          {item?.count_feedback}
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default MessageCenter;
