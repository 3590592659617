import { request } from "../services/Request";
import { routes } from "../constants/constant.route";
import { urlConstants } from "../utils/urlConstants";

const { GET_LADDER, SAVE_LADDER, PARENT_GET_STUDENTS, PARENT_GET_LADDERS } = routes.LADDER;
const { API_URL } = urlConstants;

export default class LaddersDataService {
  static getLaddersData() {
    return request("GET", `${API_URL}${GET_LADDER}`, null, null, null, null);
  }

  static saveLaddersData(body) {
    return request("PUT", `${API_URL}${SAVE_LADDER}`, null, body, null, null);
  }

  // Parent Role API
  static getParentRoleStudentList() {
    return request("GET", `${API_URL}${PARENT_GET_STUDENTS}`, null, null, null, null);
  }

  static getParentRoleLadders(queryValue) {
    let { studentid } = queryValue;
    return request("GET", `${API_URL}${PARENT_GET_LADDERS}?student_id=${studentid}`, null, null, null, null);
  }
}
