import { combineReducers } from 'redux';
import ThemeOptions from './ThemeOptions';
import UserReducer from './UserReducer';
import {locationState} from "./locationState"
import {locationReducer} from "./locationReducer"
import MessageNotificationReducer from './MessageNotificationReducer';
import ResetReducer, { RESET } from './ResetReducer';

const appReducer = combineReducers({
  ThemeOptions,
  UserReducer,
  MessageNotificationReducer,
  locationState,
  locationReducer
});

// const rootReducer = combineReducers({
//   // your reducers here
//   ThemeOptions,
//   UserReducer,
//   ResetReducer
// });

const rootReducer = (state, action) => {
  if (action.type === RESET) {
    // Reset the entire state to its initial value
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;