import React, { Fragment, useState, useRef } from "react";
import { Row, Col, Container } from "reactstrap";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
//import UpdateProfileService from '../../services/UpdateProfile';
//import Loader from "react-loader-spinner";
import AuthService from "../../../services/AuthService";
//import cookie from 'react-cookies';
import GoalChange from "./GoalChange";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import ContactCounselor from "./ContactCounselor";
import { useDispatch, useSelector } from "react-redux";
import { userInfoAction } from "../../../reducers/UserReducer";
import swal from "sweetalert";

const SchoolitProfile = () => {
  const dispatch = useDispatch();
  let scrollToSuccess = useRef();
  const [loading, setLoading] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [cancelClicked, setCancelClicked] = useState(false);
  // const [user, setUser] = useState(
  //   localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  // );
  const user = useSelector((state) => state.UserReducer?.userObj);
  const [firstName, setFirstName] = useState(user && user.first_name);
  const [lastName, setLastName] = useState(user && user.last_name);
  const [email, setEmail] = useState(user && user.email);
  const [mobile, setMobile] = useState(user && user.mobile);
  const [recoveryEmail, setRecoveryEmail] = useState(
    user && user.recovery_email
  );
  const [language, setLanguage] = useState(user && user.language);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showError, setShowError] = useState(false);
  const [profileSavedSuccessfully, setProfileSavedSuccessfully] = useState(
    false
  );
  //const [checkBoxOpportunity, setCheckBoxOpportunity] = useState();
  const [openGoalChange, setOpenGoalChange] = useState(false);
  const [openContactCounselor, setOpenContactCounselor] = useState(false);
  let history = useHistory();

  const onNextClick = () => {
    // setNextClicked(true);
    if (confirmPassword && password !== confirmPassword) {
      setShowError(true);
      return;
    }
    else if (!firstName || !lastName || !mobile || !email || !recoveryEmail) {
      swal({
        text: "Please fill in all required fields (First Name, Last Name, Mobile, Email & Recovery Email)",
        icon: "warning",
        dangerMode: true,
        buttons: {
          cancel: true,
        },
        closeOnClickOutside: false,
      });
      return;
    }
    let obj = {};
    // if (firstName) {
      obj.first_name = firstName;
    // }
    // if (lastName) {
      obj.last_name = lastName;
    // }
    // if (email) {
      obj.email = email;
    // }
    // if (mobile) {
      obj.mobile = mobile;
    // }
    if (language) {
      obj.language = language;
    }
    // if (recoveryEmail) {
      obj.recovery_email = recoveryEmail;
    // }
    if (password) {
      obj.password = password;
    }
    if (confirmPassword) {
      obj.confirm_password = confirmPassword;
    }
    let finalObj = {};
    Object.assign(finalObj, user, obj);
    setLoading(true);
    AuthService.updateProfile(obj)
      .then((data) => {
        setNextClicked(false);
        setLoading(false);
        //cookie.save('user', JSON.stringify(finalObj), {path:'/', domain:'.siembramobile.com'})
        localStorage.setItem("user", JSON.stringify(finalObj));
        setProfileSavedSuccessfully(true);
        dispatch(userInfoAction(finalObj));
        setTimeout(() => {
          setProfileSavedSuccessfully(false);
        }, 3500);
        scrollFunction();
      })
      .catch((error) => {
        //   setNextClicked(false);
        setLoading(false);
        if (error && error.response.status === 400) {
          console.log("error",error);
          if (error?.response?.data?.mobile) {
            swal({
              text: error?.response?.data?.mobile[0],
              icon: "warning",
              dangerMode: true,
              buttons: {
                cancel: true,
              },
              closeOnClickOutside: false,
            });
          } else if (error?.response?.data?.recovery_email) {
            swal({
              text: error?.response?.data?.recovery_email[0],
              icon: "warning",
              dangerMode: true,
              buttons: {
                cancel: true,
              },
              closeOnClickOutside: false,
            });
          }
        }

        console.log("error from the service", error);
      });
  };
  const scrollFunction = () => {
    scrollToSuccess.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  const onCancelClick = () => {
    if(user.role === "school_it"){
      history.push("/school_it/student_statistics");
    }
    else {
      history.push("/admin/export");
    }
  };

  const onGoalChange = () => {};

  return (
    <div style={{ overflowX: "hidden"}}>
      <Fragment>
        <AppHeader />
        <div className="app-main">
          <AppSidebar />
          <div className="app-main__outer">
            <div className="TitleContainer">
              <h4 style={{ width: "100%" }} className="titleText">
                PROFILE INFORMATION
              </h4>
            </div>
            {profileSavedSuccessfully && (
              <div
                ref={scrollToSuccess}
                id="success-container"
                className="success_alert"
              >
                Profile Information Saved Successfully.
              </div>
            )}
            <div
              className="flex"
              style={{
                float: "right",
                padding: "20px 10px 0px 0px",
                marginLeft: "auto",
              }}
            >
              <button
                onClick={() => setOpenGoalChange(true)}
                style={{ marginRight: "15px" }}
                className="create-maintain-group-button"
                // style={{ backgroundColor: "#057EBB", borderRadius:"5px", borderColor:"#057EBB", padding:"5px",
                // color: "#FFFF", width: "120px", marginLeft:"940px"}}
              >
                <span>Goal Change</span>
              </button>
              <button
                onClick={() => setOpenContactCounselor(true)}
                className="create-maintain-group-button"
                //style={{ backgroundColor: "#057EBB", borderRadius:"5px", borderColor:"#057EBB", padding:"5px", color: "#FFFF", width: "150px" }}
                // , marginLeft:"620px"
              >
                <span>Contact Counselor</span>
              </button>
            </div>
            <div className="profile_container">
              <div className="profile_personal_information">
                <h4 className="personal_information_title">
                  Personal Information
                </h4>
                <div style={{ borderBottom: "1px solid #e5e5e5" }} />
              </div>
            </div>
            <Container style={{ marginLeft: "0" }}>
              <Row>
                <Col style={{ paddingLeft: "8px" }} md={6}>
                  <div className="profile_input_container">
                    <input
                      name="firstName"
                      className="profile_input"
                      type="text"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    />
                    <label className="registration_label">First Name*</label>
                  </div>
                </Col>
                <Col md={6} style={{ paddingLeft: "8px" }}>
                  <div className="profile_input_container">
                    <input
                      name="lastName"
                      className="profile_input"
                      type="text"
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                    />
                    <label className="registration_label">Last Name*</label>
                  </div>
                </Col>
              </Row>
            </Container>
            <div className="profile_container">
              <div className="profile_contact_information">
                <h4 className="personal_information_title">
                  Contact Information
                </h4>
                <div style={{ borderBottom: "1px solid #e5e5e5" }} />
              </div>
            </div>
            <Container style={{ marginLeft: "0" }}>
              <Row>
                <Col style={{ paddingLeft: "8px" }} md={6}>
                  <div className="profile_input_container">
                    <input
                      disabled
                      name="email"
                      className="profile_input"
                      type="text"
                      value={email}
                    />
                    <label className="registration_label">Email Address*</label>
                  </div>
                </Col>
                <Col md={6} style={{ paddingLeft: "8px" }}>
                  <div className="profile_input_container">
                    <input
                      name="mobile"
                      className="profile_input"
                      type="text"
                      onChange={(e) => setMobile(e.target.value)}
                      value={mobile}
                    />
                    <label className="registration_label">
                      Mobile Phone Number*
                    </label>
                  </div>
                </Col>
              </Row>
              <Row className="email_container">
                <Col md={6} style={{ paddingLeft: "8px" }}>
                  <div className="profile_input_container recovery_email">
                    <input
                      name="recoveryEmail"
                      className="profile_input"
                      type="text"
                      onChange={(e) => setRecoveryEmail(e.target.value.toLowerCase())}
                      value={recoveryEmail}
                    />
                    <label className="registration_label">
                      Recovery Email Address*
                    </label>
                  </div>
                  <div className="email-msg-container">
                    <span style={{ fontSize: "14px", color: "#333333" }}>
                      &nbsp;Email address used in case account needs to be
                      recovered.
                    </span>
                  </div>
                </Col>
                {/* <Col md={6} style={{}}>
                                    <div style={{display:'flex', justifyContent:'center'}}>
                                        <input name="checkBoxOpportunity" style={{marginRight:'10px'  ,marginTop:'5px'}} type="checkbox" value={checkBoxOpportunity} checked={checkBoxOpportunity} onChange={()=>setCheckBoxOpportunity(!checkBoxOpportunity)} />
                                        <p style={{fontSize:"14px"}}>I want help and guidance from Colleges and Universities to provide opportunities for my family.</p>
                                     </div>
                                </Col> */}
              </Row>
            </Container>
            <div className="profile_container">
              <div className="profile_contact_information">
                <h4 className="personal_information_title">User Information</h4>
                <div style={{ borderBottom: "1px solid #e5e5e5" }} />
              </div>
            </div>
            <Container style={{ marginLeft: "0", padding: "0" }}>
              <Col style={{ paddingLeft: "8px" }} md={6}>
                <div className="profile_input_container">
                  <select
                    onChange={(e) => setLanguage(e.target.value)}
                    name="language"
                    className="profile_input"
                    type="text"
                    value={language}
                  >
                    <option value="en">en</option>
                    <option value="es">es</option>
                  </select>
                  <label className="registration_label">
                    Language Preference
                  </label>
                </div>
              </Col>
              <Col md={6} style={{ paddingLeft: "8px" }}>
                <div className="profile_input_container">
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    className="profile_input"
                    type="password"
                    value={password}
                  />
                  <label className="registration_label">Password</label>
                </div>
              </Col>

              <Col md={6} style={{ paddingLeft: "8px" }}>
                <div className="profile_input_container">
                  <input
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onFocus={() => setShowError(false)}
                    name="confirmPassword"
                    className="profile_input"
                    type="password"
                    value={confirmPassword}
                  />
                  <label className="registration_label">Confirm Password</label>
                </div>
              </Col>
              {showError && (
                <div className="show_error">
                  Password and Confirm Password should be same.
                </div>
              )}
            </Container>
            <div style={{ marginTop: "20px" }}>
              <Row>
                <div className="profile_button_container">
                  <div
                    style={{ marginLeft: "18px" }}
                    className="col-md-2 col-lg-2"
                  >
                    <button
                      onClick={() => onNextClick()}
                      className="update-button"
                    >
                      <span>
                        <FontAwesomeIcon
                          style={{ marginRight: "5px" }}
                          icon={faSave}
                        />
                        Update
                      </span>
                    </button>
                  </div>
                  <div
                    style={{ marginLeft: "8px" }}
                    className="col-md-4 col-lg-4 profile_button_next"
                  >
                    <button
                      style={{ width: "160px", borderRadius: "5px" }}
                      onClick={onCancelClick}
                      className={
                        "registration_cancel_button " +
                        (cancelClicked ? "buttonRingCancel" : "")
                      }
                    >
                      <span>
                        <FontAwesomeIcon
                          style={{ marginRight: "5px" }}
                          icon={faTimes}
                        />
                        CANCEL
                      </span>
                    </button>
                  </div>
                </div>
              </Row>
              {openGoalChange && (
                <GoalChange
                  open={openGoalChange}
                  setOpenGoalChange={setOpenGoalChange}
                />
              )}
              {openContactCounselor && (
                <ContactCounselor
                  open={openContactCounselor}
                  setOpenContactConselor={setOpenContactCounselor}
                />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
};
export default SchoolitProfile;
