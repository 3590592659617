import { faEnvelope, faTrash, faUser, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import moment from "moment";
import CreateMessage from "./CreateMessage";
import CannedModal from "./CannedModal";
import CustomLoader from "../../customLoader/CustomLoader";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import { useHistory, useLocation } from "react-router";
import MsgDataService from "../../../services/MsgService";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const StudentMessage = () => {
  let location = useLocation();
  let history = useHistory();
  console.log("location from the student message", location);
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [page, setPage] = useState(1);
  const [messageDetails, setMessageDetails] = useState();
  const [singleMessageOpen, setSingleMessageOpen] = useState(
    location?.state?.open ? location?.state?.open : false
  );
  const [singleMessageValue, setSingleMessageValue] = useState(
    location?.state?.state
  );
  const [allMessageValue, setAllMessageValue] = useState();
  const [message, setMessage] = useState();
  const [simplifiedMessageArray, setSimplifiedMessageArray] = useState([]);
  const [openCreateNewMessage, setOpenCreateNewMessage] = useState(
    location?.state?.openCreateMessage
      ? location?.state?.openCreateMessage
      : false
  );
  const [openCannedModal, setOpenedCannedModal] = useState(false);
  const [messageIndex, setMessageIndex] = useState(
    location?.state?.index ? location?.state?.index : -1
  );
  const [messageValueForCreateMessage, setMessageValueForCreateMessage] =
    useState("");
  const [loading, setLoading] = useState(false);
  const [selectedMessageFromScheduled, setSelectedMessageFromScheduled] =
    useState(false);
  const [selectedMessage, setSelectedMessage] = useState();

  const getStudentMessage = () => {
    setLoading(true);
    let obj = {
      counselor_id: user?.counselor_id,
      event_type: "school_it_student",
      page: page,
      school_id: user?.school_id,
    };
    GetStudentService.getMessageForStudentInIT(obj)
      .then((data) => {
        console.log("data from the service", data);
        setMessageDetails(data?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  const deleteStudentMessage = (e, value) => {
    console.log("value from the delete", value);
    let body = {
      id: value?.id,
      type: "student",
    };
    GetStudentService.deleteMessageForStudent(body)
      .then((data) => {
        console.log("data from the message", data);
        getStudentMessage();
        setSimplifiedMessageArray([]);
        setSingleMessageValue();
        setSingleMessageOpen(false);
      })
      .catch((error) => {
        console.log("error from the message", error);
      });
    e.stopPropagation();
  };

  // useEffect(() => {
  //   if (singleMessageValue) {
  //     let simplifiedMessageArray = Object.values(singleMessageValue?.messages);
  //     setSimplifiedMessageArray(simplifiedMessageArray);
  //   }
  // }, [singleMessageValue]);

  const getSelectedContactMessageDetail = (item) => {
    GetStudentService.getMessageListPerUser(page, item.src_id).then((data) => {
      setSingleMessageValue(data?.data?.messages);
    });
  };

  const handleSinglePage = (item, index) => {
    console.log("item4",item);
    setSingleMessageOpen(true);
    // (value);
    setSelectedMessage(item);
    getSelectedContactMessageDetail(item);
    setMessageIndex(index);
  };

  // const sendMessage = () => {
  //   let obj = {
  //     body: message,
  //     student: singleMessageValue?.student?.id,
  //   };
  //   GetStudentService.sendMessageToStudent(singleMessageValue?.id, obj)
  //     .then((data) => {
  //       console.log("data from the send message", data);
  //       let obj = { ...data?.data };
  //       setMessage("");
  //       setSimplifiedMessageArray([...simplifiedMessageArray, obj]);
  //     })
  //     .catch((error) => {
  //       console.log("error from the service", error);
  //     });
  // };
  const sendMessage = (e) => {
    if (message === "" || message.trim() === "" || message === "undefined") {
      e.preventDefault();
    }
    else{
    let obj = {
      message_body: message,
      dst_id: [selectedMessage?.src_id],
      event_type: "school_it_student"
    };
    // if (selected) {
    //   obj.parent_ack = selected;
    // }
    // if (msgTab === "student-message") {
    //   obj.answer_id = msgData.answer_id;
    // }
    // if (msgTab === "recruiter-message") {
    //   obj.counselor = msgData?.counselor?.id;
    //   obj.answer = msgData?.answers_id;
    // }
    // if (fileDatafromApi) {
    //   obj["attachment"] = fileDatafromApi?.id;
    //   if (fileDatafromApi?.url) {
    //     obj["url"] = fileDatafromApi?.url;
    //   }
    //   if (fileDatafromApi?.image_name) {
    //     obj["image_name"] = fileDatafromApi?.image_name;
    //   }
    // }
    // if (fileDatafromApi) {
    //   obj.attachment_url= fileDatafromApi?.Status?.image_name;
    // }
    MsgDataService.sendMessage(obj)
    //  CounselorMsgService.reply(msgData?.id, obj)
      .then((data) => {
        setMessage("");
        setSingleMessageOpen(false);
        getStudentMessage();
        // if (data?.data?.Status){
        //   window.location.reload();
        // }
        // setSelected(false);
        // setShowSingleMessageComponent(false);
        // getSelectedContactMessageDetail();
        // getMsgData();
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  }}

  console.log("singleMesageValue", singleMessageValue);
  const createNewMessage = () => {
    setOpenCreateNewMessage(true);
  };

  const handleCannedMessageSelect = (value) => {
    console.log("value from the user", value);
    setMessageValueForCreateMessage(value?.messages?.en?.body);
    setSelectedMessageFromScheduled(true);
    setOpenedCannedModal(false);
    setAllMessageValue(value);
  };
  useEffect(() => {
    getStudentMessage();
  }, []);

  console.log("selected Value", simplifiedMessageArray);
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
      <div className="student-message-container">
        <div
          className="flex"
          style={{ justifyContent: "space-between", marginBottom: "25px" }}
        >
          <p style={{ fontSize: "24px", marginBottom: "0px" }}>
            Students Messages
          </p>
          <div>
            <button
              onClick={createNewMessage}
              className="create-maintain-group-button"
            >
              <span style={{ marginRight: "10px" }}>
                <FontAwesomeIcon icon={faUser} />
              </span>
              Create Student Message
            </button>
            <button
              style={{ marginLeft: "15px" }}
              className="create-maintain-group-button"
              onClick={() => {
                history.push("/school_it/message_center");
              }}
            >
              Back
            </button>
          </div>
        </div>
        <div className="school_it-message-container">
          {loading ? (
            <CustomLoader />
          ) : (
            <div style={{ margin: "0 10px 10px 20px" }}>
              {messageDetails?.map((item, index) => (
                <div
                  onClick={() => handleSinglePage(item, index)}
                  style={{ padding: "15px 30px"}}
                  className={`schoolIT-student-message-container ${
                    messageIndex === index && "message-active-class"
                  }`}
                >
                  <div
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                    {item?.priority === 'critical' &&
                <span style={{color:"red",
                fontSize:'20px', position: 'relative', left: '-40px', width:'0'}}>
                <FontAwesomeIcon icon={faExclamationCircle} />
                </span>}
                      <img
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          marginRight: "30px",
                        }}
                        src={IMG_URL+"CounselorPlaceholder.png"}
                        alt="Placeholder"
                      />

                      <p
                        style={{ fontSize: "16px" }}
                        className="no-margin-bottom"
                      >
                        {item?.name}
                      </p>
                    </div>
                    <p
                      className="no-margin-bottom"
                      style={{
                        fontSize: "14px",
                        color: "#777",
                        fontWeight: "400",
                      }}
                    >
                      {moment(item.time).format(
                        "ddd DD MMM YYYY, hh:mm A"
                      )}
                    </p>
                    <FontAwesomeIcon
                      onClick={(e) => deleteStudentMessage(e, item)}
                      icon={faTrash}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <p
                      className="no-margin-bottom"
                      style={{ fontSize: "14px", textAlign: "justify" }}
                    >
                      {/* {Object.keys(item?.canned_message).length !== 0
                        ? item?.canned_message?.en?.body */}
                        {/* : */}
                        {item?.last_message}
                        {/* } */}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
          {singleMessageOpen && (
            <div>
              <div>
                <p style={{ fontSize: "14px", marginBottom: "8px" }}>
                  Conversation with
                  <strong style={{ marginLeft: "5px" }}>
                    {singleMessageValue &&
                      singleMessageValue[0]?.dst_name}
                  </strong>{" "}
                </p>
              </div>
              <div
                style={{
                  border: "1px solid #ddd",
                  padding: "15px",
                  fontSize: "14px",
                }}
              >
                {/*  Object. keys(obj). length. */}
                {/* {singleMessageValue?.canned_message.hasOwnProperty("en") && ( */}
                 
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom:"1px solid #ddd"
                      }}
                    > */}
                      {/* <p
                        style={{
                          color: "#333",
                          width: "85%",
                          marginTop: "15px",
                          textAlign: "justify",
                        }}
                      > */}
                        {/* {singleMessageValue &&
                          singleMessageValue?.canned_message?.en?.body} */}
                      {/* </p> */}
                        {/* <img
                          src={IMG_URL+"CounselorPlaceholder.png"}
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "15px",
                          }}
                        /> */}
                        {/* <p
                          className="no-margin-bottom"
                          style={{ fontSize: "11px", color: "#777" }}
                        >
                          {moment(singleMessageValue?.date_sent).format(
                            "DD MMM"
                          )}
                        </p> */}
                        {/* <p
                          className="no-margin-bottom"
                          style={{ fontSize: "11px", color: "#777" }}
                        >
                          {moment(singleMessageValue?.date_sent).format(
                            "hh:mm A"
                          )}
                        </p> */}
                    {/* </div> */}
                    {/* <div style={{ marginBottom: "10px" }}>
                      <p>Pick your answer:</p>
                      <div
                        style={{ border: "1px solid #ddd", padding: "10px" }}
                      >
                        <input style={{ marginRight: "5px" }} type="checkbox" />
                        <strong>
                          {singleMessageValue &&
                            singleMessageValue?.canned_answers?.["1"]?.text?.en}
                        </strong>
                      </div>
                    </div> */}
                  
                {/* )} */}
                <div
                  style={{
                    paddingBottom: "15px",
                    width: "100%",
                  }}
                >
                  {singleMessageValue?.map((item, index) => {
                    return (
                      item?.message_body !== null && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #ddd",
                            margin: "5px 0px",
                            padding: "5px 0px",
                          }}
                        >
                          <div>
                            <p style={{ marginBottom: "5px" }}>{item.message_body}</p>
                            {item.attachment_url !== null && (
                              <a target="_blank" href={item?.attachment_url}>
                              <button className="import-csv-button"> Download Attachment</button>
                              </a>
                            )}
                          </div>
                          <div>
                            <img
                              src={IMG_URL+"CounselorPlaceholder.png"}
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "15px",
                              }}
                            />
                            <p
                              className="no-margin-bottom"
                              style={{ fontSize: "11px", color: "#777" }}
                            >
                              {moment(item.date_sent)?.format("DD MMM")}
                            </p>
                            <p
                              className="no-margin-bottom"
                              style={{ fontSize: "11px", color: "#777" }}
                            >
                              {moment(item.date_sent)?.format("hh:mm A")}
                            </p>
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <textarea
                    style={{ width: "85%" }}
                    rows="5"
                    cols="50"
                    placeholder="Type your reply here"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <button
                    disabled= {!message}
                    onClick={() => sendMessage()}
                    style={{
                      background: message ? "var(--success-color)" : "#337ab7",
                      border: "#2e6da4",
                      padding: "5px 15px",
                      color: "white",
                      height: "min-content",
                      borderRadius: "5px",
                    }}
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {openCreateNewMessage && (
        <CreateMessage
          role="student"
          close={() => setOpenCreateNewMessage(false)}
          openCannedModal={openCannedModal}
          setOpenedCannedModal={setOpenedCannedModal}
          message={messageValueForCreateMessage}
          setMessage={setMessageValueForCreateMessage}
          getMessageDetails={getStudentMessage}
          selectedMessageFromScheduled={selectedMessageFromScheduled}
          allMessageValue={allMessageValue}
        />
      )}

      {openCannedModal && (
        <CannedModal
          open={openCannedModal}
          close={() => setOpenedCannedModal(false)}
          handleCannedMessageSelect={(value) =>
            handleCannedMessageSelect(value)
          }
        />
      )}
      </div>
      </div>
    </Fragment>
  );
};
export default StudentMessage;
