import React, { Fragment, useState, useEffect, useRef } from "react";
import CustomLoader from "../../customLoader/CustomLoader";
import GetAdminService from "../../../services/admin/GetAdminService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import CustomPagination from "../../pagination/Pagination";
import AddSchoolIt from "./AddSchoolIt";

const Recruiters = () => {
  const [schoolName, setSchoolName] = useState();
  const [pageData, setPageData] = useState();
  const [showSchoolNameContainer, setShowSchoolNameContainer] = useState(false);
  const [openModifyRecruiter, setOpenModifyRecruiter] = useState(false);
  const [page, setPage] = useState(1);
  const [recruiterListData, setRecruiterListData] = useState([]);
  const [schoolNameList, setSchoolNameList] = useState();
  const schoolNameRef = useRef();
  const timeoutRef = useRef();
  const [showRecruiter, setShowRecruiter] = useState(false);
  const [openRecruiter, setOpenRecruiter] = useState(false);
  const [collegeId, setCollegeId] = useState();
  const [loading, setLoading] = useState(false);
  const [modifyData, setModifyData] = useState({});
  const [modifyRecruiterIndex, setRecruiterIndex] = useState();
  const [schoolNameLoading, setSchoolNameLoading] = useState(false);

  const getSchoolsList = (value) => {
    setSchoolNameLoading(true);
    GetAdminService.getSchoolsList(value)
      .then((data) => {
        console.log("data from the service", data);
        setSchoolNameList(data?.data?.school);

        setSchoolNameLoading(false);
        setShowSchoolNameContainer(true);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setSchoolNameLoading(false);
      });
  };

  const handleCollegeName = () => {
    clearTimeout(timeoutRef.current);
    setSchoolName(schoolNameRef.current.value);
    if (!schoolNameRef.current.value) {
      setShowSchoolNameContainer(false);
      setSchoolNameList([]);
      setRecruiterListData([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getSchoolsList(schoolNameRef.current.value);
      }, 1000);
    }
  };

  const handleSelectedCollegeName = (item) => {
    setSchoolNameList([item]);
    setShowSchoolNameContainer(false);
    setShowRecruiter(true);
    setCollegeId(item?.id);
    setSchoolName(item.name);
    getRecruiterwithId(item?.id);
  };

  const getRecruiterwithId = (id) => {
    setLoading(true);
    let obj = {
      school: id,
      page: page,
      role: "school_it",
    };
    GetAdminService.getAdminSchoolIT(obj)
      .then((data) => {
        console.log("student from the service", data);
        setRecruiterListData(data.data);
        setPageData(data?.data)
        setLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  const handleSelectedRecruiter = (item) => {
    setModifyData(item);
    setOpenModifyRecruiter(true);
  };

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="admin-canned-container">
            <div className="flex canned-message-heading">
              <p>
                <span style={{ fontSize: "25px", fontWeight: "400" }}>
                  Create School IT
                </span>
              </p>
              {showRecruiter ? (
                <div>
                  <button
                    onClick={() => setOpenRecruiter(true)}
                    style={{
                      marginLeft: "auto",
                      marginRight: "10px",
                      padding: "10px",
                    }}
                    className="create-maintain-group-button"
                  >
                    <span>
                      {" "}
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ marginRight: "10px" }}
                      />
                      New School IT
                    </span>
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="school-name-container">
              <p>Select School</p>
              <div>
                <input
                  ref={schoolNameRef}
                  className="search-student-input"
                  type="text"
                  style={{
                    width: "500px",
                    height: "35px",
                    padding: "5px 10px",
                    marginBottom: "10px",
                  }}
                  placeholder="School Name"
                  value={schoolName}
                  onChange={handleCollegeName}
                  autoComplete="off"
                />
                {showSchoolNameContainer && (
                  <div>
                    <ul
                      className="maintain-group-student-dropdown-menu"
                      style={{
                        width: "700px",
                        maxHeight: "600px",
                        overflowY: "scroll",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      {schoolNameLoading && <CustomLoader />}
                      {schoolNameList?.length > 0 &&
                        schoolNameList.map((item, index) => (
                          <li
                            onClick={() => handleSelectedCollegeName(item)}
                            key={index}
                            className="flex maintain-group-student-list cursor"
                          >
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="admin-student-list-container">
                {loading ? (
                  <CustomLoader />
                ) : (
                  recruiterListData.length !== 0 && (
                    <div>
                      <p
                        style={{
                          padding: "8px 0px 8px 0px",
                          fontSize: "18px",
                        }}
                      >
                        School IT
                      </p>
                      {recruiterListData?.result?.map((item, index) => {
                        return (
                          <div
                            style={{ margin: "0" }}
                            className={`student-list ${index ===
                              modifyRecruiterIndex && "student-list-active"}`}
                            onClick={() => handleSelectedRecruiter(item)}
                          >
                            {/* <p style={{ marginBottom: "5px" }}>{`${
                              item.last_name
                            }, ${item.first_name}`}</p> */}
                            <p style={{ marginBottom: "5px" }}>{`<${
                              item.email
                            }>`}</p>
                          </div>
                        );
                      })}
                    </div>
                  )
                )}
              </div>
            </div>
            {pageData && pageData?.page_list?.length > 1 && (
              <div style={{ margin: "0 20px" }}>
                <CustomPagination
                  count={pageData && pageData}
                  pageValue={page}
                  setPage={setPage}
                />
              </div>
            )}
            {openRecruiter && (
              <AddSchoolIt
                setOpenRecruiter={setOpenRecruiter}
                modify={false}
                school_id={collegeId}
                recruiterCall={getRecruiterwithId}
              />
            )}
            {openModifyRecruiter && (
              <AddSchoolIt
                setOpenRecruiter={setOpenModifyRecruiter}
                modify={modifyData}
                school_id={collegeId}
                recruiterCall={getRecruiterwithId}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Recruiters;
