import React, { Fragment, useState, useEffect, useRef } from "react";
import { TrendingUpRounded } from "@mui/icons-material";
import Loader from "react-loader-spinner";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import Storage from "../../../services/Storage";
import StudentImportConfirmation from "../../counselor/studentImportConfirmationModal/StudentImportConfirmationModal";
const API_URL = process.env.REACT_APP_API_URL;

const CSVImport = () => {
  const [loadingForCsvDownload, setLoadingForCsvDownload] = useState(false);
  const [user, setUser] = useState(
    localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : []
  );
  const [dataForDownload, setDataForDownload] = useState([]);
  const [loadingForStudentCsvDownload, setLoadingForStudentCsvDownload] =
    useState(false);
  const [downloadCSVStudent, setdownloadCSVStudent] = useState(false);
  const [apiCallOrNot, setApiCallOrNot] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const selectedFileRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [uploadCSVData, setUploadCSVData] = useState();

  const downLoadCsvTemplate = () => {
    setLoadingForCsvDownload(TrendingUpRounded);
    let template = [];
    template.push([
      '"Student First Name"',
      '"Student Middle Name"',
      '"Student Last Name"',
      '"Student Grade"',
      '"Student ID"',
      '"Student Email"',
      '"Student Mobile"',
      '"Student DOB"',
      '"Counselor Emails'
    ]);
    let arr = template;
    let ret = [];
    for (let i = 0, len = arr.length; i < len; i++) {
      let line = [];
      for (let key in arr[i]) {
        if (arr[i].hasOwnProperty(key)) {
          line.push(arr[i][key]);
        }
      }
      ret.push(line.join(","));
    }
    let result_csv = ret.join("\n");
    let saving = document.createElement("a");
    saving.href = "data:attachment/csv," + encodeURIComponent(result_csv);
    saving.download = "csv_counselor_import_template.csv";
    document.body.appendChild(saving);
    saving.click();
    setLoadingForCsvDownload(false);
  };

  const downloadFile = (downloadURL, fileName) => {
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.download = fileName;
    downloadLink.href = downloadURL;
    downloadLink.click();
}

  const getDataForCSVDownload = (offset) => {
    let finalObj = [];
    setLoadingForStudentCsvDownload(true);
    GetStudentService.getStudentCountForCSVImport(user?.school_id)
      .then((data) => {
        downloadFile(data.data.download);
        setLoadingForStudentCsvDownload(false);
        // setApiCallOrNot(data.data.meta);
        // if (data.data.meta.next !== null) {
        //   data.data.objects.map((item) => {
        //     let obj = {
        //       ...item.student,
        //       ...{ counselor_email: item?.counselor?.email },
        //     };
        //     finalObj.push(obj);
        //   });
        //   setDataForDownload(finalObj);
        // } else if (data.data.meta.next === null) {
        //   data.data.objects.map((item) => {
        //     let obj = {
        //       ...item.student,
        //       ...{ counselor_email: item?.counselor?.email },
        //     };
        //     finalObj.push(obj);
        //   });
        //   setDataForDownload([...dataForDownload, ...finalObj]);
        //   setdownloadCSVStudent(true);
        //   setLoadingForStudentCsvDownload(false);
        // }
      })
      .catch((error) => {
        setLoadingForStudentCsvDownload(false);
        console.log("error from the service", error);
      });
  };

  useEffect(() => {
    if (apiCallOrNot?.next !== null && dataForDownload.length > 0) {
      getDataForCSVDownload(1000);
    }
  }, [apiCallOrNot && dataForDownload && dataForDownload.length > 0]);

  useEffect(() => {
    if (dataForDownload.length > 0) {
      let result_csv = getHeaderRow();
      addStudentsToCsv(result_csv, dataForDownload);
    }
  }, [dataForDownload?.length > 0 && downloadCSVStudent === true]);

  function getHeaderRow() {
    var template = [];
    template.push([
      '"Student First Name"',
      '"Student Middle Name"',
      '"Student Last Name"',
      '"Student Grade"',
      '"Student ID"',
      '"Student Email"',
      '"Student Mobile"',
      '"Student DOB"',
      '"Counselor Emails"',
    ]);
    var arr = template;
    var ret = [];
    for (var i = 0, len = arr.length; i < len; i++) {
      var line = [];
      for (var key in arr[i]) {
        if (arr[i].hasOwnProperty(key)) {
          line.push(arr[i][key]);
        }
      }
      ret.push(line.join(","));
    }
    return ret;
  }

  function addStudentsToCsv(csv, students) {
    var studentRecord = [];
    for (var i = 0; i < students.length; i++) {
      studentRecord[0] = students[i].first_name;
      studentRecord[1] = students[i].middle_name;
      studentRecord[2] = students[i].last_name;
      studentRecord[3] = students[i].current_grade;
      studentRecord[4] = students[i].student_id;
      studentRecord[5] = students[i].email;
      studentRecord[6] = students[i].mobile;
      studentRecord[7] = students[i].student_dob;
      studentRecord[8] = students[i].counselor_email;
      var newRow = studentRecord.join(",");
      csv.push(newRow);
    }
    var csvDownload = csv.join("\n");
    var saving = document.createElement("a");
    saving.href = "data:attachment/csv," + encodeURIComponent(csvDownload);
    saving.download = "csv_student_download.csv";
    document.body.appendChild(saving);
    saving.click();
  }

  const uploadFileHandler = () => {
    let file = selectedFileRef.current.files[0];
    console.log("file from the upload file handler", file);
    setSelectedFile(file);
  };

  const uploadFile = () => {
    let formData = new FormData();
    formData.append("school", user?.school_id);
    formData.append("mode", "validation");
    formData.append("csv_file", selectedFile);
    let token = Storage.getTokenInCookie() ? Storage.getTokenInCookie() : "";
    console.log("token",token)
    fetch(`${API_URL}/api/v1/admin/upload-csv`, {
      method: "POST",
      headers: {
        "x-authorization": `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data from the upload CSV File", data);
        setUploadCSVData(data);
        if (data?.error_csv?.length > 0) {
          setShowModal(true);
        }
      })
      .catch((error) => {
        console.log("eror from the service", error);
      });
  };

  useEffect(() => {
    console.log("selected File Ref", selectedFileRef, selectedFile)
    if (selectedFileRef && selectedFile) {
      uploadFile();
    }
  }, [selectedFile]);
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
      <div className="csv-import-container">
        <div style={{ justifyContent: "space-between" }} className="flex">
          <p>
            <span style={{ fontSize: "36px", fontWeight: "400" }}>Export</span>
            <span
              style={{ color: "#777777", fontSize: "24px", marginLeft: "10px" }}
            >
              {/* Import Users from CSV */}
              Students Data
            </span>
          </p>
          {/* <button onClick={downLoadCsvTemplate} className="import-csv-button">
            Download CSV Template File
            {loadingForCsvDownload && (
              <span>
                <Loader type="TailSpin" color="white" height={20} width={20} />
              </span>
            )}
          </button> */}
        </div>
        {/* <div
          style={{
            border: "1px solid rgb(204,204,204)",
            borderLeft: "6px solid rgb(204,204,204)",
            padding: "0 15px",
          }}
        >
          <p
            style={{
              color: "rgb(58,58,58)",
              fontSize: "14px",
              marginBottom: "0px",
            }}
          >
            To import students, first download the CSV template and add any
            student information available.The student last name and student
            first name are required fields. You also must include the email
            address, a student ID or both an email address and student ID to
            uniquely identify the student. Once the CSV file has been created,
            upload the file.
          </p>
        </div> */}
        <div>
          <div className="school-name-container">
            <p className="school-name-container-heading">School</p>
            <div className="school-name">
              <p className="no-margin-bottom">{user?.school_name}</p>
            </div>
          </div>
        </div>
        <div>
          <div className="school-name-container">
            <p className="school-name-container-heading">Download Students</p>
            <div>
              <button
                onClick={() => getDataForCSVDownload(0)}
                style={{ display: "flex"}}
                className="download-report-button btn-secondary"
              >
                Download Student
                {loadingForStudentCsvDownload && (
                  <span>
                    <Loader
                      type="TailSpin"
                      color="white"
                      height={15}
                      width={15}
                    />
                  </span>
                )}
              </button>
            </div>
          </div>
          {/* <div className="school-name-container">
            <p className="school-name-container-heading">Select File</p>
            <div>
              <input
                type="file"
                accept="text/csv"
                onChange={uploadFileHandler}
                ref={selectedFileRef}
              />
            </div>
          </div> */}
        </div>
      </div>
      {showModal && (
        <StudentImportConfirmation
          student={uploadCSVData}
          closeHandler={() => {
            setShowModal(false);
            selectedFileRef.current.value = null;
          }}
          isOpen={showModal}
          school_it_ok={true}
        />
      )}
      </div>
      </div>
    </Fragment>
  );
};
export default CSVImport;
