import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CommonButton } from '../../components';
import '../LetsGetStart/LetsGetStart.scss'
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const SelectAccount = () => {
    const history = useHistory();
    return (
        <div className='connectWrap'>
            <div className='header text-center'>
                <img src={IMG_URL+"logo.png"} alt='colorLogo' className='img-fluid colorLogo' />
            </div>
            <div className='connectBlockWrap'>
                <div className='connectBlockInner text-center'>
                    <div className='connectBlock boxShadow'>
                        <h2 className='connect-title'>Let's Get Started !</h2>
                        <br/>
                        <div className='btnGroup'>
                            <CommonButton
                                name='With Student ID'
                                btnClass='primaryBtn w-100 connectBtn'
                                btnWidth='320px'
                                btnHeight='50px'
                                onClick={() => history.push(`create-student-account`)}
                            />
                            <CommonButton
                                name='Without Student ID'
                                btnClass='primaryBtn w-100 connectBtn'
                                btnWidth='320px'
                                btnHeight='50px'
                                onClick={() => history.push(`create-student-personal`)}
                            />
                        </div>
                    </div>
                    <Link className='backLink' to='lets-get-start'> {'<'} Back </Link>
                </div>
            </div>
        </div>
    )
}

export default SelectAccount
