import React, { Fragment, useState, useEffect } from 'react';
import { FileDrop } from 'react-file-drop';
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const BannerComponent = ({
    imageRef,
    isEdit,
    bannerImage,
    resetBanner,
    bannerName,
    setBannerName,
    bannerUrl,
    setBannerUrl,
    showSelectedImageNameFromEdit,
    handleFileChange,
    imageValidator,
    uploadImage,
    uploadedBannerImageData,
    createBanner,
    saveBannerAfterEdit,
}) => {
    return (
        <Fragment>
            <div style={{ background: 'var(--background-color)', height: '900px' }}>
                <div className="campaign-header">
                    <p className="color bold no-margin-bottom">{isEdit ? 'EDIT BANNER' : 'CREATE NEW BANNER'}</p>
                    <div style={{ padding: '15px 0px' }}>
                        <input
                            className="campaign-input"
                            placeholder="Banner Name"
                            type="text"
                            onChange={(e) => setBannerName(e.target.value)}
                            value={bannerName}
                        />
                    </div>
                    <div style={{ lineHeight: 'normal' }}>
                        <p style={{ fontSize: '18px', color: '#333333', marginBottom: '10px', fontWeight: 'bold' }}>
                            Upload Photo
                        </p>
                        <p style={{ fontSize: '14px', color: '#333333' }} className="no-margin-bottom">
                            Image must be jpeg or png with resolution of 468x60 or 160x600
                        </p>
                    </div>
                    <div className="image-drop-wrapper">
                        <div className="image-drop-container">
                            <FileDrop
                                onDrop={(files, event) => {
                                    console.log('onDrop!', files, event)
                                    imageValidator(files[0])
                                }}
                            >
                                <img src={IMG_URL+"placeholder.jpg"} className="banner-placeholder-image" />
                                <p style={{ fontSize: '16px', color: '#333333', marginBottom: '10px', fontWeight: 'bold' }}>Drag Photo(s) here</p>
                                <p>{bannerImage && bannerImage.name}</p>
                            </FileDrop>
                            <div>
                                <h6 className="or_text"><span>OR</span></h6>
                            </div>
                            <div className="image-drop" style={{marginLeft:"10px"}}>
                                <input
                                    type="file"
                                    onChange={(e) => handleFileChange(e)}
                                    accept=" image/* "
                                    ref={imageRef}
                                />
                            </div>
                            {showSelectedImageNameFromEdit && <p className="no-margin-bottom">{showSelectedImageNameFromEdit}</p>}
                            <div style={{ marginTop: '15px' }}>
                                <p style={{ textAlign: 'left', padding: '0 25px', lineHeight:'initial' }} className="font color bold no-margin-bottom">
                                    Uploaded Banner Image:
                                    <span>{uploadedBannerImageData && uploadedBannerImageData}</span>
                                </p>
                                <button disabled={!bannerImage} onClick={uploadImage} className={`upload-button ${!bannerImage && 'less-opacity'}`}>Upload</button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ padding: '15px 0px' }}>
                            <input
                                className="campaign-input"
                                placeholder="URL"
                                type="text"
                                onChange={(e) => setBannerUrl(e.target.value)}
                                value={bannerUrl}
                            />
                        </div>
                        <div style={{ justifyContent: 'space-between', marginTop: '20px' }}
                            className="flex">
                            <button disabled={!bannerImage || !bannerUrl || !bannerName} onClick={isEdit ? saveBannerAfterEdit : createBanner} className={`campaign-button active-color ${(!bannerImage || !bannerUrl || !bannerName) ? "less-opacity" : ""}`}>
                                Save Banner
                            </button>
                            <button onClick={resetBanner} className="campaign-button inactive-color">Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default BannerComponent;