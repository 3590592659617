import React from "react";
import { Modal } from "reactstrap";

function UseAcceptance({ isOpen, setIsOpen }) {
    const toggle = () => {
        setIsOpen(false);
      };
  return (
    <Modal isOpen={isOpen} centered={true} toggle={toggle}>
          <div className="modal-header">
            <h4 className="modal-title w3-text-blue">
              <center>Siembra Mobile, Inc. Acceptable Use Policy</center>
            </h4>
            <button
              type="button"
              className="close"
              onClick={toggle}
              aria-hidden="true"
            >
              ×
            </button>
          </div>
          <div 
          style={{ padding: "25px", textAlign: "justify" }}
          className="modal-body">
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>Terms of Use</strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                Welcome to Siembra. Please continue reading to learn about the
                rules and restrictions that govern your use of Siembra’s
                website(s) and all other products, services and applications
                made available by Siembra from time to time (such as, Siembra’s
                iOS and Android mobile applications and the Siembra services
                available on SMS) (collectively, the “Services”). This Agreement
                includes the terms in this document, as well as references
                Siembra’s Privacy Policy, Copyright Dispute Policy, and Apple
                Application Terms.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                We are constantly trying to improve our Services, so this
                Agreement may need to change along with the Services. We reserve
                the right to modify this Agreement at any time, but we will do
                our best to bring this to your attention by posting a notice on
                the Services, sending you a notice via email, and/or by some
                other means. If you don’t agree with the new terms, you may
                reject them but unfortunately you will no longer be able to use
                the Services. Your use of the Services in any way following such
                notification constitutes your acceptance of the terms and
                conditions as modified. We may suspend or discontinue any part
                of the Services, or we may introduce new features or impose
                limits on certain features or restrict access to parts or all of
                the Services, at any time. We’ll do our best to provide you
                notice when we make a material change to the Services that would
                adversely affect you, but this isn’t always practical.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                You represent and warrant to Siembra that: (i) if you are not at
                least 18 years old, you have your parent’s permission to use the
                Services, and your parent has read and agrees to this Agreement
                on your behalf); (ii) all registration information you submit is
                accurate and truthful; (iii) you will maintain the accuracy of
                such information; (iv) if you’re agreeing to this Agreement on
                behalf of an organization or entity, you represent and warrant
                that you are authorized to agree to this Agreement on that
                organization or entity’s behalf and bind them to this Agreement
                (in which case, the references to “you” and “your” in these
                Terms, except for in this sentence, refer to that organization
                or entity). You also certify that you are legally permitted to
                use and access the Services and take full responsibility for the
                selection and use of and access to the Services. This Agreement
                is void where prohibited by law, and the right to access the
                Services is revoked in such jurisdictions.
              </span>
            </p>
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>Privacy </strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                Another important document to look at is our Privacy Policy.
                This outlines what personal information we collect from you and
                how we use that information to provide the Siembra service.
              </span>
            </p>
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>
                  Fees and payment; Responsibility for usage charges
                </strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                Access to and use of the Services itself is free, but in the
                future, we may offer new or additional special features which
                Siembra may charge for. Therefore, Siembra reserves the right to
                require payment of fees for certain Services. If you want access
                to those Services, you will pay all applicable fees for those
                Services, as will be described on the Services. If you continue
                to use those Siembra Mobile Inc. – Acceptable Use Policy 2
                Services following such notification, that use constitutes your
                acceptance of any new or increased charges.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                Note that, by using the Services, you may receive e-mail or text
                messages on your phone or mobile device, which may cause you to
                incur usage charges or other fees or costs in accordance with
                your wireless or data service plan. Any and all such charges,
                fees, or costs are your sole responsibility. You should consult
                with your wireless carrier to determine what rates, charges,
                fees, or costs may apply to your use of the Services.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                Siembra Counselor Connect<sup>tm</sup> is currently free, and
                has no plans to charge Counselors, Schools, or student families
                for the basic service.
              </span>
            </p>
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>Additional terms </strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                This Agreement applies to all users of our Services, but there
                are special terms for you depending on what type of user you
                are. If you are, for example, a Counselor who organizes a
                “group” of members, then you are an Organizer. If you are, for
                example, a student, teammate or parent who is invited to be a
                member of a class or group by an Organizer, you are a
                Subscriber. In certain cases, Subscribers may be allowed to
                invite new members to a “group” to become Subscribers. If you
                are agreeing to this Agreement on behalf of an organization,
                such as a school or school district, you are an Organization.
                You may not sign up for or use the Siembra Services as someone
                you are not; Siembra may disable or terminate your account if
                you do so. Please read on below to find out what additional
                terms apply to you.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                <i> If you are an Organization:</i>
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                You will only permit staff members (for example, your
                Counselors) who are current employees of your organization to
                use the Services and to create their own class or group codes.
                Upon termination of a staff member’s employment with the
                Organization, you will require such individual to return and
                cease using all class or group codes he or she has in his or her
                possession. If at any time you learn a user of the Services
                claims to be affiliated with your organization who is not, in
                fact, affiliated with your Organization, you will notify Siembra
                immediately by emailing info@siembralatino.com.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                <i>If you are a Subscriber (such as a student, parent):</i>
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                You must get your parent or guardian to read and agree to your
                school’s Terms of Use or this Agreement before using the
                Services if you are under 18.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                You will only join groups that you have been invited to, or
                based on your school, class or group affiliation, to which you
                are a part of.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                <i>If you are accessing the Services as an Organizer:</i>
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                The Services may allow you to invite users to join your group(s)
                and/or to add users to your group(s). IF YOU INVITE OTHER USERS
                TO JOIN YOUR GROUP, YOU REPRESENT AND WARRANT TO US THAT YOU
                HAVE OBTAINED CONSENT FROM EACH PERSON THAT YOU INVITE AND/OR
                ADD TO RECEIVE SUCH INVITATION AND TO RECEIVE MESSAGES FROM
                SIEMBRA, FROM YOU, AND FROM ANYONE ELSE IN THE GROUP.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                Organizers can invite members by creating a group on the Siembra
                platform and communicate with Subscribers, (in some cases, there
                may be more than one Organizer per group).
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                You may only share a group with Subscribers whom you wish to
                subscribe to your group. If you plan to broadly or publicly
                publish these groups, you must manage the accuracy of your
                subscriber lists carefully.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                Organizers must maintain and monitor Subscriber lists in order
                to help ensure accurate representation of the Subscribers in
                your group.
              </span>
            </p>
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>Registration and security </strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                As a condition to using Services, you may be required to
                register with Siembra by selecting a password and providing your
                name and a valid email address. You will provide Siembra with
                accurate, complete, and updated registration information. You
                may not (i) select or use the name or email of another person
                with the intent to impersonate that person; or (ii) use a name
                subject to any rights of any person other than you without
                appropriate authorization. Siembra reserves the right to refuse
                registration of or cancel a Siembra account in its sole
                discretion. You will be responsible for maintaining the
                confidentiality of your account and password. You may not
                transfer your account to anyone without express prior written
                consent of Siembra. Violation of any of the foregoing may result
                in immediate termination of your account, revocation of your
                ability to use the Services, and may subject you to state and
                federal penalties and other legal consequences. Siembra reserves
                the right, but will have no obligation, to investigate your use
                of the Services in order to determine whether a violation of the
                Agreement has occurred or to comply with any applicable law,
                regulation, legal process or governmental request.
              </span>
            </p>
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>
                  Consent to receive periodic messages from Siembra
                </strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                As part of the Services, Siembra may send communications (such
                as SMS, emails, and push notifications) to Subscribers of a
                class group. For example, upon adding a new Subscriber to a
                class group, such new Subscriber will receive a welcome message,
                instructions on how to stop receiving messages, and information
                on how to avoid certain charges by downloading the Siembra
                Application. Siembra also may inform Subscribers who have not
                replied that they automatically will be removed from the class
                group if they do not reply. Siembra may send other
                administrative messages as well. BY SIGNING UP FOR THE SERVICES,
                YOU AGREE TO RECEIVE COMMUNICATIONS FROM SIEMBRA, AND YOU
                REPRESENT AND WARRANT THAT EACH USER YOU INVITE AND/OR ADD TO A
                GROUP HAS CONSENTED TO RECEIVE COMMUNICATIONS FROM SIEMBRA AS
                WELL AS FROM YOU AND ANYONE ELSE IN THE GROUP. You agree to
                indemnify and hold Siembra harmless for and against any and all
                claims, liabilities, damages (actual and consequential), losses
                and expenses (including attorneys’ fees) arising from or in any
                way related to your breach of the foregoing.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                To permanently cease receiving SMS text messages, please follow
                the instructions below:
              </span>
            </p>
            <ul style={{ fontSize: "small" }}>
              <li>Text STOP, CANCEL, or UNSUBSCRIBE:</li>
              <li>in reply to any SMS message from that group,</li>
              <li>
                to a Siembra long-code (a phone number issued by Siembra), or
              </li>
            </ul>
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>Content </strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                You agree that you may only use the Services in accordance with
                Siembra’s Community Guidelines.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                All materials displayed or performed or available on the
                Services (including, but not limited to text, graphics, data,
                articles, photographs, images, illustrations, User Submissions
                (defined below), and so forth (all of the foregoing, the
                “Content”)) are protected by copyright and other intellectual
                property laws. You may only use the Services for your own
                personal, non-commercial use and only in accordance with the
                terms of this Agreement and all laws that apply to you. Siembra
                is not responsible for your use of the Services in any way that
                breaks the law. You agree you will abide by all copyright
                notices, trademark rules, information, and restrictions
                contained in any Content accessed through the Services, and will
                not use, copy, reproduce, modify, translate, publish, broadcast,
                transmit, distribute, perform, upload, display, license, sell or
                otherwise exploit for any purposes whatsoever any Content or
                third party submissions or other proprietary rights not owned by
                you: (i) without the express prior written consent of the
                respective owners, and (ii) in any way that violates anyone
                else’s (including Siembra’s) rights.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                You may download or copy certain Content for personal
                non-commercial use only, provided that you maintain all
                copyright and other notices contained in such Content. Copying
                or storing of any Content other than personal, noncommercial use
                is expressly prohibited without prior written permission from
                Siembra or from the copyright holder identified in such
                Content’s copyright notice. You will not link to the Services
                without Siembra’s prior written consent, except in accordance
                with the terms of this Agreement.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                In the course of using the Services, you and other users may
                provide information which may be used by Siembra in connection
                with the Services and which may be visible to certain other
                users. All materials, information and content that you post or
                otherwise provide to Siembra (and in connection with the
                Services) are your “User Submissions.” You retain ownership of
                the rights you may have in your User Submissions, provided that
                Siembra hereby is and will be granted a non-exclusive,
                worldwide, royalty free, perpetual, irrevocable, and
                transferable right to use such User Submissions (including all
                related intellectual property rights) and to allow other users
                to do so (however, Siembra will only share your personally
                identifiable information in accordance with Siembra’s current
                Privacy Policy). Furthermore, Siembra (and any other user who
                accesses User Submissions) retains the right to reformat,
                modify, create derivative works of, excerpt, and translate any
                User Submissions submitted by you. You grant other users of the
                Services a nonexclusive license to access your User Submissions
                through the Services, and to exercise rights in and to such User
                Submissions and to view such User Submissions as permitted
                through the functionality of the Services and under this
                Agreement (for example, the recipients of a message sent by you
                will be able to save, copy, edit and store that message). All
                information publicly posted or privately transmitted through the
                Services is the sole responsibility of the person from which
                such content originated and that Siembra will not be liable for
                any errors or omissions in any content. Siembra cannot guarantee
                the identity of any other users with whom you may interact in
                the course of using the Services. Additionally, Siembra cannot
                guarantee the authenticity of any data which users may provide
                about themselves. You acknowledge that all Content accessed by
                you using the Services is at your own risk and you will be
                solely responsible for any damage or loss to any party resulting
                therefrom.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                Under no circumstances will Siembra be liable in any way for any
                Content, including, but not limited to, any errors or omissions
                in any Content, or any loss or damage of any kind incurred in
                connection with use of or exposure to any Content posted,
                emailed, accessed, transmitted, or otherwise made available via
                the Services.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                Also, be sure to read our Community Guidelines, which include
                best practices for how to keep the Siembra community safe. We
                need to work together to ensure Siembra is a secure and
                supportive environment.
              </span>
            </p>
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>Your restrictions and responsibilities</strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                You warrant, represent and agree that you will not contribute
                any Content or otherwise use (or encourage any third party to
                use) the Services in a manner that;
              </span>
            </p>
            <ul style={{ fontSize: "small" }}>
              <li>
                infringes or violates the intellectual property rights or
                proprietary rights, rights of publicity or privacy, or other
                rights of any third party;
              </li>
              <li>violates any law, statute, ordinance or regulation;</li>
              <li>
                is harmful, fraudulent, threatening, abusive, harassing,
                tortuous, defamatory, vulgar, obscene, libelous, or otherwise
                objectionable as reasonably determined by Siembra;
              </li>
              <li>
                jeopardizes the security of your account in any way, such as
                allowing someone else access to your account or password;
              </li>
              <li>
                attempts, in any manner, to obtain the password, account, or
                other security information from any other user;
              </li>
              <li>
                violates the security of any computer network, or cracks any
                passwords or security encryption codes;
              </li>
              <li>
                runs Maillist, Listserv, any form of auto-responder or “spam” on
                the Services, or any processes that run or are activated while
                you are not logged into the Services, or that otherwise
                interfere with the proper working of the Services (including by
                placing an unreasonable load on the Services’ infrastructure);
              </li>
              <li>
                “crawls,” “scrapes,” or “spiders” any page, data, or portion of
                or relating to the Services or Content (through use of manual or
                automated means);
              </li>
              <li>copies or stores any significant portion of the Content;</li>
              <li>
                decompiles, reverse engineers, or otherwise attempts to obtain
                the source code or underlying ideas or information of or
                relating to the Services (or the products of Siembra’s third
                party service providers).
              </li>
            </ul>
            <p>
              <span style={{ fontSize: "small" }}>
                Siembra reserves the right to terminate access of any third
                party application or service to our published or unpublished
                APIs.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                Siembra reserves the right (but does not have the obligation) to
                remove any Content from the Services at any time, for any reason
                (including, but not limited to, upon receipt of claims or
                allegations from third parties or authorities relating to such
                Content or if Siembra is concerned that you may have breached
                the immediately preceding sentence), or for no reason at all.
                You, not Siembra, remain solely responsible for all Content that
                you upload, post, email, transmit, or otherwise disseminate
                using, or in connection with, the Services, and you warrant that
                you possess all rights necessary to provide such content to
                Siembra and to grant Siembra the rights to use such information
                in connection with the Services and as otherwise provided herein
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                You will be responsible for obtaining and maintaining any
                equipment or ancillary services needed to connect to, access, or
                otherwise use the Services, including, without limitation,
                modems, hardware, software, and long distance or local telephone
                service. You will be responsible for ensuring that such
                equipment or ancillary services are compatible with the
                Services. You will be responsible for withholding, filing, and
                reporting all taxes, duties and other governmental assessments
                associated with your activity in connection with the Services.
              </span>
            </p>
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>Copyright dispute policy </strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                Under the Digital Millenium Copyright Act (the “DMCA”), online
                service providers such as Siembra have the right, but not the
                obligation, to remove material that allegedly violates someone’s
                copyright. We respect others’ intellectual property rights, and
                we reserve the right to delete or disable Content alleged to be
                infringing, and to terminate the accounts of repeat alleged
                infringers.
              </span>
            </p>
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>Third party websites </strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                The Services may contain links to third party websites that are
                not owned or controlled by Siembra. When you access third party
                websites, you do so at your own risk. Siembra encourages you to
                be aware when you leave the Services and to read the terms and
                conditions and privacy policy of each third party website that
                you visit or utilize.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                Siembra has no control over, and assumes no responsibility for,
                the content, accuracy, privacy policies, or practices of or
                opinions expressed in any third party websites. In addition,
                Siembra will not and cannot monitor, verify, censor or edit the
                content of any third party site. By using the Services, you
                expressly relieve and hold harmless Siembra from any and all
                liability arising from your use of any third party website or
                service.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                Your interactions with organizations and/or individuals found on
                or through the Services, including payment and delivery of goods
                or services, and any other terms, conditions, warranties or
                representations associated with such dealings, are solely
                between you and such organizations and/or individuals. You
                should make whatever investigation you feel necessary or
                appropriate before proceeding with any online or offline
                transaction with any of these third parties. You agree that
                Siembra will not be responsible or liable for any loss or damage
                of any sort incurred as the result of any such dealings.
              </span>
            </p>
            <p>
              <span style={{ fontSize: "small" }}>
                If there is a dispute between users on this site, or between
                users and any third party, you understand and agree that Siembra
                is under no obligation to become involved. In the event that you
                have a dispute with one or more other users, you hereby release
                Siembra, its officers, employees, agents, and successors in
                rights from claims, demands, and damages (actual and
                consequential) of every kind or nature, known or unknown,
                suspected or unsuspected, disclosed or undisclosed, arising out
                of or in any way related to such disputes and/or our service. If
                you are a California resident, you will and hereby do waive
                California Civil Code Section 1542, which says: “A general
                release does not extend to claims which the creditor does not
                know or suspect to exist in his favor at the time of executing
                the release, which, if known by him must have materially
                affected his settlement with the debtor.”
              </span>
            </p>
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>Warranty and disclaimer </strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                Siembra has no special relationship with or fiduciary duty to
                you. You acknowledge that Siembra has no control over, and no
                duty to take any action regarding: which users gains access to
                the Services; what Content you accesses via the Services; what
                effects the Content may have on you; how you may interpret or
                use the Content; or what actions you may take as a result of
                having been exposed to the Content. You release Siembra from all
                liability for you having acquired or not acquired Content
                through the Services. The Services may contain, or direct you to
                websites or services containing, information that some people
                may find offensive or inappropriate. Siembra makes no
                representations concerning any content contained in or accessed
                through the Services, and Siembra will not be responsible or
                liable for the accuracy, copyright compliance, legality or
                decency of material contained in or accessed through the
                Services. Siembra makes no representations or warranties
                regarding suggestions or recommendations of services or products
                (including Content provided by publishers) offered or purchased
                through the Services. Products and services purchased or offered
                (whether or not following such recommendations and suggestions)
                the Services are provided “AS IS” without any warranty of any
                kind from Siembra or others unless, with respect to others
                (only), otherwise made expressly and unambiguously in writing by
                a designated third party for a specific product. THE SERVICES,
                CONTENT, AND ANY SOFTWARE ARE PROVIDED ON AN “AS IS” BASIS,
                WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE
                UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW
                LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE
                LIMITATIONS MAY NOT APPLY TO YOU.
              </span>
            </p>
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>Indemnity </strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                You will indemnify and hold Siembra, its parents, subsidiaries,
                affiliates, officers, and employees harmless (including, without
                limitation, from all damages, liabilities, settlements, costs
                andattorneys’ fees) from any claim or demand made by any third
                party due to or arising out of your access to the Services, use
                of the Services, your violation of this Agreement, or the
                infringement by you or any third party using your account of any
                intellectual property or other right of any person or entity.
              </span>
            </p>
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>Limitation of Liability </strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, IN NO EVENT
                WILL SIEMBRA OR ITS SUPPLIERS OR ITS SERVICE PROVIDERS, OR THEIR
                RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
                WITH RESPECT TO THE SERVICES OR THE SUBJECT MATTER OF THIS
                AGREEMENT UNDER ANY CONTRACT, NEGLIGENCE, TORT, STRICT LIABILITY
                OR OTHER LEGAL OR EQUITABLE THEORY (I) FOR ANY AMOUNT IN THE
                AGGREGATE IN EXCESS OF THE GREATER OF $100 OR THE FEES PAID BY
                YOU TO SIEMBRA HEREUNDER DURING THE 12-MONTH PERIOD PRECEDING
                THE APPLICABLE CLAIM; (II) FOR ANY INDIRECT, INCIDENTAL,
                PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER; (III)
                FOR DATA LOSS OR COST OF PROCUREMENT OF SUBSTITUTE GOODS OR
                SERVICES; OR (IV) FOR ANY MATTER BEYOND SIEMBRA’S REASONABLE
                CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS
                AND EXCLUSIONS MAY NOT APPLY TO YOU.
              </span>
            </p>
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>Termination</strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                This Agreement will remain in full force and effect while you
                use the Services. You may terminate your use of the Services or
                your account at any time by deleting your account in your
                account settings tab or by contacting us at contact@Siembra.com.
                Siembra may disable or terminate your access to the Services or
                your account at any time, for any reason (without cause or for
                your violation of any term of this Agreement), and without
                warning or notice, which may result in the forfeiture of all
                information associated with your membership. Upon termination of
                your account, your right to use the Services, and any Content
                will immediately cease; however, Siembra may retain messaging
                data or other data and information pursuant to this Agreement
                and as necessary for auditing purposes and as required by law.
                All provisions of this Agreement which, by their nature, should
                survive termination, will survive termination, including,
                without limitation, ownership provisions, warranty disclaimers,
                and limitations of liability.
              </span>
            </p>
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>Choice of law and arbitration </strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                This Agreement will be governed by and construed in accordance
                with the laws of the State of California without regard to the
                conflict of laws provisions thereof. Any dispute arising from or
                relating to the subject matter of this Agreement will be finally
                settled in small claims court or by arbitration in San
                Francisco, California or in a location that is reasonably
                convenient for you, using the English language in accordance
                with the Arbitration Rules and Procedures of Judicial
                Arbitration and Mediation Services, Inc. (“JAMS”) then in
                effect, by one commercial arbitrator with substantial experience
                in resolving intellectual property and commercial contract
                disputes, who will be selected from the appropriate list of JAMS
                arbitrators in accordance with the Arbitration Rules and
                Procedures of JAMS. If you cannot afford or otherwise do not
                want to pay arbitration filing, administrative, hearing and/or
                other fees and cannot obtain a waiver of fees, we will consider
                in good faith any request by you for us to bear the cost of
                those fees. We will pay any administration fee, arbitration fees
                and fees and charges of attorneys, experts and witnesses if and
                to the extent we are required to pay such fees and charges by
                law or in order to make this arbitration provision enforceable.
                By agreeing to these terms, you acknowledge that you may not
                participate in a class action in court or in arbitration, either
                as a class representative, class member or class opponent.
                ACCORDINGLY, YOU ACKNOWLEDGE THAT YOU MAY NOT HAVE ACCESS TO A
                COURT (OTHER THAN A SMALL CLAIMS COURT) OR TO A JURY TRIAL.
                Judgment upon the award so rendered may be entered in a court
                having jurisdiction, or application may be made to such court
                for judicial acceptance of any award and an order of
                enforcement, as the case may be. Notwithstanding the foregoing,
                each party will have the right to institute an action in a court
                of proper jurisdiction for injunctive or other equitable relief
                pending a final decision by the arbitrator.
              </span>
            </p>
            <h3>
              <span style={{ fontSize: "medium" }}>
                <strong>Miscellaneous </strong>
              </span>
            </h3>
            <p>
              <span style={{ fontSize: "small" }}>
                The failure of either party to exercise, in any respect, any
                right provided for herein will not be deemed a waiver of any
                further rights hereunder. Siembra will not be liable for any
                failure to perform its obligations hereunder where such failure
                results from any cause beyond Siembra’s reasonable control,
                including, without limitation, mechanical, electronic or
                communications failure or degradation (including “line-noise”
                interference). If any provision of this Agreement is found to be
                unenforceable or invalid, that provision will be limited or
                eliminated to the minimum extent necessary so that this
                Agreement will otherwise remain in full force and effect and
                enforceable. This Agreement is not assignable, transferable or
                sub licensable by you except with Siembra’s prior written
                consent. Siembra may transfer, assign or delegate this Agreement
                and its rights and obligations without consent. Both parties
                agree that this Agreement is the complete and exclusive
                statement of the mutual understanding of the parties and
                supersedes and cancels all previous written and oral agreements,
                communications and other understandings relating to the subject
                matter of this Agreement, and that all modifications must be in
                a writing signed by both parties, except as otherwise provided
                herein. This Agreement and any subsequent versions of this
                Agreement posted to the Services will be deemed a writing signed
                by both parties. No agency, partnership, joint venture, or
                employment is created as a result of this Agreement and you do
                not have any authority of any kind to bind Siembra in any way
                whatsoever.
              </span>
            </p>
          </div>
    </Modal>
  );
}

export default UseAcceptance;
