import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, FormGroup, Input, Label } from "reactstrap";
import swal from "sweetalert";
import { CommonButton } from "../../components";
import Loading from "../../components/Loading/Loading";
import AuthService from "../../services/AuthService";
import "./ForgotPassword.scss";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const ForgotPassword = () => {
  const history = useHistory();
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setUserEmail(e.target.value.toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let payload = {
      email: userEmail,
    };
    AuthService.resetPassword(payload)
      .then((res) => {
        if (res.status === 200) {
          // swal("Success!", "", "success");
          history.push(`/verify-email`);
        }
        setLoading(false);
      })
      .catch((error) => {
        swal("", error?.response?.data?.error, "error");
        setLoading(false);
      });
  };
  return (
    <>
      {loading && <Loading />}
      <div className="forgotPswdWrap">
        <div className="forgotPswdWrapInner">
          <img
            src={IMG_URL + "forgot-pswd-bg.png"}
            alt="forgotPswdBg"
            className="forgotPswdBg"
          />
          <div className="forgotPswdArea text-center">
            <img
              src={IMG_URL + "white-logo.png"}
              alt="whiteLogo"
              className="img-fluid logoImg"
            />
            <div className="forgotFormWrap">
              <Form onSubmit={handleSubmit} autoComplete="off">
                <div className="boxWrap">
                  <div className="boxwrapInner">
                    <h1 className="forgotTitle">Forgot your Password?</h1>
                    <p>
                      Please enter your registered email address to reset your
                      password.
                    </p>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                          Registered Email Address{" "}
                        <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                        type="email"
                        name="userEmail"
                        className="form-control"
                        placeholder="Enter your registered email address"
                        value={userEmail}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <CommonButton
                      name="Resend Password"
                      type="submit"
                      btnClass="primaryBtn resendBtn"
                      btnWidth="320px"
                      btnHeight="54px"
                    />
                    <div className="rememberText">
                      Remember it?{" "}
                      <Link to="login" className="primaryText">
                        Sign In
                      </Link>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
