import React, { useEffect, useState } from "react";

import Ionicon from "react-ionicons";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  Button,
  NavItem,
} from "reactstrap";

// Dropdown Tabs Content
import ChatExample from "./TabsContent/ChatExample";
import TimelineEx from "./TabsContent/TimelineExample";
import SysErrEx from "./TabsContent/SystemExample";
import { useDispatch, useSelector } from "react-redux";
import MsgDataService from "../../../services/MsgService";
import { messageNotificationAction } from "../../../reducers/MessageNotificationReducer";
import { Roles } from "../../../utils/Roles";
import LaddersDataService from "../../../services/LaddersService";
import { useHistory } from "react-router-dom";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const tabsContent = [
  {
    title: "Messages",
    content: <ChatExample />,
  },
  {
    title: "Events",
    content: <TimelineEx />,
  },
  {
    title: "System Errors",
    content: <SysErrEx />,
  },
];

function getTabs() {
  return tabsContent.map((tab, index) => ({
    title: tab.title,
    getContent: () => tab.content,
    key: index,
  }));
}

const HeaderDots = () => {
  let dispatch = useDispatch();
  let history = useHistory();
  let userData = useSelector(state => state.UserReducer.userObj);
  let notificationData = useSelector(state => state.MessageNotificationReducer.messageNotification);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userData?.role === Roles.STUDENT_NAME) {
      getUnreadCountforStudentRole();
    } else if (userData?.role === Roles.PARENT_NAME) {
      getParentRoleStudentList();
    }
  }, []);

  const getUnreadCountforStudentRole = () => {
    setLoading(true);
    MsgDataService.getUnreadCountForStudent()
      .then((res) => {
        if (res?.status === 200) {
          dispatch(messageNotificationAction(res?.data?.total_notification))
        }
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };

  // Parent Students
  const getParentRoleStudentList = () => {
    setLoading(true);
    MsgDataService.getTotalParentCount()
      .then((res) => {
        if (res?.status === 200) {
          dispatch(messageNotificationAction(res?.data?.count))
        setLoading(false);
      }

    }).catch(error => {
      console.log('error from the service', error)
      setLoading(false);
    })
  }

  // const getStudentWiseunreadCount = (userId, school_Id) => {
  //   let payload = {
  //     user_id: userId,
  //     school_id: school_Id
  //   }
  //   setLoading(true);
  //   MsgDataService.getParentRoleTotalUnreadCount(payload)
  //     .then(res => {
  //       if (res?.status === 200) {
  //         dispatch(messageNotificationAction(res?.data?.total_notification))
  //       }
  //       setLoading(false);
  //     }).catch(error => {
  //       console.log('total count error', error);
  //       setLoading(false);
  //     })
  // }

  const handleClickNotify = () => {
    if (userData?.role === Roles.STUDENT_NAME) {
      history.push(`/student/message`);
    } else if (userData?.role === Roles.PARENT_NAME) {
      history.push(`/parent/message`);
    }
  }

  return (
    <>
      <div className="header-dots">
        <UncontrolledDropdown>
          <DropdownToggle className="p-0 mr-2" color="link">
            {userData.role === 'student' ?
            <div className="icon-wrapper icon-wrapper-alt rounded-circle notifyIconBlock" onClick={handleClickNotify}>
              <img src={IMG_URL+"notifyIcon.png"} alt='notifyIcon' width={20} height={16} className='img-fluid' />
              {notificationData ? <div className="badge badge-dot notifyBadge">{notificationData}</div> : ''}
            </div> : ''}
            {userData.role === 'parent' ?
            <div className="icon-wrapper icon-wrapper-alt rounded-circle notifyIconBlock" onClick={handleClickNotify}>
              <img src={IMG_URL+"notifyIcon.png"} alt='notifyIcon' width={20} height={16} className='img-fluid' />
            { notificationData ? <div className="badge badge-dot notifyBadge">{notificationData}</div> : ''}
            </div> : ''}

          </DropdownToggle>
          {/* <DropdownMenu right className="dropdown-menu-xl rm-pointers">
          <div className="dropdown-menu-header mb-0">
            <div className="dropdown-menu-header-inner bg-deep-blue">
              <div
                className="menu-header-image opacity-1"
                style={{
                  backgroundImage: "url(" + city3 + ")",
                }}
              />
              <div className="menu-header-content text-dark">
                <h5 className="menu-header-title">Notifications</h5>
                <h6 className="menu-header-subtitle">
                  You have <b>21</b> unread messages
                </h6>
              </div>
            </div>
          </div>
          <Tabs
            tabsWrapperClass="body-tabs body-tabs-alt"
            transform={false}
            showInkBar={true}
            items={getTabs()}
          />
          <Nav vertical>
            <NavItem className="nav-item-divider" />
            <NavItem className="nav-item-btn text-center">
              <Button
                size="sm"
                className="btn-shadow btn-wide btn-pill"
                color="focus"
              >
                View Latest Changes
              </Button>
            </NavItem>
          </Nav>
        </DropdownMenu> */}
        </UncontrolledDropdown>
      </div>
    </>
  )
}

export default HeaderDots
