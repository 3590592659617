import React, { Fragment, useState, useEffect, useRef } from "react";
import Loader from "react-loader-spinner";
import CustomLoader from "../../customLoader/CustomLoader";
import GetAdminService from "../../../services/admin/GetAdminService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const UpdateGoalsForSchools = () => {
  const [schoolName, setSchoolName] = useState();
  const [showSchoolNameContainer, setShowSchoolNameContainer] = useState(false);
  const [schoolNameList, setSchoolNameList] = useState();
  const schoolNameRef = useRef();
  const timeoutRef = useRef();
  const [schoolNameLoading, setSchoolNameLoading] = useState(false);
  const [schoolId, setSchoolId] = useState();
  const [loading, setLoading] = useState(false);
  const [oldGoalData, setOldGoalData] = useState();
  const [newGoalData, setNewGoalData] = useState();
  const [oldGoalId, setOldGoalId] = useState();
  const [newGoalId, setNewGoalId] = useState();

  const getSchoolsList = (value) => {
    setSchoolNameLoading(true);
    GetAdminService.getSchoolsList(value)
      .then((data) => {
        console.log("data from the service", data);
        setSchoolNameList(data?.data?.school);
        setSchoolNameLoading(false);
        setShowSchoolNameContainer(true);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setSchoolNameLoading(false);
      });
  };

  const getGoalData = (id) => {
    setLoading(true);
    let obj = { school_id: id };
    GetAdminService.goalBySchool(obj)
      .then((data) => {
        console.log("student from the service", data);
        setOldGoalData(data.data.goal_name);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });

    setLoading(true);
    GetAdminService.goalPublish(id)
      .then((data) => {
        console.log("student from the service", data);
        setNewGoalData(data.data.objects);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  const updateSchoolGoal = () => {
    let obj = {
      school_id: schoolId,
    };
    if (parseInt(oldGoalId)) obj.old_goal_id = parseInt(oldGoalId);
    if (parseInt(newGoalId)) obj.new_goal_id = parseInt(newGoalId);
    console.log(obj);
    setLoading(true);
    GetAdminService.updateSchoolGoal(obj)
      .then((data) => {
        console.log("student from the service", data);
        setLoading(false);
        if (data.data.status === "success")
          swal({
            text: "Goals for schools updated successfully!",
            icon: "success",
            buttons: {
              confirm: true,
            },
            closeOnClickOutside: false,
          });
        setSchoolName("");
        setSchoolId();
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  const handleSchoolName = () => {
    clearTimeout(timeoutRef.current);
    setSchoolName(schoolNameRef.current.value);
    if (!schoolNameRef.current.value) {
      setShowSchoolNameContainer(false);
      setSchoolNameList([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getSchoolsList(schoolNameRef.current.value);
      }, 1000);
    }
  };

  const handleSelectedSchoolName = (item) => {
    setSchoolNameList([item]);
    setShowSchoolNameContainer(false);
    setSchoolName(item.name);
    setSchoolId(item?.id);
    getGoalData(item?.id);
  };

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="csv-import-container">
            <div className="flex canned-message-heading">
              <p>
                <span style={{ fontSize: "25px", fontWeight: "400" }}>
                  Update Goals For Schools
                </span>
              </p>
            </div>
            <div className="school-name-container">
              <p>Select School</p>
              <input
                ref={schoolNameRef}
                className="search-admin-school-input"
                type="text"
                placeholder="School Name"
                value={schoolName}
                onChange={handleSchoolName}
                autoComplete="off"
              />
              {showSchoolNameContainer && (
                <div>
                  <ul className="admin-school-name-list">
                    {schoolNameLoading && <CustomLoader />}
                    {schoolNameList?.length > 0 &&
                      schoolNameList.map((item, index) => (
                        <li
                          onClick={() => handleSelectedSchoolName(item)}
                          key={index}
                          className="flex admin-school-list cursor"
                        >
                          <span>{item.name}</span>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>

            {schoolId && (
              <div>
                {loading ? (
                  <CustomLoader />
                ) : (
                  <div style={{ padding: "16px" }}>
                    <div style={{ marginBottom: "20px" }}>
                      <label>Select Old Goal </label>
                      <br />
                      <select
                        name="ack"
                        className="require-input-name"
                        style={{
                          padding: "5px",
                          borderRadius: "5px",
                          marginRight: "20px",
                          minWidth: "350px",
                        }}
                        value={oldGoalId}
                        onChange={(e) => setOldGoalId(e.target.value)}
                      >
                        <option value="">--Select Goal--</option>
                        {oldGoalData?.map((item, index) => (
                          <option key={index} value={item?.id}>
                            {item.goal_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                      <label> Select New Goal</label>
                      <br />
                      <select
                        name="ack"
                        className="require-input-name"
                        style={{
                          padding: "5px",
                          borderRadius: "5px",
                          marginRight: "20px",
                          minWidth: "350px",
                        }}
                        value={newGoalId}
                        onChange={(e) => setNewGoalId(e.target.value)}
                      >
                        <option value="">--Select Goal--</option>
                        {newGoalData?.map((item, index) => (
                          <option key={index} value={item?.goal?.id}>
                            {item?.goal?.goal_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <button
                      className={"save-modal-button"}
                      onClick={updateSchoolGoal}
                    >
                      <FontAwesomeIcon
                        icon={faSave}
                        style={{ margin: "0 5px", color: "white" }}
                      />
                      Update Goals
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateGoalsForSchools;
