import { Fragment } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


const CustomDatePicker=({date , setDate , placeHolder})=>{
    let todaysdate=new Date();
    let tomorrrowsDate = todaysdate.setDate(todaysdate.getDate() + 1)
    return(
        <Fragment>
            <DatePicker
                minDate={tomorrrowsDate}
                className="custom-date-picker"
                placeholderText={placeHolder}
                dateFormat="yyyy-MM-dd"
                selected={date} 
                onChange={(date)=>setDate(date)}
            />
        </Fragment>
    )
}
export default CustomDatePicker;