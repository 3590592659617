import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { Card, CardBody, Col, FormGroup, Input, Row } from 'reactstrap';
import AppHeader from '../../../Layout/AppHeader';
import AppSidebar from '../../../Layout/AppSidebar';
import LaddersDataService from '../../../services/LaddersService';
import MessageTemplateService from '../../../services/MessageTemplateService';
import PortFolioService from '../../../services/PortfolioService';
import Loading from '../../../components/Loading/Loading';
import './ParentPassion.scss';

const ParentPassion = () => {
    const [loading, setLoading] = useState(false);
    const [language, setLanguage] = useState(false);
    const [langVal, setLangVal] = useState('en');
    const [studentList, setStudentList] = useState([]);
    const [studentId, setStudentId] = useState();
    const [allInterest, setAllInterest] = useState([]);
    const [interestList, setInterestList] = useState([]);
    const [chekcedInterest, setChekcedInterest] = useState([]);

    const handleChangeLanguage = (checked) => {
        setLanguage(checked)
        let language = checked === true ? "es" : "en"
        setLangVal(language);

        if (language === 'es' && interestList && interestList.length > 0) {
            getTranslatedVal(language, interestList)
        } else {
            setInterestList(allInterest.map(a => { return { ...a } }))
        }
    };

    useEffect(() => {
        getStudentList();
    }, [])

    const getStudentList = () => {
        setLoading(true);
        LaddersDataService.getParentRoleStudentList().then(res => {
            if (res?.status === 200) {
                console.log("data22",res?.data?.list)
                if (res?.data && res?.data?.list && res?.data?.list.length > 0) {
                    let studentArr = res?.data?.list || [];
                    // let tempArr = [{ name: "Vibha", id: 99463 }, { name: "heena", id: 5678 }];
                    // studentArr.push(...tempArr);
                    setStudentList(studentArr);
                    setStudentId(studentArr[0]?.id);
                    setLoading(false);
                }
            }

        }).catch(error => {
            console.log('error from the service', error)
            setLoading(false);
        })
    }

    const handleChangeStudent = (e) => {
        setStudentId(e.target.value);
    }

    useEffect(() => {
        if (studentList && studentList.length > 0 && studentId) {
            getParentInterest();
        }
    }, [studentList, studentId])

    const getParentInterest = () => {
        let payload = {
            student_id: studentId || '',
        }
        setLoading(true);
        setLoading(true);
        PortFolioService.getParentRoleInterest(payload).then(res => {
            console.log("res",res?.data);
            if (res?.status === 200) {
                if (res?.data) {
                    setChekcedInterest(res?.data?.interests);
                    if (res?.data?.total_intrests && res?.data?.total_intrests.length > 0) {

                        // set for allInterest
                        let orgInterestArr = res?.data?.total_intrests && res?.data?.total_intrests.length > 0 ? res?.data?.total_intrests.map(item => ({ ...item, esName: item.name })) : []
                            console.log("org",orgInterestArr);
                        let interestArr = res?.data?.total_intrests && res?.data?.total_intrests.length > 0 ? res?.data?.total_intrests.map(item => ({ ...item, esName: item.name })) : [];

                        if (langVal === 'es') {
                            // setInterestList(interestArr);
                            getTranslatedVal(langVal, interestArr);
                        } else {
                            setAllInterest(orgInterestArr);
                            setInterestList(interestArr);
                        }
                    }
                    setLoading(false);
                }
            }
        }).catch(error => {
            console.log('error from the service', error)
            setLoading(false);
        })
    }

    const getTranslatedVal = (lang, intArr) => {
        let payload = {
            list_of_values: intArr.map(val => val.name) || [],
            to: lang // 'es'
        }
        setLoading(true);
        MessageTemplateService.parentTranslateBragbook(payload)
            .then((res) => {
                if (res?.status === 200) {
                    let esArr = res?.data?.list_of_results;
                    let newArr = [...intArr];
                    newArr.map((item, index) => item.esName = esArr[index]);
                    setInterestList(newArr);
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log("error from the service", error);
            });
    }

    return (
        <>
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner parentPassion">
                        <Row>
                            <Col lg="12">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h1 className="title-main">My Passion</h1>
                                    <Switch
                                        onChange={handleChangeLanguage}
                                        checked={language}
                                        width={52}
                                        height={24}
                                        handleDiameter={18}
                                        onColor={'#28131A'}
                                        offColor={'#049804'}
                                        uncheckedIcon={<div className="switchIncon uncheckIcon">EN</div>}
                                        checkedIcon={<div className="switchIncon checkIcon">ES</div>}
                                        className="langSwitch"
                                    />
                                </div>
                            </Col>
                        </Row>
                        {studentList && studentList.length > 0 ?
                            <Row>
                                <Col lg="12">
                                    <FormGroup className='text-left studentSelect'>
                                        <Input
                                            className="form-control"
                                            type='select'
                                            name='studentId'
                                            value={studentId || ''}
                                            onChange={handleChangeStudent}
                                        >
                                            {studentList.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                );
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            : ''}
                        <Row>
                            <Col lg="12" className="mx-auto">
                                <Card className="main-card mb-3 interest-main">
                                    <CardBody>
                                        {loading ? (
                                            <Loading />
                                        ) : (
                                            <>
                                                <Row>
                                                    {interestList &&
                                                        interestList.map((item, index) => {
                                                            return (
                                                                <Col lg="6" key={index}>
                                                                    <div className="shadow-box">
                                                                        <label className="chekbox-container">
                                                                            {item.esName}
                                                                            <input
                                                                                type="checkbox"
                                                                                disabled
                                                                                checked={chekcedInterest && chekcedInterest.length > 0 && chekcedInterest.find(elem => elem.intrest_name === item.name) ? true : false}
                                                                            />
                                                                            <span className="checkmark" />
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            );
                                                        })}
                                                </Row>
                                            </>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ParentPassion;
