import React from 'react';
import Loader from "react-loader-spinner";
import './CustomLoader.css';

const CustomLoader = () => {
  return (
    <>
      <div className="loader_alignment">
        <Loader
          type="TailSpin"
          color="#E36221"
          height={40}
          width={40}
        />
      </div>
    </>
  )
}
export default CustomLoader