import React, { Fragment, useState, useEffect, useRef } from "react";
import CustomLoader from "../../customLoader/CustomLoader";
import GetAdminService from "../../../services/admin/GetAdminService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import CustomPagination from "../../pagination/Pagination";
import { Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckCircle,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalBody } from "reactstrap";
import swal from "sweetalert";

const MessageModal = ({ isOpen, setIsOpen, item, function1 }) => {
  return (
    <Fragment>
      <Modal isOpen={isOpen} centered={true}>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            <p>By Clicking OK the Contract for {item.name} will be Closed!!!</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={() => function1(item.id, false)}
                style={{
                  backgroundColor: "#009900",
                  color: "white",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginRight: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon
                  style={{ margin: "0 5px" }}
                  color="white"
                  icon={faCheck}
                />
                Ok
              </button>
              <button
                onClick={() => setIsOpen(false)}
                style={{
                  backgroundColor: "#efefef",
                  color: "black",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginLeft: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon style={{ margin: "0 5px" }} icon={faTimes} />
                Cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const ContractSetup = () => {
  const [districtName, setDistrictName] = useState();
  const [showDistrictNameContainer, setShowDistrictNameContainer] = useState(
    false
  );
  const [districtNameList, setDistrictNameList] = useState();
  const [districtID, setDistrictID] = useState();
  const [pageData, setPageData] = useState();
  const districtNameRef = useRef();
  const timeoutRef = useRef();
  const [districtNameLoading, setDistrictNameLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [clickedSchool, setClickedSchool] = useState();

  const getDistrictsList = (value) => {
    setDistrictNameLoading(true);
    GetAdminService.getDistrictsList(value)
      .then((data) => {
        console.log("data from the service", data);
        setDistrictNameList(data?.data?.objects);
        setDistrictNameLoading(false);
        setShowDistrictNameContainer(true);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setDistrictNameLoading(false);
      });
  };

  const handleDistrictName = () => {
    clearTimeout(timeoutRef.current);
    setDistrictName(districtNameRef.current.value);
    if (!districtNameRef.current.value) {
      setShowDistrictNameContainer(false);
      setDistrictNameList([]);
      setPageData([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getDistrictsList(districtNameRef.current.value);
      }, 1000);
    }
  };

  const handleSelectedDistrictName = (item) => {
    setDistrictID(item?.id);
    setDistrictNameList([item]);
    setShowDistrictNameContainer(false);
    setDistrictName(item.name);
    getDistrictPage(item?.id);
  };

  const getDistrictPage = (id) => {
    let obj = {
      district: id,
    };
    setLoading(true);
    GetAdminService.districtPage(obj)
      .then((data) => {
        console.log("data from the service", data);
        setPageData(data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  const changeSubscribe = (school_id, sub) => {
    setOpenMessageModal(false);
    let obj = {
      isSigned: sub,
      // district: districtID,
    };
    console.log(obj);
    setLoading(true);
    GetAdminService.subscribeContract(school_id, obj)
      .then((data) => {
        console.log("data from the service", data);
        getDistrictPage(districtID);
        setLoading(false);
        swal({
          text: sub
            ? "Contract Subscribed Successfully"
            : "Contract Unsubscribed Successfully",
          icon: "success",
          dangerMode: true,
          buttons: {
            confirm: true,
          },
          closeOnClickOutside: true,
        });
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="admin-canned-container">
            <div className="flex canned-message-heading">
              <p>
                <span style={{ fontSize: "25px", fontWeight: "400" }}>
                  Contract Setup with Schools
                </span>
              </p>
            </div>

            <div className="school-name-container">
              <p>Select District</p>
              <div>
                <input
                  ref={districtNameRef}
                  className="search-admin-school-input"
                  type="text"
                  placeholder="District Name"
                  value={districtName}
                  onChange={handleDistrictName}
                  autoComplete="off"
                />
                {showDistrictNameContainer && (
                  <div>
                    <ul
                      className="maintain-group-student-dropdown-menu"
                      style={{
                        width: "700px",
                        maxHeight: "600px",
                        overflowY: "scroll",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      {districtNameLoading && <CustomLoader />}
                      {districtNameList?.length > 0 &&
                        districtNameList.map((item, index) => (
                          <li
                            onClick={() => handleSelectedDistrictName(item)}
                            key={index}
                            className="flex admin-school-list cursor"
                          >
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {loading ? (
              <CustomLoader />
            ) : (
              pageData && (
                <Table
                  className="text-center"
                  bordered
                  style={{
                    width: "90%",
                    background: "white",
                    margin: "0 20px",
                  }}
                >
                  {pageData?.result?.length > 0 &&
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>School Name</th>
                      <th style={{ textAlign: "center" }}>Status</th>
                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>}
                  <tbody>
                    {pageData?.result?.map((item, index) => {
                      return (
                        <tr>
                          {/* <th scope="row">1</th> */}
                          <td style={{ maxWidth: "400px" }}>{item?.name}</td>
                          <td>
                            {item?.isSigned ? (
                              <span
                                style={{
                                  background: "#286090",
                                  borderRadius: "8px",
                                  padding: "4px 8px",
                                  color: "#fff",
                                }}
                              >
                                Contract Signed
                              </span>
                            ) : (
                              <span
                                style={{
                                  background: "#c9302c",
                                  borderRadius: "8px",
                                  padding: "4px 8px",
                                  color: "#fff",
                                }}
                              >
                                Contract Unsigned
                              </span>
                            )}
                          </td>
                          <td>
                            <button
                              disabled={item?.isSigned}
                              className="save-modal-button"
                              style={{
                                marginRight: "20px",
                                opacity: item?.isSigned ? "0.5" : "1",
                              }}
                              onClick={() => changeSubscribe(item.id, true)}
                            >
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                color="#fff"
                              />
                              {"  "}
                              Subscribe Contract
                            </button>
                            <button
                              disabled={!item?.isSigned}
                              style={{
                                opacity: !item?.isSigned ? "0.5" : "1",
                              }}
                              onClick={() => {
                                setOpenMessageModal(true);
                                setClickedSchool(item);
                              }}
                              className="cancel-modal-button"
                            >
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                color="#fff"
                              />
                              {"  "}
                              Unsubscribe Contract
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )
            )}

            {pageData && pageData?.page_list?.length > 1 && (
              <div style={{ margin: "20px" }}>
                <CustomPagination
                  count={pageData && pageData}
                  pageValue={page}
                  setPage={setPage}
                />
              </div>
            )}
            {openMessageModal && (
              <MessageModal
                setIsOpen={setOpenMessageModal}
                isOpen={openMessageModal}
                item={clickedSchool}
                function1={changeSubscribe}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContractSetup;