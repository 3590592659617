// all localstorage operation will be reside here
import cookie from "react-cookies";

function setLocalItem(key, value) {
  localStorage.setItem(key, value);
}

function getLocalItem(key) {
  return localStorage.getItem(key);
}

function removeLocalItem(key) {
  return localStorage.removeItem(key);
}

function setCookie(name, value, options) {
  cookie.save(name, value, options);
}

function getCookie(name) {
  return cookie.load(name);
}

export default class Storage {
  static setUserDetail(userDetail) {
    setLocalItem("siembra-user-detail", JSON.stringify(userDetail));
  }

  static getUserDetail() {
    let userDetail = getLocalItem("siembra-user-detail");
    return JSON.parse(userDetail);
  }

  static setKeepMeLoggedInCookie(value) {
    if (value) {
      setCookie("KeepMeLoggedIn", true, { maxAge: 21321231312 });
    } else {
      setCookie("KeepMeLoggedIn", false, null);
    }
  }

  static getKeepMeLoggedInCookie() {
    return getCookie("KeepMeLoggedIn");
  }

  static setLoginDataInCookie(value, name) {
    if (value, name) {
      setCookie("LoginEmail", value, {  path: "/", });
      setCookie("LoginPassword", name, {  path: "/", })
    } else {
      setCookie("LoginEmail","LoginPassword", false, null);
    }
  }

  static getLoginDataInCookie() {
    return {email:getCookie("LoginEmail"), password: getCookie('LoginPassword')}
  }

  static setTokenInCookie(value) {
    if (value) {
      setCookie("userToken", value, {
        path: "/",
        secure: true,
      });
    } else {
      setCookie("userToken", false, null);
    }
  }

  static getTokenInCookie() {
    return getCookie("userToken");
  }
  static removeTokenCookie() {
    cookie.remove("userToken", { path: "/" });
  }

  static setSessionId(value) {
    if (value) {
      setCookie("sessionId", value, {
        path: "/",
        secure: true,
      });
    } else {
      setCookie("sessionId", false, null);
    }
  }

  static getSessionId() {
    return getCookie("sessionId");
  }
  static removeSessionId() {
    cookie.remove("sessionId", { path: "/" });
  }

  // Clear Local Storage
  static clearLocalStorage() {
    return localStorage.clear();
  }

  // Clear Session Storage
  static clearSessionStorage() {
    return sessionStorage.clear();
  }
}