import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, FormGroup, Input, Label } from 'reactstrap'
import swal from 'sweetalert';
import { CommonButton } from '../../components';
import Loading from '../../components/Loading/Loading';
import { CommonMessages } from '../../constants/CommonMessages';
import AuthService from '../../services/AuthService';
import './ResetPassword.scss';
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const ResetPassword = () => {
  const param = useParams();
  const history = useHistory();
  const initState = { newPswd: '', confirmPswd: '' };
  const [data, setData] = useState(initState);
  const [showNewPswd, setShowNewPswd] = useState(false);
  const [showconfirmPswd, setShowconfirmPswd] = useState(false)
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    let { newPswd, confirmPswd } = data;
    if (newPswd !== confirmPswd) {
      swal('', CommonMessages.passwordMatchError, 'error')
    } else {
      let payload = {
        code: param.id || '',
        password: newPswd || ''
      }
      setLoading(true);
      AuthService.changePassword(payload).then(res => {
        if(res.status === 200){
          swal({
            text: "Password Updated Successfully!",
            icon: "success",
            buttons: {
              OK: true,
            },
            closeOnClickOutside: false,
          }).then((value) => {
            if (value === "OK") {
              history.push('/login')
            }
          });
          setData(initState);
        }
        setLoading(false);
      }).catch(error => {
        swal("", error?.response?.data?.Message, "error");
        setLoading(false);
      })
    }
  }

  return (
    <>
      {loading && <Loading />}
      <div className='resetPswdWrap'>
        <div className='resetPswdInner text-center'>
          <img src={IMG_URL+"white-logo.png"} alt='whiteLogo' className='logoImg img-fluid' />
          <div className='resetPswdBlockwrap'>
            <div className='resetPswdBlockInner'>
              <Form onSubmit={handleSubmit} autoComplete='off'>
                <div className='boxWrap'>
                  <div className='boxwrapInner'>
                    <h2 className='accountTitle text-center'>Reset your password</h2>
                    <p>Enter and confirm your new password below.</p>
                    <FormGroup className='text-left position-relative'>
                      <Label className='customLabel'>New Password <span className='requiredField'>*</span></Label>
                      <Input
                        required
                        minLength={8}
                        className="form-control"
                        placeholder='Enter new password'
                        type={showNewPswd ? 'text' : 'password'}
                        name='newPswd'
                        value={data.newPswd || ''}
                        onChange={handleChange}
                      />
                      <FontAwesomeIcon
                        icon={showNewPswd ? faEye : faEyeSlash}
                        onClick={() => setShowNewPswd(!showNewPswd)}
                        style={{
                          position: "absolute",
                          right: "13px",
                          top: "41px",
                        }}
                      />
                    </FormGroup>
                    <FormGroup className='text-left position-relative'>
                      <Label className='customLabel'>Confirm Password <span className='requiredField'>*</span></Label>
                      <Input
                        required
                        minLength={8}
                        className="form-control"
                        placeholder='Enter confirm password'
                        type={showconfirmPswd ? 'text' : 'password'}
                        name='confirmPswd'
                        value={data.confirmPswd || ''}
                        onChange={handleChange}
                      />
                      <FontAwesomeIcon
                        icon={showconfirmPswd ? faEye : faEyeSlash}
                        onClick={() => setShowconfirmPswd(!showconfirmPswd)}
                        style={{
                          position: "absolute",
                          right: "13px",
                          top: "41px",
                        }}
                      />
                    </FormGroup>
                  </div>
                  <CommonButton
                    type='submit'
                    name='Reset & Save'
                    btnWidth='320px'
                    btnHeight='54px'
                    btnClass='primaryBtn resetBtn'
                    // onClick={handleVerifyClick}
                  />
                </div>

              </Form>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default ResetPassword
