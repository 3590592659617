export const CommonMessages = {
    // Error
    recoveryEmailError: 'Please insert valid recovery email address format.',
    emailError: 'Please insert valid email address format.',
    passwordMatchError: 'Password and confirm password both should match!',
    schoolNameMatchError: 'Please select Appropriate School Name!',
    // userDetailMatchError: 'You are currently not in our system. Please contact your principal to help activate the account. We will also follow up with your school to assist.',
    // 'Your First Name and Last Name does not match with your Student Id. Please check your information or contact your counselor',
    // userAlreadyExistError: 'Already your account has been established. Please login.',
    userAlreadyRegistered: 'User Already Registered !!',
    noDataFound: 'No data found!!',

    // Success
    userDetailMatchSuccess: 'Go to Next', // on student register next click
    studentRegSuccess: 'Student Registered Successfully', // student account create
    // ParentRegSuccess: 'Parent Registered Successfully', // parent account create

    // Academics, Athletics, Activity, Interest
    curriculumSaved: 'Curriculum Saved.',
    interestSaved: 'Interest Saved.',
    academicSaved: 'Academics Saved.',
    activitySaved: 'Activity Saved.',
    athleticsSaved: 'Athletics Saved.',
    ladderSaved: 'College Checklist Saved.',  // college checklist


}