import React,{ useState, useEffect, Fragment} from 'react';
import { Modal, ModalBody } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';
import QueryService from '../../services/QueryService';
import { useHistory } from 'react-router';

const ConfirmationDialog=({isOpen , 
                           setIsOpen , 
                           query, 
                           inputValue, 
                           from , 
                           setInputValue , 
                           getSchoolQueryPage,
                           setQuery,
                           openErrorModal,
                           setOpenErrorModal
                    })=>{
    let history=useHistory();

    const saveForSiembra=async()=>{
        let user = await JSON.parse(localStorage.getItem('user'));
        let obj={
            college_id: user && user.college_id,
            creator_id: user && user.id,
            id: from ==="schoolQuery" ? query : "",
            is_global: true,
            name: inputValue,
            query: from === "schoolQuery" ? [] : [null , ...query],
            type: "recruiter.simple_query",
        }
        QueryService.saveQueryForCollege(obj)
        .then(data => {
            setIsOpen(false)
            if(from === "schoolQuery"){
                setInputValue('');
                getSchoolQueryPage();
            }else {
                history.push('/recruiter/search-query')
            }
            
        })
        .catch(error => {
            console.error("error from the service", error.response)
            if(error && error.response && error.response.status === 409){
                  setIsOpen(false);
                  setInputValue('');
                  setOpenErrorModal(true);
                 
            }
        })
    }
      return(
          <Fragment>
              <Modal
                isOpen={isOpen}
                toggle={() => setIsOpen(false)}
                //centered={true}
            >
                <ModalBody>
                    <div style={{ justifyContent: 'space-between', padding:'30px', alignItems:'start', display:'flex' }}>
                        <div><p style={{fontSize:'16px', fontWeight:'bold', wordBreak:'break-all'}} className="no-margin-bottom color">{`Are You sure you want to save "New Query" as a siembra query? It will be visible and usable by user at any college`}</p></div>
                        <div onClick={() => setIsOpen(false)} className="cursor">
                            <CloseIcon />
                        </div>
                    </div>
                    <div className="flex" style={{ justifyContent: 'space-evenly', padding:'0 0 30px' }}>
                        <button
                            className="modal-button-active"
                            onClick={saveForSiembra}
                        >
                            Yes
                        </button>
                        <button className="modal-button-inactive" onClick={() => setIsOpen(false)}>
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>
          </Fragment>
      )
}
export default ConfirmationDialog