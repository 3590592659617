import { request } from "./Request";
import { routes } from "../constants/constant.route";
import Storage from "./Storage";
import { urlConstants } from "../utils/urlConstants";

const {
  LOGIN,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  PASSWORD_VERIFY_TOKEN,
  VERIFY_OTP,
  RESET_OTP,
} = routes.AUTH;
const {API_URL} = urlConstants;

export default class AuthService {
  static isAuthenticated() {
    const tokenDetail = Storage.getTokenDetail();
    if (tokenDetail && tokenDetail.accessToken) {
      return true;
    }
    return false;
  }

  static updateProfile(body){
    return request('PUT', `${API_URL}${routes.updateProfile.UPDATE_PROFILE}` , null , body , null, null);
}

  static login(loginData) {
    return request("POST", `${API_URL}${LOGIN}`, null, loginData, null);
  }

  static verifyToken(obj) {
    return request(
      "POST",
      `${API_URL}${PASSWORD_VERIFY_TOKEN}`,
      null,
      obj,
      null
    );
  }

  // send email to reset password
  static resetPassword(body) {
    return request("POST", `${API_URL}${RESET_PASSWORD}`, null, body, null);
  }

  // Change password
  static changePassword(body) {
    return request("POST", `${API_URL}${CHANGE_PASSWORD}`, null, body, null);
  }


  static forgotPassword(body) {
    return request("POST", `${API_URL}${FORGOT_PASSWORD}`, null, body, null);
  }

  
  static verifyOTP(body) {
    return request("POST", `${API_URL}${VERIFY_OTP}`, null, body, null);
  }
  static resetOTP(body) {
    return request("POST", `${API_URL}${RESET_OTP}`, null, body, null);
  }

  static logout() {
    Storage.removeTokenCookie();
  }
}
