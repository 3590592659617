import { textAlign } from "@mui/system";
import React, { Fragment, useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import MsgDataService from "../../../services/MsgService";

const CannedModal = ({ open, close, handleCannedMessageSelect }) => {
  const [cannedMessagedata, setCannedMessageData] = useState([]);
  const [page, setPage] = useState(1);
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );

  const getCannedMessage = () => {
    let obj = {
      school_id:user?.school_id,
      page:page,
    }
    MsgDataService.getCannedMsg(obj)
      .then((data) => {
        console.log("data from the service", data);
        setCannedMessageData(data?.data?.result);
      })
      .catch((error) => {
        console.log("error from the service", error);
      });
  };
  useEffect(() => {
    getCannedMessage();
  }, []);
  return (
    <Fragment>
      <Modal
        className="save-modal"
        wrapClassName="modal-dialog"
        contentClassName="modal-dialog"
        isOpen={open}
      >
        <ModalHeader className="modal-header">
          <p>Select Message</p>
        </ModalHeader>
        <ModalBody>
          {cannedMessagedata?.map((item, index) => (
            <div
              onClick={() => handleCannedMessageSelect(item)}
              className="canned-message-container"
            >
              <p
                style={{ fontSize: "18px", color: "#333", marginBottom: "5px" }}
              >
                {item?.messages?.en?.subject}
              </p>
              <p style={{ fontSize: "14px", color: "#555" }}>
                {item?.messages?.en?.body}
              </p>
            </div>
          ))}
          <button
            style={{
              outline: "none",
              border: "none",
              padding: "7px",
              borderRadius: "5px",
              marginTop: "10px",
              textAlign: "right",
            }}
            onClick={close}
          >
            Cancel
          </button>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
export default CannedModal;
