import React, { Fragment, useState, useEffect } from 'react';
import CustomLoader from '../customLoader/CustomLoader';
import MessageService from '../../services/MsgService';
import DeleteModal from '../../components/deleteModal/DeleteModal';
import MessageTemplateService from '../../services/MessageTemplateService';
import CustomPagination from '../pagination/Pagination';
import { useSelector } from "react-redux";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const MessageTemplate = () => {
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState();
    //const [user, setUser] = useState(localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')));
    const user = useSelector((state) => state.UserReducer?.userObj);
    const [page, setPage] = useState(1);
    const [subjectValue, setSubjectValue] = useState("");
    const [languageSelectedFromList , setLanguageSelectedFromList] = useState('');
    const [editValue, setEditValue] = useState();
    const [bodyMessageValue, setBodyMessageValue] = useState('');
    const [languageSelect, setLanguageSelect] = useState('en');
    const [includeFeedBack, setIncludeFeedback] = useState(false);
    const [openDeleteModal, setOpendeleteModal] = useState(false);
    const [feedbackValue , setFeedBackValue] = useState();
    const [showBodyTranslating, setShowBodyTranslating] = useState(false);
    const [showSubjectTranslating, setShowSubjectTranslating] = useState(false);
    const [bodyValueInSpanish, setBodyValueInSpanish] = useState();
    const [subjectValueInSpanish, setSubjectValueInSpanish] = useState();
    const [feedBackValueInSpanish, setFeedBackValueInSpanish] = useState();

    const getPageData = () => {
        setLoading(true);
    
        const fetchPageData = user?.role === 'recruiter' 
            ? MessageService.getCannedMsgPage(user?.role, user?.college, page) 
            : MessageService.getCannedMsgPage(user?.role, user?.school_id, page);
    
        fetchPageData
            .then((data) => {
                console.log("Data from the service:", data);
                setPageData(data.data);
                setLoading(false);
                setIncludeFeedback(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error from the service:", error);
            });
    };
    
    const handleSelect =  (item , language) => {
        console.log('editValue ==>')
        if( language === 'en'){
            setEditValue(item)
            setSubjectValue(item?.messages?.en?.subject);
            setBodyMessageValue(item?.messages?.en?.body);
            // setIncludeFeedback(true)
            setLanguageSelect('en');
            setLanguageSelectedFromList('en')
        }else if(language === 'es'){
            console.log("es from the es", item)
            setEditValue(item)
            setSubjectValue(item?.messages?.es?.subject);
            setBodyMessageValue(item?.messages?.es?.body);
           // setIncludeFeedback(true)
            setLanguageSelect('es');
            setLanguageSelectedFromList('es')
        }
        
    }

    const handleLanguageSelect = (e) => {
        setLanguageSelect(e.target.value)
    }

    const handleInputChange = (e) => {
        if (e.target.name === "Subject") {
            setSubjectValue(e.target.value)
        } else if (e.target.name === "Body-Message") {
            setBodyMessageValue(e.target.value)
        } else if (e.target.name === "IncludeFeedback") {
            setIncludeFeedback(!includeFeedBack)
        }
    }


    const deleteTemplate = () => {
        setOpendeleteModal(true)
    }

    const saveTemplateForEdit=()=>{
        if(subjectValue && subjectValue.trim() && bodyMessageValue && bodyMessageValue.trim()){
        setShowSubjectTranslating(true);
        setShowBodyTranslating(true)
        let objForSubject={
            text:subjectValue && subjectValue,
            to:'es'
        }
        let objForBody={
            text:bodyMessageValue && bodyMessageValue,
            to:'es'
        }
        MessageTemplateService.translateTemplate(objForSubject)
        .then(async(data) => {
            console.log('data from the message template service',  data);
            await setShowSubjectTranslating(false);
            await setSubjectValueInSpanish(data.data.tr)
        }).
        catch(error => {
            console.log(error);
            setShowSubjectTranslating(false)
        })

        MessageTemplateService.translateTemplate(objForBody)
        .then(async(data) => {
            console.log('data from the service', data);
            await setShowBodyTranslating(false);
            await setBodyValueInSpanish(data.data.tr);
        })
        .catch(error => { 
            console.log("error from the service", error);
            setShowBodyTranslating(false)
        })
        }
        if(feedbackValue && feedbackValue.trim()){
            let objForFeedback={
                text:feedbackValue && feedbackValue,
                to:'es'
            }
            MessageTemplateService.translateTemplate(objForFeedback)
            .then(data => {
                console.log('data from the service', data);
                setFeedBackValueInSpanish(data.data.tr)
                setShowBodyTranslating(false)
            })
            .catch(error => { 
                console.log("error from the service", error);
                setShowBodyTranslating(false)
            }) 
        }
    };

    const saveTemplateForCreatingNew=()=>{
        if(subjectValue && subjectValue.trim() && bodyMessageValue && bodyMessageValue.trim()){
        setShowSubjectTranslating(true);
        setShowBodyTranslating(true)
        let objForSubject={
            text:subjectValue && subjectValue,
            to:'es'
        }
        let objForBody={
            text:bodyMessageValue && bodyMessageValue,
            to:'es'
        }
        MessageTemplateService.translateTemplate(objForSubject)
        .then(data => {
            console.log('data from the message template service',  data);
            setShowSubjectTranslating(false);
            setSubjectValueInSpanish(data.data.tr)
        }).
        catch(error => {
            console.log(error);
            setShowSubjectTranslating(false)
        })

        MessageTemplateService.translateTemplate(objForBody)
        .then(data => {
            console.log('data from the service', data);
            setShowBodyTranslating(false);
            setBodyValueInSpanish(data.data.tr);
        })
        .catch(error => { 
            console.log("error from the service", error);
            setShowBodyTranslating(false)
        })
        }
        if(feedbackValue && feedbackValue.trim()){
            let objForFeedback={
                text:feedbackValue && feedbackValue,
                to:'es'
            }
            MessageTemplateService.translateTemplate(objForFeedback)
            .then(data => {
                console.log('data from the service', data);
                setFeedBackValueInSpanish(data.data.tr)
                setShowBodyTranslating(false)
            })
            .catch(error => { 
                console.log("error from the service", error);
                setShowBodyTranslating(false)
            }) 
        }
    };

    const getCannedTemplateMessageAfterNew=()=>{
        console.log("getting called")
        if(!(subjectValueInSpanish && bodyValueInSpanish)) return 
        let body={}
        let answers={
            1:{
                ack: 0,
                ackError: true
            },
            text:{
               en:feedbackValue && feedbackValue,
               es:feedBackValueInSpanish && feedBackValueInSpanish
            },
            textError:false
        };
        let messages={
            en:{
                body:bodyMessageValue,
                subject:subjectValue
            },
            es:{
                body:bodyValueInSpanish,
                subject:subjectValueInSpanish
            }
        }
        body.answers = answers
        body.college = user && user.college
        body.messages=messages;

        MessageTemplateService.getCannedMessageForTemplateAfterCreatingNew(body)
        .then(data => {
            console.log("data from the canned template", data);
            setLanguageSelect('en');
            setSubjectValue('');
            setBodyMessageValue('');
            getPageData()
        })
        .catch(error => {
            console.log("error form the service", error)
        })
    }

    const getCannedTemplateMessageAfterEdit=()=>{
        if(!(subjectValueInSpanish && bodyValueInSpanish)) return 
        let body={}
        let answers={
            1:{
                ack: 0,
                ackError: true
            },
            text:{
               en:feedbackValue && feedbackValue,
               es:feedBackValueInSpanish && feedBackValueInSpanish
            },
            textError:false
        };
        let messages={
            en:{
                body:bodyMessageValue,
                subject:subjectValue
            },
            es:{
                body:bodyValueInSpanish,
                subject:subjectValueInSpanish
            }
        }
        body.answers = answers
        body.college = user && user.college;
        body.messages=messages;
        body.id=editValue && editValue.id;

        MessageTemplateService.getCannedMessageForTemplateAfterEditing(body)
        .then(data => {
            console.log("data from the canned template", data);
            setSubjectValue();
            setBodyMessageValue();
            setIncludeFeedback(false);
            setEditValue()
            getPageData()        
        })
        .catch(error => {
            console.log("error form the service", error)
        })
    }
    useEffect(() => {
        getPageData();
    }, [page])
 
    useEffect(()=>{ 
        if(editValue){
            getCannedTemplateMessageAfterEdit()
        }else{
            getCannedTemplateMessageAfterNew();
        }    
    },[(subjectValueInSpanish && bodyValueInSpanish) , feedBackValueInSpanish ]);

    console.log("subjectValeINSpanish", subjectValueInSpanish , bodyValueInSpanish, feedBackValueInSpanish)
    return (
          <Fragment>
        <AppHeader />
        <div className="app-main">
          <AppSidebar />
          <div className="app-main__outer">
            {loading ? <CustomLoader /> : <div style={{ height: '100%' }}>
                <div>
                    <p style={{ fontSize: '18px', padding: '10px' }} className="title-container bold no-margin-bottom">
                        MESSAGE TEMPLATES
                    </p>
                </div>
                <div style={{ margin: '0px 25px' }}>
                    <div style={{ borderBottom: '1px solid #333' }} className="flex">
                        <img style={{ width: '52px', height: '52px', margin: '20px' }} src={IMG_URL+"mail.jpg"} alt="Message" />
                        <div>
                            <p className="no-margin-bottom" style={{ fontSize: '24px', color: '#333' }}>{pageData && pageData.result && pageData.result.length}</p>
                            <span style={{ fontSize: '14px' }}>MESSAGE TEMPLATE</span>
                        </div>
                    </div>
                    <div className="template-grid-container">
                        <div>
                            {pageData && pageData.result && pageData.result.map((item, index) => (
                                <>
                                    <div className="single-template-container">
                                        <p className="subject-text">{item?.messages?.en?.subject}</p>
                                        <div className="flex">
                                            <p onClick={() => handleSelect(item , 'en')}>en</p>
                                            <p onClick={() => handleSelect(item, 'es')} style={{ marginLeft: '10px' }}>es</p>
                                        </div>
                                    </div>
                                    <div className="template-separator" />
                                </>
                            ))}
                        </div>
                        <div style={{ borderTop: '1px solid black', borderBottom: '1px solid black', height: '100%', background: 'var(--background-color)' }}>
                            <div>
                                <div style={{ borderBottom: '2px solid black', padding: '5px' }}>
                                    <span style={{ fontSize: '14px', fontWeight: 'bold', margin: '10px' }}>CREATE NEW MESSAGE TEMPLATES</span>
                                </div>
                                <div style={{ padding: '15px' }}>
                                    <select disabled={languageSelectedFromList === 'es'} className="language-select" onChange={(e) => handleLanguageSelect(e)} value={languageSelect}>
                                        <option className="select-option" selected hidden disabled >Language Preference</option>
                                        <option value="en">en</option>
                                        <option value="es">es</option>
                                    </select>
                                    <div style={{ marginTop: '10px' }}>
                                        <input
                                            name="Subject"
                                            className="subject-input"
                                            type="Subject"
                                            placeholder="Subject"
                                            value={subjectValue}
                                            disabled={languageSelectedFromList === 'es'}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                    </div>
                                    {showSubjectTranslating && <div><p style={{color:'#737373', fontSize:'17px'}}>Translating ...</p></div>}
                                    <div style={{ marginTop: '10px' }}>
                                        <textarea
                                            row="10"
                                            col="50"
                                            name="Body-Message"
                                            style={{ height: '90px' }}
                                            className="subject-input"
                                            type="Body Message"
                                            placeholder="Body Message"
                                            value={bodyMessageValue}
                                            disabled={languageSelectedFromList === 'es'}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                    </div>
                                    {showBodyTranslating && <div><p style={{color:'#737373', fontSize:'17px'}}>Translating ...</p></div>}
                                    <div className="flex" style={{ background: 'white', width: '100%', alignItems: 'center', padding: '5px 0px 20px 0px', borderBottom: '1px solid black', marginTop: '10px' }}>
                                        <input style={{ marginRight: '10px', marginLeft: '10px' }} type="checkbox" name="IncludeFeedback" value={includeFeedBack} checked={includeFeedBack} onChange={(e)=>handleInputChange(e)} />
                                        <p style={{ fontSize: '18px' }} className="no-margin-bottom bold">Include feedback response</p>
                                    </div>
                                    {includeFeedBack && <div style={{ width: '100%', background: 'white', borderBottom: '1px solid #000' }}>
                                        <p className="bold no-margin-bottom" style={{ color: '#b0a8a8', fontSize: '12px', padding: '10px' }}>Feedback</p>
                                        <input type="text" className="feedback-input" onChange={(e)=>handleInputChange(e)} value={feedbackValue} style={{ fontSize: '16px', padding: '0px 10px' }} />
                                    </div>}
                                </div>
                                <div className="flex" style={{ justifyContent: 'space-between', padding: '15px 15px' }}>
                                    <button onClick={editValue ? saveTemplateForEdit :  saveTemplateForCreatingNew} className="save-template-button">SAVE TEMPLATE </button>
                                    <button onClick={deleteTemplate} className="delete-template-button">DELETE TEMPLATE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <CustomPagination pageValue={page} setPage={setPage} count={pageData}/>
                </div>
            </div>
            }
            {
                openDeleteModal && <DeleteModal
                    deleteValue={editValue && editValue}
                    openDeleteModal={openDeleteModal}
                    setOpenDeleteModal={setOpendeleteModal}
                    setDeleteValue={setEditValue}
                    getQueryPage={getPageData}
                    setBodyMessageValue={setBodyMessageValue}
                    setSubjectValue={setSubjectValue}
                    setIncludeFeedback={setIncludeFeedback}
                    setFeedBackValue={setFeedBackValue}
                    setSubjectValueInSpanish={setSubjectValueInSpanish}
                    setBodyValueInSpanish={setBodyValueInSpanish}
                    setFeedBackValueInSpanish={setFeedBackValueInSpanish}
                    from="message-template"
                />
            }
 </div>
    </div>
  </Fragment>    )
}
export default MessageTemplate