import React from 'react';
import './CommonButton.scss';

const CommonButton = ({ btnClass, type, onClick, disabled, btnWidth, btnHeight, name, children }) => {
    return (
        <button
            id='commonButton'
            className={`commonBtn ${btnClass || ''}`}
            type={type || 'button'}
            onClick={onClick}
            disabled={disabled}
            style={{
                width: btnWidth || '175px',
                minHeight: btnHeight || '40px',
                opacity: disabled ? 0.5 : 1 // Apply opacity based on disabled prop
            }}
        >
            {children || name || ''}
        </button>
    )
}

export default CommonButton
