import React, { Fragment, useState, useEffect, useRef } from "react";
import { TrendingUpRounded } from "@mui/icons-material";
import Loader from "react-loader-spinner";
import CustomLoader from "../../customLoader/CustomLoader";
import GetAdminService from "../../../services/admin/GetAdminService";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import StudentImportConfirmation from "../../counselor/studentImportConfirmationModal/StudentImportConfirmationModal";
import Storage from "../../../services/Storage";
import { Table } from "reactstrap";
const API_URL = process.env.REACT_APP_API_URL;
let obj1 = {};
obj1.activeCounselorsMonth = 0;
obj1.activeSchoolsMonth = 0;
obj1.messageSentMonth = 0;
obj1.activeCounselorsWeek = 0;
obj1.activeSchoolsWeek = 0;
obj1.messageSentWeek = 0;
obj1.numStudents = 0;
obj1.numActiveStudents = 0;
obj1.numOptedInStudents = 0;

const Statistics = () => {
  const [loadingForCsvDownload, setLoadingForCsvDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState(obj1);

  // const dowload_csv = (res) => {
  //   var result_csv =
  //     "School,Counselor,Total Students,Active Students,Student Opted In,Message 7 Days,Messages 30 days\n";

  //   for (var schoolName in res.schools) {
  //     if (res.schools.hasOwnProperty(schoolName)) {
  //       for (var counselorName in res.schools[schoolName]) {
  //         if (res.schools[schoolName].hasOwnProperty(counselorName)) {
  //           var counselor = res.schools[schoolName][counselorName];
  //           result_csv += schoolName;
  //           result_csv += ",";
  //           result_csv += counselor.name;
  //           result_csv += ",";
  //           result_csv += counselor.numStudents;
  //           result_csv += ",";
  //           result_csv += counselor.numActiveStudents;
  //           result_csv += ",";
  //           result_csv += counselor.numOptedInStudents;
  //           result_csv += ",";
  //           result_csv += counselor.messageSentWeek;
  //           result_csv += ",";
  //           result_csv += counselor.messageSentMonth;
  //           result_csv += "\n";
  //         }
  //       }
  //     }
  //   }
  //   var saving = document.createElement("a");
  //   saving.href = "data:attachment/csv," + encodeURIComponent(result_csv);
  //   saving.download = "csv_statistics_download.csv";
  //   document.body.appendChild(saving);
  //   saving.click();
  // };

  const downloadFile = (downloadURL, fileName) => {
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.download = fileName;
    downloadLink.href = downloadURL;
    downloadLink.click();
}


  const downLoadCsvTemplate = () => {
    setLoadingForCsvDownload(true)
    GetAdminService.getStudentStatisticsExcel()
      .then((data) => {
        console.log("data",data?.data?.download)
        downloadFile(data?.data?.download);
        setLoadingForCsvDownload(false)
      })
      .catch((error) => {
        console.log("error from the service", error);
        setLoadingForCsvDownload(false)
      });
  };

  const getStatisticsData = () => {
    // var activeCounselorsMonth = 0;
    // var activeSchoolsMonth = 0;
    // var messageSentMonth = 0;
    // var activeCounselorsWeek = 0;
    // var activeSchoolsWeek = 0;
    // var messageSentWeek = 0;
    // var numStudents = 0;
    // var numActiveStudents = 0;
    // var numOptedInStudents = 0;
    setLoading(true);
    GetAdminService.getStudentStatistics()
      .then((data) => {
        setLoading(false);
        // let res = ;
        // for (var schoolName in res.schools) {
        //   if (res.schools.hasOwnProperty(schoolName)) {
        //     var school = res.schools[schoolName];
        //     var schoolMonthActive = false;
        //     var schoolWeekActive = false;
        //     for (var counselorName in school) {
        //       if (school.hasOwnProperty(counselorName)) {
        //         var counselor = school[counselorName];
        //         if (counselor.messageSentMonth > 0) {
        //           activeCounselorsMonth++;
        //           schoolMonthActive = true;
        //         }
        //         if (counselor.messageSentWeek > 0) {
        //           activeCounselorsWeek++;
        //           schoolWeekActive = true;
        //         }
        //         messageSentMonth =
        //           messageSentMonth + counselor.messageSentMonth;
        //         messageSentWeek = messageSentWeek + counselor.messageSentWeek;
        //         if (counselor.name == "ALL") {
        //           numStudents = numStudents + counselor.numStudents;
        //           numActiveStudents =
        //             numActiveStudents + counselor.numActiveStudents;
        //           numOptedInStudents =
        //             numOptedInStudents + counselor.numOptedInStudents;
        //         }
        //       }
        //     }
        //     if (schoolMonthActive) {
        //       activeSchoolsMonth++;
        //     }
        //     if (schoolWeekActive) {
        //       activeSchoolsWeek++;
        //     }
        //   }
        // }
        // let obj = {};
        // obj.activeCounselorsMonth = activeCounselorsMonth;
        // obj.activeSchoolsMonth = activeSchoolsMonth;
        // obj.messageSentMonth = messageSentMonth;
        // obj.activeCounselorsWeek = activeCounselorsWeek;
        // obj.activeSchoolsWeek = activeSchoolsWeek;
        // obj.messageSentWeek = messageSentWeek;
        // obj.numStudents = numStudents;
        // obj.numActiveStudents = numActiveStudents;
        // obj.numOptedInStudents = numOptedInStudents;
        setStatistics(data?.data?.response);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error from the service", error);
      });
  };

  useEffect(() => {
    getStatisticsData();
  }, []);

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          {loading ? (
            <CustomLoader />
          ) : (
            <div className="csv-import-container">
              <div className="flex canned-message-heading">
                <p>
                  <span style={{ fontSize: "30px", fontWeight: "400" }}>
                    Statistics
                  </span>
                  <span
                    style={{
                      color: "#777777",
                      fontSize: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    Usage Statistics for College Connect
                  </span>
                </p>
                <button
                  onClick={() => downLoadCsvTemplate()}
                  className="import-csv-button"
                >
                  Download Detailed Statistics
                  {loadingForCsvDownload && (
                    <span>
                      <Loader
                        type="TailSpin"
                        color="white"
                        height={20}
                        width={20}
                      />
                    </span>
                  )}
                </button>
              </div>
              <div style={{ margin: "20px 10px 10px 10px" }}>
                <h3
                  style={{
                    textAlign: "center",
                    width: "50%",
                    marginTop: "10px",
                  }}
                >
                  Global Statistics
                </h3>
                <Table
                  bordered
                  style={{
                    width: "50%",
                    background: "white",
                    marginBottom: "30px",
                  }}
                >
                  <tbody>
                    <tr>
                      <th>% Students Signed Up</th>
                      <td>{statistics?.percentage_active_students || 0}%</td>
                    </tr>
                    <tr>
                      <th>Total Number of Students</th>
                      <td>{statistics?.total_students}</td>
                    </tr>
                    <tr>
                      <th># Active Students</th>
                      <td>{statistics?.total_active_students}</td>
                    </tr>
                    <tr>
                      <th># Students Opted In</th>
                      <td>{statistics?.total_opted_in_students}</td>
                    </tr>
                  </tbody>
                </Table>
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "5px",
                    width: "50%",
                  }}
                >
                  <h3>Monthly Statistics</h3>
                  Statistics over the last 30 days.
                </div>

                <Table bordered style={{ width: "50%", background: "white", marginBottom:"30px" }}>
                  <tbody>
                    <tr>
                      <th># Active Counselors</th>
                      <td>{statistics?.active_counselor_last_month}</td>
                    </tr>
                    <tr>
                      <th># Active Schools</th>
                      <td>{statistics?.active_school_in_last_month}</td>
                    </tr>
                    <tr>
                      <th># Message Sent</th>
                      <td>{statistics?.sent_message_in_last_month}</td>
                    </tr>
                  </tbody>
                </Table>
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "5px",
                    width: "50%",
                  }}
                >
                  <h3>Weekly Statistics</h3>
                  Statistics over the last 7 days.
                </div>
                <Table bordered style={{ width: "50%", background: "white" }}>
                  <tbody>
                    <tr>
                      <th># Active Counselors</th>
                      <td>{statistics?.active_counselor_last_week}</td>
                    </tr>
                    <tr>
                      <th># Active Schools</th>
                      <td>{statistics?.active_school_in_last_week}</td>
                    </tr>
                    <tr>
                      <th># Message Sent</th>
                      <td>{statistics?.sent_message_in_last_week}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Statistics;